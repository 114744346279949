angular.module('everon.component.data-list-pagination')
       .controller('DataListPaginationController', DataListPaginationController);

DataListPaginationController.$inject = ['CONSTANTS'];

function DataListPaginationController(CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.pageSizes = CONSTANTS.PAGINATION.SIZES;
        $ctrl.pages = getPagesArray();
        $ctrl.itemsRange = getItemsRange();
    };

    $ctrl.$onChanges = changes => {
        if (!changes.pagination.isFirstChange()) {
            $ctrl.pages = getPagesArray();
            $ctrl.itemsRange = getItemsRange();
        }
    };

    function getPagesArray() {
        return new Array($ctrl.pagination.totalPages);
    }

    function getItemsRange() {
        const from = $ctrl.pagination.number ? ($ctrl.pagination.size * $ctrl.pagination.number) + 1 : 1;
        const to = $ctrl.pagination.last ? $ctrl.pagination.totalElements : from + $ctrl.pagination.size - 1;

        return {
            from,
            to,
            total: $ctrl.pagination.totalElements
        };
    }
}
