angular.module('everon.users.system-users.groups')
       .factory('userGroupsService', userGroupsService);

userGroupsService.$inject = ['$http', '$q', 'searchMixin'];

function userGroupsService($http, $q, searchMixin) {
    const API_URL = '/api/platform/user-groups';

    return _.assign({}, searchMixin, {
        /**
         * Returns a list of user groups
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getGroups(params) {
            const defaultParams = {
                page: 0,
                size: 30,
                sort: 'name,asc'
            };

            return $http.get(API_URL, {params: _.defaults(params, defaultParams)});
        },

        /**
         * Create a group with users and a manager
         * @param {Object} payload
         * @returns {Promise.<Array>}
         */
        createGroup(payload) {
            return $http.post(API_URL, payload);
        },

        /**
         * Create a group with users and a manager
         * @param {string} groupName
         * @returns {Promise.<Array>}
         */
        verifyGroup(groupName) {
            return $http.get(`${API_URL}/verification`, {params: {name: groupName}});
        }

    });
}
