angular.module('everon.cards.card.billing-plan.change.overview')
       .controller('CardBillingPlanOverviewController', CardBillingPlanOverviewController);

CardBillingPlanOverviewController.$inject = ['cardService', 'accountService', 'CONSTANTS', 'mediatorService', 'subscriptionService', 'utils'];

function CardBillingPlanOverviewController(cardService, accountService, CONSTANTS, mediatorService, subscriptionService, utils) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        const {
            card,
            subscription,
            selectedProduct
        } = $ctrl.cardBillingPlanChangeCtrl.payload;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            termsAgreed: false,
            saveInProgress: false,
            isAccountOwner: false
        };

        if (card.eMI3ContractId) {
            card.legacyContractId = card.contractId;
            card.contractId = card.eMI3ContractId;
        }

        if (subscription.plan.total > selectedProduct.plan.total) {
            $ctrl.planStartDate = utils.toLocalMoment(subscription.renewalDate || subscription.endedAt).toDate();
            $ctrl.planRenewalDate = utils.getMoment($ctrl.planStartDate).add(selectedProduct.plan.billingInterval, 'months').toDate();
        } else {
            $ctrl.planRenewalDate = selectedProduct.plan.renewalDate;
        }

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.previous = () => {
        $ctrl.cardBillingPlanChangeCtrl.previous();
    };

    $ctrl.next = () => {
        const {
            card: {id: cardId},
            selectedProduct: {
                plan: {
                    id: planId
                },
                purchaseOrderNumber
            }
        } = $ctrl.cardBillingPlanChangeCtrl.payload;

        $ctrl.state.saveInProgress = true;

        subscriptionService.updateCardSubscription(cardId, {planId, purchaseOrderNumber})
                           .then($ctrl.cardBillingPlanChangeCtrl.next)
                           .catch(response => {
                               mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                   type: 'error',
                                   isPersistent: true,
                                   messageKey: CONSTANTS.ERROR_CODES[response.status]
                               });
                           })
                           .finally(() => {
                               $ctrl.state.saveInProgress = false;
                           });
    };

    function onDataLoaded({billing, isAccountOwner}) {
        $ctrl.billing = billing;
        $ctrl.state.dataLoaded = true;
        $ctrl.state.isAccountOwner = isAccountOwner;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
