angular.module('everon.tenant-settings.notifications')
       .controller('NotificationsController', NotificationsController);

NotificationsController.$inject = ['CONSTANTS', 'mediatorService', 'tenantSettingsService'];

function NotificationsController(CONSTANTS, mediatorService, tenantSettingsService) {
    const $ctrl = this;
    let modelCopy;
    const formName = 'notificationsForm';

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            hasChanged: false,
            saveInProgress: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.onChange = () => {
        $ctrl.state.hasChanged = hasModelChanged();
    };

    $ctrl.cancel = () => {
        resetState();
    };

    $ctrl.save = () => {
        $ctrl.state.saveInProgress = true;

        tenantSettingsService.saveNotificationSettings($ctrl.model)
                             .then(() => {
                                 mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                     type: 'success',
                                     messageKey: 'tenantSettings.notifications.notification.success'
                                 });

                                 modelCopy = _.assign({}, $ctrl.model);
                                 resetState();
                             })
                             .catch(response => {
                                 mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                     type: 'error',
                                     isPersistent: true,
                                     messageKey: CONSTANTS.ERROR_CODES[response.status]
                                 });
                             })
                             .finally(() => {
                                 $ctrl.state.saveInProgress = false;
                             });
    };

    /**
     * Resets to initial state
     */
    function resetState() {
        resetForm();
        $ctrl.model = _.assign({}, modelCopy);
        $ctrl.state.hasChanged = false;
    }

    /**
     * Resets form to its initial state
     */
    function resetForm() {
        $ctrl[formName].$setPristine();
        $ctrl[formName].$setUntouched();
    }

    /**
     * Checks whether the the model has changed or not
     * @returns {boolean}
     */
    function hasModelChanged() {
        return JSON.stringify($ctrl.model) !== JSON.stringify(modelCopy);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.model = data;
        modelCopy = _.assign({}, data);
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
