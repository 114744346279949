angular.module('everon.component.breadcrumb')
       .controller('BreadcrumbController', BreadcrumbController);

BreadcrumbController.$inject = [];

function BreadcrumbController() {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.breadcrumbs = $ctrl.items.map(extendItem);
    };

    /**
     * Adds `srefOpts` to item object
     * @param {Object} item
     * @returns {Object}
     */
    function extendItem(item) {
        return _.assign({srefOpts: {reload: item.state}}, item);
    }
}
