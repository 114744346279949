angular.module('everon.stations.station-add.station-overview')
       .controller('StationAddOverviewController', StationAddOverviewController);

StationAddOverviewController.$inject = ['$q', 'stationService', 'accountService', 'CONSTANTS', 'mediatorService', 'modalService', 'mapService'];

function StationAddOverviewController($q, stationService, accountService, CONSTANTS, mediatorService, modalService, mapService) {
    const $ctrl = this;
    let modal;
    const CANCELLED_MODAL = 'CANCELLED_MODAL';

    $ctrl.$onInit = () => {
        $ctrl.state = {
            saveInProgress: false,
            termsAgreed: false
        };

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController',
            data: {
                title: 'generic.title.partialActivation',
                paragraph: 'stations.stationActivate.partialActivationModal.paragraph',
                translateValues: {
                    // Handle cases when billing was removed from station and it's being accessed
                    email: $ctrl.stationAddCtrl.payload.accountOwnerEmail ? $ctrl.stationAddCtrl.payload.accountOwnerEmail : ''
                },
                button: {
                    ok: 'generic.action.sendLink'
                }
            }
        }).then(modalInstance => {
            modal = modalInstance;
        });
    };

    $ctrl.previous = () => {
        $ctrl.stationAddCtrl.previous();
    };

    $ctrl.next = () => {
        $ctrl.state.saveInProgress = true;

        $ctrl.selectStationActivationMethod()
             .then(station => $ctrl.stationAddCtrl.next({
                 stationId: station.id,
                 wifiSupported: station.wifiSupported,
                 wifiSetupEnabled: station.wifiSetupEnabled,
                 gsmSupported: station.gsmSupported,
                 manufacturer: station.manufacturer
             }))
             .catch(response => {
                 // If there was a modal and the user hits cancel, the modal promise will reject.
                 // We need to catch that here, but there is no problem so we just bail without showing an error.
                 if (response.message === CANCELLED_MODAL) {
                     return;
                 }

                 mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                     type: 'error',
                     isPersistent: true,
                     messageKey: CONSTANTS.ERROR_CODES[response.status]
                 });
             })
             .finally(() => {
                 $ctrl.state.saveInProgress = false;
             });
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    $ctrl.selectStationActivationMethod = () => {
        switch (true) {
            case $ctrl.stationAddCtrl.state.canActivateStationOnBehalf:
                return activateStationPendingConfirmation();
            case $ctrl.stationAddCtrl.state.isConfirmingActivation:
                return confirmStationActivation();
            default:
                return saveBillingAndActivateStation();
        }
    };

    /**
     * Filters out unused properties from our station object when activating on behalf so it can be sent to the API
     * @returns {Object}
     */
    function transformStationActivatedOnBehalfForApi() {
        return _($ctrl.stationAddCtrl.payload.station).pick(['identityCode', 'securityCode', 'reference', 'accountToActivateOn', 'features'])
                                                      .assign({
                                                          planId: $ctrl.stationAddCtrl.payload.selectedProduct.plan.id,
                                                          purchaseOrderNumber: $ctrl.stationAddCtrl.payload.selectedProduct.purchaseOrderNumber
                                                      }).value();
    }

    /**
     * Filters out unused properties from our station object so it can be sent to the API
     * @returns {Object}
     */
    function transformStationForApi() {
        return _.assign(transformLocationForApi($ctrl.stationAddCtrl.payload.station), {
            planId: $ctrl.stationAddCtrl.payload.selectedProduct.plan.id,
            purchaseOrderNumber: $ctrl.stationAddCtrl.payload.selectedProduct.purchaseOrderNumber
        });
    }

    /**
     * Transforms the station's location object so it can be sent to the API
     * @param {Object} stationPayload
     * @returns {Object}
     */
    function transformLocationForApi(stationPayload) {
        let geoCoordinatesPayload = null;

        if (_.has(stationPayload.location.geoCoordinates, 'geoCoordinates')) {
            const [lat, lng] = mapService.getLatLngFromGeoCoordinatesString(stationPayload.location.geoCoordinates.geoCoordinates);

            geoCoordinatesPayload = {lat, lng};
        }

        stationPayload.location.geoCoordinates = geoCoordinatesPayload;
        stationPayload.location.address = _.has(stationPayload.location.address, 'country') ? stationPayload.location.address : null;

        return _.omit(stationPayload, ['accountNameToActivateOn', 'assignToExisting']);
    }

    /**
     * Saves billing information and activates a station
     * @returns {Promise}
     */
    function saveBillingAndActivateStation() {
        const station = transformStationForApi();
        const billingData = _($ctrl.stationAddCtrl.payload.billing).omit('billingContact')
                                                                   .assign({invoiceLanguage: $ctrl.stationAddCtrl.payload.billing.invoiceLanguage.tag})
                                                                   .value();

        return accountService.saveBillingInfo(billingData)
                             .then(() => stationService.activateStation(station));
    }

    /**
     * Checks that the user wants to activate a station on behalf of another user and if so, partially activates it
     * @returns {Promise}
     */
    function activateStationPendingConfirmation() {
        return modal.open()
                    .then(canProceed => {
                        if (canProceed) {
                            return activateStationOnBehalf();
                        }

                        return $q.reject(new Error(CANCELLED_MODAL));
                    });
    }

    /**
     * Activates station on behalf of another user
     * @returns {Promise}
     */
    function activateStationOnBehalf() {
        const station = transformStationActivatedOnBehalfForApi();

        return stationService.activateStationOnBehalf(station);
    }

    /**
     * Confirms that a station which was activated on behalf of the current user is good
     * @returns {Promise}
     */
    function confirmStationActivation() {
        const station = transformLocationForApi($ctrl.stationAddCtrl.payload.station);
        const {id, location} = station;
        const data = {activationToken: $ctrl.stationAddCtrl.payload.activationToken, location};

        return stationService.confirmStationActivation(id, data);
    }
}
