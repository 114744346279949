angular.module('everon.component.donut-chart')
       .controller('DonutChartController', DonutChartController);

DonutChartController.$inject = ['$window', '$timeout'];

function DonutChartController($window, $timeout) {
    const $ctrl = this;
    const circumference = 125;

    $ctrl.$onInit = () => {
        $ctrl.model = buildChart($ctrl.data);
    };

    $ctrl.$onChanges = changes => {
        if (!changes.data.isFirstChange()) {
            $ctrl.model = buildChart(changes.data.currentValue);
        }
    };

    function buildChart(data = []) {
        const total = data.reduce((sum, segment) => sum + segment.value, 0);

        // We need the percentage
        data.forEach(segment => {
            segment.value = _.round(total ? (segment.value * 100 / total) : segment.value, 2);
        });

        $ctrl.chartModel = {
            r: circumference / (2 * $window.Math.PI),
            c: circumference,
            segments: data.slice(0, 3) // Due to the limited space, we can only display 3 segments at most currently
        };

        // We updated on the next tick to animate the segments
        $timeout(() => {
            let totalPercent = 0;

            $ctrl.chartModel.segments.forEach((segment, index) => {
                segment.percentage = (segment.value / 100) * circumference;
                segment.offset = -circumference;

                if (index > 0) {
                    segment.offset = -totalPercent;
                }

                totalPercent += segment.percentage;
            });
        }, 0);

        return data;
    }
}
