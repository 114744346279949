angular.module('everon.landing.login-oauth')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.landing.login-oauth', {
        url: '/__oauth__',
        data: {
            title: 'landing.login.title',
            sourceState: null,
            sourceParams: null
        },
        params: {
            activationCompleted: false,
            activationExpired: false,
            registrationSent: false
        },
        resolve: {
            data: ['tenantSettingsService', tenantSettingsService => tenantSettingsService.getSupportLinks()]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@anon.landing': {
                component: 'evcloudLoginOAuth',
                bindings: {
                    dataPromise: 'data'
                }
            }
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/landing/login-oauth' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.landing.login-oauth');
        }
    });
}
