angular.module('everon.roaming.locations')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.locations', {
        url: '/locations?connectionId&query&sort&page&size',
        params: {
            sort: {
                value: 'externalId,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'roaming-locations-navigation-link',
            title: 'roaming.locations.title',
            menuPriority: 4,
            navigationType: ['secondary'],
            permissions: ['ROAMING:READ']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/locations' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.locations');
        },
        resolve: {
            data: ['$q', 'roamingService', '$transition$', ($q, roamingService, $transition$) => {
                const params = _.pick($transition$.params('to'), ['connectionId', 'sort', 'query', 'page', 'size']);

                return $q.all({
                    locations: roamingService.getLocations(params),
                    connections: roamingService.getConnectionsByType('CPO')
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                template: '<evcloud-locations data-data-promise="::$resolve.data" class="flex-auto flex" />'
            }
        }
    });
}
