angular.module('everon.welcome')
       .factory('welcomeService', welcomeService);

welcomeService.$inject = ['$q', 'accountFormModel', 'accountService', 'countryService'];

function welcomeService($q, accountFormModel, accountService, countryService) {
    /**
     * Returns only `billingAddress` part of account data object
     * @param {Object} account
     * @returns {Object}
     */
    function getAddressPartial(account) {
        return _.pick(account, 'billingAddress');
    }

    return {
        getAddressPartial() {
            return $q.all({
                accountFormModel,
                account: accountService.getBillingInfo()
                                       .then(getAddressPartial),
                availableCountries: countryService.getTenantCountries()
            });
        },

        getAccountData() {
            return $q.all({
                accountFormModel,
                account: accountService.getBillingInfo(),
                countries: countryService.getCountries()
            });
        }
    };
}
