angular.module('everon.profile')
       .controller('ProfileController', ProfileController);

ProfileController.$inject = ['$q', '$translate', '$state', 'localisationService', 'mediatorService', 'CONSTANTS', 'profileService', 'userService', 'sessionService', 'modalService', 'permissionService', 'validationService'];

function ProfileController($q, $translate, $state, localisationService, mediatorService, CONSTANTS, profileService, userService, sessionService, modalService, permissionService,
                           validationService) {
    const $ctrl = this;
    let passwordConfirmationModal;
    let blockingConfirmationModal;
    let deleteConfirmationModal;
    let previousAccountStatus;
    const comparisonFields = ['firstName', 'lastName', 'language', 'passwordLastChanged', 'email', 'id', 'roles', 'lastLoginAt', 'createdAt', 'invitedBy', 'password'];

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.profileCopy = null;

        $ctrl.roles = [];

        $ctrl.state = {
            dataLoaded: false,
            isCurrentUser: false,
            hasChanged: false,
            emailPasswordVisible: false,
            passwordVisible: false,
            confirmPasswordVisible: false,
            saveInProgress: false,
            deleteInProgress: false,
            saveStatusInProgress: false,
            canEditUserRoles: false,
            isPendingActivation: false,
            canSeeUserRoles: checkPermissions(['SYSTEM_USER:READ_ALL']) || checkPermissions(['CUSTOMER:READ_ALL']),
            canBlockProfile: false,
            canDeleteProfile: false,
            isEditingTenantAdmin: false,
            isUserTenantAdmin: false
        };

        $ctrl.accountStatuses = ['unblocked', 'blocked'];

        $ctrl.accountStatus = null;

        $ctrl.passwordStrength = -1;

        modalService.register({
            templateUrl: 'modules/profile/password-confirmation-modal/password-confirmation-modal.html',
            controller: 'PasswordConfirmationModalController',
            data: {
                title: 'profile.form.heading.confirmChanges'
            }
        }).then(modalInstance => {
            passwordConfirmationModal = modalInstance;
        });

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController',
            data: {
                title: 'profile.form.heading.confirmBlockingStatusChange',
                button: {
                    ok: 'generic.action.save'
                }
            }
        }).then(modalInstance => {
            blockingConfirmationModal = modalInstance;
        });

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController',
            data: {
                title: 'profile.form.action.deleteUser',
                paragraph: 'profile.form.helpText.deleteUserDescription',
                translateValues: {tenantName: location.host},
                button: {
                    ok: 'profile.form.action.deleteUser'
                },
                isDistractive: true
            }
        }).then(modalInstance => {
            deleteConfirmationModal = modalInstance;
        });

        $ctrl.dataPromise.then(onDataLoaded);

        $ctrl.asyncPasswordStrengthValidatorConfig = {
            name: 'passwordStrength',
            validatorFn: password => {
                if (!password) {
                    $ctrl.passwordStrength = -1;

                    return $q.resolve();
                }

                return validationService.validatePasswordStrength(password).then(({score}) => {
                    $ctrl.passwordStrength = score;

                    return score < CONSTANTS.PASSWORD_STRENGTH_MIN_LEVEL ? $q.reject() : $q.resolve();
                });
            }
        };
    };

    /**
     * Triggered when form elements change
     */
    $ctrl.onChange = () => {
        setChanged(true);
    };

    $ctrl.onRolesChange = () => {
        $ctrl.profile.roles = Object.keys(_.pickBy($ctrl.selectedRoles, 'selected'));

        setChanged(true);
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(passwordConfirmationModal);
        modalService.destroy(blockingConfirmationModal);
    };

    /**
     * Checks if the state has changed and opens confirmation modal in case of email or password change
     */
    $ctrl.save = () => {
        if (!$ctrl.state.hasChanged) {
            restoreModel();
            resetFormState();

            return;
        }

        const profile = getChangedModel();

        // Show password confirmation dialog to confirm current user profile changes
        if ($ctrl.state.isCurrentUser && profile.email || profile.password) {
            passwordConfirmationModal.open()
                                     .then(_.partialRight(onPasswordModalClosed, profile));
        } else {
            $ctrl.saveProceed(profile);
        }
    };

    /**
     * Saves updated user profile
     * @param {Object} profile
     * @returns {Promise|undefined}
     */
    $ctrl.saveProceed = profile => {
        if (profile.language) {
            profile.languageTag = profile.language.tag;
        }

        profile = _(profile).omit(['confirmPassword', 'language', ...$ctrl.roles])
                            .pickBy(_.identity)
                            .value();

        $ctrl.state.saveInProgress = true;

        return ($ctrl.state.isCurrentUser ? profileService.updateProfile(profile) : userService.updateUser($ctrl.profile.id, profile)).then(onFulfilled)
                                                                                                                                      .catch(onRejected)
                                                                                                                                      .finally(() => {
                                                                                                                                          $ctrl.state.saveInProgress = false;
                                                                                                                                      });
    };

    $ctrl.cancel = () => {
        restoreModel();
        resetFormState();
    };

    $ctrl.delete = id => {
        deleteConfirmationModal.open().then(ok => handleDelete(ok, id));
    };

    $ctrl.togglePasswordVisibility = type => {
        $ctrl.state[type] = !$ctrl.state[type];
    };

    $ctrl.toggleStatus = () => {
        setChanged(false);
        blockingConfirmationModal.open({
            paragraph: `profile.form.helpText.${$ctrl.accountStatus}ProfileDescription`
        }).then(handleAccountStatusChange);
    };

    $ctrl.resendInvitation = () => {
        sessionService.requestActivation($ctrl.profile.email)
                      .then(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              buttonText: 'generic.action.ok',
                              messageKey: 'profile.notification.invitationSucceeded'
                          });
                      })
                      .catch(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {type: 'error'});
                      });
    };

    function handleDelete(confirmed, id) {
        if (confirmed) {
            $ctrl.state.deleteInProgress = true;
            profileService.deleteProfile(id)
                          .then(() => {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'success',
                                  buttonText: 'generic.action.ok',
                                  messageKey: 'profile.notification.userDeleted'
                              });
                              $state.go('^');
                          })
                          .catch(() => mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {type: 'error'}))
                          .finally(() => {
                              $ctrl.state.deleteInProgress = false;
                          });
        }
    }

    function handleAccountStatusChange(changeConfirmed) {
        if (changeConfirmed) {
            $ctrl.state.saveStatusInProgress = true;
            profileService.updateProfileStatus($ctrl.profile.id, $ctrl.accountStatus.slice(0, -2)).then(() => {
                mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                    type: 'success',
                    buttonText: 'generic.action.ok',
                    messageKey: `profile.notification.account${_.capitalize($ctrl.accountStatus)}`
                });
                previousAccountStatus = $ctrl.accountStatus;
                $state.go('^', {highlightItem: $ctrl.profile.id});
            }).catch(() => {
                mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {type: 'error'});
                $ctrl.accountStatus = previousAccountStatus;
            }).finally(() => {
                $ctrl.state.saveStatusInProgress = false;
            });
        } else {
            $ctrl.accountStatus = previousAccountStatus;
        }
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        const identityChanged = hasIdentityChanged();

        $ctrl.state.hasChanged = hasChanged && identityChanged;

        if (!identityChanged) {
            $ctrl.profileForm.$setPristine();
        }
    }

    /**
     * Checks whether the user profile the same as it was on controller initialisation
     * @returns {boolean}
     */
    function hasIdentityChanged() {
        return $ctrl.profileCopy !== JSON.stringify(_.pick($ctrl.profile, comparisonFields));
    }

    /**
     * Successful user profile update callback
     * @param {Object} profile
     * @returns {Object}
     */
    function onFulfilled(profile) {
        syncModel(profile);
        resetFormState();

        if ($ctrl.state.isCurrentUser) {
            handleLanguageChange(profile).then(() => mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {type: 'success'}));

            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.PROFILE_CHANGE, profile);
        } else {
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {type: 'success'});
        }

        dispatchContextTitle();

        return profile;
    }

    /**
     * Unsuccessful user profile update callback
     * @param {Object} response
     */
    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: (response.data.message === 'Incorrect password') ?
                'profile.form.error.incorrectPassword' :
                (response.status === 409 ? 'profile.form.error.emailAddressTaken' : (CONSTANTS.ERROR_CODES[response.status] || CONSTANTS.ERROR_CODES[500]))
        });
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl.profileForm.$setUntouched();
        $ctrl.profileForm.$setPristine();
        setChanged(false);
    }

    /**
     * Reverts changes to profile
     */
    function restoreModel() {
        $ctrl.profile = JSON.parse($ctrl.profileCopy);
        $ctrl.selectedRoles = mapUserRoles($ctrl.profile.roles);
    }

    /**
     * Updates the language on the fly for currently logged in user
     * @param {Object} profile
     * @returns {Promise}
     */
    function handleLanguageChange(profile) {
        // Translate on the fly
        return $translate.use(profile.language.tag)
                         .then(() => {
                             // Localise date and time
                             localisationService.setLanguage(profile.language.tag)
                                                .localiseDateTime();
                         });
    }

    /**
     * Returns a key/value pair control object
     * @param {Object} control
     * @returns {Array}
     */
    function getControlPair(control) {
        return [control.$name, control.$modelValue];
    }

    function applyRolesChanges(updates) {
        const selectedRoles = _.sortBy($ctrl.profile.roles);
        const userRoles = _.sortBy(JSON.parse($ctrl.profileCopy).roles);

        return _.isEqual(selectedRoles, userRoles) ? updates : _.assign(updates, {roles: selectedRoles});
    }

    /**
     * Returns changed form data
     * @returns {Object}
     */
    function getChangedModel() {
        return _($ctrl.profileForm.$$controls).filter('$dirty')
                                              .map(getControlPair)
                                              .fromPairs()
                                              .thru(applyRolesChanges)
                                              .value();
    }

    /**
     * If no data provided, return immediately. Otherwise, extend profile with data from modal and proceed with save
     * @param {Object} data
     * @param {Object} profile
     */
    function onPasswordModalClosed(data, profile) {
        if (!data) {
            return;
        }

        _.assign(profile, data);
        $ctrl.saveProceed(profile);
    }

    function syncModel(profile) {
        // We wanted to handle strings status instead of boolean here to make easier the presentation in the template
        const profileTransformed = Object.assign({}, profile, {password: ''});

        $ctrl.profile = _.pick(profileTransformed, comparisonFields);
        $ctrl.profileCopy = JSON.stringify($ctrl.profile);
    }

    function mapUserRoles(userRoles) {
        return userRoles.reduce((selectedRoles, role) => _.assign(selectedRoles, {
            [role]: {
                selected: true,
                disabled: !_.includes($ctrl.availableRoles, role)
            }
        }), {});
    }

    function dispatchContextTitle() {
        const {firstName, lastName} = $ctrl.profile;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, $ctrl.state.isCurrentUser ? ['current'] : (firstName ? ['parent', `${firstName} ${lastName}`] : ['parent']));
    }

    /**
     * Assign controller states based on available roles of a user profile
     * @param {Array} availableRoles
     * @param {Object} profile
     */
    function assignUserRoles(availableRoles, profile) {
        if (availableRoles) {
            availableRoles = profile.roles.includes('tenantAdmin') ? availableRoles.filter(role => !['accountAdmin', 'accountStationAdmin'].includes(role)) : availableRoles;

            $ctrl.availableRoles = availableRoles;
            $ctrl.selectedRoles = mapUserRoles(profile.roles);
            $ctrl.roles = userService.sortUserRoles([...profile.roles, ...availableRoles]);
            $ctrl.state.canEditUserRoles = Boolean(availableRoles.length);
        }
    }

    /**
     * Updates state and makes data available to the template. Account Owner and Driver roles cannot be changed so they are read-only
     * @param {Array} data
     */
    function onDataLoaded({profile, languages, availableRoles}) {
        $ctrl.languages = languages.slice();

        assignUserRoles(availableRoles, profile);

        $ctrl.accountStatus = profile.blocked ? 'blocked' : 'unblocked';
        previousAccountStatus = $ctrl.accountStatus;

        syncModel(profile);

        const userProfile = sessionService.getUserProfile();

        $ctrl.state.isCurrentUser = profile.id === userProfile.id;
        $ctrl.state.isPendingActivation = !profile.activated;
        $ctrl.state.dataLoaded = true;
        $ctrl.state.canDeleteProfile = !$ctrl.state.isCurrentUser && permissionService.resolve(userProfile.permissions, ['CUSTOMER:DELETE']) && userProfile.accountId !== profile.accountId;
        $ctrl.state.canBlockProfile = $ctrl.showAccountStatus && profile.activated && !$ctrl.state.isCurrentUser &&
            profileService.hasPrivilegeToBlockAccount(userProfile, profile);
        $ctrl.state.isEditingTenantAdmin = profile.roles.includes('tenantAdmin');
        $ctrl.state.isUserTenantAdmin = userProfile.roles.includes('tenantAdmin');

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        dispatchContextTitle();
    }
}
