angular.module('everon.cards.card.billing-plan.change.overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card.billing-plan.change.overview', {
        data: {
            title: 'generic.title.overview',
            order: 1
        },
        resolve: {
            data: ['$q', 'accountService', 'permissionService', 'sessionService', ($q, accountService, permissionService, sessionService) => {
                const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);
                const isAccountOwner = checkPermissions(['CARD:READ']) && !checkPermissions(['CARD:ENTER_RAW']);

                return $q.all({
                    isAccountOwner,
                    billing: isAccountOwner ? accountService.getBillingInfo() : null
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'section@auth.cards.card.billing-plan.change': {
                component: 'evcloudCardBillingPlanOverview',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
