angular.module('everon.landing.activation')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.landing.activation', {
        url: '/activation/:code',
        data: {
            title: 'landing.activation.title'
        },
        resolve: {
            activation: ['$state', '$transition$', 'sessionService', ($state, $transition$, sessionService) => {
                return sessionService.activateAccount({code: $transition$.params('to').code})
                                     .finally(() => $state.go('anon.landing.login', {activationCompleted: true}, {location: 'replace'}));
            }]
        }
    });
}
