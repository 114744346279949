angular.module('everon', [
    'pascalprecht.translate',
    'ngAria',
    'ngSanitize',
    'ngMessages',
    'ngCookies',
    'oc.lazyLoad',

    'everon.core',
    'everon.components',
    'everon.global',
    'everon.dashboard',
    'everon.landing',
    'everon.welcome',
    'everon.account-recovery',
    'everon.logout',
    'everon.tenant-settings',
    'everon.cards',
    'everon.stations',
    'everon.profile',
    'everon.account',
    'everon.support',
    'everon.forbidden',
    'everon.branding',
    'everon.roaming',
    'everon.billing-plans',
    'everon.map',
    'everon.accounts',
    'everon.users'
]);
