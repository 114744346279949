angular.module('everon.account-recovery.password-reset')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.account-recovery.password-reset', {
        url: '/password-reset/:code',
        data: {
            title: 'accountRecovery.passwordReset.title'
        },
        onEnter: ['$transition$', 'sessionService', 'mediatorService', 'CONSTANTS', '$q', ($transition$, sessionService, mediatorService, CONSTANTS, $q) => {
            const toParams = $transition$.params('to');

            if (!toParams.code) {
                return $q.reject({message: 'Password reset code is missing'});
            }

            return sessionService.verifyPasswordResetCode(toParams.code)
                                 .catch(response => {
                                     mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                         type: 'error',
                                         messageKey: 'accountRecovery.passwordReset.notification.codeInvalid'
                                     });

                                     return $q.reject(response);
                                 });
        }],
        views: {
            'content@anon.account-recovery': {
                template: '<evcloud-password-reset />'
            }
        }
    });
}
