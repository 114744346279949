/**
 * Accordion - The accordion pattern in a simple way with a smooth slide
 * <evcloud-accordion> will start expanded if the tabpanel has aria-expanded set to true
 * optionally evcloud-accordion can have an expanded attr being written from a parent $ctrl and will react to changes
 *
 * @example
 *
 *  <li evcloud-accordion>
 *      <header role="tab">
 *          Accordion title
 *      </header>
 *      <section role="tabpanel"
 *               aria-expanded="false">
 *                   Accordion content
 *      </section>
 *  </li>
 */
angular.module('everon.component.accordion')
       .directive('evcloudAccordion', accordion);

accordion.$inject = ['$window', 'modernizr'];

function accordion($window, modernizr) {
    return {
        restrict: 'A',
        link: (scope, element, attrs) => {
            const duration = 180;
            const accordion = element[0];
            let isInitialised = false;
            const accordionHeader = accordion.querySelector('[role=tab]');
            const accordionBody = accordion.querySelector('[role=tabpanel]');
            let expanded = accordionBody.getAttribute('aria-expanded') === 'true';

            /*
             * FIXME:
             * The observer shouldn't be on the non-existing 'expanded' attribute, it should be on the 'ariaExpanded' attribute, because that's the one we use anyway.
             * Somehow this observer does work when we set the value on the 'data-expanded' attribute. Angular magic?
             * In the charging-profile-form template we actually use the 'data-expanded' attribute, to trigger this observer. But there 'aria-expended' attribute is also set.
             * Keep this in mind when refactoring.
             */
            attrs.$observe('expanded', value => {
                const isExpanded = value === 'true';
                const timeout = isInitialised ? 100 : 300; // Open accordion takes longer to pre-render on page refresh so we increase the timeout

                isInitialised = true;

                if (isExpanded === expanded) {
                    return;
                }

                $window.setTimeout(slideY, timeout);
            });

            const options = modernizr.passiveeventlisteners ? {capture: false} : false;

            if (expanded) {
                setAccordionAttributes(true);
            }

            if (accordionHeader && !attrs.expanded) {
                accordionHeader.addEventListener('click', slideY, options);
            }

            if (accordionHeader) {
                accordionHeader.addEventListener('keypress', checkKeyCode, options);
            }

            /**
             * Checks if keyCode is "Return"
             * @param {Object} event
             */
            function checkKeyCode(event) {
                if (13 === event.keyCode) {
                    slideY();
                }
            }

            /**
             * Handles the animation
             */
            function slideY() {
                const accordionHeaderHeight = accordionHeader ? accordionHeader.offsetHeight : 0;

                // pre-render class will allow the element to render in the background
                accordionBody.classList.add('pre-render');

                // We can then learn the accordion body height
                const accordionBodyHeight = accordionBody.offsetHeight;

                // The accordion body is then pulled up
                accordionBody.style.transform = `translate3d(0,${-accordionBodyHeight}px,0)`;
                accordionBody.classList.remove('pre-render');

                if (!expanded) {
                    // We mark the element as visible, for accessibility, also used as css selector
                    setAccordionAttributes(true);

                    // The transition is then bootstrapped by setting the height
                    accordion.style.height = `${accordionHeaderHeight}px`;
                    accordionBody.style.height = `${accordionBodyHeight}px`;

                    // At the next tick we 'release' the element, the accordion height and transform are animated together
                    $window.requestAnimationFrame(() => {
                        accordionBody.style.transform = 'translate3d(0,0,0)';
                        accordion.style.height = `${accordionHeaderHeight + accordionBodyHeight}px`;

                        $window.setTimeout(removeStyles, duration);
                    });
                } else {
                    accordion.style.height = `${accordion.offsetHeight}px`;

                    $window.requestAnimationFrame(() => {
                        // To revert the transition(slideUp) we just set the accordion height to header height
                        // height is animated and takes the body up with it
                        accordion.style.height = `${accordionHeaderHeight}px`;

                        $window.setTimeout(() => {
                            setAccordionAttributes(false);
                            removeStyles();
                        }, duration);
                    });
                }

                expanded = !expanded;
            }

            /**
             * Sets specific attributes on accordion elements
             * @param {boolean} value
             */
            function setAccordionAttributes(value) {
                accordionBody.setAttribute('aria-expanded', value);
                accordionHeader && accordionHeader.setAttribute('aria-selected', value);
            }

            function removeStyles() {
                accordion.removeAttribute('style');
                accordionBody.removeAttribute('style');
            }

            scope.$on('$destroy', () => {
                if (accordionHeader) {
                    accordionHeader.removeEventListener('click', slideY, options);
                    accordionHeader.removeEventListener('keypress', checkKeyCode, options);
                }
            });
        }
    };
}
