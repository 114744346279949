angular.module('everon.stations.station.charging-profile')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.charging-profile', {
        url: '/charging-profile',
        data: {
            id: 'station-charging-profile-navigation-link',
            title: 'generic.title.chargingProfile',
            navigationType: ['context'],
            menuPriority: 60,
            permissions: ['STATION:UPDATE', 'CHARGING_PROFILE:READ']
        },
        resolve: {
            data: ['$q', 'data', 'stationService', 'chargingProfilesService', 'accountService', ($q, data, stationService, chargingProfilesService, accountService) => {
                return $q.all({
                    station: stationService.getStation(data.id),
                    chargingProfiles: stationService.getAvailableChargingProfilesForStation(data.id),
                    translatedGroupNames: chargingProfilesService.translateChargingProfileGroupNames(data.accountName),
                    accountFeatures: accountService.getFeatureFlags()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-charging-profile data-data-promise="::$resolve.data" />'
            }
        }
    });
}
