/**
 * Adds a loading bar if loading takes more than half a second.
 *
 * The loading bar background color is inherit from the evcloud-loader element.
 *
 * @example
 * <evcloud-loader class="bg-brand-1" loading="{{!$ctrl.state.dataLoaded}}"></evcloud-loader>
 */
angular.module('everon.component.loader')
       .directive('evcloudLoader', loader);

loader.$inject = [];

function loader() {
    return {
        restrict: 'E',
        scope: {
            loading: '@'
        },
        link(scope, element, attributes) {
            attributes.$observe('loading', onChange);

            /**
             * Waits for half a second to make the loader visible when starting to load and
             * hides is right away when loading is over by setting `role` and `aria-busy` attributes
             * @param {string} value
             */
            function onChange(value) {
                const isLoading = value === 'true';

                element.attr('role', isLoading ? 'progressbar' : '');
                element.attr('aria-busy', isLoading);
            }
        }
    };
}
