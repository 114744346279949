import vueModules from './../../../index-vue';
import {$ng} from '../../../angular-injector';
import getStore from './store';

export default async function createVueApp() {
    const {Vue, i18n} = await vueModules;

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development') {
        // Enable devtools before create the app
        Vue.config.devtools = true;
    }

    /* eslint-disable-next-line no-new */
    const app = new Vue({
        el: '#cpo-dashboard',

        components: {
            EvoCpoDashboard: () => import(/* webpackChunkName: 'app/stations/cpo-dashboard' */ './cpo-dashboard.vue'),
            GoBackLink: () => import(/* webpackChunkName: 'app/stations/go-back-link' */ './go-back-link.vue')
        },

        data: {},

        i18n,

        store: getStore($ng)
    });

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development') {
        window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app.constructor;
    }

    return app;
}
