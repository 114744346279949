angular.module('everon.tenant-settings.companies')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.companies', {
        url: '/companies?sort',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'companies-navigation-link',
            title: 'generic.entity.legalEntities',
            menuPriority: 2,
            navigationType: ['secondary']
        },
        resolve: {
            data: ['tenantCompanyService', tenantCompanyService => tenantCompanyService.getCompanies()]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.tenant-settings': {
                component: 'evcloudCompanies',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
