angular.module('everon.stations.station-add.station-plan')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add-on-behalf.station-plan', {
        data: {
            title: 'generic.title.billingPlan',
            order: 1
        },
        params: {
            country: null,
            identityCode: null,
            accountId: null
        },
        resolve: {
            data: ['$transition$', 'stationService', function ($transition$, stationService) {
                const {country, identityCode, accountId} = $transition$.params('to');

                return stationService.getProductCatalog(country, identityCode, accountId);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station-add-on-behalf': {
                template: '<evcloud-station-add-plan data-data-promise="::$resolve.data" class="flex flex-column overflow-hidden col-12"/>'
            }
        }
    });
}
