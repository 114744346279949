export const $ng = serviceName => angular.element(document.body)
                                         .injector()
                                         .get(serviceName);

export default {
    install(Vue) {
        Vue.directive('evo-route', (el, {value: {href, params = {}, absolute = true}}) => {
            const $state = $ng('$state');

            if ($state) {
                el.href = $state.href(href, params, {absolute});
            }
        });

        Vue.prototype.$ng = $ng;
    }
};
