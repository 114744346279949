angular.module('everon.accounts.account.overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.accounts.account.overview', {
        url: '/overview',
        data: {
            id: 'account-overview-navigation-link',
            title: 'generic.title.overview',
            navigationType: ['context'],
            menuPriority: 1
        },
        resolve: {
            data: ['$q', '$transition$', 'accountOwner', 'accountService', 'permissionService', 'sessionService', 'stationService',
                ($q, $transition$, accountOwner, accountService, permissionService, sessionService, stationService) => {
                    const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);
                    const {id} = $transition$.params('to');

                    return $q.all({
                        accountOwner,
                        billingOverview: accountService.getBillingOverview(id),
                        users: checkPermissions(['CUSTOMER:READ_ALL', 'SYSTEM_USER:READ_ALL', 'ACCOUNT:READ_PERSONAL_INFO']) ? accountService.getAccountUsers({accountId: id}) : {},
                        cards: checkPermissions(['CARD:READ_ALL']) ? accountService.getAccountCards({accountId: id}) : {},
                        stations: checkPermissions(['STATION:READ_ALL']) ? accountService.getAccountStations({accountId: id, status: 'active'})
                                                                                         .then(accountService.transformAccountStations)
                                                                                         .then(({numberOfElements, totalElements, content}) => $q.all({
                                                                                             numberOfElements,
                                                                                             totalElements,
                                                                                             stations: content,
                                                                                             statuses: stationService.getStationStatus(content)
                                                                                         }).then(accountService.mergeStationStatus)) : {}
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.accounts.account': {
                template: '<evcloud-account-overview data-data-promise="::$resolve.data" />'
            }
        }
    });
}
