angular.module('everon.stations', [
    'everon.stations.cpo-dashboard',
    'everon.stations.charging-profiles',
    'everon.stations.list',
    'everon.stations.station',
    'everon.stations.station-add',
    'everon.stations.station-import-details-form',
    'everon.stations.station-location-form',
    'everon.stations.station-import-csv',
    'everon.stations.sites'
]);
