angular.module('everon.cards.card-import-csv')
       .controller('CardImportCsvController', CardImportCsvController);

CardImportCsvController.$inject = ['CONSTANTS', 'mediatorService', '$translate', 'cardService'];

function CardImportCsvController(CONSTANTS, mediatorService, $translate, cardService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.importConfig = {
            buttonText: 'generic.action.import',
            errorText: 'generic.error.importFailed',
            validationMessages: {
                400: 'generic.importCsv.notification.noHeader',
                413: 'generic.importCsv.notification.tooManyRows',
                417: 'generic.importCsv.notification.invalidRecords'
            },
            url: '/api/tokens/import',
            mimeTypes: CONSTANTS.MIME_TYPES.CSV,
            onUpload: onImport,
            onError
        };

        $ctrl.sortingParams = {property: 'row', order: 'asc'};

        $ctrl.columns = [
            {
                property: 'row',
                translationKey: 'generic.importCsv.list.column.row',
                desc: true,
                sortable: true,
                active: true,
                cssClass: 'col-3 sm-col-2'
            },
            {
                property: 'field',
                translationKey: 'generic.importCsv.list.column.field',
                desc: true,
                sortable: true,
                active: false,
                cssClass: 'col-5 sm-col-4'
            },
            {
                property: 'translatedReason',
                translationKey: 'generic.importCsv.list.column.reason',
                desc: true,
                sortable: true,
                active: false,
                cssClass: 'col-4 sm-col-6'
            }
        ];

        $ctrl.csvExample = [
            {
                name: 'RFID',
                example: '12345ABCDE'
            },
            {
                name: 'Contract ID',
                example: 'NL-EVB-C00123456-K'
            }
        ];

        $ctrl.errors = null;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    };

    /**
     * Makes re-sorted data available to the template
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = sortingParams => {
        $ctrl.sortingParams = sortingParams;
        $ctrl.errors = cardService.orderBy($ctrl.errors, $ctrl.sortingParams);
    };

    /**
     * If file was imported successfully, we reset previous errors and close error notification
     */
    function onImport() {
        $ctrl.errors = null;
        mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.CLOSE_NOTIFICATION);
    }

    /**
     * In case of missing headers, the server returns 400 with JSON object in the response. Hence, we check if response is an array
     * @param {Array|Object} response
     */
    function onError(response) {
        $ctrl.errors = Array.isArray(response) ? cardService.orderBy(response.map(extendError), $ctrl.sortingParams) : null;
    }

    /**
     * Extends error object with uuid and a the translated reason for the template
     * @param {Object} error
     * @returns {Object}
     */
    function extendError(error) {
        return _.assign({
            uuid: _.uniqueId('error-'),
            translatedReason: $translate.instant(error.reason === 'invalidFormat' ? `cards.cardImportCsv.list.errors.${error.reason}` : `generic.importCsv.list.errors.${error.reason}`, {
                field: error.field
            })
        }, error);
    }
}
