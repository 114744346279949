angular.module('everon.component.product-catalog')
       .component('evcloudProductCatalog', {
           templateUrl: 'components/product-catalog/product-catalog.html',
           controller: 'ProductCatalogController',
           bindings: {
               products: '<',
               selectedProduct: '<',
               currentSubscription: '<?',
               onSelect: '&'
           }
       });
