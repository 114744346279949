angular.module('everon.dashboard')
       .controller('DashboardController', DashboardController);

DashboardController.$inject = ['sessionService', 'permissionService', 'mediatorService', 'CONSTANTS', 'dashboardService', 'stationService', '$log'];

function DashboardController(sessionService, permissionService, mediatorService, CONSTANTS, dashboardService, stationService, $log) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            canImportCard: checkPermissions(['CARD:IMPORT']),
            canActivateCard: checkPermissions(['CARD:ACTIVATE']),
            canActivateCardOnBehalf: checkPermissions(['CARD:ACTIVATE_ON_BEHALF']),
            canImportStation: checkPermissions(['STATION:IMPORT']),
            canActivateStation: checkPermissions(['STATION:ACTIVATE']),
            canActivateStationOnBehalf: checkPermissions(['STATION:ACTIVATE_ON_BEHALF']),
            loadingData: true
        };

        $ctrl.actions = [
            {
                name: 'cards.action.import',
                state: 'auth.cards.card-import',
                icon: 'import',
                isAuthorised: $ctrl.state.canImportCard
            },
            {
                name: 'cards.action.importCsvDashboard',
                state: 'auth.cards.card-import-csv',
                icon: 'import',
                isAuthorised: $ctrl.state.canImportCard
            },
            {
                name: 'cards.action.activate',
                state: 'auth.cards.card-add.card-details',
                icon: 'cards',
                isAuthorised: $ctrl.state.canActivateCard && !$ctrl.state.canActivateCardOnBehalf
            },
            {
                name: 'cards.action.activate',
                state: 'auth.cards.card-add-on-behalf.card-details',
                icon: 'cards',
                isAuthorised: $ctrl.state.canActivateCardOnBehalf
            },
            {
                name: 'stations.action.import',
                state: 'auth.stations.station-import',
                icon: 'import',
                isAuthorised: $ctrl.state.canImportStation
            },
            {
                name: 'stations.action.importCsvDashboard',
                state: 'auth.stations.station-import-csv',
                icon: 'import',
                isAuthorised: $ctrl.state.canImportStation
            },
            {
                name: 'stations.action.activate',
                state: 'auth.stations.station-add.station-credentials',
                icon: 'evse',
                isAuthorised: $ctrl.state.canActivateStation && !$ctrl.state.canActivateStationOnBehalf
            },
            {
                name: 'stations.action.activate',
                state: 'auth.stations.station-add-on-behalf.station-credentials',
                icon: 'evse',
                isAuthorised: $ctrl.state.canActivateStationOnBehalf
            }
        ];

        $ctrl.actions = _($ctrl.actions).filter('isAuthorised')
                                        .map(_.partialRight(_.omit, 'isAuthorised'))
                                        .value();

        $ctrl.mapConfig = {
            mapOptions: {
                disableDefaultUI: true,
                scaleControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                gestureHandling: 'none',
                zoomControl: false
            }
        };

        $ctrl.noAssetsConfig = {
            heading: 'dashboard.noAssets.heading',
            paragraph: 'dashboard.noAssets.paragraph',
            icon: 'empty-state-generic',
            values: {name: $ctrl.profile.firstName}
        };

        $ctrl.dataPromise.then(onConfigLoaded);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['current']);
    };

    $ctrl.noAssetsActivated = ({config, stations}) => !config ||
        ['users', 'account', 'cards', 'stationTransaction', 'cardTransaction'].every(entity => !config[entity]) &&
        !(stations && stations.totalCount);

    const transformsMap = {
        stations: prepareStationDonutModel,
        users: prepareUserDonutModel,
        account: prepareAccountDonutModel
    };

    function onConfigLoaded(data) {
        $ctrl.config = data;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);

        _.each(data, ({paths}, module) => {
            $ctrl[module] = {
                dataLoaded: false
            };

            if (paths.entity) {
                dashboardService.getWidgetData(paths.entity)
                                .then(entity => {
                                    dashboardService.getLatestTransaction(paths.latestTransaction, entity.id)
                                                    .then(transaction => {
                                                        $ctrl[module].data = {transaction};

                                                        if (module === 'stationTransaction') {
                                                            $ctrl[module].data.station = entity;

                                                            getStationStatus([entity]).then(() => {
                                                                $ctrl[module].dataLoaded = true;
                                                            });

                                                            return;
                                                        }

                                                        if (module === 'cardTransaction' && Boolean(transaction)) {
                                                            $ctrl.mapConfig.mapOptions.center = transaction.station.location;
                                                        }

                                                        $ctrl[module].dataLoaded = true;
                                                    });
                                });
            } else {
                dashboardService.getWidgetData(paths[module])
                                .then(data => {
                                    $ctrl[module].data = data;

                                    if (transformsMap[module]) {
                                        $ctrl[module].chartModel = transformsMap[module](data);
                                    }

                                    $ctrl[module].dataLoaded = true;
                                });
            }
        });

        $ctrl.state.loadingData = false;
    }

    /**
     * Gets station connector status and updates the UI
     * @param {Array} stations
     * @returns {Promise.<Array>}
     */
    function getStationStatus(stations) {
        return stationService.getStationStatus(stations)
                             .then(_.partialRight(setConnectorStatus, stations))
                             .catch(error => {
                                 $log.error('Failed to get the status for the following stations: %s', _.map(stations, 'identityCode').join(', '), error);
                             });
    }

    /**
     * Sets connector status for a given station
     * @param {Object} connectorStatus
     * @param {Array} stations
     */
    function setConnectorStatus(connectorStatus, stations) {
        stations.forEach(station => {
            station.connectorStatus = connectorStatus[station.identityCode];
        });
    }

    function mapProperties(value, key) {
        return {
            value,
            name: key
        };
    }

    function prepareStationDonutModel({singleMultiConnectorsCount}) {
        return singleMultiConnectorsCount ?
            _(singleMultiConnectorsCount).map(mapProperties)
                                         .filter(segment => _.includes(['multi', 'single'], segment.name))
                                         .sortBy('name')
                                         .value() : [];
    }

    function prepareUserDonutModel(model) {
        return _(model).map(mapProperties)
                       .sortBy('active')
                       .value();
    }

    function prepareAccountDonutModel({accountsPerCountry}) {
        return accountsPerCountry ?
            _.map(accountsPerCountry, value => ({
                name: value.countryCode,
                noTranslate: true,
                value: value.count
            })) : [];
    }
}
