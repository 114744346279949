angular.module('everon.stations.station.transactions')
       .controller('StationTransactionsController', StationTransactionsController);

StationTransactionsController.$inject = ['$q', '$state', '$translate', 'permissionService', 'mediatorService', 'CONSTANTS', 'stationService', '$log', 'config', '$timeout', 'utils', '$rootScope', 'stateHelper'];

function StationTransactionsController($q, $state, $translate, permissionService, mediatorService, CONSTANTS, stationService, $log, config, $timeout, utils, $rootScope, stateHelper) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({
            startDate: setRange,
            endDate: setRange,
            sort: setSortingParams,
            connectorId: setActiveConnector
        }, getData);

        $ctrl.range = stateHelper.getDateRangeParams();

        $ctrl.rangeDatePickers = [{
            name: 'startDate',
            config: {
                lastDate: getCurrentDate()
            }
        }, {
            name: 'endDate',
            config: {
                firstDate: $ctrl.range.startDate,
                // should be a constant value, otherwise config.lastDate changes dynamically.
                lastDate: utils.getMoment().endOf('day').toDate()
            }
        }];

        $ctrl.state = {
            dataLoaded: false,
            reloadInProgress: false,
            downloadButtonDisabled: false,
            invoicingEnabled: permissionService.resolve($ctrl.profile.permissions, ['INVOICE:READ']),
            downloadInProgress: false,
            connectorsSelectorDisabled: false,
            showReimbursableColumn: false,
            isRoleAllowedToSeeReimbursable: false
        };

        $ctrl.columns = [
            {
                property: 'connector',
                translationKey: 'generic.entity.connector',
                sortable: false,
                cssClass: 'col-2 flex-grow-1'
            },
            {
                property: 'endedAt',
                translationKey: 'generic.label.endedAt',
                sortable: true,
                cssClass: 'col-3 flex-grow-1'
            },
            {
                property: 'duration',
                translationKey: 'generic.label.duration',
                sortable: true,
                cssClass: 'col-2 flex-grow-1'
            },
            {
                property: 'consumption',
                translationKey: 'generic.units.kwh',
                sortable: true,
                cssClass: 'col-2 flex-grow-1'
            }
        ];

        $ctrl.downloadButtonConfig = {
            title: 'generic.action.download',
            actions: [{
                title: 'PDF',
                type: 'application/pdf'
            }]
        };

        // CSV files have no associated apps on mobile by default
        if (!config.isMobile) {
            $ctrl.downloadButtonConfig.actions.unshift({
                title: 'CSV',
                type: 'text/csv'
            });
        }

        $ctrl.noResultsConfig = {
            heading: 'generic.noResults.noTransactions'
        };

        $ctrl.defaultOption = {
            id: 'all',
            identityCode: $translate.instant('stations.station.transactions.label.allConnectors')
        };

        if ($ctrl.state.invoicingEnabled) {
            addColumn('netAmount', 'col-3 flex-grow-1');
        }

        $q.all([
            $ctrl.dataPromise,
            $translate('stations.station.transactions.tools.infoMessage')
        ]).then(onDataLoaded);
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Reloads the state with updated connector id param
     */
    $ctrl.onFiltersChange = () => {
        stateHelper.changeParams(_.assign({}, $ctrl.range, {connectorId: getActiveConnectorId()}));
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    $ctrl.toggleDetails = transaction => {
        transaction.showDetails = !transaction.showDetails;
        transaction.loaded = true;
    };

    /**
     * Downloads transactions as PDF or CSV file
     * @param {Object} action
     */
    $ctrl.download = ({type}) => {
        const connectorId = getActiveConnectorId();
        const stationId = $ctrl.station.id;

        $log.info(`Downloading station ${stationId} transactions`);
        $ctrl.state.downloadInProgress = true;

        stationService.downloadStationTransactions(type, {id: stationId, range: $ctrl.range, connectorId})
                      .catch(error => {
                          $log.debug(error);

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: 'generic.notification.downloadFileError'
                          });
                      })
                      .finally(() => $timeout(() => {
                          $ctrl.state.downloadInProgress = false;
                      }));
    };

    function setRange() {
        $ctrl.range = stateHelper.getDateRangeParams();

        const {startDate, endDate} = $ctrl.range;
        const startOfEndDate = utils.getMoment(endDate).startOf('day').toDate();
        const today = utils.getMoment().startOf('day').toDate();

        $ctrl.rangeDatePickers[1].config.firstDate = startDate;
        $ctrl.rangeDatePickers[0].config.lastDate = startOfEndDate >= today ? startOfEndDate : (startDate < today ? startOfEndDate : startDate);

        /**
         * Check date range immediately after it changed to prevent clicking on download button with invalid range.
         * Later, after transactions fetched, check again if we got any transactions and they are within the past year.
         */
        checkDownloadButtonAvailability();
    }

    /**
     * Set sorting params from the state and re-orders transactions accordingly
     */
    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
        $ctrl.transactions = stationService.orderBy($ctrl.transactions, $ctrl.sortingParams);
    }

    function setActiveConnector(connectorId) {
        $ctrl.activeConnector = _.find($ctrl.station.connectors, {id: connectorId}) || $ctrl.defaultOption;
    }

    /**
     * Updates transactions
     * @param {Array} data
     */
    function onDataUpdated(data) {
        if (!data.length) {
            updateConfig($ctrl.activeConnector.id);
        }

        $ctrl.transactions = stationService.orderBy(data, $ctrl.sortingParams);
        checkDownloadButtonAvailability();
        $ctrl.state.reloadInProgress = false;
    }

    /**
     * Updates noResults config message
     * @param {string} connector
     */
    function updateConfig(connector) {
        $ctrl.noResultsConfig.paragraph = `stations.station.transactions.noResults.${connector === 'all' ? 'station' : 'connector'}Paragraph`;
    }

    function getActiveConnectorId() {
        return $ctrl.activeConnector.id !== 'all' ? $ctrl.activeConnector.id : null;
    }

    /**
     * Checks if selected range is within the past year and transactions are not empty. The start date and current date are included in 1 year range for transaction download, hence we subtract 1
     * day for moment diff to pass.
     */
    function checkDownloadButtonAvailability() {
        const startDateMoment = utils.getMoment($ctrl.range.startDate);
        const endDateMoment = utils.getMoment($ctrl.range.endDate).subtract(1, 'day');

        $ctrl.state.downloadButtonDisabled = endDateMoment.diff(startDateMoment, 'year') > 0 || !$ctrl.transactions.length || $ctrl.filtersForm.$invalid;
    }

    /**
     * Returns today's date
     * @returns {Date}
     */
    function getCurrentDate() {
        return utils.getMoment().startOf('day').toDate();
    }

    /**
     * Fetches filtered data from the server
     * @param {Object} changedParams
     */
    function getData(changedParams) {
        if ($ctrl.filtersForm.$invalid || !_(changedParams).omit('sort')
                                                           .size()) {
            return;
        }

        $ctrl.state.reloadInProgress = true;

        if ($state.params.connectorId) {
            stationService.getClosedTransactionsByConnector($ctrl.station.id, $state.params.connectorId, $ctrl.range)
                          .then(onDataUpdated);
        } else {
            stationService.getClosedTransactions($ctrl.station.id, $ctrl.range)
                          .then(onDataUpdated);
        }
    }

    /**
     * Updates columns config with passed name column
     * @param {string} colName
     * @param {string} [cssClass=col-2]
     */
    function addColumn(colName, cssClass = 'col-2') {
        $ctrl.columns.push({
            property: colName,
            translationKey: `generic.label.${colName}`,
            sortable: true,
            cssClass
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded([{station, transactions, reimbursement, isRoleAllowedToSeeReimbursable}, downloadButtonTooltip]) {
        $ctrl.station = station;
        $ctrl.transactions = transactions;
        $ctrl.connectors = [$ctrl.defaultOption, ...station.connectors];
        setActiveConnector($state.params.connectorId);
        setSortingParams();

        if ($rootScope.app.touch) {
            $ctrl.downloadButtonTooltipMobile = downloadButtonTooltip;
        } else {
            $ctrl.downloadButtonTooltipDesktop = downloadButtonTooltip;
        }

        $ctrl.state.downloadButtonDisabled = !transactions.length;
        $ctrl.state.connectorsSelectorDisabled = station.connectors.length < 2;
        $ctrl.state.showReimbursableColumn = isRoleAllowedToSeeReimbursable && Boolean(reimbursement.rate);
        $ctrl.state.isRoleAllowedToSeeReimbursable = isRoleAllowedToSeeReimbursable;

        if ($ctrl.state.showReimbursableColumn) {
            addColumn('reimbursableAmount', 'col-3 flex-grow-1');
        }

        $ctrl.state.dataLoaded = true;

        updateConfig($ctrl.activeConnector.id);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
