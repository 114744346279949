angular.module('everon.stations.station-import-details-form')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-import-details', {
        url: '/:stationId/import-details',
        data: {
            id: 'station-details-navigation-link',
            title: 'generic.title.details',
            permissions: ['STATION:IMPORT']
        },
        resolve: {
            data: ['$q', '$transition$', 'stationService', ($q, $transition$, stationService) => {
                return $q.all({
                    station: stationService.getStation($transition$.params('to').stationId)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-station-details data-data-promise="::$resolve.data" class="col-12 md-col-10 mx-auto flex flex-column" role="main"/>'
            }
        }
    });
}
