angular.module('everon.landing')
       .controller('LandingController', LandingController);

LandingController.$inject = ['mediatorService', 'CONSTANTS', 'brandingService'];

function LandingController(mediatorService, CONSTANTS, brandingService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.logo = brandingService.resolveImage('logo');
        $ctrl.brandImage = brandingService.resolveImage('brand');
    };
}
