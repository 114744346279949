angular.module('everon.stations.station-add.station-credentials')
       .controller('StationAddCredentialsController', StationAddCredentialsController);

StationAddCredentialsController.$inject = ['stationService', 'accountService', 'mediatorService', 'CONSTANTS'];

function StationAddCredentialsController(stationService, accountService, mediatorService, CONSTANTS) {
    const $ctrl = this;
    const formName = 'stationCredentialsForm';

    $ctrl.$onInit = () => {
        $ctrl.asyncStationValidatorConfig = {
            name: 'verifiedStation',
            validatorFn: verifyStation
        };

        $ctrl.asyncStationSecurityCodeValidatorConfig = {
            name: 'verifiedSecurityCode',
            validatorFn: verifySecurityCode
        };

        $ctrl.regex = {
            identityCode: CONSTANTS.REGEX.STATION_IDENTITY_CODE,
            securityCode: CONSTANTS.REGEX.STATION_SECURITY_CODE
        };

        $ctrl.model = $ctrl.stationAddCtrl.payload.station;
        $ctrl.searchOptions = {
            id: 'accounts',
            initialValue: $ctrl.model.accountNameToActivateOn || '',
            asyncSearch: true,
            getDataAsync: getData,
            onSelect: onAccountSelected
        };
        $ctrl.searchOptionsOnBehalf = {
            id: 'accounts-on-behalf',
            initialValue: $ctrl.model.accountNameToActivateOn || '',
            asyncSearch: true,
            getDataAsync: getDataOnBehalf,
            onSelect: onAccountSelectedOnBehalf
        };

        if ($ctrl.dataPromise) {
            $ctrl.dataPromise.then(onDataLoaded);
        }
    };

    /**
     * Re-validates security code every time identity code is changed
     */
    $ctrl.onChange = () => {
        if ($ctrl[formName].identityCode.$valid && $ctrl[formName].securityCode.$viewValue) {
            $ctrl[formName].securityCode.$setDirty();
            $ctrl[formName].securityCode.$validate();
        }
    };

    $ctrl.next = () => {
        if ($ctrl.stationAddCtrl.state.canActivateStationOnBehalf) {
            const {billingAddress, accountId} = $ctrl.stationAddCtrl.payload.billing;

            $ctrl.stationAddCtrl.next({country: billingAddress.country.code, identityCode: $ctrl.stationAddCtrl.payload.station.identityCode, accountId});
        } else {
            $ctrl.stationAddCtrl.next();
        }
    };

    $ctrl.onAssignToExistingChange = () => {
        // The search-dropdown component will be destroyed when 'no' is selected, so we need to reflect that in the model
        if (!$ctrl.model.assignToExisting) {
            $ctrl.model.accountToActivateOn = null;
            $ctrl.model.accountNameToActivateOn = null;
        }
    };

    /**
     * Verifies if station exists and has a registered tenant/owner
     * @param {string} modelValue
     * @returns {Promise}
     */
    function verifyStation(modelValue) {
        return stationService.verifyStation(modelValue);
    }

    /**
     * Verifies if security code is valid for a given station
     * @param {string} modelValue
     * @returns {Promise}
     */
    function verifySecurityCode(modelValue) {
        return stationService.verifySecurityCode($ctrl.model.identityCode, modelValue);
    }

    /**
     * Extends controller's model with the selected account
     * @param {Object} account
     */
    function onAccountSelected({id, primary}) {
        $ctrl.model.accountToActivateOn = id;
        $ctrl.model.accountNameToActivateOn = primary;
    }

    /**
     * Extends controller's model with the selected account on fetch the billing info
     * @param {Object} account
     */
    function onAccountSelectedOnBehalf({id, primary, billingAccountId, accountOwnerEmail}) {
        onAccountSelected({id, primary});

        accountService.getBillingInfo(billingAccountId)
                      .then(billingInfo => {
                          $ctrl.stationAddCtrl.payload.billing = billingInfo;
                          $ctrl.stationAddCtrl.payload.accountOwnerEmail = accountOwnerEmail;
                      }).catch(onBillingInfoRejected);
    }

    function getData(query) {
        return accountService.getChildAccounts({query})
                             .then(data => data.content.map(({id, name}) => ({id, primary: name})));
    }

    function getDataOnBehalf(query) {
        return accountService.getAllAccounts({query})
                             .then(data => data.content.map(({id, name, billingAccountId, accountOwnerEmail}) => ({id, primary: name, billingAccountId, accountOwnerEmail})));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        if (data.childAccounts) {
            $ctrl.accountHasSubaccounts = Boolean(data.childAccounts.totalElements > 0);
        }

        if (data.station) {
            $ctrl.model.identityCode = data.station.identityCode;
            $ctrl.model.securityCode = data.station.securityCode;
            $ctrl.stationCredentialsForm.identityCode.$setDirty();
            $ctrl.stationCredentialsForm.securityCode.$setDirty();
        }
    }

    function onBillingInfoRejected(response) {
        if (response.status !== 404) {
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                type: 'error',
                messageKey: CONSTANTS.ERROR_CODES[response.status]
            });
        }
    }
}
