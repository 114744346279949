angular.module('everon.roaming.cdrs.accepted')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.cdrs.accepted', {
        url: '/accepted',
        dynamic: true,
        data: {
            id: 'cdrs-accepted-navigation-link',
            title: 'roaming.cdrs.status.accepted',
            menuPriority: 1,
            navigationType: ['context']
        },
        params: {
            startDate: {
                value: ['utils', utils => utils.monthBeforeDate(new Date())],
                type: 'date',
                squash: true
            },
            endDate: {
                value: ['utils', utils => utils.endOfTheDay()],
                type: 'date',
                squash: true
            },
            type: {
                value: 'cpo',
                squash: true
            },
            connectionId: {
                value: 'all',
                squash: true
            }
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/cdrs/accepted' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.cdrs.accepted');
        },
        resolve: {
            data: ['$q', 'roamingService', ($q, roamingService) => {
                return $q.all({
                    emsp: roamingService.getConnectionsByType('emsp'),
                    cpo: roamingService.getConnectionsByType('cpo')
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                component: 'evcloudCdrsAccepted',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
