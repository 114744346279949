angular.module('everon.stations.station.location')
       .controller('StationLocationController', StationLocationController);

StationLocationController.$inject = ['mediatorService', 'CONSTANTS', 'stationService', 'permissionService', 'sessionService'];

function StationLocationController(mediatorService, CONSTANTS, stationService, permissionService, sessionService) {
    const $ctrl = this;
    const formName = 'stationLocationForm';

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            hasChanged: false,
            saveInProgress: false,
            stationInSite: false,
            canManageSite: checkPermissions(['STATION:UPDATE']),
            addressDisabled: false,
            toggleSiteInProgress: false
        };

        $ctrl.searchOptions = {
            id: 'sites',
            placeholder: 'stations.station.location.form.search.placeholder',
            initialValue: '',
            onSelect: onSiteSelected,
            tabIndex: 1
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.onChange = () => {
        setChanged(true);
    };

    $ctrl.onCountryChange = () => {
        $ctrl.model.features.advenir = $ctrl.model.address.country === 'FR';
        $ctrl.onChange();
    };

    /**
     * Saves station location
     */
    $ctrl.save = () => {
        const requestData = _.pick($ctrl.model, [$ctrl.model.siteId ? 'siteId' : 'address', $ctrl.accountFeatures.advenir ? 'features' : null]);

        $ctrl.state.saveInProgress = true;

        stationService.updateStation($ctrl.model.id, requestData)
                      .then(station => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success'
                          });

                          $ctrl.model = station;
                          $ctrl.modelCopy = JSON.stringify($ctrl.model);
                          resetFormState();
                      })
                      .catch(response => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: response.status === 417 ? 'generic.error.invalidAddress' : CONSTANTS.ERROR_CODES[response.status]
                          });
                      })
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    /**
     * Resets station location form and updates the state
     */
    $ctrl.cancel = () => {
        $ctrl.state.stationInSite = $ctrl.state.addressDisabled = !_.isEmpty($ctrl.model.site);
        restoreModel();
        resetFormState();
    };

    /**
     * Assigns/un-assigns a station to/from a site
     */
    $ctrl.toggleSiteAssignment = () => {
        $ctrl.state.toggleSiteInProgress = true;

        const action = $ctrl.state.stationInSite ? 'unassign' : 'assign';

        stationService.updateSiteAssociation($ctrl.model.id, $ctrl.state.stationInSite ? null : $ctrl.model.siteId)
                      .then(station => {
                          $ctrl.model = station;
                          $ctrl.state.stationInSite = $ctrl.state.addressDisabled = Boolean(station.site);
                          $ctrl.model.siteId = $ctrl.state.stationInSite ? station.site.id : null;
                          $ctrl.searchOptions.initialValue = $ctrl.state.stationInSite ? station.site.name : '';

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              messageKey: `stations.station.location.form.notification.${action}.success`
                          });
                      })
                      .catch(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: `stations.station.location.form.notification.${action}.failure`
                          });
                      })
                      .finally(() => {
                          $ctrl.state.toggleSiteInProgress = false;
                      });
    };

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
        setChanged(false);
    }

    /**
     * Reverts changes to profile
     */
    function restoreModel() {
        $ctrl.model = JSON.parse($ctrl.modelCopy);
    }

    /**
     * Extends address form model with `id` property
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        return _.map(formModel, value => {
            return _.assign(value, {id: _.kebabCase(value.name)});
        });
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        const modelChanged = hasModelChanged();

        $ctrl.state.hasChanged = hasChanged && modelChanged;

        if (!modelChanged) {
            $ctrl[formName].$setPristine();
        }
    }

    /**
     * Checks whether model changed
     * @returns {boolean}
     */
    function hasModelChanged() {
        return $ctrl.modelCopy !== JSON.stringify($ctrl.model);
    }

    /**
     * Builds the items list object for the search with the right format
     * @param {Array} sites
     * @returns {Array}
     */
    function buildItemsListForSearchOptions(sites) {
        return _.map(sites, ({id, name, address}) => {
            return {
                id,
                primary: name,
                secondary: `${address.streetName} ${address.house}, ${address.city}`
            };
        });
    }

    /**
     * Extends controller's model with the selected site
     * @param {Object} site
     */
    function onSiteSelected({id}) {
        const site = _.find($ctrl.availableSites, {id});

        if (site) {
            $ctrl.model.siteId = id;
            $ctrl.model.address = site.address;
            $ctrl.state.addressDisabled = true;
        } else {
            $ctrl.cancel();
        }
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({station, countries, accountFeatures, addressFormModel, sites}) {
        $ctrl.model = station;
        $ctrl.countries = countries;
        $ctrl.accountFeatures = accountFeatures;
        $ctrl.addressFormModel = extendFormModel(addressFormModel);
        $ctrl.modelCopy = JSON.stringify($ctrl.model);
        $ctrl.state.dataLoaded = true;
        $ctrl.availableSites = sites;
        $ctrl.searchOptions.data = buildItemsListForSearchOptions(sites);

        if (station.site) {
            $ctrl.state.stationInSite = $ctrl.state.addressDisabled = true;
            $ctrl.model.siteId = station.site.id;
            $ctrl.searchOptions.initialValue = station.site.name;
        }

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [$ctrl.model.reference || $ctrl.model.identityCode, 'current']);
    }
}
