angular.module('everon.billing-plans.plans.plan')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans.plan', {
        url: '/:type/:id',
        redirectTo: 'auth.billing-plans.plans.plan.basic-info',
        data: {
            title: 'billingPlans.plans.plan.title',
            permissions: ['BILLING_PLAN:UPDATE']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/plans/plan' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.billing-plans.plans.plan');
        },
        resolve: {
            data: ['$transition$', 'countryService', 'subscriptionService', ($transition$, countryService, subscriptionService) => {
                const {type, id} = $transition$.params('to');

                return subscriptionService.getPlan(id, type);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-plan data-data-promise="::$resolve.data" class="flex flex-auto" />'
            },
            'contextNav@auth.billing-plans.plans.plan': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.billing-plans.plans.plan\'}" />'
            }
        }
    });
}
