function filterParams(params, filter = []) {
    if (!filter.length) {
        return params;
    }

    return filter.reduce((acc, key) => {
        if (Object.keys(params).includes(key)) {
            acc[key] = params[key];
        }

        return acc;
    }, {});
}

export default function urlActions($ng) {
    const stateHelper = $ng('stateHelper');
    const paramsChangeActions = new Map();
    let stateHelperListener;

    function createParamsChangeNotifier({dispatch}) {
        return urlParams => paramsChangeActions.forEach(
            (options, action) => {
                const filteredParams = filterParams(urlParams, options.filter);

                if (Object.keys(filteredParams).length) {
                    dispatch(action, {
                        params: filteredParams,
                        stateHelper
                    });
                }
            }
        );
    }

    return {
        registerParamChangeListener({dispatch}, payload) {
            dispatch('setupParamsChangeListener');

            const {action, ...options} = payload;

            paramsChangeActions.set(action, options);

            if (options.immediate) {
                dispatch(action, {
                    params: stateHelper.getStateParams(options.filter),
                    stateHelper
                });
            }
        },

        unregisterParamChangeListener(_, action) {
            paramsChangeActions.delete(action);
        },

        setupParamsChangeListener(context) {
            if (!stateHelperListener) {
                stateHelperListener = stateHelper.subscribeToParamsChange({}, createParamsChangeNotifier(context));
            }
        },

        clearParamsChangeListener() {
            if (stateHelperListener) {
                stateHelperListener.resolve();
            }
        },

        updateUrlParams(_, params) {
            stateHelper.changeParams(params);
        },

        clearUrlParams(_, params = []) {
            stateHelper.clearStateParams(params);
        }
    };
}
