angular.module('everon.stations.station-add.station-billing-information')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add.station-billing-information', {
        data: {
            title: 'generic.title.billingInformation',
            order: 2
        },
        resolve: {
            data: ['$q', 'accountFormModel', 'accountService', 'countryService', ($q, accountFormModel, accountService, countryService) => {
                return $q.all({
                    accountFormModel,
                    billing: accountService.getBillingInfo(),
                    countries: countryService.getTenantCountries()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station-add': {
                template: '<evcloud-station-add-billing-information data-data-promise="::$resolve.data" class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
