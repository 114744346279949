angular.module('everon.stations.station-add.station-credentials')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add-on-behalf.station-credentials', {
        url: '?stationId',
        data: {
            title: 'generic.title.details',
            order: 0
        },
        resolve: {
            data: ['$q', '$transition$', 'stationService', ($q, $transition$, stationService) => {
                const {stationId} = $transition$.params('to');

                if (stationId) {
                    return $q.all({
                        station: stationService.getStation(stationId)
                    }).catch(() => ({}));
                }

                return $q.resolve({});
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station-add-on-behalf': {
                template: '<evcloud-station-add-credentials data-data-promise="::$resolve.data" class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
