angular.module('everon.accounts.account')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.accounts.account', {
        url: '/:id',
        redirectTo: 'auth.accounts.account.overview',
        data: {
            title: 'generic.entity.account'
        },
        resolve: {
            accountOwner: ['$transition$', 'accountService', ($transition$, accountService) => accountService.getAccountOwner($transition$.params('to').id)]
        },
        resolvePolicy: {
            accountOwner: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                templateUrl: 'modules/account/account.html'
            },
            'contextNav@auth.accounts.account': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.accounts.account\'}"  />'
            }
        }
    });
}
