angular.module('everon.users.system-users')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users.system-users', {
        url: '?sort&page&size',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            },
            highlightItem: null
        },
        dynamic: true,
        data: {
            id: 'system-users-navigation-link',
            title: 'generic.entity.systemUsers',
            navigationType: ['context'],
            menuPriority: 1,
            permissions: ['SYSTEM_USER:READ_ALL'],
            userType: 'system-users'
        },
        resolve: {
            data: ['userService', '$transition$', (userService, $transition$) => {
                const params = _.pick($transition$.params('to'), ['sort', 'page', 'size']);

                return userService.getUsers(_.assign({type: 'system-users'}, params));
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.users.system-users': {
                template: '<evcloud-users data-data-promise="::$resolve.data" class="flex-auto flex flex-column col-12" />'
            }
        }
    });
}
