import createVueApp from './index-vue';

angular.module('everon.stations.cpo-dashboard')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.cpo-dashboard', {
        url: '/dashboard?view&size&page&account&status&query',
        dynamic: true,
        data: {
            id: 'cpo-dashboard-navigation-link',
            title: 'generic.title.stationsManagement',
            permissions: ['STATION:DASHBOARD_READ'],
            features: ['cpoDashboard']
        },
        redirectTo($transition$) {
            const $state = $transition$.injector().get('$state');
            const featureToggleService = $transition$.injector().get('featureToggleService');
            const useList = featureToggleService('@@useStationsList').get();

            return useList ? $state.target('auth.stations.list', null, {reload: 'auth.stations.list'}) : true;
        },
        params: {
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            },
            account: {
                type: 'base64'
            },
            status: {
                value: 'all',
                squash: true
            }
        },
        vueInstance: null,
        resolve: {
            data: ['$transition$', 'mediatorService', 'CONSTANTS', ($transition$, mediatorService, CONSTANTS) => {
                $transition$.onSuccess({}, async () => {
                    mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

                    const state = $transition$.to();

                    state.vueInstance = await createVueApp();

                    mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
                    mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['current']);
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: `<div id="cpo-dashboard"
                                class="col-12 flex flex-column items-center evo-pt-5"
                                role="main">
                                    <go-back-link></go-back-link>
                                    <evo-cpo-dashboard></evo-cpo-dashboard>
                            </div>`
            }
        }
    });
}
