angular.module('everon.roaming.cdrs.rejected')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    const urlParams = ['connectionId', 'startDate', 'endDate', 'type', 'query', 'page', 'size', 'sort'];

    $stateProvider.state('auth.roaming.cdrs.rejected', {
        url: `/rejected?${urlParams.join('&')}`,
        dynamic: true,
        data: {
            id: 'cdrs-rejected-navigation-link',
            title: 'roaming.cdrs.status.rejected',
            menuPriority: 2,
            navigationType: ['context']
        },
        params: {
            startDate: {
                value: ['utils', utils => utils.monthBeforeDate(new Date())],
                type: 'date',
                squash: true
            },
            endDate: {
                value: ['utils', utils => utils.endOfTheDay()],
                type: 'date',
                squash: true
            },
            type: {
                value: 'cpo',
                squash: true
            },
            sort: {
                value: 'createdAt,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            },
            connectionId: {
                value: 'all',
                squash: true
            }
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/cdrs/rejected' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.cdrs.rejected');
        },
        resolve: {
            data: ['$transition$', 'roamingService', '$q', ($transition$, roamingService, $q) => {
                const params = _.pick($transition$.params('to'), urlParams);

                return $q.all({
                    connections: $q.all({
                        emsp: roamingService.getConnectionsWithFailedCdrs('emsp'),
                        cpo: roamingService.getConnectionsWithFailedCdrs('cpo')
                    }),
                    cdrs: roamingService.getRejectedCDRs(params)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                template: '<evcloud-cdrs-rejected data-data-promise="::$resolve.data" class="flex-auto flex" />'
            }
        }
    });
}
