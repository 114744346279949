angular.module('everon.account-recovery')
       .controller('AccountRecoveryController', AccountRecoveryController);

AccountRecoveryController.$inject = ['mediatorService', 'CONSTANTS', 'brandingService'];

function AccountRecoveryController(mediatorService, CONSTANTS, brandingService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.logo = brandingService.resolveImage('logo');
    };
}
