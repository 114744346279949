angular.module('everon.branding')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.branding', {
        url: '/branding',
        data: {
            id: 'branding-settings-navigation-link',
            title: 'branding.title',
            menuPriority: 20,
            icon: 'icon-branding',
            navigationType: ['primary'],
            tenantLevel: true,
            permissions: ['BRANDING:UPDATE']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/branding' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.branding');
        },
        resolve: {
            data: ['brandingService', brandingService => brandingService.getBrandingData()]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-branding data-data-promise="::$resolve.data" class="flex-auto"/>'
            }
        }
    });
}
