angular.module('everon.core')
       .factory('httpInterceptor', httpInterceptor);

httpInterceptor.$inject = ['$q', '$log', 'CONSTANTS', 'mediatorService', '$window', '$injector', 'config'];

function httpInterceptor($q, $log, CONSTANTS, mediatorService, $window, $injector, config) {
    return {
        request(reqConfig) {
            reqConfig.timeout = reqConfig.timeout || CONSTANTS.HTTP_REQUEST_TIMEOUT;

            if (!reqConfig.url.includes(config.apiUrl)) {
                return reqConfig;
            }

            // Calls to the cdn will fail, xDomain calls can only contain certain headers, otherwise must be handled
            // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
            if (!reqConfig.url.includes('/assets/translations')) {
                reqConfig.headers.tenantId = $window.EVERON.tenantId;
            }

            const authHeader = $injector.get('sessionService').getAuthorizationHeader();

            if (authHeader) {
                reqConfig.headers.Authorization = authHeader;
            }

            // eslint-disable-next-line no-undef
            if (process.env.NODE_ENV === 'development' && reqConfig.url.includes('mock=yes')) {
                reqConfig.url = reqConfig.url.replace(/(https?:)(\/\/\/?)[\w.]+.everon.io(\/(ad)+\w+)?/i, 'http://localhost:8888');
            }

            return reqConfig;
        },

        requestError(rejection) {
            return $q.reject(rejection);
        },

        response(response) {
            const {url, method} = response.config;

            return (_.isObject(response.data) && !url.includes('/assets') && !(method === 'GET' && url.includes('/api/platform/users/me'))) ? response.data : response;
        },

        responseError(rejection) {
            // Handle request timeouts
            if (rejection.status === -1) {
                mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                    type: 'error',
                    messageKey: CONSTANTS.ERROR_CODES['-1'],
                    isPersistent: true
                });

                $log.debug('Request to `%s` has been cancelled or timed out after %s seconds', rejection.config.url, rejection.config.timeout / 1000);
            }

            return $q.reject(rejection);
        }
    };
}
