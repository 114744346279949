angular.module('everon.stations.station-import-details-form')
       .controller('StationImportDetailsFormController', StationImportDetailsFormController);

StationImportDetailsFormController.$inject = ['stationService', 'CONSTANTS', 'mediatorService', '$state', 'localStore'];

function StationImportDetailsFormController(stationService, CONSTANTS, mediatorService, $state, localStore) {
    const $ctrl = this;
    const subscribers = [];
    const storageKeyPrefix = 'importStation';

    $ctrl.$onInit = () => {
        $ctrl.state = {
            dataLoaded: false,
            saveInProgress: false,
            editMode: Boolean($ctrl.dataPromise)
        };

        $ctrl.storageKey = `${storageKeyPrefix}-${$ctrl.state.editMode ? 'edit' : 'create'}`;

        $ctrl.model = stationService.getStationCapabilities();
        $ctrl.modelCopy = JSON.stringify($ctrl.model);

        $ctrl.states = _($state.$current.parent.children).sortBy('data.order')
                                                         .map(item => ({
                                                             name: item.name,
                                                             title: item.data.title
                                                         }))
                                                         .value();

        $ctrl.payload = localStore.get($ctrl.storageKey) || {
            basicInfo: {
                selectedConnectivityType: 'gsm'
            },
            connectorSpecs: [],
            params: {}
        };

        updateIndex();

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);

        if ($ctrl.state.editMode) {
            $ctrl.dataPromise.then(onDataLoaded);
        } else {
            $ctrl.state.dataLoaded = true;
        }
    };

    $ctrl.$onDestroy = () => {
        _.invokeMap(subscribers, _.call);
        localStore.remove($ctrl.storageKey);
    };

    /**
     * Saves station details
     */
    $ctrl.save = () => {
        const requestPayload = buildRequestPayload(['manufacturer', 'connectivityTypes', 'identityCode', 'securityCode', 'articleNumber', 'connectorSpecs']);

        $ctrl.state.saveInProgress = true;

        stationService.importStation(requestPayload)
                      .then(() => {
                          showSuccessNotification();

                          $state.go('auth.stations', null, {reload: 'auth.stations'});
                          localStore.remove($ctrl.storageKey);
                      })
                      .catch(showErrorNotification)
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    /**
     * Updates station details
     */
    $ctrl.update = () => {
        const requestPayload = buildRequestPayload(['manufacturer', 'connectivityTypes', 'address', 'reference', 'securityCode', 'articleNumber', 'identityCode', 'features', 'connectorSpecs']);

        $ctrl.state.saveInProgress = true;

        stationService.updateStation($ctrl.payload.params.id, requestPayload)
                      .then(() => {
                          showSuccessNotification();

                          $state.go('auth.stations', null, {reload: 'auth.stations'});
                          localStore.remove($ctrl.storageKey);
                      })
                      .catch(showErrorNotification)
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    $ctrl.onCancel = () => {
        $state.go('auth.stations', null, {reload: 'auth.stations'});
    };

    /**
     * Transitions to the next state
     * @param {Object} [params]
     * @returns {Promise}
     */
    $ctrl.next = params => {
        const nextState = _.get($ctrl.states[$state.current.data.order + 1], 'name');

        $ctrl.payload.params = _.assign({}, $ctrl.payload.params ? $ctrl.payload.params : null, params);

        return $ctrl.goToStep(nextState);
    };

    /**
     * Transitions to the previous state
     * @param {Object} [params]
     * @returns {Promise}
     */
    $ctrl.previous = params => {
        const previousState = $ctrl.states[$state.current.data.order - 1].name;

        $ctrl.payload.params = _.assign({}, $ctrl.payload.params, params);

        return $ctrl.goToStep(previousState);
    };

    /**
     * Transitions to a given state
     * @param {string} name
     * @returns {Promise}
     */
    $ctrl.goToStep = name => {
        localStore.set($ctrl.storageKey, $ctrl.payload);

        return $state.go(name, $ctrl.payload.params, {reload: name}).then(updateIndex);
    };

    /**
     * Keeps localStorage updated on the connector settings information
     * @param {Array} connectors
     */
    $ctrl.storeConnectors = connectors => {
        $ctrl.payload.connectorSpecs = connectors;

        localStore.set($ctrl.storageKey, $ctrl.payload);
    };

    /**
     * Updates current state index
     */
    function updateIndex() {
        $ctrl.currentStateIndex = _.findIndex($ctrl.states, {name: $state.current.name});
    }

    function mapType(connectivityTypes) {
        return _.find(stationService.extendConnectivityTypes(connectivityTypes), 'enabled').name;
    }

    /**
     * Builds the request payload base on the provided properties
     * @param {Array} properties
     * @returns {Object}
     */
    function buildRequestPayload(properties) {
        return _.assign({},
            _.pick($ctrl.payload.params, properties),
            {connectivityTypes: stationService.getConnectivityTypes(_.find($ctrl.model.connectivityTypes, type => type.name === $ctrl.payload.basicInfo.selectedConnectivityType))});
    }

    /**
     * Shows success notification message
     */
    function showSuccessNotification() {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'success'
        });
    }

    /**
     * Shows the error notification message based on the error status
     * @param {Object} which includes the status property
     */
    function showErrorNotification({status}) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: (status === 409) ? 'stations.stationImport.notification.stationExists' : CONSTANTS.ERROR_CODES[status]
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        const {articleNumber, identityCode, manufacturer, securityCode, connectivityTypes, connectorSpecs} = data;

        $ctrl.payload = {
            basicInfo: {
                articleNumber,
                identityCode,
                manufacturer,
                securityCode,
                selectedConnectivityType: mapType(connectivityTypes)
            },
            connectorSpecs,
            params: data
        };

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
