angular.module('everon.users.system-users.user-invite')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users.user-invite', {
        url: '/new',
        data: {
            title: 'users.action.invite'
        },
        resolve: {
            data: ['userService', userService => userService.getAvailableRoles({type: 'system-users'}).then(roles => ({roles}))]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-user-invite data-data-promise="::$resolve.data" class="col-12" />'
            }
        }
    });
}
