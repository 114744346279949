angular.module('everon.welcome')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.welcome.language-details', {
        url: '/welcome',
        data: {
            title: 'generic.entity.profile',
            order: 0
        },
        resolve: {
            data: ['$q', 'sessionService', 'languageService', ($q, sessionService, languageService) => {
                return $q.all({
                    profile: sessionService.getUserProfile(),
                    languages: languageService.getTenantLanguages()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.welcome': {
                template: '<evcloud-welcome-language-details data-data-promise="::$resolve.data" class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
