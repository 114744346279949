angular.module('everon.core')
       .factory('$exceptionHandler', exceptionHandler);

exceptionHandler.$inject = ['$log', 'mediatorService', 'CONSTANTS', '$window'];

function exceptionHandler($log, mediatorService, CONSTANTS, $window) {
    /**
     * Capture state resolve promise rejection errors and display error notification based on status code
     * @param {string} exception
     * @param {Object} [cause]
     */
    return (exception, cause) => {
        const match = _.isString(exception) && exception.match(/{.+}/);
        const json = _.isArray(match) ? JSON.parse(match[0]) : null;

        if (json) {
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                type: 'error',
                isPersistent: true,
                messageKey: CONSTANTS.ERROR_CODES[json.status]
            });
        }

        $log.debug(exception, cause);

        if ($window.EVERON.errorHandler) {
            $window.EVERON.errorHandler.report(exception);
        }
    };
}
