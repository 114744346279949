angular.module('everon')
       .factory('lazyLoadService', lazyLoadService);

lazyLoadService.$inject = ['$templateCache', '$ocLazyLoad', '$q', 'mediatorService', 'CONSTANTS'];

function lazyLoadService($templateCache, $ocLazyLoad, $q, mediatorService, CONSTANTS) {
    function initiateTemplate({name, template}) {
        $templateCache.put(name, template);
    }

    function wrapInArray(value) {
        return Array.isArray(value) ? value : [value];
    }

    return {
        loadModule(promise, name) {
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

            return promise.then(module => {
                if (module.default && module.default.templates) {
                    wrapInArray(module.default.templates).forEach(initiateTemplate);
                }

                wrapInArray(name).forEach(moduleName => {
                    $ocLazyLoad.load({name: moduleName});
                });

                return $q.resolve();
            });
        }
    };
}
