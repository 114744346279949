angular.module('everon.component.datetime-picker')
       .factory('dateTimePickerService', dateTimePickerService);

dateTimePickerService.$inject = ['$translate', 'CONSTANTS'];

function dateTimePickerService($translate, CONSTANTS) {
    const picker = {
        calendar: null
    };

    /**
     * Returns an array of 31 days
     * @returns {Array}
     */
    function getDays() {
        return _.range(1, 32);
    }

    /**
     * Returns an array of 12 months
     * @returns {Array}
     */
    function getMonths() {
        return _.range(12).map((value, index) => $translate.instant(`generic.datetime.months.${CONSTANTS.DATES.MONTHS[index]}`));
    }

    /**
     * Returns an array of 7 days in a week
     * @returns {Array}
     */
    function getDaysOfWeek() {
        return _.range(7).map((value, index) => {
            const day = $translate.instant(`generic.datetime.days.${
                CONSTANTS.DATES.DAYS[(index + CONSTANTS.DATES.FIRST_DAY_OF_WEEK) % 7]}`);

            return {
                fullName: day,
                shortName: day.substr(0, 3)
            };
        });
    }

    /**
     * Returns an array of leading days in a given month view
     * @param {number} leadingDays
     * @param {number} daysInLastMonth
     * @returns {Array}
     */
    function getLeadingDays(leadingDays, daysInLastMonth) {
        return leadingDays ? picker.calendar.days.slice(-leadingDays - (31 - daysInLastMonth), daysInLastMonth) : [];
    }

    /**
     * Returns an array of trailing days in a given month view
     * @param {Array} trailingDays
     * @returns {Array}
     */
    function getTrailingDays(trailingDays) {
        return trailingDays > 7 ? trailingDays.slice(0, trailingDays.length) : trailingDays;
    }

    return {
        /**
         * Sets calendar data
         * @param {?Object} data
         */
        setCalendarData(data) {
            picker.calendar = data;
        },

        /**
         * Returns calendar data
         * @returns {Object}
         */
        getCalendarData() {
            if (!picker.calendar) {
                this.setCalendarData({
                    days: getDays(),
                    months: getMonths(),
                    daysOfWeek: getDaysOfWeek(),
                    firstDayOfWeek: CONSTANTS.DATES.FIRST_DAY_OF_WEEK,
                    hoursTitle: _.capitalize($translate.instant('generic.datetime.hours')),
                    minutesTitle: _.capitalize($translate.instant('generic.datetime.minutes')),
                    previousTitle: _.capitalize($translate.instant('generic.action.previous')),
                    nextTitle: _.capitalize($translate.instant('generic.action.next'))
                });
            }

            return picker.calendar;
        },

        /**
         * Returns combined single month view and calendar data
         * @param {number} year
         * @param {number} month
         * @returns {Object}
         */
        getData(year, month) {
            if (month > 11) {
                year += 1;
            } else if (month < 0) {
                year -= 1;
            }

            month = (month + 12) % 12;

            const calendar = this.getCalendarData();
            const firstDayOfMonth = new Date(year, month, 1);
            const lastDayOfMonth = new Date(year, month + 1, 0);
            const lastDayOfPreviousMonth = new Date(year, month, 0);
            const daysInMonth = lastDayOfMonth.getDate();
            const daysInLastMonth = lastDayOfPreviousMonth.getDate();
            const dayOfWeek = firstDayOfMonth.getDay();
            const leadingDays = (dayOfWeek - calendar.firstDayOfWeek + 7) % 7;
            const trailingDays = calendar.days.slice(0, 6 * 7 - (leadingDays + daysInMonth));

            return {
                monthView: {
                    year,
                    month,
                    lastDayOfPreviousMonth: daysInLastMonth,
                    days: calendar.days.slice(0, daysInMonth),
                    leadingDays: getLeadingDays(leadingDays, daysInLastMonth),
                    trailingDays: getTrailingDays(trailingDays)
                },
                calendar
            };
        }
    };
}
