angular.module('everon.component.pull-refresh')
       .directive('evcloudPullRefresh', pullRefresh);

pullRefresh.$inject = ['$window', '$document', '$timeout', '$templateRequest', 'config'];

function pullRefresh($window, $document, $timeout, $templateRequest, config) {
    return {
        restrict: 'A',
        link: (scope, element) => {
            // Should only start if the app is running in standalone mode or ios device
            if (!($window.matchMedia('(display-mode: standalone)').matches || $window.navigator.standalone || config.isMobile)) {
                return;
            }

            const templateUrl = 'components/pull-refresh/pull-refresh.html';
            const viewportHeight = $document[0].body.clientHeight;
            const elementHeight = 2.7 * 16; // 2.7rem
            const defaultStyleAttribute = `height: ${elementHeight}px; top: -${elementHeight}px; background-color: transparent`;
            const styleAttribute = offset => `top: -${elementHeight}px; background-color: transparent; height: ${offset}px; pointer-events: none;`;
            let panel = null;
            let icon = null;
            let maxPull = 0;
            let spinningPosition = 0;

            $templateRequest(templateUrl).then(template => {
                panel = angular.element(template);
                icon = panel.find('svg');

                element.append(panel);
                panel.attr('style', defaultStyleAttribute);

                element.on('touchmove', onDragProgress);
                element.on('touchend', onDragEnd);

                maxPull = Math.trunc(viewportHeight / 3);
                spinningPosition = maxPull - 30;
            });

            let ignoreEvent = _.once(shouldIgnoreEvent);

            /**
             * Manage event when drag ends depending on the dragged offset
             * @param {Object} event
             */
            function onDragEnd(event) {
                if (ignoreEvent(event)) {
                    ignoreEvent = _.once(shouldIgnoreEvent);

                    return;
                }

                if (event.changedTouches[0].pageY >= maxPull) {
                    icon.addClass('rotate');
                    panel.attr('style', styleAttribute(spinningPosition));
                    $timeout(reload, 1000);
                } else {
                    panel.attr('style', defaultStyleAttribute);
                }
            }

            /**
             * Manage event when dragging depending on the dragged offset
             * @param {Object} event
             */
            function onDragProgress(event) {
                if (ignoreEvent(event)) {
                    return;
                }

                const dragged = event.changedTouches[0].pageY;

                if (dragged <= maxPull) {
                    const spin = Math.trunc(dragged * 360 / maxPull);
                    const offset = Math.trunc(dragged + elementHeight);

                    icon.attr('style', `transform: rotate(${spin}deg);`);
                    panel.attr('style', styleAttribute(offset));
                }
            }

            /**
             * Reloads the app
             */
            function reload() {
                $window.location.reload();
            }

            /**
             * Checks if the event should be ignoredIt should be ignored when hitting on any of the user profile drop-down options
             * @param {Object} event
             * @returns {boolean}
             */
            function shouldIgnoreEvent(event) {
                const userProfileDropdownElement = document.querySelector('.pull-refresh-directive-ignore-page-reload');
                const eventTarget = event.target;

                return userProfileDropdownElement ? userProfileDropdownElement.contains(eventTarget) : false;
            }
        }
    };
}

