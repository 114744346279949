angular.module('everon.component.donut-text')
       .controller('DonutTextController', DonutTextController);

DonutTextController.$inject = ['CONSTANTS', 'utils'];

function DonutTextController(CONSTANTS, utils) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.statusCssClass = CONSTANTS.STATUS.STATION_CONNECTOR;

        $ctrl.state = {
            connectorStatus: $ctrl.data.station ? $ctrl.statusCssClass[$ctrl.data.station.connectorStatus[0].status].replace('text', 'stroke') : 'stroke-brand stroke-brand-1-custom'
        };

        if ($ctrl.data.transaction) {
            $ctrl.data.transaction.humanisedDuration = utils.humaniseDuration($ctrl.data.transaction.duration);
        }
    };
}
