angular.module('everon.users.system-users.groups')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users.groups.user-group-create', {
        url: '/new',
        redirectTo: 'auth.users.system-users.groups.user-group-create.user-group-details',
        data: {
            title: 'users.groups.groupCreate.title',
            permissions: ['USER_GROUP:CREATE']
        },
        views: {
            'main@auth': {
                template: '<evcloud-user-group-create class="flex-auto flex flex-column col-12" />'
            }
        }
    });
}
