import {Store} from 'vuex';
import getters from './getters';
import mutations from './mutations';
import getActions from './actions';
import createInitialState from './initialState';

export default function ($ng) {
    return new Store({
        state: createInitialState(),
        getters,
        mutations,
        actions: getActions($ng)
    });
}
