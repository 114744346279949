angular.module('everon.users.system-users.groups.user-group-create.user-group-name')
       .controller('UserGroupCreateNameController', GroupCreateNameController);

GroupCreateNameController.$inject = ['userGroupsService'];

function GroupCreateNameController(userGroupsService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.groupName = $ctrl.userGroupCreateCtrl.payload.groupName;

        $ctrl.asyncValidatorConfig = {
            name: 'verifiedGroupName',
            validatorFn: verifyGroupName
        };
    };

    $ctrl.next = () => {
        $ctrl.userGroupCreateCtrl.payload.groupName = $ctrl.groupName;

        $ctrl.userGroupCreateCtrl.next({name: $ctrl.groupName});
    };

    $ctrl.previous = () => {
        $ctrl.userGroupCreateCtrl.payload.groupName = $ctrl.groupName;

        $ctrl.userGroupCreateCtrl.previous();
    };

    /**
     * Verifies if group name is not already taken
     * @param {string} modelValue
     * @returns {Promise}
     */
    function verifyGroupName(modelValue) {
        return userGroupsService.verifyGroup(modelValue)
                                .then(data => data);
    }
}
