angular.module('everon.users.system-users.groups.user-group-create.user-group-name')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users.groups.user-group-create.user-group-name', {
        data: {
            title: 'generic.label.name',
            order: 1
        },
        views: {
            'content@auth.users.system-users.groups.user-group-create': {
                template: '<evcloud-user-group-create-name class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
