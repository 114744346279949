angular.module('everon.component.google-maps')
       .controller('GoogleMapsController', GoogleMapsController);

GoogleMapsController.$inject = ['$element', '$q', 'mapService'];

function GoogleMapsController($element, $q, mapService) {
    const $ctrl = this;
    let map;
    let apiConfigPromise;

    $ctrl.$onInit = () => {
        $ctrl.config = $ctrl.config || {};
        apiConfigPromise = mapService.loadMapApi()
                                     .then(initMap);
    };

    $ctrl.$onChanges = event => {
        if (!event.location.isFirstChange() && apiConfigPromise) {
            apiConfigPromise.then(() => {
                createMarker(event.location.currentValue).setMap(map);
                setCenter(event.location.currentValue);
            });
        }
    };

    function initMap() {
        const mapHolder = $element.find('evcloud-google-maps-holder');

        map = mapService.createMap(mapHolder[0], _.assign({
            center: $ctrl.location
        }, $ctrl.config.mapOptions));

        createMarker($ctrl.location).setMap(map);

        return $q.resolve();
    }

    /**
     * Creates and sets marker on a map
     * @param {Object} location
     * @returns {Object}
     */
    function createMarker(location) {
        return mapService.createMarker(null, _.assign({
            position: location
        }, $ctrl.config.markerOptions));
    }

    /**
     * Update map center coordinates
     * @param {Object} coordinates
     */
    function setCenter(coordinates) {
        map.setCenter(coordinates);
    }
}
