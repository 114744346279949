angular.module('everon.cards.card.billing-plan.change.select')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card.billing-plan.change.select', {
        data: {
            title: 'generic.title.billingPlan',
            order: 0
        },
        views: {
            'section@auth.cards.card.billing-plan.change': {
                component: 'evcloudCardBillingPlanSelect'
            }
        }
    });
}
