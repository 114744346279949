angular.module('everon.billing-plans.plans.plan.pricing')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans.plan.pricing', {
        url: '/pricing',
        data: {
            title: 'generic.title.pricing',
            id: 'plan-pricing-navigation-link',
            navigationType: ['context'],
            menuPriority: 2
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/plans/plan/pricing' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.billing-plans.plans.plan.pricing');
        },
        resolve: {
            data: ['$q', '$transition$', 'subscriptionService', 'countryService',
                ($q, $transition$, subscriptionService, countryService) => {
                const {type, id} = $transition$.params('to');

                return $q.all({
                    plan: subscriptionService.getPlan(id, type),
                    countryCurrencies: countryService.getTenantCountryCurrencies()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.billing-plans.plans.plan': {
                component: 'evcloudPlanPricing',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
