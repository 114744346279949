angular.module('everon')
       .factory('geolocation', geolocation);

geolocation.$inject = ['$q', '$http', '$window', '$log', 'localStore', 'utils', 'localisationService', 'CONSTANTS'];

function geolocation($q, $http, $window, $log, localStore, utils, localisationService, CONSTANTS) {
    return {
        /**
         * Gets coordinates based on IP address geolocation
         * @returns {Promise.<Object|Error>}
         */
        getIpCoordinates() {
            return $http.get('/api/poi/geolocation');
        },

        /**
         * Resolves current position using browser's Geolocation API and stores coordinates in local storage. If geolocation request is rejected, we remove stored CURRENT_POSITION
         * @param {Object} [options = {maximumAge: 9000, timeout: 10000}]
         * @returns {Promise.<Object|Error>}
         */
        getCurrentPosition(options = {maximumAge: 9000, timeout: 10000}) {
            return $q((resolve, reject) => {
                $window.navigator.geolocation.getCurrentPosition(position => {
                    localStore.set('CURRENT_POSITION', {lat: position.coords.latitude, lng: position.coords.longitude});
                    resolve({lat: position.coords.latitude, lng: position.coords.longitude});
                }, error => {
                    $log.debug(error);
                    localStore.remove('CURRENT_POSITION');
                    reject(error);
                }, options);
            });
        },

        /**
         * Returns stored current position coordinates
         * @returns {Object|null}
         */
        getStoredPosition() {
            return localStore.get('CURRENT_POSITION');
        },

        /**
         * Opens external geolocation permission info page in user's profile language
         */
        showGeolocationPermissionInfo() {
            utils.openExternalLink(`${CONSTANTS.EXTERNAL_URLS.GEOLOCATION_PERMISSION_INFO}?hl=${localisationService.getLanguageTag()}`);
        }
    };
}
