angular.module('everon.stations.charging-profiles.charging-profile-form')
       .controller('ChargingProfileFormController', ChargingProfileFormController);

ChargingProfileFormController.$inject = ['CONSTANTS', 'mediatorService', '$state', 'chargingProfilesService'];

function ChargingProfileFormController(CONSTANTS, mediatorService, $state, chargingProfilesService) {
    const $ctrl = this;
    const daysInAWeek = 7;
    const intervalsInADay = 24;
    const defaultMaxCurrent = 500;
    const emptyInterval = {current: undefined, offset: undefined};
    const emptyIntervalPowerValues = {singlePhase: '-', threePhase: '-'};

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            saveInProgress: false,
            createAnother: $state.params.createAnotherChecked || false,
            hasChanged: false,
            dataLoaded: false,
            creatingChargingProfile: true
        };

        $ctrl.weekDays = chargingProfilesService.buildDefaultWeekDaysObject();

        $ctrl.model = {
            maxCurrent: defaultMaxCurrent,
            daySchedules: chargingProfilesService.generateDaySchedules(getDefaultProfile())
        };

        if ($ctrl.dataPromise) {
            $ctrl.dataPromise.then(onDataLoaded);
        } else {
            completeInitialSetup();

            $ctrl.state.dataLoaded = true;

            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
        }
    };

    /**
     * Collapses or expands a single weekDay view
     * Changes are handled by evcloud-accordion
     * @param {Object} weekDay
     */
    $ctrl.toggleWeekDayView = weekDay => {
        weekDay.isExpanded = !weekDay.isExpanded;
    };

    /**
     * Expands a single weekDay view
     * Changes are handled by evcloud-accordion
     * @param {Object} weekDay
     */
    $ctrl.expandWeekDay = weekDay => {
        weekDay.isExpanded = true;
    };

    /**
     * Saves charging profile details
     */
    $ctrl.save = () => {
        $ctrl.state.saveInProgress = true;

        const payload = _.assign({}, _.omit($ctrl.model, 'daySchedules', 'id'), {schedule: chargingProfilesService.buildSchedulesPayload($ctrl.model.daySchedules)});

        ($ctrl.state.creatingChargingProfile ? chargingProfilesService.createChargingProfile(payload) : chargingProfilesService.updateChargingProfile($ctrl.model.id, payload))
            .then(() => {
                if (!$ctrl.state.creatingChargingProfile) {
                    displaySuccessMessage('generic.notification.success');

                    setDataBaseState();
                    resetForm();

                    return;
                }

                displaySuccessMessage('stations.chargingProfiles.form.notification.create.success');

                if (!$ctrl.state.createAnother) {
                    $state.go('auth.stations.charging-profiles', null, {reload: 'auth.stations.charging-profiles'});

                    return;
                }

                $state.go($state.current.name, {createAnotherChecked: true}, {reload: $state.current.name});
            })
            .catch(({status}) => {
                mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                    type: 'error',
                    messageKey: status === 409 ? 'stations.chargingProfiles.form.notification.create.nameAlreadyInUse' : CONSTANTS.ERROR_CODES[status]
                });
            })
            .finally(() => {
                $ctrl.state.saveInProgress = false;
            });
    };

    /**
     * Cancels the creation of the charging profile and navigates to charging profiles list
     */
    $ctrl.onCancel = () => {
        if ($ctrl.state.creatingChargingProfile) {
            $state.go('auth.stations.charging-profiles', null, {reload: 'auth.stations.charging-profiles'});
        } else {
            $ctrl.model = JSON.parse($ctrl.modelCopy);
            $ctrl.state.hasChanged = false;
            resetForm();

            const {weekDays, offsetOptions, powerBars, powerValues} = JSON.parse($ctrl.formDataCopy);

            $ctrl.weekDays = weekDays;
            $ctrl.offsetOptions = offsetOptions;
            $ctrl.powerBars = powerBars;
            $ctrl.powerValues = powerValues;
        }
    };

    /**
     * Updates the state hasChanged property based on the form's base model's values
     */
    $ctrl.onChange = () => {
        $ctrl.state.hasChanged = JSON.stringify($ctrl.model) !== $ctrl.modelCopy;

        buildPowerBars();
        buildOffsetOptions();

        $ctrl.chargingProfileForm.$pristine = !$ctrl.state.hasChanged;
    };

    /**
     * Calculates power values and updates the state hasChanged property based on the form's base model's values
     * @param {number} weekdayIndex
     * @param {number} intervalIndex
     * @param {number} current
     */
    $ctrl.onIntervalMaxCurrentChange = (weekdayIndex, intervalIndex, current) => {
        $ctrl.powerValues[weekdayIndex][intervalIndex] = chargingProfilesService.calculatePowerValues(current);

        $ctrl.onChange();
    };

    /**
     * Sorts intervals in the day schedule that has changed
     * @param {Object} interval
     * @param {number} dayIndex
     */
    $ctrl.onOffsetChange = (interval, dayIndex) => {
        $ctrl.model.daySchedules[dayIndex] = _.sortBy($ctrl.model.daySchedules[dayIndex], ['offset']);
        $ctrl.powerValues[dayIndex] = chargingProfilesService.calculatePowerValuesForDay($ctrl.model.daySchedules[dayIndex]);

        $ctrl.onChange();
    };

    /**
     * Resets the first schedule to max current from 00:00 hours
     * @param {Object} day
     */
    $ctrl.resetInterval = day => {
        $ctrl.model.daySchedules[day.index] = [{current: $ctrl.model.maxCurrent, offset: 0}];
        $ctrl.powerValues[day.index] = [chargingProfilesService.calculatePowerValues($ctrl.model.maxCurrent)];

        $ctrl.onChange();

        extendSingleWeekDay(day);
    };

    /**
     * Removes an interval from a day
     * If it is the last one remaining, the schedule is set to max current from 00:00 hours
     * @param {Object} interval
     * @param {Object} day
     */
    $ctrl.removeInterval = (interval, day) => {
        _.remove($ctrl.model.daySchedules[day.index], interval);
        $ctrl.powerValues[day.index] = chargingProfilesService.calculatePowerValuesForDay($ctrl.model.daySchedules[day.index]);

        if (interval.offset === 0) {
            $ctrl.model.daySchedules[day.index][0].offset = 0;
        }

        $ctrl.onChange();

        extendSingleWeekDay(day);
    };

    /**
     * Adds a new empty row to specific day
     * @param {Object} day
     */
    $ctrl.addInterval = day => {
        $ctrl.model.daySchedules[day.index].push(_.assign({}, emptyInterval));
        $ctrl.powerValues[day.index].push(_.assign({}, emptyIntervalPowerValues));

        $ctrl.onChange();

        extendSingleWeekDay(day);
    };

    /**
     * Extends $ctrl.weekDays with property to manage add button state
     */
    function extendWeekDays() {
        _.forEach($ctrl.weekDays, extendSingleWeekDay);
    }

    /**
     * Extends single weekDay object with property to manage add button state and with a tabIndex
     * @param {Object} day
     */
    function extendSingleWeekDay({index}) {
        $ctrl.weekDays[index].addIntervalButtonVisible = $ctrl.model.daySchedules[index].length < intervalsInADay;
    }

    /**
     * Builds the offsetOptions object which contains the available options per hour per day of the week
     */
    function buildOffsetOptions() {
        $ctrl.offsetOptions = chargingProfilesService.createOffsetOptions($ctrl.model.daySchedules, intervalsInADay);
    }

    /**
     * Builds the power bar data from the day schedules
     */
    function buildPowerBars() {
        $ctrl.powerBars = chargingProfilesService.createPowerBarsFromDaySchedules($ctrl.model.daySchedules, $ctrl.model.maxCurrent);
    }

    /**
     * Builds the power values for the specified current - both single and three phase
     */
    function buildPowerValues() {
        $ctrl.powerValues = _.map($ctrl.model.daySchedules, daySchedule => chargingProfilesService.calculatePowerValuesForDay(daySchedule));
    }

    /**
     * Returns the default charging profile object
     * @returns {Object}
     */
    function getDefaultProfile() {
        return {
            maxCurrent: defaultMaxCurrent,
            schedule: _.range(daysInAWeek).map(index => ({current: defaultMaxCurrent, offset: index * CONSTANTS.TIMES.SECONDS_PER_DAY}))
        };
    }

    /**
     * Sets the form's base data from current model
     */
    function setDataBaseState() {
        $ctrl.state.hasChanged = false;
        $ctrl.modelCopy = JSON.stringify($ctrl.model);
        $ctrl.formDataCopy = JSON.stringify(_.pick($ctrl, ['weekDays', 'offsetOptions', 'powerBars', 'powerValues']));
    }

    /**
     * Resets the form state
     */
    function resetForm() {
        $ctrl.chargingProfileForm.$setUntouched();
        $ctrl.chargingProfileForm.$setPristine();
    }

    /**
     * Sets the initial setup
     */
    function completeInitialSetup() {
        buildPowerBars();
        buildOffsetOptions();
        buildPowerValues();
        extendWeekDays();
        setDataBaseState();
    }

    /**
     * Displays success message with the provided messageKey to use
     * @param {string} messageKey
     */
    function displaySuccessMessage(messageKey) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'success',
            messageKey
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.model = _.assign({}, data, {daySchedules: chargingProfilesService.generateDaySchedules(data)});

        completeInitialSetup();

        $ctrl.state.creatingChargingProfile = false;
        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', $ctrl.model.name]);
    }
}
