angular.module('everon.welcome')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.welcome.billing-information', {
        url: '/billing-information',
        data: {
            title: 'generic.title.billingInformation',
            order: 1
        },
        resolve: {
            data: ['$q', 'accountFormModel', 'accountService', 'countryService', 'profileService', ($q, accountFormModel, accountService, countryService, profileService) => {
                return $q.all({
                    accountFormModel,
                    billing: accountService.getBillingInfo().catch(response => response.status === 404 ? {} : $q.reject(response)),
                    reimbursement: accountService.getReimbursementSettings().catch(response => response.status === 404 ? {} : $q.reject(response)),
                    countries: countryService.getTenantCountries(),
                    profile: profileService.getProfile()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.welcome': {
                template: '<evcloud-welcome-account-billing-information data-data-promise="::$resolve.data" class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
