angular.module('everon.stations.sites.site.smatch-integration')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.sites.site.smatch-integration', {
        url: '/smatch-integration',
        data: {
            id: 'smatch-integration-navigation-link',
            title: 'generic.title.smatchIntegration',
            navigationType: ['context'],
            menuPriority: 2,
            features: ['smatch']
        },
        resolve: {
            data: ['$transition$', 'stationService', ($transition$, stationService) => {
                return stationService.getSite($transition$.params('to').id);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.sites.site': {
                component: 'evcloudSmatchIntegration',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}

