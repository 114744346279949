/**
 * Checks if at least one model is true in an array
 *
 * @example
 *
 */
angular.module('everon.component.is-one-checked')
       .directive('evcloudIsOneChecked', isOneChecked);

isOneChecked.$inject = [];

function isOneChecked() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.isOneChecked = () => {
                const model = attributes.evcloudIsOneChecked ? JSON.parse(attributes.evcloudIsOneChecked) : [];

                return !model.length || model.some(_.property('enabled'));
            };

            attributes.$observe('evcloudIsOneChecked', () => {
                if (!_.isUndefined(ngModel.$viewValue)) {
                    ngModel.$validate();
                }
            });
        }
    };
}
