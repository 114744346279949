angular.module('everon.stations.station.details')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.details', {
        url: '/details',
        data: {
            id: 'station-details-navigation-link',
            title: 'generic.title.details',
            navigationType: ['context'],
            menuPriority: 30,
            permissions: ['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']
        },
        resolve: {
            data: ['$q', 'data', 'stationService', ($q, data, stationService) => {
                return $q.all({
                    chargePoint: stationService.getChargePointData(data.id),
                    station: stationService.getStation(data.id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-details data-data-promise="::$resolve.data" />'
            }
        }
    });
}
