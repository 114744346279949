import vueModules from '../../index-vue';

angular.module('everon.component.vue-component')
       .directive('evcloudVueComponent', vueComponent);

function vueComponent() {
    return {
        restrict: 'E',
        scope: {
            props: '<?',
            events: '<?',
            name: '@'
        },
        async link(scope, element) {
            const {Vue, i18n} = await vueModules;
            const {name, props, events} = scope;

            let component = new Vue({
                el: element[0],

                data: {
                    componentName: name,
                    props: {...props},
                    events: events
                },

                template: `<component :is="componentName"
                                      v-bind="props"
                                      v-on="events"><span ref="__slot__"></span></component>`,

                i18n
            });

            if (component.$refs.__slot__) {
                component.$refs.__slot__.replaceWith(...element[0].children);
            }

            scope.$watch('props', newProps => {
                component.props = newProps;
            });

            scope.$watch('events', newEvents => {
                component.events = newEvents;
            });

            scope.$on('$destroy', () => {
                component.$destroy();
                component.$el.remove();
                component = null;
            });
        }
    };
}
