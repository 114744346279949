angular.module('everon.component.no-results')
       .controller('NoResultsController', NoResultsController);

function NoResultsController() {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.model = $ctrl.config;
    };
}
