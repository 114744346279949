angular.module('everon.landing.registration')
       .controller('RegistrationController', RegistrationController);

RegistrationController.$inject = ['$q', '$window', '$state', '$translate', '$log', 'config', 'CONSTANTS', 'sessionService', 'mediatorService', 'grecaptcha', 'localStore', 'validationService'];

function RegistrationController($q, $window, $state, $translate, $log, config, CONSTANTS, sessionService, mediatorService, grecaptcha, localStore, validationService) {
    const $ctrl = this;
    const needsReCaptcha = !config.isMobile && !config.isDevMode;
    let reCaptchaClientId;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        if (needsReCaptcha) {
            $ctrl.recaptchaLanguage = $translate.use();

            mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.LANGUAGE_CHANGE, updateReCaptchaLanguage);
        }

        $ctrl.state = {
            passwordVisible: false,
            confirmPasswordVisible: false,
            registerInProgress: false,
            reCaptchaPassed: true,
            isInvitedUser: false
        };

        $ctrl.model = {};

        $ctrl.dataPromise.then(onDataLoaded);

        $ctrl.passwordStrength = -1;

        $ctrl.asyncPasswordStrengthValidatorConfig = {
            name: 'passwordStrength',
            validatorFn: password => validationService.validatePasswordStrength(password).then(({score}) => {
                $ctrl.passwordStrength = score;

                return score < CONSTANTS.PASSWORD_STRENGTH_MIN_LEVEL ? $q.reject() : $q.resolve();
            })
        };
    };

    $ctrl.$postLink = () => {
        if (needsReCaptcha) {
            reCaptchaClientId = grecaptcha.render('g-recaptcha', {
                sitekey: localStore.get('E2E_TEST_ENV') ? CONSTANTS.RECAPTCHA.TEST_SITE_KEY : $ctrl.reCaptcha.key,
                size: 'invisible',
                badge: 'bottomright',
                hl: $ctrl.recaptchaLanguage
            });
        }
    };

    $ctrl.togglePasswordVisibility = passwordField => {
        $ctrl.state[`${passwordField}Visible`] = !$ctrl.state[`${passwordField}Visible`];
    };

    $ctrl.register = () => {
        if (needsReCaptcha) {
            grecaptcha.execute(reCaptchaClientId, {
                          action: 'registration'
                      })
                      .then(token => sessionService.getReCaptchaScore({response: token}))
                      .then(response => {
                          $log.debug(response);
                      });
        }

        const requestData = _.omit($ctrl.model, ['confirmPassword']);

        requestData.languageTag = $window.localStorage.getItem($translate.storageKey()) || 'en-GB';
        $ctrl.state.registerInProgress = true;

        ($ctrl.state.isInvitedUser ? updateAndActivateInvitedAccount(requestData, $ctrl.params.activationCode) : createAccount(requestData)).finally(() => {
            $ctrl.state.registerInProgress = false;
        });
    };

    $ctrl.onPasswordChange = () => {
        if (!$ctrl.model.password) {
            $ctrl.passwordStrength = -1;
        }
    };

    /**
     * Updates and activates invited user account. If account has already been activated/activation code has expired (status code 417), we redirect to the login page
     * @param {Object} requestData
     * @param {string} activationCode
     * @returns {Promise}
     */
    function updateAndActivateInvitedAccount(requestData, activationCode) {
        return sessionService.updateAndActivateInvitedAccount(requestData, activationCode)
                             .then(() => $state.go('anon.landing.login', {activationCompleted: true}, {location: 'replace'}))
                             .catch(({status}) => {
                                 onRejected(status === 417 ? 'landing.registration.notification.registrationFailed' : (CONSTANTS.ERROR_CODES[status] || CONSTANTS.ERROR_CODES[500]));
                             });
    }

    /**
     * Creates new user account
     * @param {Object} requestData
     * @returns {Promise}
     */
    function createAccount(requestData) {
        return sessionService.createAccount(requestData)
                             .then(() => $state.go('anon.landing.login', {registrationSent: true}))
                             .catch(({status}) => {
                                 onRejected((status === 409) ? 'landing.registration.notification.registrationFailed' : (CONSTANTS.ERROR_CODES[status] || CONSTANTS.ERROR_CODES[500]));
                             });
    }

    /**
     * Shows notification error message depending on response status code
     * @param {string} messageKey
     */
    function onRejected(messageKey) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey,
            isPersistent: true
        });
    }

    /**
     * Updates the language of Google reCAPTCHA once it is already rendered
     * @param {string} language The language to use in the translations
     */
    function updateReCaptchaLanguage(language) {
        const reCaptchaIframe = document.querySelector('#g-recaptcha iframe');

        reCaptchaIframe.src = reCaptchaIframe.src.replace(`hl=${$ctrl.recaptchaLanguage}`, `hl=${language}`);
        $ctrl.recaptchaLanguage = language;
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({params, supportLinks}) {
        $ctrl.supportLinks = supportLinks;
        $ctrl.params = params;
        $ctrl.model.email = params.email || null;
        $ctrl.state.isInvitedUser = Boolean(params.email && params.activationCode);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
