angular.module('everon.cards.card-add.card-overview')
       .controller('CardAddOverviewController', CardAddOverviewController);

CardAddOverviewController.$inject = ['$q', 'cardService', 'accountService', 'CONSTANTS', 'mediatorService', 'modalService'];

function CardAddOverviewController($q, cardService, accountService, CONSTANTS, mediatorService, modalService) {
    const $ctrl = this;
    let modal;
    const CANCELLED_MODAL = 'CANCELLED_MODAL';

    $ctrl.$onInit = () => {
        $ctrl.state = {
            saveInProgress: false,
            termsAgreed: false
        };

        if ($ctrl.cardAddCtrl.payload.card.eMI3ContractId) {
            $ctrl.cardAddCtrl.payload.card.legacyContractId = $ctrl.cardAddCtrl.payload.card.contractId;
            $ctrl.cardAddCtrl.payload.card.contractId = $ctrl.cardAddCtrl.payload.card.eMI3ContractId;
        }

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController',
            data: {
                title: 'generic.title.partialActivation',
                paragraph: 'cards.cardActivate.partialActivationModal.paragraph',
                translateValues: {
                    // Handle cases when billing was removed from card and it's being accessed
                    email: $ctrl.cardAddCtrl.payload.accountOwnerEmail ? $ctrl.cardAddCtrl.payload.accountOwnerEmail : ''
                },
                button: {
                    ok: 'generic.action.sendLink'
                }
            }
        }).then(modalInstance => {
            modal = modalInstance;
        });
    };

    $ctrl.previous = () => {
        $ctrl.cardAddCtrl.previous();
    };

    $ctrl.next = () => {
        $ctrl.state.saveInProgress = true;

        $ctrl.selectCardActivationMethod().then($ctrl.cardAddCtrl.next)
                                             .catch(response => {
                                                 // If there was a modal and the user hits cancel, the modal promise will reject.
                                                 // We need to catch that here, but there is no problem so we just bail without showing an error.
                                                 if (response.message === CANCELLED_MODAL) {
                                                     return;
                                                 }

                                                 mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                                     type: 'error',
                                                     isPersistent: true,
                                                     messageKey: CONSTANTS.ERROR_CODES[response.status]
                                                 });
                                             })
                                             .finally(() => {
                                                 $ctrl.state.saveInProgress = false;
                                             });
    };

    $ctrl.selectCardActivationMethod = () => {
        switch (true) {
            case $ctrl.cardAddCtrl.state.canActivateCardOnBehalf:
                return activateCardPendingConfirmation();
            case $ctrl.cardAddCtrl.state.isConfirmingActivation:
                return confirmCardActivation();
            default:
                return saveBillingAndActivateCard();
        }
    };

    function activateCardPendingConfirmation() {
        return modal.open()
                    .then(canProceed => {
                        if (canProceed) {
                            return activateCardOnBehalf();
                        }

                        return $q.reject(new Error(CANCELLED_MODAL));
                    });
    }

    /**
     * Activates card on behalf of another user
     * @returns {Promise}
     */
    function activateCardOnBehalf() {
        const card = transformCardForApi();

        return cardService.activateCardOnBehalf(card);
    }

    /**
     * Filters out unused properties from our card object so it can be sent to the API
     * @returns {Object}
     */
    function transformCardForApi() {
        return _($ctrl.cardAddCtrl.payload.card).omit(['eMI3ContractId', 'accountNameToActivateOn', 'assignToExisting'])
                                                .assign({
                                                    planId: $ctrl.cardAddCtrl.payload.selectedProduct.plan.id,
                                                    purchaseOrderNumber: $ctrl.cardAddCtrl.payload.selectedProduct.purchaseOrderNumber
                                                })
                                                .value();
    }

    /**
     * Saves account billing info and saves card details including product and plan ids
     * @returns {Promise}
     */
    function saveBillingAndActivateCard() {
        const card = transformCardForApi();
        const billingData = _($ctrl.cardAddCtrl.payload.billing).omit('billingContact')
                                                                .assign({invoiceLanguage: $ctrl.cardAddCtrl.payload.billing.invoiceLanguage.tag})
                                                                .value();

        return accountService.saveBillingInfo(billingData)
                             .then(() => cardService.activateCard(card));
    }

    /**
     * Confirms that a card which was activated on behalf of the current user is good
     * @returns {Promise}
     */
    function confirmCardActivation() {
        const {id} = $ctrl.cardAddCtrl.payload.card;
        const data = {activationToken: $ctrl.cardAddCtrl.payload.activationToken};

        return cardService.confirmCardActivation(id, data);
    }
}
