angular.module('everon.account.reimbursement')
       .controller('AccountReimbursementController', AccountReimbursementController);

AccountReimbursementController.$inject = ['$q', 'CONSTANTS', 'accountService', 'validationService', 'mediatorService', 'sessionService', 'permissionService'];

function AccountReimbursementController($q, CONSTANTS, accountService, validationService, mediatorService, sessionService, permissionService) {
    const $ctrl = this;
    let modelCopy = null;

    $ctrl.$onInit = () => {
        const {permissions} = sessionService.getUserProfile();
        const checkPermissions = requiredPermissions => permissionService.resolve(permissions, requiredPermissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            hasChanged: false,
            saveInProgress: false,
            dataLoaded: false,
            canUpdateReimbursementRate: checkPermissions(['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']),
            canSeePersonalInformation: checkPermissions(['ACCOUNT:READ_PERSONAL_INFO'])
        };

        $ctrl.asyncIbanValidatorConfig = {
            name: 'iban',
            validatorFn: verifyIban
        };

        $ctrl.asyncBicValidatorConfig = {
            name: 'bic',
            validatorFn: verifyBic
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state
     */
    $ctrl.onChange = () => {
        setChanged(true);
    };

    /**
     * Cleanup account model and attempt an update
     */
    $ctrl.save = () => {
        if (!$ctrl.state.hasChanged) {
            resetFormState();

            return;
        }

        const model = _.assign({}, $ctrl.model, {
            iban: $ctrl.model.iban.replace(/\s/g, ''),
            bic: $ctrl.model.bic.replace(/\s/g, '')
        });

        $ctrl.state.saveInProgress = true;

        const method = $ctrl.state.isUpdate ? 'updateReimbursementSettings' : 'saveReimbursementSettings';

        accountService[method](model, $ctrl.accoundId).then(data => {
                                                          $ctrl.model = data;
                                                          modelCopy = JSON.stringify($ctrl.model);

                                                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                                              type: 'success'
                                                          });

                                                          resetFormState();
                                                      })
                                                      .catch(response => {
                                                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                                              type: 'error',
                                                              isPersistent: true,
                                                              messageKey: CONSTANTS.ERROR_CODES[response.status]
                                                          });
                                                      })
                                                      .finally(() => {
                                                          $ctrl.state.saveInProgress = false;
                                                      });
    };

    $ctrl.cancel = () => {
        restoreModel(JSON.parse(modelCopy));
        resetFormState();
    };

    /**
     * Stringifies model, replacing all `null` values with empty strings to be consistent with ngModel defaults
     * @param {Object} model
     * @returns {*}
     */
    function stringifyModel(model) {
        return JSON.stringify(model).replace(/null/g, '""');
    }

    /**
     * Checks whether account details have changed while editing
     * @returns {boolean}
     */
    function hasModelChanged() {
        return modelCopy !== stringifyModel($ctrl.model);
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        $ctrl.state.hasChanged = hasChanged && hasModelChanged();
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl.reimbursementForm.$setUntouched();
        $ctrl.reimbursementForm.$setPristine();
        setChanged(false);
    }

    /**
     * Restores the original model
     * @param {Object} model
     */
    function restoreModel(model) {
        $ctrl.model = model;
    }

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        return _.map(formModel, item => _.assign(item, {id: _.kebabCase(item.name)}));
    }

    /**
     * Verifies if IBAN is valid
     * @param {string} modelValue
     * @param {string} viewValue
     * @returns {Promise}
     */
    function verifyIban(modelValue, viewValue) {
        if ($ctrl.modelCopy && JSON.parse($ctrl.modelCopy).iban === viewValue) {
            return $q.resolve();
        }

        return validationService.verifyIban(modelValue.replace(/\s/g, ''));
    }

    /**
     * Verifies if SWIFT/BIC is valid
     * @param {string} modelValue
     * @param {string} viewValue
     * @returns {Promise}
     */
    function verifyBic(modelValue, viewValue) {
        if ($ctrl.modelCopy && JSON.parse($ctrl.modelCopy).bic === viewValue) {
            return $q.resolve();
        }

        return validationService.verifyBic(modelValue.replace(/\s/g, ''));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({accountOwner, reimbursementFormModel, reimbursement, billingOverview, accountId, countryCurrencies}) {
        const {id: userID} = sessionService.getUserProfile();
        const {canSeePersonalInformation} = $ctrl.state;
        const isBankInfoMissing = !reimbursement.iban && !reimbursement.bic;
        const reimbursementForm = canSeePersonalInformation ? reimbursementFormModel : isBankInfoMissing ? [] : reimbursementFormModel.filter(fieldMeta => fieldMeta.name === 'rate');

        $ctrl.state.dataLoaded = true;
        $ctrl.state.isUpdate = !_.isEmpty(reimbursement);
        $ctrl.state.isBankInfoMissing = isBankInfoMissing;
        $ctrl.reimbursementFormModel = extendFormModel(reimbursementForm);
        $ctrl.formDescriptionMessage = `account.reimbursement.form.${canSeePersonalInformation ? 'description' : isBankInfoMissing && 'missingBankInfo'}`;
        $ctrl.model = reimbursement;
        $ctrl.accoundId = accountId;
        $ctrl.currency = _.find(countryCurrencies, {country: billingOverview.billingAddress.country}).currency;
        modelCopy = stringifyModel($ctrl.model);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [accountOwner && accountOwner.id !== userID ? billingOverview.accountName : 'parent', 'current']);
    }
}
