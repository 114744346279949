/**
 * Creates address string
 * @param {Object} address
 * @param {string} address.streetName
 * @param {string} address.house
 * @param {string} address.city
 * @returns {string}
 */
function formatAddress({streetName, house, city}) {
    return `${streetName} ${house}, ${city}`;
}

/**
 * Checks if a station is considered as one within site
 * @param {Object} station
 * @param {Object} station.site
 * @param {string} station.status
 * @returns {boolean}
 */
function assignedToSite({site, status}) {
    return Boolean(site) && status !== 'inactive';
}

/**
 * Creates a wrapper with a station and a placeholder for connectors status
 * @param {Object} station
 * @returns {Object}
 */
function wrapStation(station) {
    return {
        station
    };
}

/**
 * Creates a site object
 * @param {Object} station
 * @returns {Object}
 */
function transformSiteData(station) {
    const {
        site,
        address,
        accountName
    } = station;

    return {
        site: Object.assign({}, site, {
            location: address ? formatAddress(address) : '',
            accountName
        })
    };
}

/**
 * Groups stations by site. Stations without site go as standalone
 * @param {Array} stations - the original list of stations returned by BE
 * @returns {Object}
 */
function groupBySite(stations) {
    const sites = new Map();
    const standalone = [];

    return stations.reduce((acc, station) => {
        const wrapper = wrapStation(station);

        if (assignedToSite(station)) {
            const siteName = station.site.name;

            if (!acc.sites.has(siteName)) {
                acc.sites.set(siteName, [transformSiteData(station)]);
            }

            acc.sites.get(siteName).push(wrapper);
        } else {
            acc.standalone.push(wrapper);
        }

        return acc;
    }, {
        sites,
        standalone
    });
}

/**
 * Structures the content coming from server into ordered [sites|stations] array
 * @param {Array} content
 * @returns {Array}
 */
export function structureContent(content) {
    const {
        sites = new Map(),
        standalone = []
    } = groupBySite(content);

    return [].concat(
        ...sites.values(),
        (sites.size && standalone.length ? [{standaloneHeader: true}] : []),
        standalone
    );
}

/**
 * Convert to camel case a given string, used mainly for station connector types format coming from backend
 * @param {string} text
 * @returns {string}
 */
export function toCamelCase(text) {
    return text.toLowerCase().replace(/([-_][a-z0-9])/ig, $1 => $1.toUpperCase().replace(/^[-_]/g, ''));
}

/**
 * Returns locale which is stored in localStorage.NG_TRANSLATE_LANG_KEY, with 'en-GB' as fallback
 * @returns {string}
 */
export function getLocale() {
    return localStorage.getItem('NG_TRANSLATE_LANG_KEY') || 'en-GB';
}

/**
 * Formats number passed as argument to locale by adding decimal and thousand separators.
 * @param {number} number
 * @returns {string}
 */
export function formatNumberForLocale(number) {
    return new Intl.NumberFormat(getLocale()).format(number);
}

/**
 * Transforms a string array into an object with the values as keys and true as value.
 * @param {Array}arr
 * @returns {*}
 */
export function strArrayToMap(arr = []) {
    return arr.reduce((acc, value) => {
        if (typeof value === 'string') {
            acc[value] = true;
        }

        return acc;
    }, Object.create(null));
}
