angular.module('everon.tenant-settings')
       .factory('tenantSettingsService', tenantSettingsService);

tenantSettingsService.$inject = ['$http'];

function tenantSettingsService($http) {
    /**
     * Transforms data by replacing `null` with empty strings
     * @param {Object} data
     * @returns {Object}
     */
    function transform(data) {
        return _.assignWith({}, data, (destinationValue, sourceValue) => sourceValue || '');
    }

    return {
        /**
         * Returns all support links configured for the tenant
         * @returns {Promise.<Object>}
         */
        getSupportLinks() {
            return $http.get('/api/platform/tenant-settings/support-links')
                        .then(transform);
        },

        /**
         * Saves support links config
         * @param {Object} payload
         * @returns {Promise.<Object>}
         */
        saveSupportLinks(payload) {
            return $http.put('/api/platform/tenant-settings/support-links', payload);
        },

        /**
         * Returns tenant notification settings
         * @returns {Promise.<Object>}
         */
        getNotificationSettings() {
            return $http.get('/api/platform/tenant-settings/notifications');
        },

        /**
         * Saves support links config
         * @param {Object} payload
         * @returns {Promise.<Object>}
         */
        saveNotificationSettings(payload) {
            return $http.put('/api/platform/tenant-settings/notifications', payload);
        },

        /**
         * Returns the feature flags map
         * @returns {Promise.<Object>}
         */
        getFeatureFlags() {
            return $http.get('/api/platform/tenant-settings/features');
        }
    };
}
