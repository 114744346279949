angular.module('everon.billing-plans.products')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.products', {
        url: '/products?sort',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'products-navigation-link',
            title: 'billingPlans.products.title',
            menuPriority: 1,
            navigationType: ['secondary']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/products' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.billing-plans.products');
        },
        resolve: {
            data: ['$q', 'subscriptionService', 'tenantCountryService', ($q, subscriptionService, tenantCountryService) => {
                return $q.all({
                    hasCountries: tenantCountryService.hasCountries(),
                    products: subscriptionService.getProducts()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.billing-plans': {
                template: '<evcloud-products data-data-promise="::$resolve.data" class="flex-auto flex flex-column" />'
            }
        }
    });
}
