angular.module('everon')
       .factory('grecaptcha', grecaptcha);

grecaptcha.$inject = ['$window'];

function grecaptcha($window) {
    if (!$window.grecaptcha) {
        throw new Error('Google reCAPTCHA lib was not added to the project!');
    }

    return $window.grecaptcha;
}
