angular.module('everon.component.nav-list')
       .controller('NavListController', NavListController);

NavListController.$inject = ['sessionService', 'navListService', '$state', 'mediatorService', 'CONSTANTS'];

function NavListController(sessionService, navListService, $state, mediatorService, CONSTANTS) {
    const $ctrl = this;
    const subscribers = [];

    $ctrl.$onInit = () => {
        $ctrl.$state = $state;
        $ctrl.menuItems = null;

        $ctrl.state = {
            expandedSection: null,
            contextNavVisible: false
        };

        setMenuItems();
        subscribers.push(mediatorService.subscribe(CONSTANTS.EVENTS.ACTION.NAV_UPDATE, setMenuItems));
    };

    $ctrl.$onDestroy = () => {
        $ctrl.state.expandedSection = null;
        _.invokeMap(subscribers, _.call);
    };

    /**
     * Sets the expanded section
     * @param {string} sref
     */
    $ctrl.setCurrentExpandedSection = sref => {
        $ctrl.state.expandedSection = sref === $ctrl.state.expandedSection ? null : sref;
    };

    /**
     * Toggles context navigation panel visibility or sets if flag is passed
     * @param {boolean} flag
     */
    $ctrl.setContextNavVisible = flag => {
        $ctrl.state.contextNavVisible = _.isUndefined(flag) ? !$ctrl.state.contextNavVisible : flag;
    };

    /**
     * Sets relevant menu items
     * @param {Object} [params]
     */
    function setMenuItems(params) {
        const user = sessionService.getUserProfile();

        navListService.getFilteredItems($ctrl.filter, user, params).then(items => {
            $ctrl.menuItems = items;
        });
    }
}
