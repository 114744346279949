angular.module('everon.tenant-settings.countries.country-form')
       .controller('CountryFormController', CountryFormController);

CountryFormController.$inject = ['CONSTANTS', 'mediatorService', 'tenantCountryService', '$state', 'modalService'];

function CountryFormController(CONSTANTS, mediatorService, tenantCountryService, $state, modalService) {
    const $ctrl = this;
    const formName = 'countryForm';
    let modal;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            saveInProgress: false,
            deleteInProgress: false,
            hasChanged: false,
            isUpdate: _.has($ctrl.tenantCountry, 'id')
        };

        $ctrl.model = {};

        if ($ctrl.state.isUpdate) {
            $ctrl.model = {
                country: $ctrl.tenantCountry.country,
                company: $ctrl.tenantCountry.company
            };
            $ctrl.modelCopy = JSON.stringify($ctrl.model);
        }

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController',
            data: {
                title: 'tenantSettings.countries.country.form.heading.deleteCountry',
                paragraph: 'tenantSettings.countries.country.form.heading.deleteConfirmation',
                button: {
                    ok: 'generic.action.delete'
                }
            }
        }).then(modalInstance => {
            modal = modalInstance;
        });
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    /**
     * Triggered when a form elements changes
     */
    $ctrl.onChange = () => {
        setChanged(true);
    };

    $ctrl.save = () => {
        if (!$ctrl.state.hasChanged) {
            resetFormState();

            return;
        }

        const payload = {
            companyId: $ctrl.model.company.id
        };

        if (!$ctrl.state.isUpdate) {
            payload.country = $ctrl.model.country;
        }

        $ctrl.state.saveInProgress = true;

        return ($ctrl.state.isUpdate ? update(payload) : save(payload)).finally(onSettled);
    };

    /**
     * Ask for country delete confirmation, if user confirms we attempt delete
     */
    $ctrl.delete = () => {
        modal.open()
             .then(attemptDelete);
    };

    function save(payload) {
        return tenantCountryService.saveCountry(payload)
                                   .then(_.partialRight(onFulfilled, 'countryAddSuccess'));
    }

    function update(payload) {
        return tenantCountryService.updateCountry($ctrl.tenantCountry.id, payload)
                                   .then(_.partialRight(onFulfilled, 'countryUpdateSuccess'));
    }

    /**
     * Attempt country delete
     * @param {boolean} canProceed
     */
    function attemptDelete(canProceed) {
        if (canProceed) {
            $ctrl.state.deleteInProgress = true;

            tenantCountryService.deleteCountry($ctrl.tenantCountry.id)
                                .then(_.partialRight(onFulfilled, 'countryDeleteSuccess'))
                                .catch(() => {
                                    mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                        type: 'error',
                                        isPersistent: true,
                                        messageKey: 'tenantSettings.countries.notification.countryDeleteError'
                                    });
                                })
                                .finally(onSettled);
        }
    }

    /**
     * Successful country creation or update callback
     * @param {Object} response
     * @param {string} messageSuffix
     */
    function onFulfilled(response, messageSuffix) {
        $state.go('auth.tenant-settings.countries', null, {reload: 'auth.tenant-settings.countries'})
              .then(() => {
                  mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                      type: 'success',
                      messageKey: `tenantSettings.countries.notification.${messageSuffix}`
                  });
              });
    }

    /**
     * Reset the state after the promise is resolved
     */
    function onSettled() {
        $ctrl.state.saveInProgress = false;
        $ctrl.state.deleteInProgress = false;
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        const modelChanged = hasModelChanged();

        $ctrl.state.hasChanged = hasChanged && modelChanged;

        if (!modelChanged) {
            $ctrl[formName].$setPristine();
        }
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
        setChanged(false);
    }

    /**
     * Checks whether model changed
     * @returns {boolean}
     */
    function hasModelChanged() {
        return $ctrl.modelCopy ? ($ctrl.modelCopy !== JSON.stringify($ctrl.model)) : true;
    }
}
