angular.module('everon.stations.sites.site.details')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.sites.site.details', {
        url: '/details',
        data: {
            id: 'site-details-navigation-link',
            title: 'generic.title.details',
            navigationType: ['context'],
            menuPriority: 1
        },
        resolve: {
            data: ['$q', '$transition$', 'stationService', 'countryService', 'siteFormModel', ($q, $transition$, stationService, countryService, siteFormModel) => {
                    return $q.all({
                        countries: countryService.getTenantCountries(),
                        addressFormModel: siteFormModel.address,
                        site: stationService.getSite($transition$.params('to').id)
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.sites.site': {
                template: '<evcloud-site-details data-data-promise="::$resolve.data" />'
            }
        }
    });
}

