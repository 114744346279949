angular.module('everon.cards.card-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card-add-on-behalf-confirm', {
        url: '/:cardId/confirm-activation?activationToken',
        redirectTo: 'auth.cards.card-add-on-behalf-confirm.card-overview',
        data: {
            title: 'cards.cardActivate.title',
            permissions: ['CARD:ACTIVATE']
        },
        resolve: {
            data: ['$q', '$transition$', 'cardService', 'subscriptionService', 'accountService', ($q, $transition$, cardService, subscriptionService, accountService) => {
                const {cardId, activationToken} = $transition$.params('to');

                return $q.all({
                    activationToken,
                    card: cardService.getCard(cardId),
                    billing: accountService.getBillingInfo().catch(),
                    subscription: subscriptionService.getCardSubscription(cardId).catch()
                }).catch(e => {
                    // Handle the case when a card was put back into inactive state and it's subscription was removed
                    return $q.all({
                        activationToken,
                        card: cardService.getCard(cardId),
                        billing: null,
                        subscription: null
                    });
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-card-add data-data-promise="::$resolve.data" class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}
