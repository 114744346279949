angular.module('everon.users.system-users.groups.user-group-create')
       .controller('UserGroupCreateController', UserGroupCreateController);

UserGroupCreateController.$inject = ['$state', '$q', 'localStore', 'mediatorService', 'CONSTANTS'];

function UserGroupCreateController($state, $q, localStore, mediatorService, CONSTANTS) {
    const $ctrl = this;
    const storageKey = 'newGroup';

    $ctrl.$onInit = () => {
        $ctrl.state = {
            stepsCompleted: false
        };

        $ctrl.states = _($state.$current.parent.children).sortBy('data.order')
                                                         .map(item => ({
                                                             name: item.name,
                                                             title: item.data.title
                                                         }))
                                                         .value();

        $ctrl.payload = localStore.get(storageKey) || {
            members: [],
            manager: null,
            groupName: null
        };

        updateIndex();

        $ctrl.memberNames = memberNames;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [{isKey: true, title: 'generic.entity.systemUsers'}, {isKey: true, title: 'users.groups.groupCreate.title'}]);
    };

    $ctrl.$onDestroy = () => {
        localStore.remove(storageKey);
    };

    /**
     * Goes to the next step
     * @param {Object} params
     * @returns {Promise}
     */
    $ctrl.next = params => {
        const isLastStep = _.last($ctrl.states).name === $state.current.name;
        const nextState = _.get($ctrl.states[$state.current.data.order + 1], 'name');

        $ctrl.payload.params = _.assign({}, $ctrl.payload.params, params);
        localStore.set(storageKey, $ctrl.payload);

        return isLastStep ? setComplete() : $state.go(nextState, $ctrl.payload.params, {reload: nextState}).then(updateIndex);
    };

    /**
     * Goes to the previous step
     * @param {Object} params
     * @returns {Promise}
     */
    $ctrl.previous = params => {
        const previousState = $ctrl.states[$state.current.data.order - 1].name;

        $ctrl.payload.params = _.assign({}, $ctrl.payload.params, params);
        localStore.set(storageKey, $ctrl.payload);

        return $state.go(previousState, $ctrl.payload.params, {reload: previousState}).then(updateIndex);
    };

    /**
     * Transitions to a given state
     * @param {string} name
     */
    $ctrl.goToStep = name => {
        localStore.set(storageKey, $ctrl.payload);

        $state.go(name, $ctrl.payload.params, {reload: name}).then(updateIndex);
    };

    /**
     * Updates current state index
     */
    function updateIndex() {
        $ctrl.currentStateIndex = _.findIndex($ctrl.states, {name: $state.current.name});
    }

    /**
     * Updates the state to completed and removes group data from local storage
     * @returns {Promise}
     */
    function setComplete() {
        $ctrl.state.stepsCompleted = true;
        localStore.remove(storageKey);

        return $q.resolve();
    }

    function memberNames() {
        return $ctrl.payload.members.map(member => member.name).join(', ');
    }
}
