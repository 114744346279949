angular.module('everon.stations.list')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.list', {
        url: '?status&sort&query&page&size',
        dynamic: true,
        data: {
            id: 'stations-navigation-link',
            title: 'stations.list.title',
            navigationType: ['secondary'],
            menuPriority: 1
        },
        redirectTo($transition$) {
            const $state = $transition$.injector().get('$state');
            const featureToggleService = $transition$.injector().get('featureToggleService');
            const sessionService = $transition$.injector().get('sessionService');

            // Check if user prefers CPO dashboard and redirect there.
            // Stay at stations list otherwise
            const preferenceSetting = featureToggleService('@@useStationsList')
                .select({
                    disabledObject: $state.target('auth.stations.cpo-dashboard', null, {reload: 'auth.stations.cpo-dashboard'}),
                    enabledObject: true
                });

            const {permissions} = sessionService.getUserProfile();
            const {cpoDashboard: cpoDashboardEnabled} = sessionService.getTenantFeatureFlags();

            return cpoDashboardEnabled && permissions.includes('STATION:DASHBOARD_READ') ? preferenceSetting : true;
        },
        params: {
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            }
        },
        resolve: {
            data: ['stationService', '$transition$', (stationService, $transition$) => {
                const {status, query, page, size} = $transition$.params('to');

                return stationService.getStations({status, query, page, size});
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-stations-list data-data-promise="::$resolve.data" profile="::$resolve.auth.profile" class="flex-auto flex flex-column" />'
            }
        }
    });
}
