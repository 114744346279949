angular.module('everon.users.system-users.groups.user-group-create.user-group-overview')
       .controller('UserGroupCreateOverviewController', GroupCreateOverviewController);

GroupCreateOverviewController.$inject = ['userGroupsService', 'mediatorService', 'CONSTANTS'];

function GroupCreateOverviewController(userGroupsService, mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            saveInProgress: false
        };
    };

    $ctrl.next = () => {
        $ctrl.state.saveInProgress = true;

        saveGroup()
            .then($ctrl.userGroupCreateCtrl.next)
            .catch(response => {
                mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                    type: 'error',
                    isPersistent: true,
                    messageKey: CONSTANTS.ERROR_CODES[response.status]
                });
            })
            .finally(() => {
                $ctrl.state.saveInProgress = false;
            });
    };

    $ctrl.previous = () => {
        $ctrl.userGroupCreateCtrl.previous();
    };

    /**
     * Saves the created group
     * @returns {Promise}
     */
    function saveGroup() {
        return userGroupsService.createGroup(
            _.pick($ctrl.userGroupCreateCtrl.payload.params, ['name', 'users', 'manager'])
        );
    }
}
