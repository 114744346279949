angular.module('everon.tenant-settings.support-links')
       .value('supportLinksFormModel', [
           {
               id: 'knowledge-center',
               name: 'knowledgeCenter',
               type: 'url',
               required: false
           },
           {
               id: 'privacy-policy',
               name: 'privacyPolicy',
               type: 'url',
               required: false
           },
           {
               id: 'terms-and-conditions',
               name: 'termsAndConditions',
               type: 'url',
               required: true
           }
       ]);
