angular.module('everon.users.system-users.groups.user-group-create.user-group-overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users.groups.user-group-create.user-group-overview', {
        data: {
            title: 'generic.title.overview',
            order: 2
        },
        views: {
            'content@auth.users.system-users.groups.user-group-create': {
                template: '<evcloud-group-create-overview class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
