angular.module('everon.stations.sites.site-add')
       .controller('SiteAddController', SiteAddController);

SiteAddController.$inject = ['$state', 'stationService', 'mediatorService', 'CONSTANTS'];

function SiteAddController($state, stationService, mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            saveInProgress: false,
            createAnother: false,
            dataLoaded: false
        };

        $ctrl.model = {};

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.save = () => {
        $ctrl.state.saveInProgress = true;

        stationService.createSite($ctrl.model)
                      .then(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              messageKey: 'sites.site.create.notification.success'
                          });

                          if (!$ctrl.state.createAnother) {
                              $state.go('auth.stations', null, {reload: 'auth.stations'});
                          }

                          $ctrl.model = {};
                          $ctrl.SiteDetailsForm.$setUntouched();
                          $ctrl.SiteDetailsForm.$setPristine();
                      })
                      .catch(error => {
                          showErrorNotification(error.status);
                      })
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    $ctrl.cancel = () => {
        $state.go('auth.stations', null, {reload: 'auth.stations'});
    };

    /**
     * Dispatches events for error messages
     * @param {number} responseStatus
     */
    function showErrorNotification(responseStatus) {
        const messageMap = {
            409: 'sites.site.create.notification.nameAlreadyInUse',
            417: 'sites.site.create.notification.invalidAddress'
        };

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: messageMap[responseStatus] || CONSTANTS.ERROR_CODES[responseStatus],
            isPersistent: true
        });
    }

    /**
     * Extends address form model with `id` property
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        return _.map(formModel, value => _.assign(value, {id: _.kebabCase(value.name)}));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({countries, addressFormModel}) {
        $ctrl.countries = countries;
        $ctrl.addressFormModel = extendFormModel(addressFormModel);

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
