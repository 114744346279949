angular.module('everon.cards')
       .factory('cardService', cardService);

cardService.$inject = ['$http', '$q', 'utils', 'transactionMixin', 'searchMixin', 'sortingMixin', 'permissionService', 'sessionService', 'accountService', 'CONSTANTS'];

function cardService($http, $q, utils, transactionMixin, searchMixin, sortingMixin, permissionService, sessionService, accountService, CONSTANTS) {
    const tokensBaseUrl = '/api/tokens';

    /**
     * Returns transformed product
     * @param {Object} product
     * @returns {Object}
     */
    function transformProductCatalog(product) {
        return _.assign(product, {
            defaultCurrency: product.plans?.[0].currency,
            hasPublicTransactionFee: _.some(product.plans, plan => !_.isEmpty(plan.publicTransactionFee)),
            characteristics: _.filter(product.characteristics, 'enabled'),
            price: _(product.plans).map('price')
                                   .sortBy(_.identity)
                                   .first()
        });
    }

    /**
     * Extends partner item with additional properties
     * @param {Object} item
     * @returns {Object}
     */
    function transformPartnerItem(item) {
        if (item.outOfSyncFields) {
            item.outOfSyncFieldsMap = {};
            item.outOfSyncFields.forEach(field => {
                    item.outOfSyncFieldsMap[field] = true;
                }
            );
        }

        return _.assign({isSelected: false, syncInProgress: false}, item);
    }

    return _.assign({}, transactionMixin, searchMixin, sortingMixin, {
        /**
         * Verifies if card with a given contract id exists and if it has an owner
         * @param {string} contractId
         * @returns {Promise.<Object>}
         */
        verifyCard(contractId) {
            return $http.get(`${tokensBaseUrl}/verification`, {params: {contractId}});
        },

        /**
         * Returns paginated cards
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getCards(params) {
            return $http.get(`${tokensBaseUrl}/list`, {
                params: _(params).pickBy()
                                 .defaults({page: 0})
                                 .value()
            });
        },

        /**
         * Activates inactive card
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        activateCard(requestData) {
            return $http.post(`${tokensBaseUrl}/activation`, requestData);
        },

        /**
         * Activates inactive card on behalf of another user
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        activateCardOnBehalf(requestData) {
            return $http.post(`${tokensBaseUrl}/activation-on-behalf`, requestData);
        },

        /**
          * Activates pending card
          * @param {string} id
          * @param {Object} requestData
          * @returns {Promise.<Object>}
          */
        confirmCardActivation(id, requestData) {
            return $http.patch(`${tokensBaseUrl}/:id/confirm-activation`, requestData, {params: {id}});
        },

        /**
         * Imports cards
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        importCard(requestData) {
            return $http.post(`${tokensBaseUrl}/import`, requestData);
        },

        /**
         * Deletes inactive card with a given ID
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        deleteCard(id) {
            return $http.delete(`${tokensBaseUrl}/:id`, {params: {id}});
        },

        /**
         * Returns a pending card with a given ID back to the inactive state
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        cancelPendingCardActivation(id) {
            return $http.delete(`${tokensBaseUrl}/:id/pending-activation`, {params: {id}});
        },

        /**
         * Returns a card by a given id
         * @param {string} cardId
         * @returns {Promise.<Object>}
         */
        getCard(cardId) {
            return $http.get(`${tokensBaseUrl}/:id`, {params: {id: cardId}});
        },

        /**
         * Updates card
         * @param {string} cardId
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        updateCard(cardId, requestData) {
            return $http.patch(`${tokensBaseUrl}/:id`, requestData, {params: {id: cardId}});
        },

        /**
         * Returns an array of all closed transactions for a given card, sorted by year and month
         * @param {string} cardId
         * @param {Object} range
         * @returns {Promise.<Array>}
         */
        getClosedTransactions(cardId, range) {
            return $http.get('/api/billing/transactions/cards/:id', {params: _.assign({id: cardId, status: 'closed'}, utils.localDatesToUTC(range))})
                        .then(_.partialRight(_.map, this.transformTransactionDetails));
        },

        /**
         * Checks if subscription can be set on a card, 200 if true, 412 if a subscription was set in the last 24h.
         * @param {string} cardId
         * @returns {Promise}
         */
        isUpdatable(cardId) {
            return $http.head('/api/billing/subscriptions/cards/:id/updatable', {params: {id: cardId}})
                        .then(response => response.status === 200)
                        .catch(() => false);
        },

        /**
         * Returns a list of card products where each products contains a list of plans
         * @param {string} country
         * @param {string} accountId
         * @returns {Promise.<Array>}
         */
        getProductCatalog(country, accountId) {
            return $http.get('/api/billing/product-catalog/cards', {params: {country, accountId}})
                        .then(_.partialRight(_.map, transformProductCatalog));
        },

        /**
         * Returns a list of card products available for card plan update
         * @param {Object} subscription
         * @param {Object} card
         * @returns {Promise.<Array>}
         */
        getAvailableProductCatalog(subscription, card) {
            const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);
            const {
                product: {country: {code}}
            } = subscription;
            const {
                accountId,
                status,
                deactivationDate
            } = card;

            return status === 'active' && !deactivationDate && checkPermissions(['CARD:UPDATE']) ?
                this.getProductCatalog(code, accountId) : $q.resolve([]);
        },

        /**
         * Returns partner connections with blueprint responses
         * @param {string} rfid Card rfid
         * @returns {Promise}
         */
        getPartnerConnections(rfid) {
            return $http.get('/api/roaming/tokens/:rfid/partner-connections', {params: {rfid}})
                        .then(items => items.map(transformPartnerItem));
        },

        /**
         * Gets details of partner connection
         * @param {string} url
         * @returns {Promise}
         */
        getPartnerItem(url) {
            return $http.get(`${url}/diff`)
                        .then(transformPartnerItem);
        },

        /**
         * Update details of partner connection
         * @param {string} url
         * @returns {Promise}
         */
        syncPartnerItem(url) {
            return $http.put(url)
                        .then(transformPartnerItem);
        },

        downloadCardTransactions(...args) {
            return this.downloadTransactions(...args, 'cards');
        },

        /**
         * Loads transactions and reimbursement for a given card.
         * Defines is user allowed to see reimbursable.
         * @param {string} startDate
         * @param {string} endDate
         * @param {Object} card
         * @returns {Promise}
         */
        setupTransactionsData(startDate, endDate, card) {
            const isRoleAllowedToSeeReimbursable = sessionService.getUserProfile()?.roles.some(userRole => CONSTANTS.ROLES.ALLOWED_TO_SEE_REIMBURSABLE.includes(userRole));
            const assetOwnerId = card.accountId || 'me';

            return $q.all({
                transactions: this.getClosedTransactions(card.id, {startDate, endDate}),
                card: card,
                reimbursement: isRoleAllowedToSeeReimbursable ? accountService.getReimbursementSettings(assetOwnerId) : null,
                isRoleAllowedToSeeReimbursable
            });
        }
    });
}
