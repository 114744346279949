/**
 * Displays a map with one marker or more markers.
 * Parameters:
 *  config - map configuration object
 *  location - A location object with coordinates
 *      {lat: 52.363184, lng: 4.976927}
 * @example
 *  Usage:
 *   <evcloud-google-maps config="{options: {zoom: 15}}" location="{lat: 52.363184, lng: 4.976927}"></evcloud-google-maps>
 */
angular.module('everon.component.google-maps')
       .component('evcloudGoogleMaps', {
           templateUrl: 'components/google-maps/google-maps.template.html',
           controller: 'GoogleMapsController',
           bindings: {
               config: '<?',
               location: '<'
           }
       });
