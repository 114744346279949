angular.module('everon')
       .factory('financialService', financialService);

financialService.$inject = ['$http'];

function financialService($http) {
    return {
        getCurrencies() {
            return $http.get('/api/platform/currencies');
        }
    };
}
