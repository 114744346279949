angular.module('everon.stations.station-add.station-address')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add-on-behalf-confirm.station-address', {
        data: {
            title: 'generic.title.location',
            order: 0
        },
        resolve: {
            data: ['$q', 'countryService', 'accountFormModel', 'accountService', 'stationLocationFormModel', ($q, countryService, accountFormModel, accountService, stationLocationFormModel) => {
                return $q.all({
                    countries: countryService.getTenantCountries(),
                    stationLocationForm: stationLocationFormModel,
                    accountFeatures: accountService.getFeatureFlags()
                });
            }]
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'stations/station-add/station-address' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.stations.station-add.station-address');
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station-add-on-behalf-confirm': {
                template: '<evcloud-station-add-address data-data-promise="::$resolve.data" class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
