angular.module('everon.stations.station.configuration')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.configuration', {
        url: '/configuration',
        data: {
            id: 'station-configuration-navigation-link',
            title: 'stations.station.configuration.title',
            navigationType: ['context'],
            menuPriority: 95,
            permissions: ['STATION:CONTROL_TERMINAL']
        },
        resolve: {
            data: ['$q', 'data', 'stationService', ($q, data, stationService) => {
                return $q.all({
                    configuration: stationService.getStationConfiguration(data.id),
                    station: data
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-configuration data-data-promise="::$resolve.data" class="flex flex-auto flex-column"/>'
            }
        }
    });
}
