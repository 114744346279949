function getSelectedAccount(state) {
    const {
        filters = []
    } = state;
    const {
        label = null,
        value = null
    } = filters.find(filter => filter.type === 'account') || {};

    return {
        accountId: value,
        name: label
    };
}

export default {
    selectedAccountName(state) {
        return getSelectedAccount(state).name;
    },

    selectedAccountId(state) {
        return getSelectedAccount(state).accountId;
    },

    stationsListFilters(state) {
        const {filters = []} = state;

        if (state.selectedView !== 'search') {
            return filters;
        }

        return null;
    }
};
