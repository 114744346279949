angular.module('everon.stations.station.terminal')
       .controller('TerminalController', TerminalController);

TerminalController.$inject = ['$state', 'stationService', 'mediatorService', 'CONSTANTS'];

function TerminalController($state, stationService, mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            error: false,
            dataLoaded: false,
            submitInProgress: false
        };

        $ctrl.data = {
            action: null,
            payload: null
        };

        $ctrl.response = null;
        $ctrl.stationFirstAidDocUrl = CONSTANTS.EXTERNAL_URLS.STATION_FIRST_AID_DOCUMENT;
        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.send = () => {
        $ctrl.state.submitInProgress = true;

        stationService.sendOcppMessage($ctrl.station.id, $ctrl.data)
                      .then(response => {
                          $ctrl.state.error = false;
                          $ctrl.response = JSON.stringify(toObject(response), null, 2);

                          showSuccessNotification(response[2].status);
                      })
                      .catch(response => {
                          showErrorNotification(response.status);
                          $ctrl.state.error = true;
                          $ctrl.response = response.data.message;
                      })
                      .finally(() => {
                          $ctrl.state.submitInProgress = false;
                      });
    };

    /**
     * Converts OCPP response message array values to key/value pair object
     * @param {Array} values
     * @returns {Object}
     */
    function toObject(values) {
        const keys = ['type', 'callId', 'action', 'payload'];

        return _.zipObject(keys, values);
    }

    /**
     * Navigates to the assistance section for this station
     */
    function goToAssistance() {
        $state.go('auth.stations.station.assistance');
    }

    /**
     * Dispatches events for success messages
     * @param {string}  status
     */
    function showSuccessNotification(status) {
        let options = {
            type: 'success',
            messageKey: 'stations.station.terminal.notification.messageSent'
        };

        if (status === 'RebootRequired') {
            options = _.merge(options, {
                type: 'alert',
                messageKey: 'generic.notification.stationsRebootRequired.message',
                link: {
                    text: 'generic.notification.stationsRebootRequired.linkText',
                    handlerFn: goToAssistance
                },
                isPersistent: true
            });
        }

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, options);
    }

    /**
     * Dispatches events for error messages
     * @param {number} responseStatus
     */
    function showErrorNotification(responseStatus) {
        const messageMap = {
            400: 'generic.notification.stationUnavailable',
            502: 'generic.notification.requestRejected'
        };

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: messageMap[responseStatus] || CONSTANTS.ERROR_CODES[responseStatus],
            isPersistent: true
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded(data) {
        $ctrl.station = data.station;
        $ctrl.actions = data.actions;
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [data.station.reference || data.station.identityCode, 'current']);
    }
}
