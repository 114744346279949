/**
 * Changes string to lower or upper case in inout fields
 * @example
 *     <input evcloud-convert-case="upper">
 *     <input evcloud-convert-case="lower">
 */
angular.module('everon.component.convert-case')
       .directive('evcloudConvertCase', convertCase);

convertCase.$inject = ['$log'];

function convertCase($log) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link(scope, elem, attrs, ngModelCtrl) {
            const options = {
                lower: {
                    fn: 'toLowerCase',
                    cssClass: 'lower-case'
                },
                upper: {
                    fn: 'toUpperCase',
                    cssClass: 'caps'
                }
            };

            const type = attrs.evcloudConvertCase;

            if (!options[type]) {
                $log.debug('No value provided of either `upper` or `lower` to directive `evcloud-convert-case`');

                return;
            }

            elem.addClass(options[type].cssClass);

            function changeCase(value) {
                return value[options[type].fn]();
            }

            ngModelCtrl.$parsers.push(changeCase);
        }
    };
}
