const core = angular.module('everon.core');

// ----- global app configuration ----- //
core.constant('config', {
    appTitle: '',
    lang: 'en-GB',
    isDevMode: localStorage.getItem('devMode') === 'true' || window.location.hostname === 'local.everon.io',
    isMobile: window.EVERON.isMobile,
    tenantUrl: window.EVERON.tenantUrl,
    tenantId: window.EVERON.tenantId,
    cdnUrl: window.EVERON.cdnUrl,
    apiUrl: window.EVERON.apiUrl
});

core.constant('oktaConfig', {
    clientId: '0oaeqd1uspGRSk5LS0x6',
    domain: 'https://everon-test.oktapreview.com',
    issuer: 'https://everon-test.oktapreview.com/oauth2/default',
    redirectUri: `${location.origin}/callback`,
    scopes: ['openid', 'everon.permissions', 'profile']
});

// ----- $stateProvider configuration ----- //
core.config(routeConfiguration);

routeConfiguration.$inject = ['$stateProvider', '$urlMatcherFactoryProvider'];

function routeConfiguration($stateProvider, $urlMatcherFactoryProvider) {
    $stateProvider.state('anon', {
                      abstract: true,
                      resolve: {
                          tenantLanguages: ['languageService', languageService => languageService.getTenantLanguages()]
                      },
                      views: {
                          global: {
                              templateUrl: 'modules/global/global.html'
                          },
                          'header@anon': {
                              template: '<evcloud-anon-header tenant-languages="::$resolve.tenantLanguages"/>'
                          }
                      }
                  })
                  .state('auth', {
                      abstract: true,
                      params: {
                          defaultState: 'auth.dashboard'
                      },
                      resolve: {
                          auth: ['sessionService', sessionService => sessionService.loadSession()]
                      },
                      views: {
                          global: {
                              templateUrl: 'modules/global/global.html'
                          },
                          'header@auth': {
                              template: '<evcloud-header profile="::$resolve.auth.profile"/>'
                          },
                          'nav@auth': {
                              template: '<evcloud-nav-main />'
                          }
                      }
                  });

    // Implements custom param type definition for paging.
    // In the URL we keep human-readable page number (starts from 1), but we send 0-based pages to the server.
    $urlMatcherFactoryProvider.type('page', {
        encode(page) {
            return page + 1;
        },
        decode(page) {
            return parseInt(page, 10) - 1;
        }
    });

    $urlMatcherFactoryProvider.type('base64', {
        encode(data) {
            if (data === undefined) {
                return;
            }

            if (typeof data !== 'string') {
                data = JSON.stringify(data);
            }

            return btoa(data);
        },
        decode(param) {
            try {
                return JSON.parse(atob(param));
            } catch (e) {
                return undefined;
            }
        }
    });
}

// ----- Other provider configuration ----- //
core.config(providerConfiguration);

providerConfiguration.$inject = ['$compileProvider', '$translateProvider', '$httpProvider', '$logProvider', '$urlServiceProvider', '$locationProvider', 'config'];

function providerConfiguration($compileProvider, $translateProvider, $httpProvider, $logProvider, $urlServiceProvider, $locationProvider, config) {
    const isDevMode = config.isDevMode;

    // angular-translate initial setup
    $translateProvider.fallbackLanguage(config.lang)
                      .useStaticFilesLoader({
                          prefix: `${config.cdnUrl ? config.cdnUrl : '.'}/assets/translations/`,
                          suffix: '.json',
                          $http: {
                              withCredentials: false
                          }
                      })
                      .useSanitizeValueStrategy(['escape', 'sceParameters']) // TODO: replace `escape` with `sce` when `$translate` service usage is removed from invoice
                      .useLocalStorage()
                      .useMissingTranslationHandlerLog()
                      .keepContent(true);

    // If enabled, the compiler will enforce that for all bindings of a component that are not set as optional with `?`, an attribute needs to be provided on the component's HTML tag.
    $compileProvider.strictComponentBindingsEnabled(true);

    // Remove angular debug capabilities in production builds for better performance
    $compileProvider.debugInfoEnabled(isDevMode);

    // Disable debug logs in production
    $logProvider.debugEnabled(isDevMode);

    // Performance improvement: resolve simultaneous $http requests at the same time
    $httpProvider.useApplyAsync(true);

    // Makes sure CORS requests share session information
    $httpProvider.defaults.withCredentials = true;

    // Add http interceptor
    $httpProvider.interceptors.push('httpInterceptor');

    // Correct default header for PATCH request
    $httpProvider.defaults.headers.patch = {
        'Content-Type': 'application/merge-patch+json'
    };

    // If a user is logged in, he will be redirected to `user.dashboard`. See abstract `anon` state config above
    $urlServiceProvider.rules.otherwise('/');

    // Due to issues when running in the Cordova wrapper, html5 mode is only active in the browser env
    $locationProvider.html5Mode(!config.isMobile);
}
