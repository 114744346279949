angular.module('everon.stations.station-location-form')
       .value('stationLocationFormModel', {
           address: [
               {
                   name: 'streetName',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   required: true
               },
               {
                   name: 'house',
                   type: 'text',
                   helpText: 'generic.helpText.house'
               },
               {
                   name: 'postcode',
                   type: 'text',
                   required: true
               },
               {
                   name: 'city',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   required: true
               },
               {
                   name: 'country',
                   accountType: 'universal',
                   type: 'select',
                   translationKey: 'generic.entity.country',
                   cssClass: 'sm-col-10',
                   required: true
               }
           ],
           geoCoordinates: [
               {
                   name: 'geoCoordinates',
                   type: 'text',
                   translationKey: 'stations.stationActivate.steps.1.form.label.latLng',
                   cssClass: 'sm-col-10',
                   required: true,
                   blur: true,
                   regex: /^\s*[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)\s*$/
               }
           ],
           common: [
               {
                   name: 'directions',
                   type: 'textarea',
                   cssClass: 'sm-col-10 block',
                   maxlength: 512
               }
           ]
       });
