angular.module('everon.stations.station.transactions')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.transactions', {
        url: '/transactions?connectorId,startDate,endDate,sort',
        data: {
            id: 'station-transactions-navigation-link',
            title: 'generic.title.transactions',
            navigationType: ['context'],
            menuPriority: 70
        },
        params: {
            startDate: {
                value: ['cardService', cardService => cardService.getDefaultTransactionsRange().startDate],
                type: 'date',
                squash: true
            },
            endDate: {
                value: ['cardService', cardService => cardService.getDefaultTransactionsRange().endDate],
                type: 'date',
                squash: true
            },
            sort: {
                value: 'endedAt,desc',
                squash: true
            }
        },
        dynamic: true,
        resolve: {
            data: ['$transition$', '$q', 'data', 'stationService', 'sessionService', 'accountService',
                ($transition$, $q, data, stationService, sessionService, accountService) => {
                const {stationId, connectorId, startDate, endDate} = $transition$.params('to');
                const transactionsRange = {startDate, endDate};
                const maybeTransactions = (_.has(data, 'connectors[0]') && !connectorId) ? stationService.getClosedTransactions(stationId, transactionsRange) : [];
                const isRoleAllowedToSeeReimbursable = Boolean(_.intersection(sessionService.getUserProfile().roles, ['accountAdmin', 'accountOwner', 'driver', 'subAccountAdmin', 'subAccountOwner']).length);
                const assetOwnerId = data.accountId || 'me';

                return $q.all({
                    station: data,
                    transactions: connectorId ? stationService.getClosedTransactionsByConnector(stationId, connectorId, transactionsRange) : maybeTransactions,
                    reimbursement: isRoleAllowedToSeeReimbursable ? accountService.getReimbursementSettings(assetOwnerId) : null,
                    isRoleAllowedToSeeReimbursable
                });
            }],
            profile: ['auth', auth => auth.profile]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: `<evcloud-station-transactions
                                data-profile="::$resolve.profile"
                                data-data-promise="::$resolve.data"
                                class="flex-auto flex flex-column"
                            />`
            }
        }
    });
}
