angular.module('everon')
       .factory('stateHelper', stateHelper);

stateHelper.$inject = ['$state', 'permissionService', '$transitions', '$q'];

function stateHelper($state, permissionService, $transitions, $q) {
    /**
     * Returns `true` if navigation type matches the one declared in route's config property `data.navigationType`.
     * IMPORTANT! UI Router children states inherit `data` from their parent, hence we check if a child state has its own property `data.navigationType`
     * @param {Object} state
     * @param {string} type
     * @returns {boolean}
     */
    function isMatchingType(state, type) {
        return Object.prototype.hasOwnProperty.call(state.data, 'navigationType') && _.includes(state.data.navigationType, type);
    }

    /**
     * Filters states by a given criteria
     * @param {Array} states
     * @param {Object} filter
     * @returns {Array}
     */
    function filterStates(states, filter) {
        return _.filter(states, state => {
            if (!state.abstract && _.startsWith(state.name, 'auth.')) {
                return filter && state.data && isMatchingType(state, filter.navigationType);
            }

            return false;
        });
    }

    /**
     * Resolves route permissions
     * @param {Array} userPermissions
     * @param {Object} state
     * @returns {boolean}
     */
    function authorise(userPermissions, state) {
        return permissionService.resolve(userPermissions, _.get(state, 'data.permissions', null));
    }

    /**
     * Invokes provided handler for every changed query param and common callback after
     * @param {Object} handlers
     * @param {Function} onTransitionDone
     * @param {Object} transition
     */
    function transitionHandler(handlers, onTransitionDone, transition) {
        const changedParams = transition.paramsChanged();
        const {custom: customOptions = {}} = transition.options();

        if (_.isEmpty(changedParams) || customOptions.silent) {
            return;
        }

        _.forEach(changedParams, (value, key) => {
            if (handlers[key]) {
                handlers[key](value, key);
            }
        });

        onTransitionDone(changedParams);
    }

    /**
     * Subscribes to onSuccess ui-router hook
     * Returns deferred promise which removes subscription on the resolving
     * @param {...*} args
     * @returns {Object}
     */
    function subscribeForTransition(...args) {
        const removeListener = $transitions.onSuccess(...args);
        const deferred = $q.defer();

        deferred.promise.then(removeListener);

        return deferred;
    }

    return {
        /**
         * Returns relevant application states
         * @param {Object} [filter] Filter criteria
         * @param {string} [filter.navigationType]
         * @param {string} [filter.parent] Optional parent state name
         * @returns {Array}
         */
        getStates(filter) {
            if (!filter) {
                return $state.get();
            }

            const states = filter.parent ? this.getChildren(filter.parent) : $state.get();

            return filterStates(states, filter);
        },

        /**
         * Returns all the sates that a user is authorised to access, sorted by menu priority
         * @param {Array} userPermissions
         * @param {Object} filter
         * @returns {Array}
         */
        getAuthorisedStates(userPermissions, filter) {
            return _(this.getStates(filter)).filter(_.partial(authorise, userPermissions))
                                            .sortBy('data.menuPriority')
                                            .value();
        },

        /**
         * Returns the default authorised state name for a user
         * @param {Array} userPermissions
         * @param {Object} filter
         * @returns {string}
         */
        getDefaultStateName(userPermissions, filter) {
            return this.getAuthorisedStates(userPermissions, filter).shift().name;
        },

        /**
         * Returns an array of children states
         * @param {string} state Parent state name
         * @returns {Array}
         */
        getChildren(state) {
            return $state.get(state).getChildren();
        },

        /**
         * Subscribes for params changes in the context of current state
         * @param {Object} handlers
         * @param {Function} onTransitionDone
         * @returns {Promise}
         */
        subscribeToParamsChange(handlers, onTransitionDone = angular.noop) {
            const {name: stateName} = $state.current;

            return subscribeForTransition({from: stateName, to: stateName}, _.partial(transitionHandler, handlers, onTransitionDone));
        },

        /**
         * Returns parsed sorting params
         * @returns {Object}
         */
        getSortingParams() {
            return _.zipObject(['property', 'order'], $state.params.sort.split(','));
        },

        /**
         * Updates current state params
         * @param {Object} params
         * @param {Object} options
         */
        changeParams(params, options = {}) {
            $state.go('.', params, {
                custom: options
            });
        },

        /**
         * Picks dates range params from the state and duplicates it
         * @returns {Object}
         */
        getDateRangeParams() {
            return _($state.params).pick(['startDate', 'endDate'])
                                   .reduce((result, date, key) => _.assign(result, {[key]: new Date(date.getTime())}), {});
        },

        getStateParams(filter) {
            if (filter) {
                return _($state.params).pick(filter)
                                       .value();
            }

            return $state.params;
        },

        clearStateParams(paramsKeys) {
            const params = paramsKeys.reduce((acc, key) => ({...acc, [key]: undefined}), {});

            this.changeParams(params, {silent: true});
        }
    };
}
