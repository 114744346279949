angular.module('everon.account-recovery.password-reset')
       .controller('PasswordResetController', PasswordResetController);

PasswordResetController.$inject = ['$q', '$state', '$stateParams', 'sessionService', 'CONSTANTS', 'mediatorService', 'validationService'];

function PasswordResetController($q, $state, $stateParams, sessionService, CONSTANTS, mediatorService, validationService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            showPassword: false,
            submitInProgress: false
        };

        $ctrl.data = {
            code: $stateParams.code
        };

        $ctrl.passwordStrength = -1;

        $ctrl.asyncPasswordStrengthValidatorConfig = {
            name: 'passwordStrength',
            validatorFn: password => validationService.validatePasswordStrength(password).then(({score}) => {
                $ctrl.passwordStrength = score;

                return score < CONSTANTS.PASSWORD_STRENGTH_MIN_LEVEL ? $q.reject() : $q.resolve();
            })
        };
    };

    $ctrl.$onDestroy = () => {
        resetData();
    };

    $ctrl.togglePasswordVisibility = () => {
        $ctrl.state.showPassword = !$ctrl.state.showPassword;
    };

    $ctrl.resetPassword = () => {
        const requestData = _.pick($ctrl.data, ['password', 'code']);

        $ctrl.state.submitInProgress = true;
        sessionService.resetPassword(requestData)
                      .then(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              buttonText: 'generic.action.ok',
                              messageKey: 'accountRecovery.passwordReset.notification.success'
                          });

                          return $state.go('anon.landing.login');
                      })
                      .catch(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              buttonText: 'generic.action.ok',
                              isPersistent: true,
                              messageKey: 'accountRecovery.passwordReset.notification.failed'
                          });
                      })
                      .finally(() => {
                          $ctrl.state.submitInProgress = false;
                      });
    };

    $ctrl.onPasswordChange = () => {
        if (!$ctrl.data.password) {
            $ctrl.passwordStrength = -1;
        }
    };

    /**
     * Resets user data and form
     */
    function resetData() {
        $ctrl.data = {};
        $ctrl.passwordResetForm.$setPristine();
        $ctrl.passwordResetForm.$setUntouched();
    }
}
