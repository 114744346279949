angular.module('everon.landing')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.landing', {
        abstract: true,
        views: {
            'main@anon': {
                template: '<evcloud-landing class="flex flex-auto" />'
            }
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/landing' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.landing');
        }
    });
}
