angular.module('everon.roaming')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming', {
        url: '/roaming',
        redirectTo: 'auth.roaming.settings',
        data: {
            id: 'roaming-navigation-link',
            title: 'generic.title.roaming',
            menuPriority: 40,
            icon: 'icon-roaming',
            navigationType: ['primary'],
            tenantLevel: true,
            permissions: ['ROAMING_TOKEN:READ']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming');
        },
        views: {
            'main@auth': {
                templateUrl: 'modules/roaming/roaming.lazy.html'
            },
            'navSecondary@auth': {
                template: '<evcloud-nav-list filter="{navigationType: \'secondary\', parent: \'auth.roaming\'}"  />'
            }
        }
    });
}
