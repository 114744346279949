angular.module('everon.cards.card-add.card-overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card-add-on-behalf.card-overview', {
        data: {
            title: 'generic.title.overview',
            order: 2
        },
        views: {
            'content@auth.cards.card-add-on-behalf': {
                template: '<evcloud-card-add-overview class="flex flex-column overflow-hidden col-12"/>'
            }
        }
    });
}
