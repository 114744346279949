angular.module('everon.account')
       .factory('accountService', accountService);

accountService.$inject = ['$http', 'searchMixin'];

function accountService($http, searchMixin) {
    const billingAccountsBaseUrl = '/api/billing/accounts';
    const platformAccountsBaseUrl = '/api/platform/accounts';
    const tokensAccountsBaseUrl = '/api/tokens/accounts';
    const platformAccountSettingsBaseUrl = '/api/platform/account-settings';
    const billingInfoUrl = `${billingAccountsBaseUrl}/:accountId/billing-information`;
    const reimbursementUrl = `${billingAccountsBaseUrl}/:accountId/reimbursement`;
    const advenirUrl = '/api/billing/advenir';

    /**
     * In case the condition is not satisfied, a 204 is returned
     * @param {Object} response
     * @returns {boolean}
     */
    function checkStatus(response) {
        return response.status === 200;
    }

    return _.assign({}, searchMixin, {
        /**
         * Returns user's account billing details
         * @param {string} [accountId=me]
         * @returns {Promise.<Object>}
         */
        getBillingInfo(accountId = 'me') {
            return $http.get(billingInfoUrl, {params: {accountId}});
        },

        /**
         * Updates user's account billing details
         * @param {Object} account
         * @param {string} [accountId=me]
         * @returns {Promise.<Object>}
         */
        saveBillingInfo(account, accountId = 'me') {
            return $http.patch(billingInfoUrl, account, {params: {accountId}});
        },

        /**
         * Returns `true` if user's billing info is complete
         * @param {string} [accountId=me]
         * @returns {Promise.<boolean>}
         */
        getBillingInfoStatus(accountId = 'me') {
            return $http.head(`${billingInfoUrl}/status`, {params: {accountId}})
                        .then(checkStatus);
        },

        /**
         * Returns `true` if user's account type is business
         * @param {string} [accountId=me]
         * @returns {Promise.<boolean>}
         */
        getBusinessAccountStatus(accountId = 'me') {
            return $http.head(`${billingAccountsBaseUrl}/:accountId/business`, {params: {accountId}})
                        .then(checkStatus);
        },

        /**
         * Saves user's account reimbursement settings
         * @param {Object} payload
         * @param {string} [accountId=me]
         * @returns {Promise.<Object>}
         */
        saveReimbursementSettings(payload, accountId = 'me') {
            return $http.post(reimbursementUrl, payload, {params: {accountId}});
        },

        /**
         * Returns reimbursement settings part for a user of a given account
         * @param {string} [accountId=me]
         * @returns {Promise.<Object>}
         */
        getReimbursementSettings(accountId = 'me') {
            return $http.get(reimbursementUrl, {params: {accountId}});
        },

        /**
         * Updates user's account reimbursement settings
         * @param {Object} payload
         * @param {string} [accountId=me]
         * @returns {Promise.<Object>}
         */
        updateReimbursementSettings(payload, accountId = 'me') {
            return $http.patch(reimbursementUrl, payload, {params: {accountId}});
        },

        /**
         * Updates all settings for a certain account
         * @param {Object.<string, boolean>} payload, e.g. {smatch: true, chargingProfile: true, someOtherFeature: false}
         * @param {string} accountId
         * @returns {Promise}
         */
        updateFeatureFlagsForAccount(payload, accountId) {
            return $http.put(`${platformAccountSettingsBaseUrl}/:accountId/features`, payload, {params: {accountId}});
        },

        /**
         * Returns account owner details
         * @param {string} accountId
         * @returns {Promise.<Object>}
         */
        getAccountOwner(accountId) {
            return $http.get(`${platformAccountsBaseUrl}/:accountId/owner`, {params: {accountId}});
        },

        /**
         * Returns advenir settings
         * @returns {Promise.<Object>}
         */
        getAdvenirSettings() {
            return $http.get(advenirUrl);
        },

        /**
         * Updates advenir settings
         * @param {Object} payload
         * @returns {Promise.<Object>}
         */
        updateAdvenirSettings(payload) {
            return $http.patch(advenirUrl, payload);
        },

        /**
         * Returns account feature flags map
         * @returns {Promise.<Object>}
         */
        getFeatureFlags() {
            return $http.get('/api/platform/account-settings/features');
        },

        /**
         * Returns feature flags map for specific account.
         * @param {string} accountId
         * @returns {Promise.<Object>}
         */
        getFeatureFlagsForAccount(accountId) {
            return $http.get(`${platformAccountSettingsBaseUrl}/:accountId/features`, {params: {accountId}});
        },

        /**
         * Checks if a feature is enabled for a certain account.
         * @param {string} feature
         * @param {string} accountId
         * @returns {Promise}
         */
        isFeatureEnabledForAccount(feature, accountId) {
            return this.getFeatureFlagsForAccount(accountId)
                       .then(flags => _.get(flags, feature, false));
        },

        /**
         * Returns accounts
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getAccounts(params) {
            const defaultParams = {
                page: 0,
                size: 30,
                sort: 'name,asc'
            };

            return $http.get(billingAccountsBaseUrl, {params: _.defaults(params, defaultParams)});
        },

        /**
         * Returns overview of billing details
         * @param {string} [accountId=me]
         * @returns {Promise.<Object>}
         */
        getBillingOverview(accountId = 'me') {
            return $http.get(`${billingAccountsBaseUrl}/:accountId/billing-information/overview`, {params: {accountId}});
        },

        /**
         * Returns a list of users (10 max.) that belong to a given account
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getAccountUsers(params) {
            const defaultParams = {
                page: 0,
                size: 10,
                sort: 'email,asc'
            };

            return $http.get(`${platformAccountsBaseUrl}/:accountId/users`, {params: _.defaults(params, defaultParams)});
        },

        /**
         * Returns a list of cards (10 max.) that belong to a given account
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getAccountCards(params) {
            const defaultParams = {
                page: 0,
                size: 10,
                sort: 'visualNumber,asc'
            };

            return $http.get(`${tokensAccountsBaseUrl}/:accountId`, {params: _.defaults(params, defaultParams)});
        },

        /**
         * Returns a list of stations (10 max.) that belong to a given account
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getAccountStations(params) {
            const defaultParams = {
                page: 0,
                size: 10,
                sort: 'identityCode,asc'
            };

            return $http.get(`${platformAccountsBaseUrl}/:accountId/stations`, {params: _.defaults(params, defaultParams)});
        },

        /**
         * Returns a paginated list of (direct) child accounts
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getChildAccounts(params) {
            const defaultParams = {
                page: 0,
                size: 50,
                sort: 'name,asc'
            };

            return $http.get(`${platformAccountsBaseUrl}/me/children`, {params: _.defaults(params, defaultParams)});
        },

        /**
         * Returns a paginated list of all accounts in the current tenant. Will return 403 if not called by a tenant level user
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getAllAccounts(params) {
            const defaultParams = {
                page: 0,
                size: 50,
                sort: 'name,asc'
            };

            return $http.get(`${platformAccountsBaseUrl}/all`, {params: _.defaults({}, params, defaultParams)});
        },

        /**
         * Transforms stations response; renames evses to connectors. TODO: the renaming should not ne needed once we refactor to use EVSES consistently
         * @param {Object} data
         * @returns {Object}
         */
        transformAccountStations({numberOfElements, totalElements, content}) {
            return {
                numberOfElements,
                totalElements,
                content: content.map(station => _.assign(station, {connectors: station.evses}))
            };
        },

        /**
         * Merges connector statuses from platform and OCPP
         * @param {Object} data
         * @returns {Object}
         */
        mergeStationStatus(data) {
            return _.assign({content: data.stations.map(station => _.assign({}, station, {connectorStatus: data.statuses[station.identityCode]}))}, _.pick(data, ['numberOfElements', 'totalElements']));
        }
    });
}
