angular.module('everon')
       .factory('validationService', validationService);

validationService.$inject = ['$http'];

function validationService($http) {
    return {
        /**
         * Verifies IBAN validity
         * @param {string} value
         * @returns {Promise}
         */
        verifyIban(value) {
            return $http.get('/api/billing/validation', {params: {type: 'iban', value}});
        },

        /**
         * Verifies SWIFT/BIC validity
         * @param {string} value
         * @returns {Promise}
         */
        verifyBic(value) {
            return $http.get('/api/billing/validation', {params: {type: 'bic', value}});
        },

        /**
         * Checks if the address in payload is valid or not
         * @param {Object} payload
         * @returns {Promise}
         */
        validateAddress(payload) {
            return $http.post('/api/platform/validation', payload, {params: {type: 'address'}});
        },

        /**
         * Validate the strength of a password
         * @param {string} password
         * @returns {*}
         */
        validatePasswordStrength(password) {
            return $http.post('/api/platform/password-check/strength', {password});
        }
    };
}
