angular.module('everon.account.invoices')
       .controller('AccountInvoicesController', AccountInvoicesController);

AccountInvoicesController.$inject = ['$state', '$q', '$window', '$timeout', '$log', 'mediatorService', 'CONSTANTS', 'invoiceService', 'stateHelper'];

function AccountInvoicesController($state, $q, $window, $timeout, $log, mediatorService, CONSTANTS, invoiceService, stateHelper) {
    const $ctrl = this;
    let accountBasicInfo;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.nextPageInvoices = null;
        $ctrl.pagination = null;

        $ctrl.columns = [
            {
                property: 'date',
                translationKey: 'account.invoices.list.column.date',
                desc: true,
                sortable: false,
                active: true,
                cssClass: 'col-4 sm-col-3'
            },
            {
                property: 'number',
                translationKey: 'account.invoices.list.column.number',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-4'
            },
            {
                property: 'total',
                translationKey: 'account.invoices.list.column.total',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-3'
            }
        ];

        $ctrl.state = {
            dataLoaded: false,
            isPagedList: false,
            isNativeInvoices: false,
            invoicesPreloaded: false,
            isLastPage: false,
            isDownloadingInvoice: false,
            pageLoadInProgress: false
        };

        $ctrl.noResultsConfig = {
            heading: 'account.invoices.list.noResults.heading',
            paragraph: 'account.invoices.list.noResults.paragraph'
        };

        $ctrl.invoicesSupportEmail = null;
        $ctrl.invoicesPreviousToDate = new Date('2020-01-01');

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        if (stateChangeListener) {
            stateChangeListener.resolve();
        }
    };

    /**
     * Updates the view with next page logs and pre-loads another page if `nextPageToken` is not `null`
     */
    $ctrl.viewMore = () => {
        $ctrl.invoices.push(...$ctrl.nextPageInvoices);

        if ($ctrl.nextPageToken) {
            preloadNextPage();

            return;
        }

        // If `nextPageToken` is `null`, we know it's the last page of results
        $ctrl.state.isLastPage = true;
    };

    /**
     * Downloads invoice PDF file
     * @param {Object} invoice
     */
    $ctrl.download = invoice => {
        invoice.downloadInProgress = true;

        invoiceService.downloadInvoice(invoice, $ctrl.accountId, !$ctrl.state.isNativeInvoices)
                      .catch($log.debug)
                      .finally(() => $timeout(() => {
                          invoice.downloadInProgress = false;
                      }));
    };

    /**
     * Updates state with pagination params
     * @param {number} [page]
     */
    $ctrl.goToPage = (page = 0) => {
        const {size} = $ctrl.pagination;

        stateHelper.changeParams({size, page});
    };

    /**
     * Updates columns config with passed name column
     * @param {string} colName
     * @param {string} [cssClass=col-2]
     * @param {number} [index=$ctrl.columns.length] order of the column
     */
    function addColumn(colName, cssClass = 'col-2', index = $ctrl.columns.length) {
        $ctrl.columns.splice(index, 0, {
            property: colName,
            translationKey: `generic.label.${colName}`,
            desc: true,
            sortable: false,
            active: false,
            cssClass
        });
    }

    /**
     * Pre-loads subsequent page of logs so that they are ready when a user clicks `View more` button
     */
    function preloadNextPage() {
        $ctrl.state.invoicesPreloaded = false;

        invoiceService.getAccountInvoices($ctrl.accountId, $ctrl.nextPageToken).then(invoices => {
            $ctrl.nextPageInvoices = invoices.data.items;
            $ctrl.nextPageToken = invoices.data.nextPageToken;
            $ctrl.state.invoicesPreloaded = true;
        });
    }

    /**
     * Updates data model on every change
     * @param {Object} data
     */
    function updateData(data) {
        $ctrl.invoices = data.items;
        $ctrl.invoicesEmailSupport = data.emailSupport;
        updatePagination(data);
    }

    /**
     * Updates pagination data on every change
     * @param {Object} data
     */
    function updatePagination(data) {
        if ($ctrl.state.isPagedList) {
            $ctrl.pagination = invoiceService.getPagination(data);

            if (!$state.params.size) {
                stateHelper.changeParams({size: 30, page: 0});
            }
        }
    }

    /**
     * Updates columns array if type is present in invoice data
     * @param {Array} data
     */
    function updateColumns(data) {
        if (_.some(data, 'type')) {
            addColumn('type', 'sm-col-3', 2);
        }
    }

    /**
     * Requests fresh data
     */
    function getData() {
        const params = _.pick($state.params, ['page', 'size']);

        $ctrl.state.pageLoadInProgress = true;

        invoiceService.getAccountInvoices($ctrl.accountId, params)
                      .then(res => updateData(res.data))
                      .catch(onRejected)
                      .finally(() => {
                          $ctrl.state.pageLoadInProgress = false;
                      });
    }

    /**
     * If requests of fresh data was unsuccessful
     * @param {Object} response
     */
    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({invoices, billingOverview, accountId}) {
        $ctrl.state.isNativeInvoices = invoices.provider === 'EVERON';
        $ctrl.state.isPagedList = !$ctrl.state.isNativeInvoices;

        if ($ctrl.state.isPagedList) {
            stateChangeListener = stateHelper.subscribeToParamsChange({}, getData);
        }

        updateData(invoices.data);
        updateColumns(invoices.data.items);

        accountBasicInfo = billingOverview;
        $ctrl.accountId = billingOverview ? billingOverview.id : accountId;
        $ctrl.nextPageToken = $ctrl.state.isPagedList ? null : invoices.data.nextPageToken;
        $ctrl.state.isLastPage = $ctrl.state.isPagedList ? $ctrl.pagination.last : !$ctrl.nextPageToken;

        $ctrl.state.dataLoaded = true;

        if ($ctrl.nextPageToken && !$ctrl.state.isPagedList) {
            preloadNextPage();
        }

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [accountBasicInfo ? accountBasicInfo.accountName : 'parent', 'current']);
    }
}
