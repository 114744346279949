angular.module('everon.map.map-station-details')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.map.station-details', {
        url: '/:chargePointId',
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/map/map-station-details' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.map.map-station-details');
        },
        resolve: {
            data: ['$q', '$log', '$transition$', 'data', 'mapService', 'geolocation', ($q, $log, $transition$, data, mapService, geolocation) => {
                const storedPosition = geolocation.getStoredPosition();
                const isPageReload = $transition$.from().abstract;

                return $q.all({
                    isPageReload,
                    station: mapService.getStation($transition$.params('to').chargePointId),
                    currentPosition: storedPosition ? $q.resolve(storedPosition) : geolocation.getIpCoordinates()
                                                                                              .catch($log.error)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        onExit: ['$transition$', 'mediatorService', 'CONSTANTS', ($transition$, mediatorService, CONSTANTS) => {
            mediatorService.dispatch(`${CONSTANTS.EVENTS.ACTION.RESIZE_MARKER}_${$transition$.params('from').chargePointId}`);
            mediatorService.dispatch(`${CONSTANTS.EVENTS.ACTION.REMOVE_MARKER}_${$transition$.params('from').chargePointId}`);
        }],
        views: {
            'section@auth.map': {
                template: `<evcloud-map-station-details data-data-promise="::$resolve.data" 
                                                        has-search-criteria="$ctrl.state.hasSearchCriteria" 
                                                        set-search-result-visibility="$ctrl.setSearchResultVisibility(visible)"
                                                        set-station-is-selected="$ctrl.setStationIsSelected(selected)" 
                                                        class="flex-auto block" />`
            }
        }
    });
}
