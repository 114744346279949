/**
 * When added to an action button, this directive will disable the button and show a spinner within the button if the attribute value is
 * equal to `'true'`.
 *
 * @example
 *
 * <button type="submit" evcloud-block-action="{{$ctrl.state.isInProgress}}">Submit</button>
 *
 */

angular.module('everon.component.block-action')
       .directive('evcloudBlockAction', blockAction);

blockAction.$inject = [];

function blockAction() {
    return {
        restrict: 'EA',
        link(scope, element, attributes) {
            attributes.$observe('evcloudBlockAction', onChange);

            /**
             * Toggles disabled state and `role="progressbar"` on the element. Toggles `role="aria-hidden"` on global ui-view
             * @param {string} value
             */
            function onChange(value) {
                const isInProgress = value === 'true';

                element.attr('role', isInProgress ? 'progressbar' : '');
                element.attr('disabled', isInProgress);
            }
        }
    };
}
