angular.module('everon')
       .factory('subscriptionService', subscriptionService);

subscriptionService.$inject = ['$http', 'utils', 'stationEligibilityMixin', 'sortingMixin'];

function subscriptionService($http, utils, stationEligibilityMixin, sortingMixin) {
    // NOTE! The order of characteristics here is important. It should correspond to the one from the BE
    const productDefaultCharacteristics = [
        {
            name: 'privateCharging',
            enabled: true
        },
        {
            name: 'roaming',
            enabled: false
        },
        {
            name: 'connectionTariff',
            enabled: false
        },
        {
            name: 'energyTariff',
            enabled: false
        },
        {
            name: 'timeTariff',
            enabled: false
        }
    ];

    /**
     * In case there are no results, a 204 is returned
     * @param {Object} response
     * @returns {boolean}
     */
    function hasProducts({status}) {
        return status === 200;
    }

    /**
     * Transforms product to get a slim payload for plans
     * @param {Object} product
     * @returns {Object}
     */
    function transformProduct(product) {
        return _.pick(product, ['id', 'name', 'type', 'country', 'currency', 'characteristics']);
    }

    /**
     * Returns default characteristics by type
     * @param {string} type
     * @returns {Array}
     */
    function getCharacteristics(type) {
        return type === 'card' ? productDefaultCharacteristics.slice(0, 2) : productDefaultCharacteristics;
    }

    /**
     * Returns extended characteristic
     * @param {Object} item
     * @returns {Object}
     */
    function extendCharacteristic(item) {
        return _.assign({value: null}, item);
    }

    /**
     * Converts dates to local date format
     * @param {Object} plan
     * @returns {Object}
     */
    function transformPlan(plan) {
        return _.assign(plan, {
            availableFrom: utils.toLocalDateWithOffset(plan.availableFrom).toDate(),
            availableUntil: plan.availableUntil ? utils.toLocalDateWithOffset(plan.availableUntil).toDate() : null
        });
    }

    /**
     * Transforms request payload
     * @param {Object} payload
     * @returns {Object}
     */
    function transformPayload(payload) {
        return _(payload).omit(['product'])
                         .assign({
                             productId: payload.product.id,
                             availableFrom: utils.toUtcDateWithOffset(payload.availableFrom),
                             availableUntil: payload.availableUntil ? utils.toUtcDateWithOffset(payload.availableUntil) : null
                         })
                         .value();
    }

    function transformSubscription(subscription) {
        const {
            renewalDate,
            scheduledUpdate
        } = subscription;

        if (renewalDate) {
            subscription.planEndDate = utils.getMoment(renewalDate).subtract(1, 'day').format();
        }

        if (scheduledUpdate) {
            scheduledUpdate.date = new Date(scheduledUpdate.date);
        }

        return subscription;
    }

    function transformPlanForList(plan) {
        const {
            product: {
                name: product,
                country: {
                    name: productCountry
                }
            }
        } = plan;

        return _(plan).pick('id', 'name', 'type')
                      .assign({product, productCountry})
                      .value();
    }

    function transformProductForList(product) {
        return _(product).pick('id', 'name', 'publicName', 'type')
                         .assign({country: product.country.name})
                         .value();
    }

    return _.assign({}, stationEligibilityMixin, sortingMixin, {
        /**
         * Returns all products
         * @returns {Promise.<Array>}
         */
        getProducts() {
            return $http.get('/api/billing/products')
                        .then(_.partialRight(_.map, transformProductForList));
        },

        /**
         * Returns all products of a given type
         * @param {string} type
         * @param {boolean} smallPayload
         * @returns {Promise.<Array>}
         */
        getProductsByType(type, smallPayload) {
            return $http.get('/api/billing/products', {params: {type}})
                        .then(data => smallPayload ? data.map(transformProduct) : data);
        },

        /**
         * Returns a single card or station product by a given id
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        getProduct(id) {
            return $http.get('/api/billing/products/:id', {params: {id}});
        },

        /**
         * Creates a product
         * @param {Object} payload
         * @returns {Promise.<Object>}
         */
        saveProduct(payload) {
            return $http.post('/api/billing/products', payload);
        },

        /**
         * Updates a product by a given id
         * @param {string} id
         * @param {Object} payload
         * @returns {Promise.<Object>}
         */
        updateProduct(id, payload) {
            return $http.patch('/api/billing/products/:id', payload, {params: {id}});
        },

        /**
         * Deletes a product by a given id
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        deleteProduct(id) {
            return $http.delete('/api/billing/products/:id', {params: {id}});
        },

        /**
         * Checks whether there are any products created
         * @param {string} type
         * @returns {Promise.<boolean>}
         */
        hasProducts(type) {
            return $http.head('/api/billing/products', {params: {type}})
                        .then(hasProducts);
        },

        /**
         * Returns an array of default product characteristics
         * @param {string} type
         * @returns {Array}
         */
        getProductDefaults(type) {
            return getCharacteristics(type).map(extendCharacteristic);
        },

        /**
         * Returns currency code for the selected product. If no product is selected, EUR is used by default
         * @param {Object} product
         * @param {Array} countryCurrencies
         * @returns {string}
         */
        getProductCurrencyCode(product, countryCurrencies) {
            return _.has(product, 'country') ? _.find(countryCurrencies, {country: product.country}).currency.code : 'EUR';
        },

        /**
         * Returns all plans
         * @returns {Promise.<Array>}
         */
        getPlans() {
            return $http.get('/api/billing/plans')
                        .then(_.partialRight(_.map, transformPlanForList));
        },

        /**
         * Returns a single card or station plan by a given id
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        getPlan(id) {
            return $http.get('/api/billing/plans/:id', {params: {id}})
                        .then(transformPlan);
        },

        /**
         * Creates a plan
         * @param {Object} payload
         * @returns {Promise.<Object>}
         */
        savePlan(payload) {
            return $http.post('/api/billing/plans', transformPayload(payload));
        },

        /**
         * Updates a plan by a given id
         * @param {string} id
         * @param {Object} payload
         * @returns {Promise.<Object>}
         */
        updatePlan(id, payload) {
            return $http.patch('/api/billing/plans/:id', transformPayload(payload), {params: {id}});
        },

        /**
         * Deletes a plans by a given id
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        deletePlan(id) {
            return $http.delete('/api/billing/plans/:id', {params: {id}});
        },

        /**
         * Filters disabled characteristics
         * @param {Array} characteristics
         * @returns {Array}
         */
        filterCharacteristics(characteristics) {
            if (Array.isArray(characteristics) && characteristics.length > 0) {
                const enabledCharacteristics = [];

                characteristics.forEach(characteristic => {
                    if (characteristic.enabled) {
                        const clonedCharacteristic = Object.assign({}, characteristic);

                        if (Array.isArray(clonedCharacteristic.value)) {
                            clonedCharacteristic.value = this.filterCharacteristics(clonedCharacteristic.value);
                        }

                        enabledCharacteristics.push(clonedCharacteristic);
                    }
                });

                return enabledCharacteristics;
            }

            return characteristics;
        },

        getStationSubscription(id) {
            return $http.get('/api/billing/subscriptions/stations/:id', {params: {id}})
                        .then(transformSubscription);
        },

        getCardSubscription(id) {
            return $http.get('/api/billing/subscriptions/cards/:id', {params: {id}})
                        .then(transformSubscription);
        },

        getStationProductCharacteristic(id, name) {
            return $http.get('/api/billing/subscriptions/stations/:id/characteristics/:name', {params: {id, name}});
        },

        getCardProductCharacteristic(id, name) {
            return $http.get('/api/billing/subscriptions/cards/:id/characteristics/:name', {params: {id, name}});
        },

        updateCardSubscription(id, payload) {
            return $http.patch('/api/billing/subscriptions/cards/:id', payload, {params: {id}});
        },

        /**
         * Returns defaults for POST request payload
         * @returns {Object}
         */
        getPlanDefaults() {
            return {
                availableFrom: utils.getMoment().startOf('day').toDate(),
                eligibilityCriteria: {
                    accountTypes: [
                        {
                            name: 'business',
                            enabled: false
                        },
                        {
                            name: 'private',
                            enabled: false
                        }
                    ]
                }
            };
        }
    });
}
