angular.module('everon.stations.station.assistance')
       .controller('StationAssistanceController', StationAssistanceController);

StationAssistanceController.$inject = ['stationService', 'sessionService', 'permissionService', 'mediatorService', 'CONSTANTS', 'utils', 'modalService', '$timeout'];

function StationAssistanceController(stationService, sessionService, permissionService, mediatorService, CONSTANTS, utils, modalService, $timeout) {
    const $ctrl = this;
    let modal;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            unlockConnectorSubmitted: false,
            startTransactionSubmitted: false,
            stopTransactionSubmitted: false,
            stationResetSubmitted: false,
            setStationMaxCurrentSubmitted: false,
            setConnectorPhaseRotationsSubmitted: false,
            dataLoaded: false,
            canUnlockConnector: checkPermissions(['CONNECTOR:UNLOCK_CONNECTOR']),
            canStartTransaction: checkPermissions(['TRANSACTION:CREATE']),
            canStartRfidTransaction: checkPermissions(['CARD:ENTER_RAW']),
            canStopTransaction: checkPermissions(['TRANSACTION:UPDATE']),
            canResetStation: checkPermissions(['STATION:CONTROL_RESET']),
            canSetStationMaxCurrent: checkPermissions(['STATION:CONTROL_MAX_CURRENT']),
            canManageConnectorPhaseRotations: checkPermissions(['STATION:CONTROL_PHASE_ROTATION'])
        };

        $ctrl.regex = {
            rfid: CONSTANTS.REGEX.CARD_RFID
        };

        $ctrl.selectedConnector = null;

        $ctrl.startTransactionModel = {
            selectedCard: null,
            selectedConnector: null
        };

        $ctrl.stationTransactionsColumns = [
            {
                property: 'connectorIdentityCode',
                translationKey: 'generic.entity.connector',
                desc: true,
                active: false,
                cssClass: 'col-3 word-break-all'
            },
            {
                property: 'rfid',
                translationKey: 'generic.label.contractId',
                desc: true,
                active: false,
                cssClass: 'col-5'
            },
            {
                property: 'duration',
                translationKey: 'generic.label.duration',
                desc: true,
                active: false,
                cssClass: 'col-3'
            }
        ];

        $ctrl.connectorPhaseRotationColumns = [
            {
                property: 'connectorIdentityCode',
                translationKey: 'generic.entity.connector',
                desc: true,
                active: false,
                cssClass: 'col-4 word-break-all'
            },
            {
                property: 'connectorPhaseRotation',
                translationKey: 'stations.station.assistance.heading.phaseRotation',
                desc: true,
                active: false,
                cssClass: 'col-8 word-break-all'
            }
        ];

        $ctrl.connectorPhaseRotationOptions = [
            {short: 'RST', long: 'Standard Reference Phasing'},
            {short: 'RTS', long: 'Reversed Reference Phasing'},
            {short: 'SRT', long: 'Reversed 240 degree rotation'},
            {short: 'STR', long: 'Standard 120 degree rotation'},
            {short: 'TRS', long: 'Standard 240 degree rotation'},
            {short: 'TSR', long: 'Reversed 120 degree rotation'}
        ];

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController'
        }).then(modalInstance => {
            modal = modalInstance;
        });

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    $ctrl.onUnlockConnector = () => {
        modal.open({
                 title: 'stations.station.assistance.heading.unlockConnector',
                 paragraph: 'stations.station.assistance.heading.unlockConfirm',
                 button: {
                     ok: 'stations.station.assistance.form.button.unlock'
                 }
             })
             .then(unlockConnector);
    };

    $ctrl.onStopTransaction = transaction => {
        modal.open({
                 title: 'stations.station.assistance.heading.stopTransaction',
                 paragraph: 'stations.station.assistance.heading.stopConfirm',
                 button: {
                     ok: 'generic.action.stop'
                 }
             })
             .then(_.partialRight(stopTransaction, transaction));
    };

    $ctrl.onStartTransaction = () => {
        modal.open({
                 title: 'generic.action.startTransaction',
                 paragraph: 'stations.station.assistance.heading.startConfirm',
                 button: {
                     ok: 'generic.action.start'
                 }
             })
             .then(startTransaction);
    };

    $ctrl.onReset = () => {
        modal.open({
                 title: 'stations.station.assistance.heading.resetStation',
                 paragraph: 'stations.station.assistance.heading.resetConfirm',
                 button: {
                     ok: 'generic.action.reset'
                 }
             })
             .then(resetStation);
    };

    /**
     * Starts flow for updating the max current for the station by showing a confirmation modal dialog
     */
    $ctrl.onSetStationMaxCurrent = () => {
        modal.open({
                 title: 'generic.label.maxCurrent',
                 paragraph: 'stations.station.assistance.heading.setMaxCurrent',
                 button: {
                     ok: 'generic.action.update'
                 }
             })
             .then(setStationMaxCurrent);
    };

    /**
     * Starts flow for updating the connector's phase rotations by showing a confirmation modal dialog
     */
    $ctrl.onSetConnectorPhaseRotations = () => {
        modal.open({
                 title: 'stations.station.assistance.heading.setConnectorPhaseRotations',
                 paragraph: 'stations.station.assistance.heading.setConnectorPhaseRotationsConfirm',
                 button: {
                     ok: 'generic.action.update'
                 }
             })
             .then(setConnectorPhaseRotations);
    };

    /**
     * Attempts to remotely unlock the connector
     * @param {boolean} canProceed
     */
    function unlockConnector(canProceed) {
        if (canProceed) {
            $ctrl.state.unlockConnectorSubmitted = true;

            stationService.unlockConnector($ctrl.station.id, $ctrl.selectedConnector.id)
                          .then(onFulfilled)
                          .catch(onRejected)
                          .finally(() => onSettled('unlockConnector'));
        }
    }

    /**
     * Attempts to remotely stop the transaction
     * @param {boolean} canProceed
     * @param {boolean} transaction
     */
    function stopTransaction(canProceed, transaction) {
        if (canProceed) {
            $ctrl.state.stopTransactionSubmitted = true;

            stationService.stopTransaction($ctrl.station.id, transaction.id)
                          .then(onFulfilled)
                          .then(() => updateTransactionState(transaction))
                          .catch(onRejected)
                          .finally(() => onSettled('stopTransaction'));
        }
    }

    /**
     * Attempts to remotely start a transaction
     * @param {boolean} canProceed
     */
    function startTransaction(canProceed) {
        if (canProceed) {
            $ctrl.state.startTransactionSubmitted = true;

            stationService.startTransaction($ctrl.station.id, $ctrl.startTransactionModel.selectedConnector.identityCode, $ctrl.startTransactionModel.selectedCard.rfid)
                          .then(onFulfilled)
                          .catch(onRejected)
                          .finally(() => onSettled('startTransaction'));
        }
    }

    /**
     * Resets a station
     * @param {boolean} canProceed
     */
    function resetStation(canProceed) {
        if (canProceed) {
            $ctrl.state.stationResetSubmitted = true;

            stationService.reset($ctrl.station.id).then(() => {
                              renderResetTime();
                              onFulfilled();
                          })
                          .catch(onRejected)
                          .finally(() => onSettled('stationReset'));
        }
    }

    /**
     * Updates station maximum current
     * @param {boolean} canProceed
     */
    function setStationMaxCurrent(canProceed) {
        if (canProceed) {
            $ctrl.state.setStationMaxCurrentSubmitted = true;

            stationService.setStationMaxCurrent($ctrl.station.id, $ctrl.stationMaxCurrentModel.maxCurrent * 10)
                          .then(manageResolvedResponse)
                          .catch(onRejected)
                          .finally(() => onSettled('setStationMaxCurrent'));
        }
    }

    /**
     * Updates connector's phase rotations
     * @param {boolean} canProceed
     */
    function setConnectorPhaseRotations(canProceed) {
        if (canProceed) {
            $ctrl.state.setConnectorPhaseRotationsSubmitted = true;

            const connectorPhaseRotations = Object.keys($ctrl.phaseRotationModel).reduce((accumulator, connectorConfiguration) => `${accumulator},${connectorConfiguration}.${$ctrl.phaseRotationModel[connectorConfiguration].short}`, '0.unknown');

            stationService.setConnectorPhaseRotations($ctrl.station.id, connectorPhaseRotations)
                          .then(({status}) => {
                              manageResolvedResponse({status});

                              if (status === 'Accepted') {
                                  $ctrl.connnectorsPhaseRotationForm.$pristine = true;
                              }
                          })
                          .catch(onRejected)
                          .finally(() => onSettled('setConnectorPhaseRotations'));
        }
    }

    /**
     * Manages 200 response for updating configuration showing success/error message based on status
     * @param {Object} response - with status property
     */
    function manageResolvedResponse({status}) {
        if (status === 'Rejected') {
            onRejected({status: 502});

            return;
        }

        onFulfilled();
    }

    function onFulfilled() {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'success',
            messageKey: 'generic.notification.requestAccepted'
        });
    }

    function onRejected({status}) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: _.includes([400, 503], status) ?
                'generic.notification.stationUnavailable' :
                (status === 502) ? 'generic.notification.requestRejected' : CONSTANTS.ERROR_CODES[status],
            isPersistent: true
        });
    }

    function onSettled(action) {
        updateTransactions();

        $ctrl.state[`${action}Submitted`] = false;
    }

    function updateTransactions() {
        let tryFor = CONSTANTS.HTTP_REQUEST_TIMEOUT;

        getTransactions(3000);

        function getTransactions(timeout) {
            $timeout(() => {
                tryFor -= timeout;
                tryFor > 0 && stationService.getOpenTransactions($ctrl.station.id).then(transactions => {
                    transactions.length === $ctrl.transactions.length ? getTransactions(timeout) : $ctrl.transactions = transactions;
                });
            }, timeout);
        }
    }

    function updateTransactionState(transaction) {
        transaction.open = false;
    }

    /**
     * Updates the last initiated hard reset/restart request time on the UI
     */
    function renderResetTime() {
        $ctrl.resetAt = utils.toUtcDateFormat(new Date());
    }

    /**
     * Builds the stationMaxCurrentModel object from the station configuration
     * @param {Object} configuration
     * @returns {Object}
     */
    function buildStationMaxCurrentModel(configuration) {
        const evboxGlobalGroup = configuration.groups.find(group => group.key === 'evboxGlobal');

        if (!evboxGlobalGroup) {
            return {maxCurrent: undefined};
        }

        const maxCurrentObject = evboxGlobalGroup.items.find(item => item.key === 'evb_MaximumStationCurrent');

        return {maxCurrent: maxCurrentObject ? maxCurrentObject.value / 10 : undefined};
    }

    /**
     * Builds the phaseRotationModel object from the station configuration
     * @param {Object} configuration
     * @returns {Object}
     */
    function buildConnectorPhaseRotationsModel(configuration) {
        const ocppCoreGroup = configuration.groups.find(group => group.key === 'ocppCore');

        if (ocppCoreGroup) {
            const connectorPhaseRotationObject = ocppCoreGroup.items.find(item => item.key === 'ConnectorPhaseRotation');

            if (connectorPhaseRotationObject) {
                const arrayConnectorsPhases = connectorPhaseRotationObject.value
                                                                          .split(',')
                                                                          .filter(connectorPhase => connectorPhase !== '0.Unknown');

                const phaseRotationModel = arrayConnectorsPhases.reduce((accumulator, connectorPhaseRotation) => {
                    const [connectorIdentityCode, phaseRotation] = connectorPhaseRotation.split('.');

                    accumulator[connectorIdentityCode] = $ctrl.connectorPhaseRotationOptions.find(option => option.short === phaseRotation);

                    return accumulator;
                }, {});

                return phaseRotationModel;
            }
        }

        return {};
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({station, cards, transactions, resetStatus, configuration}) {
        $ctrl.station = station;
        $ctrl.cards = _.filter(cards.content, {status: 'active'});
        $ctrl.transactions = transactions;
        $ctrl.resetAt = resetStatus.resetAt;
        $ctrl.state.dataLoaded = true;
        $ctrl.selectedConnector = $ctrl.station.connectors.length === 1 ? $ctrl.station.connectors[0] : null;
        $ctrl.startTransactionModel = {
            selectedCard: $ctrl.cards.length === 1 ? $ctrl.cards[0] : null,
            selectedConnector: $ctrl.selectedConnector
        };
        $ctrl.stationMaxCurrentModel = buildStationMaxCurrentModel(configuration);
        $ctrl.phaseRotationModel = buildConnectorPhaseRotationsModel(configuration);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
