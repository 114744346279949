angular.module('everon.cards.card-import')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card-import', {
        url: '/import',
        data: {
            title: 'cards.action.import',
            permissions: ['CARD:IMPORT']
        },
        views: {
            'main@auth': {
                template: '<evcloud-card-import class="col-12"/>'
            }
        }
    });
}
