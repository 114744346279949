import stationsActions from './station.actions';
import dashboardActions from './dashboard.actions';
import filterActions from './filter.actions';
import urlActions from './url.actions';
import connectorActions from './connector.actions';

export default function ($ng) {
    return {
        ...stationsActions($ng),
        ...dashboardActions($ng),
        ...urlActions($ng),
        ...filterActions(),
        ...connectorActions($ng)
    };
}
