angular.module('everon.component.actionable-cards')
       .controller('evcloudActionableCardsController', evcloudActionableCardsController);

evcloudActionableCardsController.$inject = [];

function evcloudActionableCardsController() {
    const $ctrl = this;
    const defaultWidthStyle = {width: '250px'};

    $ctrl.$onInit = () => {
        $ctrl.state = {
            actionsSpecified: Boolean($ctrl.data.actions && $ctrl.data.actions.length)
        };
        $ctrl.state.showActionNames = $ctrl.state.actionsSpecified && _.every($ctrl.data.actions, 'name');

        $ctrl.model = $ctrl.data;
        $ctrl.model.widthStyle = $ctrl.model.widthPx ? {width: `${$ctrl.model.widthPx}px`} : defaultWidthStyle;
    };
}

