angular.module('everon.cards.card.billing-plan.change')
       .controller('CardBillingPlanChangeController', CardBillingPlanChangeController);

CardBillingPlanChangeController.$inject = ['$q', '$state', 'mediatorService', 'CONSTANTS'];

function CardBillingPlanChangeController($q, $state, mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.states = _($state.$current.parent.children).sortBy('data.order')
                                                         .map(item => ({
                                                             name: item.name,
                                                             title: item.data.title
                                                         }))
                                                         .value();
        updateIndex();

        $ctrl.payload = _.assign({}, $ctrl.data);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent']);
    };

    /**
     * Goes to the next step
     * @returns {Promise}
     */
    $ctrl.next = () => {
        const isLastStep = _.last($ctrl.states).name === $state.current.name;
        const nextState = _.get($ctrl.states[$state.current.data.order + 1], 'name');

        return isLastStep ? setComplete() : $state.go(nextState, null, {reload: nextState}).then(updateIndex);
    };

    /**
     * Goes to the previous step
     * @returns {Promise}
     */
    $ctrl.previous = () => {
        const previousState = $ctrl.states[$state.current.data.order - 1].name;

        return $state.go(previousState, null, {reload: previousState}).then(updateIndex);
    };

    /**
     * Updates current state index
     */
    function updateIndex() {
        $ctrl.currentStateIndex = _.findIndex($ctrl.states, {name: $state.current.name});
    }

    function setComplete() {
        $state.go('auth.cards.card.billing-plan', null, {reload: 'auth.cards.card.billing-plan'});

        return $q.resolve();
    }
}
