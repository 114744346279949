angular.module('everon.billing-plans.plans.plan.basic-info')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans.plan.basic-info', {
        url: '/basic-info',
        data: {
            title: 'generic.title.basicInformation',
            id: 'plan-basic-info-navigation-link',
            navigationType: ['context'],
            menuPriority: 0
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/plans/plan/basic-info' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.billing-plans.plans.plan.basic-info');
        },
        resolve: {
            data: ['$q', '$transition$', 'subscriptionService', ($q, $transition$, subscriptionService) => {
                const {type, id} = $transition$.params('to');

                return $q.all({
                    products: subscriptionService.getProductsByType(type, true),
                    plan: subscriptionService.getPlan(id, type)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.billing-plans.plans.plan': {
                component: 'evcloudPlanBasicInfo',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
