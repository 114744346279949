angular.module('everon.stations.station.location')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.location', {
        url: '/location',
        data: {
            id: 'station-location-navigation-link',
            title: 'generic.title.location',
            navigationType: ['context'],
            menuPriority: 40
        },
        resolve: {
            data: ['$q', 'countryService', 'data', 'accountFormModel', 'stationService', 'accountService',
            ($q, countryService, data, accountFormModel, stationService, accountService) => {
                return $q.all({
                    addressFormModel: accountFormModel.billingAddress,
                    station: stationService.getStation(data.id),
                    countries: countryService.getTenantCountries(),
                    accountFeatures: accountService.getFeatureFlags(),
                    sites: stationService.getAvailableSitesForStation(data.id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-location data-data-promise="::$resolve.data" />'
            }
        }
    });
}
