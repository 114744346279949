angular.module('everon.tenant-settings.companies.company-add')
       .controller('CompanyAddController', CompanyAddController);

CompanyAddController.$inject = ['$state', 'CONSTANTS', 'mediatorService'];

function CompanyAddController($state, CONSTANTS, mediatorService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Callback invoked by form component
     */
    $ctrl.onCancel = () => {
        $state.go('auth.tenant-settings.companies');
    };

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.currencies = data.currencies;
        $ctrl.countries = data.countries;

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
