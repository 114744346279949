angular.module('everon.stations.station.logs')
       .controller('StationLogsController', StationLogsController);

StationLogsController.$inject = ['$state', '$timeout', '$log', 'stationService', 'mediatorService', 'utils', 'CONSTANTS', 'stateHelper'];

function StationLogsController($state, $timeout, $log, stationService, mediatorService, utils, CONSTANTS, stateHelper) {
    const $ctrl = this;
    let datePickersInitialised = false;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            expanded: false,
            dataLoaded: false,
            logsLoaded: false,
            isLastPage: false,
            downloadInProgress: false
        };

        stateChangeListener = stateHelper.subscribeToParamsChange({
            query: setSearchQuery,
            from: setRange,
            to: setRange
        }, getStationLogs);

        const {query, from, to} = $state.params;

        $ctrl.searchQuery = query;

        $ctrl.searchOptions = {
            id: 'station-logs',
            onSearch,
            stateParam: 'query',
            initialQuery: $ctrl.searchQuery
        };

        $ctrl.nextPageData = null;

        $ctrl.range = _.assign({}, stationService.getDefaultLogsRange(), {from, to});

        $ctrl.rangeDatePickers = [{
            name: 'from',
            config: {
                firstDate: utils.getMoment().endOf('day').subtract(30, 'days').toDate(),
                lastDate: utils.getMoment().endOf('day').toDate()
            }
        }, {
            name: 'to',
            config: {
                firstDate: $ctrl.range.from,
                lastDate: $ctrl.range.to
            }
        }];

        $ctrl.noResultsConfig = {
            heading: 'generic.noLogsResults.heading',
            paragraph: 'generic.noLogsResults.paragraph'
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates the view with next page logs and pre-loads another page if `nextPageToken` is not `null`
     */
    $ctrl.viewMore = () => {
        $ctrl.data.push(...$ctrl.nextPageData);

        if ($ctrl.nextPageToken) {
            preloadNextPage();

            return;
        }

        // If `nextPageToken` is `null`, we know it's the last page of results
        $ctrl.state.isLastPage = true;
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Collapses or expands a single log view
     * @param {Object} log
     */
    $ctrl.toggleLogView = log => {
        log.isExpanded = !log.isExpanded;
    };

    /**
     * Collapses or expands all log views
     */
    $ctrl.toggleAllLogs = () => {
        $ctrl.data.forEach(item => {
            item.isExpanded = !$ctrl.state.expanded;
        });
        $ctrl.state.expanded = !$ctrl.state.expanded;
    };

    /**
     * Triggers the logs download for the date range selected
     */
    $ctrl.downloadLogs = () => {
        $log.info(`Downloading station ${$ctrl.stationId} logs`);
        $ctrl.state.downloadInProgress = true;

        const dateRange = $ctrl.range;

        stationService.downloadStationLogs({stationId: $ctrl.stationId}, dateRange)
                      .catch(error => {
                          $log.debug(error);

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: 'generic.notification.downloadFileError'
                          });
                      })
                      .finally(() => $timeout(() => {
                          $ctrl.state.downloadInProgress = false;
                      }));
    };

    /**
     * Updates state with date range params
     */
    $ctrl.onDateRangeChange = () => {
        const {from, to} = $ctrl.range;

        if (!datePickersInitialised) {
            datePickersInitialised = Boolean(from && to);
        }

        stateHelper.changeParams($ctrl.range);
    };

    /**
     * Fetches data from the server
     */
    function getStationLogs() {
        const params = _.pick($state.params, ['query', 'from', 'to']);

        $ctrl.state.logsLoaded = false;

        stationService.getStationLogs({stationId: $ctrl.stationId}, params)
                      .then(logs => {
                          updateData(logs);
                          $ctrl.state.isLastPage = !$ctrl.nextPageToken;

                          if ($ctrl.nextPageToken) {
                              preloadNextPage();
                          }
                      })
                      .catch(onRejected)
                      .finally(() => $ctrl.state.logsLoaded = true);
    }

    /**
     * Manage rejected requests
     * @param {Object} response
     */
    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Updates state with query params
     * @param {Object} query
     */
    function onSearch(query) {
        stateHelper.changeParams({query});
    }

    /**
     * Sets search query controller property
     * @param {string} query
     */
    function setSearchQuery(query) {
        $ctrl.searchQuery = query;
    }

    /**
     * Sets range controller property and updates date pickers first and last date
     */
    function setRange() {
        const {from, to} = $ctrl.range;
        const startOfTo = utils.getMoment(to).startOf('day').toDate();
        const today = utils.getMoment().startOf('day').toDate();

        $ctrl.rangeDatePickers[1].config.firstDate = from;
        $ctrl.rangeDatePickers[0].config.lastDate = startOfTo >= today ? startOfTo : (from < today ? startOfTo : from);
    }

    /**
     * Pre-loads subsequent page of logs so that they are ready when a user clicks `View more` button
     */
    function preloadNextPage() {
        const params = _.assign({}, $ctrl.range, {query: $ctrl.searchQuery});

        stationService.getStationLogs({stationId: $ctrl.stationId, nextPageToken: $ctrl.nextPageToken}, params)
                      .then(logs => {
                          $ctrl.nextPageData = logs.items;
                          $ctrl.nextPageToken = logs.nextPageToken;
                      })
                      .catch(onRejected);
    }

    /**
     * Updates data model on every range change
     * @param {Object} logs
     */
    function updateData(logs) {
        $ctrl.data = logs.items;
        $ctrl.nextPageToken = logs.nextPageToken;
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({logs, station}) {
        updateData(logs);
        $ctrl.stationId = station.id;
        $ctrl.identityCode = station.identityCode;
        $ctrl.state.isLastPage = !$ctrl.nextPageToken;
        $ctrl.state.dataLoaded = true;
        $ctrl.state.logsLoaded = true;

        if ($ctrl.nextPageToken) {
            preloadNextPage();
        }

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
