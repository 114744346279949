function generateTile(type) {
    return {
        type,
        title: `generic.label.${type}Stations`,
        id: `tile-${type}`,
        url: `#${type}`,
        count: null,
        isLoading: false
    };
}

export default function () {
    return {
        currentUserProfile: null,
        fetchingStations: false,
        accountsSuggestionList: [],
        stations: [],
        tiles: ['total', 'offline', 'recent'].map(generateTile),
        pagination: {
            size: 0
        },
        /**
         * type LoadError{
         *    data: string
         *    status: number
         *    statusText: string
         * }
         */
        stationsLoadError: null,
        /**
         * filters: Array<Filter>
         * type Filter {
         *     value: any
         *     label: string
         *     type: OneOfValues('account', 'status', 'search', 'recentlyAdded') }
         * }
         */
        filters: [],
        selectedView: null,
        selectedStationsStatusFilter: null,
        removableUrlParams: [],
        connectorsData: {
            types: [],
            statuses: [],
            isConnectorStatusesLoading: true,
            isConnectorTypesLoading: true
        }
    };
}
