const URL_PARAMS = ['view'];

export default function dashboardActions($ng) {
    const stationService = $ng('stationService');
    const accountService = $ng('accountService');
    const sessionService = $ng('sessionService');
    const $log = $ng('$log');

    function changeView(commit, view = null) {
        commit('update', ['selectedView', view]);
    }

    return {
        setupCurrentUserProfile({commit}) {
            commit('setCurrentUserProfile', sessionService.getUserProfile());
        },

        setupDashboardActions({dispatch, commit}) {
            dispatch('registerParamChangeListener', {
                action: 'changeView',
                filter: URL_PARAMS,
                immediate: true
            });

            commit('updateRemovableUrlParams', ['view', 'status']);
        },

        changeView({commit}, {params = {}} = {}) {
            changeView(commit, params.view);
        },

        clearView({commit, dispatch, state}) {
            changeView(commit);
            dispatch('clearUrlParams', [...state.removableUrlParams]);
        },

        fetchSummaryData({commit, getters}) {
            const commitUpdate = data => commit('updateTiles', data);
            const logError = logInfo => (error => $log.debug(logInfo, error));
            const {selectedAccountId} = getters;

            commit('setLoadingStateForTiles');

            stationService.getStationsCount(selectedAccountId)
                          .then(commitUpdate)
                          .catch(logError('Something went wrong with stationService.getStationsCount: '));

            stationService.getOfflineStationsCount(selectedAccountId)
                          .then(commitUpdate)
                          .catch(logError('Something went wrong with stationService.getOfflineStationsCount: '));

            stationService.getRecentlyAddedStationsCount(selectedAccountId)
                          .then(commitUpdate)
                          .catch(logError('Something went wrong with stationService.getRecentlyAddedStationsCount: '));
        },

        async loadAccountsByName({commit}, query) {
            try {
                const accountsSuggestionList = await accountService.getAllAccounts({query});

                commit('update', ['accountsSuggestionList', accountsSuggestionList.content]);
            } catch (error) {
                $log.debug('Something went wrong with accountService.getAllAccounts', error);
            }
        }
    };
}
