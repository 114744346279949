angular.module('everon.component.header')
       .controller('InactiveHeaderController', InactiveHeaderController);

InactiveHeaderController.$inject = ['brandingService'];

function InactiveHeaderController(brandingService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.logo = brandingService.resolveImage('logo');
    };
}
