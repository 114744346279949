angular.module('everon.cards.card.roaming')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card.roaming', {
        url: '/roaming',
        data: {
            id: 'card-roaming-navigation-link',
            title: 'generic.title.roaming',
            navigationType: ['context'],
            menuPriority: 5,
            permissions: ['ROAMING_TOKEN:READ']
        },
        resolve: {
            data: ['$q', 'data', 'cardService', 'subscriptionService', ($q, data, cardService, subscriptionService) => {
                return $q.all({
                    card: data,
                    partnerConnections: cardService.getPartnerConnections(data.rfid),
                    roamingCharacteristic: subscriptionService.getCardProductCharacteristic(data.id, 'roaming')
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.cards.card': {
                template: '<evcloud-card-roaming data-data-promise="::$resolve.data" class="flex-auto flex flex-column"/>'
            }
        }
    });
}
