angular.module('everon.component.column-chart')
       .controller('ColumnChartController', ColumnChartController);

ColumnChartController.$inject = ['$log', '$translate', 'CONSTANTS', '$timeout'];

function ColumnChartController($log, $translate, CONSTANTS, $timeout) {
    const $ctrl = this;

    const defaultData = {
        '2017-05': 3,
        '2017-06': 3,
        '2017-07': 3,
        '2017-08': 3,
        '2017-09': 3,
        '2017-10': 3,
        '2017-11': 3,
        '2017-12': 3,
        '2018-01': 3,
        '2018-02': 3,
        '2018-03': 3,
        '2018-04': 3
    };

    $ctrl.$onInit = () => {
        $ctrl.model = buildChart($ctrl.data ? $ctrl.data : defaultData);
    };

    $ctrl.$onChanges = changes => {
        if (!changes.data.isFirstChange()) {
            $ctrl.model = buildChart(changes.data.currentValue);
        }
    };

    function buildChart(data) {
        const out = _.map(data, (value, key) => {
            const month = $translate.instant(`generic.datetime.months.${CONSTANTS.DATES.MONTHS[key.split('-').pop() - 1]}`);

            return {
                key,
                value,
                month,
                monthInitial: month.charAt(0)
            };
        });

        const max = _.maxBy(out, 'value');

        $timeout(() => {
            out.forEach(val => {
                val.percentage = val.value * 100 / max.value;
            });
        }, 0);

        return out;
    }
}
