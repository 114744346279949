angular.module('everon.tenant-settings.countries.country')
       .controller('CountryController', CountryController);

CountryController.$inject = ['CONSTANTS', 'mediatorService', '$state'];

function CountryController(CONSTANTS, mediatorService, $state) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Callback invoked by form component
     */
    $ctrl.onCancel = () => {
        $state.go('auth.tenant-settings.countries', null, {reload: 'auth.tenant-settings.countries'});
    };

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.tenantCountry = data.tenantCountry;
        $ctrl.companies = data.companies;
        $ctrl.availableCountries = [data.tenantCountry.country];
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', $ctrl.tenantCountry.country.name]);
    }
}
