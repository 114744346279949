/**
 * Scrolls UI elements into view.
 * There are two different possible behaviours:
 *    1) No value is passed in the directive attribute: The trigger element will be scrolled into view to the top.
 *    2) A css selector is passed into the attribute: The element targeted will be scrolled into view to the top.
 *
 * @example
 *
 * <div evcloud-scroll-to>onclick the view will scroll to bring this element to the top of the view</div>
 *
 * <div evcloud-scroll-to='#active-vat-countries-form'>onclick the view will scroll the target element in the passed css selector</div>
 *
 *  Known issues:
 *  This directive uses a native DOM method to scroll the element into view - scrollIntoView() - although this method is implemented in all our supported
 *  browsers the behaviour "smooth" only works in Firefox.
 */

angular.module('everon.component.scroll-to')
       .directive('evcloudScrollTo', scrollTo);

scrollTo.$inject = ['$document', 'config'];

function scrollTo($document, config) {
    return {
        restrict: 'A',
        link(scope, element, attributes) {
            if (!config.isMobile) {
                return;
            }

            element.off('click', scrollToTop)
                   .on('click', scrollToTop);

            function scrollToTop() {
                const scrollToElement = attributes.evcloudScrollTo ? $document[0].querySelector(attributes.evcloudScrollTo) : element[0];

                // Make sure we have a DOM node
                if (scrollToElement && 'nodeType' in scrollToElement) {
                    scrollToElement.scrollIntoView(
                        {
                            block: 'start',
                            behavior: 'smooth'
                        }
                    );
                }
            }

            scope.$on('$destroy', () => {
                element.off('click', scrollToTop);
            });
        }
    };
}
