angular.module('everon.landing.login')
       .controller('LoginController', LoginController);

LoginController.$inject = ['$state', '$translate', '$window', 'config', 'CONSTANTS', 'sessionService', 'mediatorService', 'stateHelper'];

function LoginController($state, $translate, $window, config, CONSTANTS, sessionService, mediatorService, stateHelper) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.credentials = {};
        $ctrl.state = {
            showPassword: false,
            loginInProgress: false
        };

        $ctrl.dataPromise.then(onDataLoaded);

        let notification = null;

        switch (true) {
            case $state.params.activationCompleted:
                notification = {
                    type: 'success',
                    messageKey: 'landing.activation.notification.success',
                    isPersistent: true
                };
                break;
            case $state.params.activationExpired:
                notification = {
                    type: 'error',
                    messageKey: 'landing.activation.notification.error',
                    isPersistent: true
                };
                break;
            case $state.params.registrationSent:
                notification = {
                    type: 'success',
                    messageKey: 'landing.registration.notification.emailSent',
                    isPersistent: true
                };
                break;
        }

        if (notification) {
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, notification);
        }
    };

    $ctrl.togglePasswordVisibility = () => {
        $ctrl.state.showPassword = !$ctrl.state.showPassword;
    };

    $ctrl.login = () => {
        $ctrl.state.loginInProgress = true;

        const redirectUrl = getRedirectUrl();

        sessionService.login($ctrl.credentials, redirectUrl)
                      .then(auth => {
                          const authorisedStates = stateHelper.getAuthorisedStates(auth.profile.permissions);

                          // Only redirect to source state if it is authorised
                          if ($state.current.data.sourceState && isAuthorisedSourceState(authorisedStates, $state.current.data.sourceState)) {
                              return $state.go($state.current.data.sourceState, $state.current.data.sourceParams);
                          }

                          return $state.go('auth.dashboard');
                      })
                      .catch(response => {
                          if (response.headers('X-Inactive-Account')) {
                              // Show notification with a link to request new activation code
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'error',
                                  messageKey: 'landing.login.notification.accountInactive',
                                  isPersistent: true,
                                  link: {
                                      handlerFn: requestActivationEmail,
                                      text: 'landing.login.link.requestActivationEmail'
                                  }
                              });
                          } else if (response.headers('X-Blocked-User')) {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'error',
                                  messageKey: 'landing.login.notification.userBlocked',
                                  isPersistent: true
                              });
                          } else if (response.status === 401) {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'error',
                                  messageKey: 'landing.login.notification.loginFailed',
                                  isPersistent: true
                              });
                          } else {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'error',
                                  messageKey: 'landing.login.notification.unknownError',
                                  isPersistent: true
                              });
                          }
                      })
                      .finally(() => {
                          $state.current.data.sourceState = null;
                          $state.current.data.sourceParams = null;
                          $ctrl.state.loginInProgress = false;
                      });
    };

    /**
     * Checks whether source state is authorised
     * @param {Array} authorisedStates
     * @param {Object} sourceState
     * @returns {boolean}
     */
    function isAuthorisedSourceState(authorisedStates, sourceState) {
        return authorisedStates.some(state => state.name === sourceState);
    }

    /**
     * Requests new activation email
     */
    function requestActivationEmail() {
        mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.CLOSE_NOTIFICATION);

        sessionService.requestActivation($ctrl.credentials.username)
                      .finally(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              messageKey: 'landing.login.notification.activationEmailSent'
                          });
                      });
    }

    /**
     * Return a redirect url based on state, used when tenant are using third-party domains
     * @returns {string} redirectUrl
     */
    function getRedirectUrl() {
        const {sourceState: route, sourceParams: params} = $state.current.data;

        return $state.href(route || 'auth.dashboard', params || {}, {absolute: true});
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({supportLinks}) {
        $ctrl.supportLinks = supportLinks;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
