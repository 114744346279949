angular.module('everon.users.customers.user-invite')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.customers.user-invite', {
        url: '/new?customerType',
        params: {
            customerType: {
                value: 'client'
            }
        },
        data: {
            title: 'users.action.invite',
            permissions: ['CUSTOMER:CREATE']
        },
        resolve: {
            data: ['$q', 'userService', 'accountService', 'countryService', ($q, userService, accountService, countryService) => {
                return $q.all({
                    roles: userService.getAvailableRoles({type: 'customers'}),
                    countryCurrencies: countryService.getTenantCountryCurrencies(),
                    billingOverview: accountService.getBillingOverview()
                                                   .catch(response => response.status === 404 ? {} : $q.reject(response))
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-user-invite data-data-promise="::$resolve.data" class="col-12" />'
            }
        }
    });
}
