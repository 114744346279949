import OktaSignInWidget from '@okta/okta-signin-widget/dist/js/okta-sign-in.min';

angular.module('everon')
       .value('OktaSignIn', OktaSignInWidget)
       .factory('oktaWidgetWrapper', oktaWidgetWrapper);

oktaWidgetWrapper.$inject = ['oktaConfig', 'brandingService', 'OktaSignIn'];

function oktaWidgetWrapper(oktaConfig, brandingService, OktaSignIn) {
    const brandColor = brandingService.resolveColor('color1');

    const config = {
        baseUrl: oktaConfig.domain,
        clientId: oktaConfig.clientId,
        redirectUri: oktaConfig.redirectUri,
        authParams: {
            pkce: true,
            issuer: 'default',
            responseType: ['token', 'code'],
            grantType: 'authorization_code',
            scopes: oktaConfig.scopes,
            display: 'page'
        },
        colors: {
            brand: brandColor
        },
        features: {
            showPasswordToggleOnSignInPage: true,
            rememberMe: false
        }
    };

    const signInWidget = new OktaSignIn(config);
    const {authClient} = signInWidget;
    const {token: tokenManager, features} = authClient;

    return {
        isPKCESupported() {
            return features.isPKCESupported();
        },
        /**
         * Wrapper for the OktaSignIn.authClient.token.renew(config).
         * @returns {Promise} Returns the promise returned by OktaSignIn.authClient.token.renew(config).
         */
        renewToken() {
            const renewPayload = {
                accessToken: '*', // renew() expect a non empty string here
                authorizeUrl: `${oktaConfig.issuer}/v1/authorize`,
                scopes: oktaConfig.scopes,
                issuer: oktaConfig.issuer
            };

            return tokenManager.renew(renewPayload);
        },
        parseFromUrl() {
            return tokenManager.parseFromUrl();
        },
        decodeToken(token) {
            return tokenManager.decode(token);
        },
        signOut() {
            return authClient.signOut();
        },
        remove() {
            signInWidget.remove();
        },
        renderEl(config) {
            signInWidget.renderEl(config);
        },
        on(event, handler) {
            signInWidget.on(event, handler);

            return () => signInWidget.off(event, handler);
        }
    };
}
