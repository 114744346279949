angular.module('everon.stations.station')
       .controller('StationController', StationController);

StationController.$inject = ['mediatorService', 'CONSTANTS'];

function StationController(mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.station = data;
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', data.reference || data.identityCode]);
    }
}
