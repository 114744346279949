import vueModules from '../../index-vue';

const core = angular.module('everon.core');

// ----- Global app runtime configuration ----- //
core.run(globalConfiguration);

globalConfiguration.$inject = ['$rootScope', '$translate', 'config', 'CONSTANTS', '$window', 'mediatorService', '$timeout', '$state', 'modernizr', 'brandingService', 'sessionService'];

function globalConfiguration($rootScope, $translate, config, CONSTANTS, $window, mediatorService, $timeout, $state, modernizr, brandingService, sessionService) {
    $rootScope.app = {
        title: config.appTitle,
        lang: config.lang,
        favicon: brandingService.resolveImage('icon'),
        themeColor1: brandingService.resolveColor('color1'),
        touch: 'ontouchstart' in $window.document,
        online: $window.navigator.onLine,
        navVisible: false
    };

    $rootScope.$on('$translateChangeSuccess', async () => {
        const language = $translate.use();
        const {i18n} = await vueModules;

        $rootScope.app.lang = $translate.use();

        if (i18n.i18next.language !== language) {
            await i18n.i18next.changeLanguage(language);
        }
    });

    mediatorService.subscribe(CONSTANTS.EVENTS.ACTION.TOGGLE_NAV, onNavToggle);

    function onNavToggle() {
        $rootScope.app.navVisible = !$rootScope.app.navVisible;
    }

    /**
     * Update $rootScope property when app goes offline/online
     * @param {Event} event
     */
    function updateOnlineStatus(event) {
        // Android triggers false positives on app start, hence we check if online and an "online" event comes we do nothing.
        if ($rootScope.app.online && event.type === 'online') {
            return;
        }

        $rootScope.app.online = event.type === 'online';
        $timeout(() => {
            notifyOnlineStatus($rootScope.app.online);
        });

        // When starting offline the app wont load any state. When online again we load login.
        if (!$state.current.name) {
            $state.go('anon.landing.login');
        }
    }

    /**
     * Publishes a notification stating the online state
     * @param {boolean} status
     */
    function notifyOnlineStatus(status) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: status ? 'success' : 'error',
            messageKey: `generic.notification.${status ? 'online' : 'offline'}`,
            isPersistent: !status
        });
    }

    mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.FAVICON_CHANGE, () => {
        $rootScope.app.favicon = brandingService.resolveImage('icon');
    });

    mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.NOTIFICATION_COMPONENT_READY, () => {
        if (!$rootScope.app.online) {
            notifyOnlineStatus(false);
        }
    });

    mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, data => {
        if (data.messageKey === 'generic.error.unauthorised') {
            // Check to see if this is coming from an expired session. If it is, logout.
            sessionService.loadSession()
                          .catch(() => {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'error',
                                  messageKey: 'generic.error.sessionExpired'
                              });
                              setTimeout(() => $state.go('auth.logout'), 2000);
                          });
        }
    });

    const options = modernizr.passiveeventlisteners ? {capture: false} : false;

    $window.addEventListener('online', updateOnlineStatus, options);
    $window.addEventListener('offline', updateOnlineStatus, options);
}

// ----- Routing security runtime configuration ----- //
core.run(routingSecurity);

routingSecurity.$inject = ['$rootScope', '$log', '$state', '$translate', '$window', 'sessionService', 'config', 'mediatorService', 'CONSTANTS', '$transitions', 'lazyLoadService'];

function routingSecurity($rootScope, $log, $state, $translate, $window, sessionService, config, mediatorService, CONSTANTS, $transitions, lazyLoadService) {
    const hasQueryParams = _.partialRight(_.includes, '?');

    /**
     * Updates current page title
     * @param {Object} transition
     */
    function onTransitionSuccess(transition) {
        // Close any persistent notifications
        if ($rootScope.app.online) {
            mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.CLOSE_NOTIFICATION);
        }

        if ($window.matchMedia('(max-width: 52em)').matches && $rootScope.app.navVisible) {
            $rootScope.app.navVisible = false;
        }

        // $state is used to dynamically update page title
        $rootScope.app.title = `${$translate.instant(transition.to().data.title)}`;
    }

    /**
     * Catch all errors. Redirects to the login state
     * @param {Object} transition
     * @returns {*}
     */
    function onTransitionError(transition) {
        const loginState = $state.get('anon.landing.login');
        const toState = transition.to();
        const error = transition.error();
        const errorTypes = {
            2: 'superseded', // In case of redirect
            5: 'ignored', // In case of navigation to the same state,
            6: 'error' // Transition rejects due a programmer's error. Most likely a bug in our code
        };

        // Do nothing for error types defined above but skip through if it is an HTTP error with proper status code
        if (errorTypes[error.type] && !_.has(error, 'detail.status')) {
            return;
        }

        // If online or user is authenticated or `toState` is `loginState`, we handle notification and return immediately
        if ($rootScope.app.online) {
            return (sessionService.isAuthenticated() || (toState === loginState)) ? handleNotification(error) : redirectToLogin(transition, loginState);
        }
    }

    /**
     * Handles user authorisation when transitioning to states based on tenant feature flags and user permissions
     * @param {Object} transition
     * @returns {boolean|Promise}
     */
    function authorisationHook(transition) {
        if (!sessionService.isAuthenticated()) {
            return false;
        }

        const permissionService = transition.injector().get('permissionService');

        return permissionService.resolveFeatures(sessionService.getFeatureFlags(), transition.to().data.features) ?
            permissionService.resolve(sessionService.getUserProfile().permissions, transition.to().data.permissions) || redirectToUnauthorisedState(transition) :
            redirectToUnauthorisedState(transition);
    }

    /**
     * Checks if a user is authenticated. Redirects authenticated user trying to access anonymous state to 'auth.dashboard'. Important! We need to catch the rejected promise and return
     * `true` to resolve the hook so that it doesn't go into an infinite loop after logout
     * @param {Object} transition
     * @returns {boolean|Promise}
     */
    function authenticationHook(transition) {
        const toStateName = transition.to().name;

        function handleRedirect() {
            if (_.startsWith(toStateName, 'auth.')) {
                return true;
            }

            $log.debug(`Attempted to access state '${toStateName}' while logged in. Redirected to 'auth.dashboard'`);

            return $state.target('auth.dashboard', null, {reload: 'auth.dashboard', location: 'replace'});
        }

        if (!sessionService.isAuthenticated() && /anon\.landing\./.test(toStateName)) {
            return true;
        }

        return sessionService.isAuthenticated() ? handleRedirect() : sessionService.loadSession()
                                                                                   .then(handleRedirect)
                                                                                   .catch(() => true);
    }

    /**
     * Redirect to welcome page if account setup is incomplete
     * @param {Object} transition
     * @returns {boolean|Promise}
     */
    function accountSetupCompleteHook(transition) {
        if (!sessionService.isAuthenticated()) {
            return false;
        }

        return sessionService.getUserProfile().accountSetupComplete || redirectToWelcome(transition);
    }

    /**
     * Checks if a transition goes from the state as target and clear query params if any exists.
     * @param {Object} transition
     * @returns {boolean|Object}
     */
    function clearQueryParamsHook(transition) {
        const {name: targetName} = transition.to();
        const {name: originName} = transition.from();
        const enteringParams = transition.params('entering');
        const targetHref = $state.href(targetName, enteringParams);

        if (targetName === originName && hasQueryParams(targetHref)) {
            return $state.target(targetName, _.zipObject(Object.keys(enteringParams)), transition.options());
        }

        return true;
    }

    function reloadHook(transition) {
        const options = transition.options();
        const origin = transition.from().name;
        const target = transition.to().name;

        if (!options.reload && origin.startsWith(target) && origin.length > target.length) {
            return $state.target(target, transition.params(), _.assign({}, options, {reload: target}));
        }

        return true;
    }

    function destroyVueHook(transition) {
        const state = transition.from();

        if (state.vueInstance) {
            state.vueInstance.$destroy();
            state.vueInstance.$el.remove();
            state.vueInstance = null;
        }

        return true;
    }

    /**
     * Logs access attempts to auth views when account setup is incomplete and redirects to account setup
     * @param {Object} transition
     * @returns {Promise}
     */
    function redirectToWelcome(transition) {
        $log.debug(`Attempted to access state '${transition.to().name}' before completing account setup. Redirected to 'auth.welcome.language-details'`);

        return $state.target('auth.welcome.language-details');
    }

    /**
     * Logs unauthorised state access attempt and redirects to 403 page
     * @param {Object} transition
     * @returns {Promise}
     */
    function redirectToUnauthorisedState(transition) {
        $log.debug(`Attempted to access unauthorised state: ${transition.to().name}. Redirected to 'auth.forbidden'`);

        return $state.target('auth.forbidden');
    }

    /**
     * Checks if `toState` is a valid source state to be redirected to after successful login
     * @param {Object} toState
     * @returns {boolean}
     */
    function isValidSourceState(toState) {
        return !sessionService.isAuthenticated() && _.startsWith(toState.name, 'auth.') && toState.name !== 'auth.logout';
    }

    /**
     * Checks if response error occurred while updating user profile
     * @param {Object} response
     * @returns {boolean}
     */
    function isProfileUpdate(response) {
        return response.config.method === 'PATCH' && _.includes(response.config.url, '/profile');
    }

    /**
     * Checks whether the route is white-listed - it shouldn't trigger error notification
     * @param {Object} response
     * @returns {boolean}
     */
    function isWhiteListedRoute(response) {
        return !_.includes(response.config.url, '/profile') && !_.includes(response.config.url, '/logout');
    }

    /**
     * Displays notification if condition is met
     * @param {Object} response
     */
    function handleNotification(response) {
        let isPersistent = response.status !== 403;

        if (response.config && (isProfileUpdate(response) || isWhiteListedRoute(response))) {
            isPersistent = true;
        }

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status],
            isPersistent
        });
    }

    /**
     * Redirects to `sourceState` after login only if a user is not authenticated and transition wasn't to a logout state
     * @param {Object} transition
     * @param {Object} loginState
     */
    function redirectToLogin(transition, loginState) {
        const toState = transition.to();

        if (isValidSourceState(toState)) {
            loginState.data.sourceState = toState.name;
            loginState.data.sourceParams = transition.params('to');
        }

        $state.go(loginState);
    }

    /**
     * Global state error handler
     * @param {Object} error
     */
    function handleError(error) {
        $log.debug(error);
    }

    $state.defaultErrorHandler(handleError);

    // ----- Global transition hooks. See https://ui-router.github.io/ng1/docs/latest/interfaces/transition.transitionhookfn.html ----- //

    // Authentication logic
    $transitions.onBefore({}, authenticationHook, {priority: 15});

    // Authorisation logic
    $transitions.onBefore({
        to(state) {
            return _.startsWith(state.name, 'auth.') && (_.hasIn(state, 'data.features') || _.hasIn(state, 'data.permissions'));
        }
    }, authorisationHook, {priority: 10});

    // Account setup complete logic
    $transitions.onBefore({
        to(state) {
            return _.startsWith(state.name, 'auth.') && !_.startsWith(state.name, 'auth.welcome') && state.name !== 'auth.logout';
        }
    }, accountSetupCompleteHook, {priority: 5});

    // Query clearing logic
    $transitions.onEnter({
        entering(state) {
            return _.startsWith(state.name, 'auth.') && hasQueryParams(_.get(state, 'url.pattern', ''));
        }
    }, clearQueryParamsHook);

    $transitions.onBefore({
        to(state) {
            return _.startsWith(state.name, 'auth.') && hasQueryParams(_.get(state, 'url.pattern', ''));
        }
    }, reloadHook);

    $transitions.onBefore({
        from(state, transition) {
            return Boolean(state.vueInstance) && state.name !== transition.to().name;
        }
    }, destroyVueHook);

    $transitions.onSuccess({}, onTransitionSuccess);
    $transitions.onError({}, onTransitionError);
}
