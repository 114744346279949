angular.module('everon.stations.station-import-csv')
       .controller('StationImportCsvController', StationImportCsvController);

StationImportCsvController.$inject = ['stationService', 'CONSTANTS', 'mediatorService', '$translate'];

function StationImportCsvController(stationService, CONSTANTS, mediatorService, $translate) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.importConfig = {
            buttonText: 'generic.action.import',
            errorText: 'generic.error.importFailed',
            validationMessages: {
                400: 'generic.importCsv.notification.noHeader',
                413: 'generic.importCsv.notification.tooManyRows',
                417: 'generic.importCsv.notification.invalidRecords'
            },
            url: '/api/platform/stations/import',
            mimeTypes: CONSTANTS.MIME_TYPES.CSV,
            onUpload: onImport,
            onError
        };

        $ctrl.sortingParams = {property: 'row', order: 'asc'};

        $ctrl.columns = [
            {
                property: 'row',
                translationKey: 'generic.importCsv.list.column.row',
                desc: true,
                sortable: true,
                active: true,
                cssClass: 'col-3 sm-col-2'
            },
            {
                property: 'field',
                translationKey: 'generic.importCsv.list.column.field',
                desc: true,
                sortable: true,
                active: false,
                cssClass: 'col-5 sm-col-4'
            },
            {
                property: 'translatedReason',
                translationKey: 'generic.importCsv.list.column.reason',
                desc: true,
                sortable: true,
                active: false,
                cssClass: 'col-4 sm-col-6'
            }
        ];

        $ctrl.csvExample = [
            {
                name: 'Station ID',
                exampleFirstRow: 'EVB-P17060001',
                exampleSecondRow: 'EVB-P17060001'
            },
            {
                name: 'Security code',
                exampleFirstRow: '12345ABCDE',
                exampleSecondRow: '12345ABCDE'
            },
            {
                name: 'Manufacturer',
                exampleFirstRow: 'ACME',
                exampleSecondRow: 'ACME'
            },
            {
                name: 'Article number',
                exampleFirstRow: 'B3162-1100-1-V-01',
                exampleSecondRow: 'B3162-1100-1-V-01'
            },
            {
                name: 'Wi-Fi supported',
                exampleFirstRow: 'true/false',
                exampleSecondRow: 'true/false'
            },
            {
                name: 'GSM supported',
                exampleFirstRow: 'true/false',
                exampleSecondRow: 'true/false'
            },
            {
                name: 'Connector type',
                exampleFirstRow: 'IEC_62196_T2',
                exampleSecondRow: 'IEC_62196_T2'
            },
            {
                name: 'Format',
                exampleFirstRow: 'CABLE',
                exampleSecondRow: 'SOCKET'
            },
            {
                name: 'Power type',
                exampleFirstRow: 'AC_1_PHASE',
                exampleSecondRow: 'AC_3_PHASE'
            },
            {
                name: 'Voltage',
                exampleFirstRow: '230',
                exampleSecondRow: '230'
            },
            {
                name: 'Amperage',
                exampleFirstRow: '16',
                exampleSecondRow: '32'
            },
            {
                name: 'Serial number',
                exampleFirstRow: 'sn-01234',
                exampleSecondRow: 'sn-01234'
            }
        ];

        $ctrl.errors = null;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    };

    /**
     * Makes re-sorted data available to the template
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = sortingParams => {
        $ctrl.sortingParams = sortingParams;
        $ctrl.errors = stationService.orderBy($ctrl.errors, $ctrl.sortingParams);
    };

    /**
     * If file was imported successfully, we reset previous errors and close error notification
     */
    function onImport() {
        $ctrl.errors = null;
        mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.CLOSE_NOTIFICATION);
    }

    /**
     * In case of missing headers, the server returns 400 with JSON object in the response. Hence, we check if response is an array
     * @param {Array|Object} response
     */
    function onError(response) {
        $ctrl.errors = Array.isArray(response) ? stationService.orderBy(response.map(extendError), $ctrl.sortingParams) : null;
    }

    /**
     * Extends error object with uuid and a the translated reason for the template
     * @param {Object} error
     * @returns {Object}
     */
    function extendError(error) {
        return _.assign({
            uuid: _.uniqueId('error-'),
            translatedReason: $translate.instant(`generic.importCsv.list.errors.${error.reason}`, {
                field: error.field
            })
        }, error);
    }
}
