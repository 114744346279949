angular.module('everon.stations.sites.site.details')
       .controller('SiteDetailsController', SiteDetailsController);

SiteDetailsController.$inject = ['$state', 'stationService', 'mediatorService', 'modalService', 'CONSTANTS'];

function SiteDetailsController($state, stationService, mediatorService, modalService, CONSTANTS) {
    const $ctrl = this;
    let modal;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            saveInProgress: false,
            dataLoaded: false,
            hasChanged: false,
            stationsAssigned: false
        };

        $ctrl.dataPromise
             .then(onDataLoaded);
    };

    $ctrl.onChange = () => {
        updateChangedState();
    };

    $ctrl.cancel = () => {
        resetState();
    };

    $ctrl.save = () => {
        $ctrl.state.saveInProgress = true;

        const requestData = _.pick($ctrl.model, ['name', 'address']);

        stationService.updateSite($ctrl.model.id, requestData)
                      .then(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              messageKey: 'sites.site.details.notification.update'
                          });

                          setNewFormBaseState();
                          resetForm();
                      })
                      .catch(error => {
                          const messageMap = {
                              409: 'sites.site.create.notification.nameAlreadyInUse',
                              417: 'sites.site.create.notification.invalidAddress'
                          };

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: messageMap[error.status] || CONSTANTS.ERROR_CODES[error.status],
                              isPersistent: true
                          });
                      })
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    $ctrl.delete = () => {
        if ($ctrl.state.stationsAssigned) {
            modal.open();
        } else {
            modal.open()
                 .then(attemptDelete);
        }
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    /**
     * Sets a new value for the form's base model
     */
    function setNewFormBaseState() {
        $ctrl.modelCopy = JSON.stringify($ctrl.model);
        updateChangedState();
    }

    /**
     * Updates the state hasChanged property based on the form's base model's values
     */
    function updateChangedState() {
        $ctrl.state.hasChanged = JSON.stringify($ctrl.model) !== $ctrl.modelCopy;
    }

    /**
     * Resets the form model to the original unedited state
     */
    function resetState() {
        $ctrl.model = _.assign({}, JSON.parse($ctrl.modelCopy));
        resetForm();
    }

    /**
     * Resets the form state
     */
    function resetForm() {
        $ctrl.siteDetailsForm.$setUntouched();
        $ctrl.siteDetailsForm.$setPristine();
    }

    /**
     * Attempts site deletion
     * @param {boolean} canProceed
     */
    function attemptDelete(canProceed) {
        if (canProceed) {
            $ctrl.state.deleteInProgress = true;

            stationService.deleteSite($ctrl.model.id)
                          .then(() => {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'success',
                                  messageKey: 'sites.site.details.notification.delete.success'
                              });

                              $state.go('auth.stations');
                          })
                          .catch(() => {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'error',
                                  isPersistent: true,
                                  messageKey: 'sites.site.details.notification.delete.failure'
                              });
                          })
                          .finally(() => {
                              $ctrl.state.deleteInProgress = false;
                          });
        }
    }

    /**
     * Sets up the modal instance with the right configuration depending on if the site has or not assigned stations
     */
    function setUpModalInstance() {
        modalService.register({
            templateUrl: $ctrl.state.stationsAssigned ? 'partials/single-button-modal-dialog.html' : 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController',
            data: {
                title: 'sites.site.details.modal.title',
                paragraph: `sites.site.details.modal.body.${$ctrl.state.stationsAssigned ? 'withStations' : 'withoutStations'}`,
                button: {
                    ok: `generic.action.${$ctrl.state.stationsAssigned ? 'ok' : 'delete'}`
                }
            }
        }).then(modalInstance => {
            modal = modalInstance;
        });
    }

    /**
     * Extends address form model with `id` property
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        return _.map(formModel, value => _.assign(value, {id: _.kebabCase(value.name)}));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({countries, addressFormModel, site}) {
        $ctrl.model = site;
        $ctrl.countries = countries;
        $ctrl.addressFormModel = extendFormModel(addressFormModel);
        $ctrl.state.stationsAssigned = !_.isEmpty(_.get($ctrl.model, 'stations'));

        setNewFormBaseState();
        setUpModalInstance();

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [$ctrl.model.name, 'current']);
    }
}
