angular.module('everon.stations.station.configuration')
       .controller('StationConfigurationController', StationConfigurationController);

StationConfigurationController.$inject = ['$state', 'mediatorService', 'CONSTANTS', 'stationService', 'modalService', '$timeout', '$translate', '$q'];

function StationConfigurationController($state, mediatorService, CONSTANTS, stationService, modalService, $timeout, $translate, $q) {
    const $ctrl = this;
    const formName = 'configurationForm';
    let modal;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            submitInProgress: false,
            refreshInProgress: false,
            expanded: false,
            hasGroups: false
        };

        $ctrl.noResultsConfig = {
            heading: 'generic.noResults.heading',
            paragraph: 'stations.station.configuration.noResults.paragraph'
        };

        $ctrl.dataPromise.then(onDataLoaded);

        $ctrl.searchOptions = {
            id: 'stations-keys',
            onSearch
        };

        modalService.register({
                        templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
                        controller: 'ConfirmationModalDialogController',
                        data: {
                            title: 'stations.station.configuration.confirmation.title',
                            button: {
                                ok: 'generic.action.save'
                            }
                        }
                    })
                    .then(modalInstance => {
                        modal = modalInstance;
                    });
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    /**
     * Checks if the key needs a confirmation from the user before update
     * @param {Object} key
     */
    $ctrl.attemptUpdateKey = key => {
        if (key.confirmation && !key.confirmUpdate) {
            modal.open({
                     paragraph: `stations.station.configuration.confirmation.messages.${key.confirmation}`
                 })
                 .then(canProceed => $ctrl.updateKey(canProceed, key));

            return;
        }

        $ctrl.updateKey(true, key);
    };

    /**
     * Sets a station config key
     * @param {boolean} canProceed
     * @param {Object} key
     */
    $ctrl.updateKey = (canProceed, key) => {
        if (canProceed) {
            $ctrl.state.submitInProgress = true;

            const data = {
                key: key.key,
                value: key.value
            };

            stationService.setStationConfigurationKey($ctrl.station.id, data)
                          .then(({status}) => {
                              if (status === 'Rejected') {
                                  showErrorNotification(502);

                                  return;
                              }

                              showSuccessNotification(status);

                              key.currentValue = JSON.stringify(key.value);
                              $ctrl[formName][key.key].$setPristine();
                          })
                          .catch(onRejected)
                          .finally(() => {
                              $ctrl.state.submitInProgress = false;
                          });
        }
    };

    /**
     * Reloads configuration model from the station
     */
    $ctrl.refreshConfiguration = () => {
        $ctrl.state.refreshInProgress = true;
        stationService.getStationConfiguration($ctrl.station.id, true)
                      .then(data => {
                          setModel(data);

                          $ctrl.state.expanded = false;
                          showSuccessNotification();
                      })
                      .catch(onRejected)
                      .finally(() => {
                          $ctrl.state.refreshInProgress = false;
                      });
    };

    /**
     * Sets the form control to pristine if is equal to currently set on station
     * @param {Object} key
     */
    $ctrl.onChange = key => {
        if (JSON.stringify(key.value) === key.currentValue) {
            $ctrl[formName][key.key].$setPristine();
        }
    };

    /**
     * restores a copy of the set value
     * @param {Object} key
     */
    $ctrl.undo = key => {
        key.value = JSON.parse(key.currentValue);
        $ctrl[formName][key.key].$setPristine();
    };

    /**
     * Collapses or expands a single group
     * @param {Object} group
     */
    $ctrl.toggleGroupView = group => {
        group.isExpanded = !group.isExpanded;
    };

    /**
     * Collapses or expands all groups
     */
    $ctrl.toggleAllGroups = () => {
        $ctrl.model.groups.forEach(group => {
            group.isExpanded = !$ctrl.state.expanded;
        });
        $ctrl.state.expanded = !$ctrl.state.expanded;
    };

    /**
     * Search callback
     * @param {string} query
     */
    function onSearch(query) {
        // if no query we restore the model
        if (!query.length) {
            $ctrl.model.groups = JSON.parse($ctrl.modelCopy).groups;
            $ctrl.state.expanded = false;

            return;
        }

        // Otherwise we close all groups and start filtering, the groups with result are then expanded again
        // We need to use a timeout to give time for the accordeon to do his job
        $ctrl.state.expanded = true;
        $ctrl.toggleAllGroups();
        $timeout(() => {
            $ctrl.model.groups = _.filter(JSON.parse($ctrl.modelCopy).groups, group => {
                group.items = _.filter(group.items, ({key, helpText}) =>
                    _.some([key, helpText], str => str.toLowerCase().indexOf(query.toLowerCase()) > -1)
                );
                group.isExpanded = Boolean(group.items.length);

                return group.isExpanded;
            });
            $ctrl.state.expanded = true;
        }, 300);// accordion takes 180ms and we wait a bit more because slower devices
    }

    function onRejected({status}) {
        showErrorNotification(status);
    }

    /**
     * Dispatches events for error messages
     * @param {number} errorCode
     */
    function showErrorNotification(errorCode) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: _.includes([400, 503], errorCode) ?
                'generic.notification.stationUnavailable' :
                (errorCode === 502) ? 'generic.notification.requestRejected' : CONSTANTS.ERROR_CODES[errorCode],
            isPersistent: true
        });
    }

    /**
     * Dispatches events for success messages
     * @param {string} status
     */
    function showSuccessNotification(status = 'Accepted') {
        let options = {
            type: 'success',
            messageKey: 'generic.notification.requestAccepted'
        };

        if (status === 'RebootRequired') {
            options = _.assign(options, {
                type: 'alert',
                messageKey: 'generic.notification.stationsRebootRequired.message',
                link: {
                    text: 'generic.notification.stationsRebootRequired.linkText',
                    handlerFn: goToAssistance
                },
                isPersistent: true
            });
        }

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, options);
    }

    /**
     * Navigates to the assistance section for this station
     */
    function goToAssistance() {
        $state.go('auth.stations.station.assistance');
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({configuration, station}) {
        setModel(configuration);

        $ctrl.station = station;
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }

    /**
     * Sets model and it's copy, updates groups availability flag
     * @param {Object} data
     */
    function setModel(data) {
        const translatePromises = [];

        $ctrl.model = data;

        $ctrl.model.groups.forEach(({items}) => {
            items.forEach(item => {
                // translate here to have the values available for search
                const promise = $translate(`stations.station.configuration.form.keys.${item.key}.helpText`)
                    .then(translation => item.helpText = translation)
                    .catch(() => item.helpText = '');

                translatePromises.push(promise);
            });
        });

        $q.all(translatePromises).then(() => {
            $ctrl.modelCopy = JSON.stringify($ctrl.model);
        });

        $ctrl.state.hasGroups = Boolean(_.get(data, 'groups', []).length);
    }
}
