angular.module('everon.tenant-settings.countries')
       .factory('tenantCountryService', tenantCountryService);

tenantCountryService.$inject = ['$http', 'sortingMixin'];

function tenantCountryService($http, sortingMixin) {
    /**
     * Transforms a country for the list view
     * @param {Object} country
     * @returns {Object}
     */
    function transformCountry(country) {
        return _(country).pick('id')
                         .assign({name: country.country.name, company: country.company.name})
                         .value();
    }

    /**
     * Transform country to extract only the relevant data for the UI
     * @param {Object} country
     * @returns {Object}
     */
    function transformSupportedCountry(country) {
        return _(country).pick(['id', 'country'])
                         .assign({company: {id: country.company.id, name: country.company.name}})
                         .value();
    }

    /**
     * In case there are no countries, a 204 is returned
     * @param {Object} response
     * @returns {boolean}
     */
    function hasCountries(response) {
        return response.status === 200;
    }

    return _.assign({}, sortingMixin, {
        /**
         * Returns all countries
         * @returns {Promise.<Array>}
         */
        getCountries() {
            return $http.get('/api/billing/settings/countries')
                        .then(_.partialRight(_.map, transformCountry));
        },

        /**
         * Returns only countries discarding company data
         * @returns {Promise.<Array>}
         */
        getCountriesOnly() {
            return $http.get('/api/billing/settings/countries')
                        .then(_.partialRight(_.map, _.property('country')));
        },

        /**
         * Returns a single country by a given id
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        getCountry(id) {
            return $http.get('/api/billing/settings/countries/:id', {params: {id}})
                        .then(transformSupportedCountry);
        },

        /**
         * Checks if there are any tenant countries defined
         * @returns {Promise.<boolean>}
         */
        hasCountries() {
            return $http.head('/api/billing/settings/countries')
                        .then(hasCountries);
        },

        /**
         * Saves new country
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        saveCountry(requestData) {
            return $http.post('/api/billing/settings/countries', requestData);
        },

        /**
         * Updates a country
         * @param {string} countryId
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        updateCountry(countryId, requestData) {
            return $http.patch('/api/billing/settings/countries/:id', requestData, {params: {id: countryId}});
        },

        /**
         * Deletes a country
         * @param {string} countryId
         * @returns {Promise.<Object>}
         */
        deleteCountry(countryId) {
            return $http.delete('/api/billing/settings/countries/:id', {params: {id: countryId}});
        },

        /**
         * Returns available countries (all countries except the ones where the company is already operating)
         * @returns {Promise.<Array>}
         */
        getAvailableCountries() {
            return $http.get('/api/billing/settings/available-countries');
        },

        /**
         * Checks if there are any available countries
         * @returns {Promise.<boolean>}
         */
        hasAvailableCountries() {
            return $http.head('/api/billing/settings/available-countries')
                        .then(hasCountries);
        }
    });
}
