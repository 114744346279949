angular.module('everon.tenant-settings.companies')
       .controller('CompaniesController', CompaniesController);

CompaniesController.$inject = ['CONSTANTS', 'mediatorService', 'stateHelper', 'tenantCompanyService'];

function CompaniesController(CONSTANTS, mediatorService, stateHelper, tenantCompanyService) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({sort: setSortingParams});

        $ctrl.state = {
            dataLoaded: false
        };

        $ctrl.noResultsConfig = {
            heading: 'tenantSettings.legalEntities.noResults.heading',
            paragraph: 'tenantSettings.legalEntities.noResults.paragraph'
        };

        $ctrl.columns = [
            {
                property: 'name',
                translationKey: 'generic.label.companyName',
                sortable: true,
                cssClass: 'col-6'
            },
            {
                property: 'country',
                translationKey: 'generic.entity.country',
                sortable: true,
                cssClass: 'col-3'
            },
            {
                property: 'currency',
                translationKey: 'generic.label.currency',
                sortable: true,
                cssClass: 'col-3 xs-hide'
            }
        ];

        $ctrl.actions = [
            {
                name: 'tenantSettings.legalEntities.action.add',
                state: 'auth.tenant-settings.companies.company-add'
            }
        ];

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    /**
     * Set sorting params from the state and re-orders transactions accordingly
     */
    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
        $ctrl.companies = tenantCompanyService.orderBy($ctrl.companies, $ctrl.sortingParams);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.companies = data;
        setSortingParams();

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
