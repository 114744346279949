angular.module('everon.stations.station.overview')
       .controller('StationOverviewController', StationOverviewController);

StationOverviewController.$inject = ['$q', 'mediatorService', 'CONSTANTS', 'stationService', '$log', 'permissionService', 'sessionService', 'chargingProfilesService', 'modalService', '$timeout', 'utils'];

function StationOverviewController($q, mediatorService, CONSTANTS, stationService, $log, permissionService, sessionService, chargingProfilesService, modalService, $timeout, utils) {
    const $ctrl = this;
    let modal;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            canUpdateSite: checkPermissions(['SITE:UPDATE']),
            canUpdateReimbursementRate: checkPermissions(['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']),
            stationIsInMyAccount: true,
            canSeeChargingProfile: checkPermissions(['CHARGING_PROFILE:READ']),
            actionInProgress: false
        };

        $ctrl.actionButtonConfig = {
            actions: [{
                title: 'Stop transaction',
                name: 'stopTransaction'
            }, {
                title: 'Full power charging',
                name: 'fullPowerCharging'
            }]
        };

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController'
        }).then(modalInstance => {
            modal = modalInstance;
        });

        $ctrl.statusCssClass = CONSTANTS.STATUS.STATION_CONNECTOR;

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    /**
     * Reacts to a click on any on the action buttons building modal config and following action
     * @param {Object} connector
     * @param {string} actionName
     */
    $ctrl.onActionsButtonClick = (connector, actionName) => {
        const stopTransactionAction = actionName === 'stopTransaction';
        const actionFunction = stopTransactionAction ? stopTransaction : fullPowerCharging;
        const modalConfig = {
            title: `stations.station.${stopTransactionAction ? 'assistance.heading.stopTransaction' : 'overview.overrideMaxCurrent.title'}`,
            paragraph: `stations.station.${stopTransactionAction ? 'assistance.heading.stopConfirm' : 'overview.overrideMaxCurrent.paragraph'}`,
            button: {
                ok: stopTransactionAction ? 'generic.action.stop' : 'stations.station.overview.overrideMaxCurrent.action'
            }
        };

        showModal(connector.identityCode, modalConfig, actionFunction);
    };

    /**
     * Shows confirmation modal dialog with the specified configuration
     * @param {string} connectorIdentityCode
     * @param {Object} modalConfig
     * @param {Function} actionFunction
     */
    function showModal(connectorIdentityCode, modalConfig, actionFunction) {
        modal.open(modalConfig)
             .then(response => actionFunction(response, connectorIdentityCode));
    }

    /**
     * Attempts to override transaction max current
     * @param {boolean} canProceed
     * @param {string} connectorIdentityCode
     * @returns {Promise}
     */
    function fullPowerCharging(canProceed, connectorIdentityCode) {
        if (canProceed) {
            $ctrl.state.actionInProgress = true;

            const transaction = getTransactionByConnectorIdentityCode(connectorIdentityCode);
            const params = {
                stationIdentityCode: $ctrl.station.identityCode,
                transactionId: transaction.id,
                connectorIdentityCode: transaction.evseId
            };

            return chargingProfilesService.overrideMaxCurrent(params)
                                          .then(() => onFulfilled('stations.station.overview.overrideMaxCurrent.notification.success'))
                                          .catch(onRejected)
                                          .finally(() => {
                                              $ctrl.state.actionInProgress = false;
                                          });
        }
    }

    /**
     * Attempts to remotely stop the transaction
     * @param {boolean} canProceed
     * @param {string} connectorIdentityCode
     * @returns {Promise}
     */
    function stopTransaction(canProceed, connectorIdentityCode) {
        if (canProceed) {
            $ctrl.state.actionInProgress = true;

            const transaction = getTransactionByConnectorIdentityCode(connectorIdentityCode);

            return stationService.stopTransaction($ctrl.station.id, transaction.id)
                                 .then(() => {
                                     onFulfilled();

                                     transaction.open = false;
                                 })
                                 .catch(onRejected)
                                 .finally(() => {
                                     updateTransactionsAndStatuses();

                                     $ctrl.state.actionInProgress = false;
                                 });
        }
    }

    /**
     * Gets the transaction with specified identityCode from transactions array
     * @param {string} identityCode
     * @returns {Object}
     */
    function getTransactionByConnectorIdentityCode(identityCode) {
        return $ctrl.transactions.find(transaction => `${transaction.evseId}` === identityCode);
    }

    /**
     * Requests transactions and statuses information and updates the view if something changes
     */
    function updateTransactionsAndStatuses() {
        let tryFor = CONSTANTS.HTTP_REQUEST_TIMEOUT;

        getTransactions(3000);

        function getTransactions(timeout) {
            $timeout(() => {
                tryFor -= timeout;

                if (tryFor > 0) {
                    getStationConnectorsStatus($ctrl.station);
                    stationService.getOpenTransactions($ctrl.station.id).then(transactions => {
                        if (transactions.length === $ctrl.transactions.length) {
                            getTransactions(timeout);
                        } else {
                            $ctrl.transactions = transactions;
                        }
                    });
                }
            }, timeout);
        }
    }

    /**
     * Displays success notification message based on the messageKey
     * @param {string} messageKey
     */
    function onFulfilled(messageKey = 'generic.notification.requestAccepted') {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {type: 'success', messageKey});
    }

    /**
     * Displays error notification message based on the error status
     * @param {Object} error
     */
    function onRejected({status}) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: _.includes([400, 503], status) ?
                'generic.notification.stationUnavailable' :
                (status === 502) ? 'generic.notification.requestRejected' : CONSTANTS.ERROR_CODES[status],
            isPersistent: true
        });
    }

    /**
     * Gets station connector status and updates the UI
     * @param {Object} station
     */
    function getStationConnectorsStatus(station) {
        stationService.getStationConnectorsStatus(station)
                      .then(statuses => {
                          $ctrl.station.connectors = extendConnectors(station.connectors, statuses);
                      })
                      .catch(error => {
                          $log.error('Failed to get station %s status', station.id, error);
                      });
    }

    /**
     * Extends connectors with status information and the action button status
     * @param {Array} connectors
     * @param {Array} statuses
     * @returns {Array}
     */
    function extendConnectors(connectors, statuses) {
        return _.map(connectors, connector => _.assign({},
            connector,
            _.find(statuses, {evseIdentityCode: connector.identityCode}),
            {actionsButtonDisabled: !_.find($ctrl.transactions, transaction => transaction.evseId.toString() === connector.identityCode)})
        );
    }

    /**
     * Builds the needed information for the charging profile card
     * @param {Object} station
     * @param {Array} weekDaysInitials
     * @returns {Object}
     */
    function buildChargingProfileInformation({chargingProfile, utcOffset}, weekDaysInitials) {
        const daySchedules = chargingProfilesService.generateDaySchedules(chargingProfile);
        const powerBarsSetup = chargingProfilesService.createPowerBarsFromDaySchedules(daySchedules, chargingProfile.maxCurrent);
        const weekDays = _.map(powerBarsSetup, (powerBarSetup, index) => ({dayInitial: weekDaysInitials[index], powerBar: powerBarSetup}));
        const formattedUtcOffset = utils.generateHumanReadableUtcOffset(utcOffset);

        return {id: chargingProfile.id, weekDays, formattedUtcOffset, title: {default: chargingProfile.name}};
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({station, subscription, accountFeatures, transactions, weekDaysInitials}) {
        if (station.chargingProfile && $ctrl.state.canSeeChargingProfile) {
            station.chargingProfile.card = buildChargingProfileInformation(station, weekDaysInitials);
        }

        $ctrl.station = station;
        $ctrl.subscription = subscription;
        $ctrl.accountFeatures = accountFeatures;
        $ctrl.transactions = transactions;
        getStationConnectorsStatus($ctrl.station);
        $ctrl.state.dataLoaded = true;
        $ctrl.state.stationIsInMyAccount = $ctrl.station.accountId === sessionService.getUserProfile().accountId;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
