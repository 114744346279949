angular.module('everon.cards.card-add.card-details')
       .controller('CardAddDetailsController', CardAddDetailsController);

CardAddDetailsController.$inject = ['$state', '$scope', 'cardService', 'accountService', 'mediatorService', '$q', 'CONSTANTS'];

function CardAddDetailsController($state, $scope, cardService, accountService, mediatorService, $q, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.card = $ctrl.cardAddCtrl.payload.card;

        $ctrl.regex = {
            contractId: CONSTANTS.REGEX.CONTRACT_ID
        };

        // When loading from storage we need to assign the old id to the current id again
        if ($ctrl.cardAddCtrl.payload.card.legacyContractId) {
            $ctrl.cardAddCtrl.payload.card.contractId = $ctrl.cardAddCtrl.payload.card.legacyContractId;
        }

        $ctrl.asyncValidatorConfig = {
            name: 'verifiedCard',
            validatorFn: verifyCard
        };

        $ctrl.searchOptions = {
            id: 'accounts',
            initialValue: $ctrl.card.accountNameToActivateOn || '',
            asyncSearch: true,
            getDataAsync: getData,
            onSelect: onAccountSelected
        };

        $ctrl.searchOptionsOnBehalf = {
            id: 'accounts-on-behalf',
            initialValue: $ctrl.card.accountNameToActivateOn || '',
            asyncSearch: true,
            getDataAsync: getDataOnBehalf,
            onSelect: onAccountSelectedOnBehalf
        };

        if ($state.params && $state.params.contractId) {
            $ctrl.card.contractId = $state.params.contractId;
        }

        if ($ctrl.dataPromise) {
            $ctrl.dataPromise.then(onDataLoaded);
        }
    };

    $scope.$watch('$ctrl.addCardForm', function () {
        if ($ctrl.card.contractId) {
            $ctrl.addCardForm.contractId.$setDirty();
        }
    });

    /**
     * Verifies if card exists and has a registered owner
     * @param {string} modelValue
     * @returns {Promise}
     */
    function verifyCard(modelValue) {
        return cardService.verifyCard(modelValue)
                          .then(data => {
                              if (data.eMI3ContractId) {
                                  $ctrl.card.eMI3ContractId = data.eMI3ContractId;
                              }
                          })
                          .catch(() => {
                              $ctrl.card.eMI3ContractId = null;

                              return $q.reject();
                          });
    }

    $ctrl.next = () => {
        if ($ctrl.cardAddCtrl.state.canActivateCardOnBehalf) {
            const {billingAddress, accountId} = $ctrl.cardAddCtrl.payload.billing;

            $ctrl.cardAddCtrl.next({country: billingAddress.country.code, accountId});
        } else {
            $ctrl.cardAddCtrl.next();
        }
    };

    $ctrl.onAssignToExistingChange = () => {
        // The search-dropdown component will be destroyed when 'no' is selected, so we need to reflect that in the model
        if (!$ctrl.card.assignToExisting) {
            $ctrl.card.accountToActivateOn = null;
            $ctrl.card.accountNameToActivateOn = null;
        }
    };

    /**
     * Extends controller's model with the selected account
     * @param {Object} account
     */
    function onAccountSelected({id, primary}) {
        $ctrl.card.accountToActivateOn = id;
        $ctrl.card.accountNameToActivateOn = primary;
    }

    /**
     * Extends controller's model with the selected account on fetch the billing info
     * @param {Object} account
     */
    function onAccountSelectedOnBehalf({id, primary, billingAccountId, accountOwnerEmail}) {
        onAccountSelected({id, primary});

        accountService.getBillingInfo(billingAccountId)
                      .then(billingInfo => {
                          $ctrl.cardAddCtrl.payload.billing = billingInfo;
                          $ctrl.cardAddCtrl.payload.accountOwnerEmail = accountOwnerEmail;
                      }).catch(onBillingInfoRejected);
    }

    function getData(query) {
        return accountService.getChildAccounts({query})
                             .then(data => data.content.map(({id, name}) => ({id, primary: name})));
    }

    function getDataOnBehalf(query) {
        return accountService.getAllAccounts({query})
                             .then(data => data.content.map(({id, name, billingAccountId, accountOwnerEmail}) => ({id, primary: name, billingAccountId, accountOwnerEmail})));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.accountHasSubaccounts = Boolean(data.childAccounts.totalElements > 0);
    }

    function onBillingInfoRejected(response) {
        if (response.status !== 404) {
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                type: 'error',
                messageKey: CONSTANTS.ERROR_CODES[response.status]
            });
        }
    }
}
