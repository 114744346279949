/**
 * When added to an action button, this directive will disable the button and show a spinner within the button if the attribute value is
 * equal to `'true'`.
 *
 * @example
 *
 * <div role="table" evcloud-block-ui="{{$ctrl.state.isInProgress}}"></div>
 *
 */

angular.module('everon.component.block-ui')
       .directive('evcloudBlockUi', blockUi);

blockUi.$inject = [];

function blockUi() {
    return {
        restrict: 'EA',
        link(scope, element, attributes) {
            attributes.$observe('evcloudBlockUi', onChange);

            /**
             * Toggles `role="alert"` and `aria-busy="true"` on the element to block the UI
             * @param {string} value
             */
            function onChange(value) {
                const isBlocked = value === 'true';

                element.attr('role', isBlocked ? 'alert' : '');
                element.attr('aria-busy', isBlocked);
            }
        }
    };
}
