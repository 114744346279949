angular.module('everon.core')
       .decorator('$http', httpDecorator);

httpDecorator.$inject = ['$delegate', '$location', 'config'];

function httpDecorator($delegate, $location, config) {
    const methods = ['get', 'post', 'put', 'patch', 'delete', 'head'];
    const regex = /:([a-zA-Z]+)/g;

    /**
     * Deletes query param from config. If there are no params left, deletes entire `config.params` object
     * @param {string} key
     * @param {Object} config
     */
    function deleteParam(key, config) {
        delete config.params[key];

        if (_.isEmpty(config.params)) {
            delete config.params;
        }
    }

    /**
     * Replaces `:param` in the url with the actual value provided in `config.params`
     * @param {Object} config
     * @param {string} match
     * @param {string} key
     * @returns {string}
     */
    function replacer(config, match, key) {
        const param = config.params[key];

        deleteParam(key, config);

        return param;
    }

    /**
     * Interpolates url with params
     * @param {string} url
     * @param {Object} config
     * @returns {string}
     */
    function interpolateUrl(url, config) {
        return regex.test(url) ? url.replace(regex, _.partial(replacer, config)) : url;
    }

    /**
     * Checks whether a given url is a static resource. Determined by a dot before file extension
     * @param {string} url
     * @returns {boolean}
     */
    function isStaticResource(url) {
        return url.indexOf('.') !== -1;
    }

    /**
     * Returns correct url based on start of the path. If it contains `api`, we prepend `apiUrl` to it
     * @param {string} url
     * @returns {string}
     */
    function resolveApiUrl(url) {
        if (isStaticResource(url)) {
            return url;
        }

        const endpointStart = _(url).split('/')
                                    .compact()
                                    .value()
                                    .shift();

        return (endpointStart === 'api') ? `${config.apiUrl}${url}` : url;
    }

    /**
     * Decorates `$http.<method>` to support interpolated urls with params; e.g. /api/platform/cards/:id/transactions/:period
     * @param {string} method
     */
    function decorate(method) {
        const originalMethod = $delegate[method];

        // GET, HEAD and DELETE methods take 2 arguments, POST, PUT, PATCH - 3 arguments
        $delegate[method] = (...args) => {
            const originalArgs = args;
            const newArgs = _.cloneDeep(args);

            if (originalArgs.length === 3) {
                newArgs.splice(1, 1);
            }

            // Call each function in array to manipulate the url and return an end result
            newArgs[0] = [resolveApiUrl, interpolateUrl].reduce((url, fn) => {
                newArgs[0] = url;

                return fn(...newArgs);
            }, newArgs[0]);

            if (originalArgs.length === 3) {
                newArgs.splice(1, 0, originalArgs[1]);
            }

            return originalMethod(...newArgs);
        };
    }

    methods.forEach(decorate);

    return $delegate;
}
