angular.module('everon.account-recovery.password-reset-request')
       .controller('PasswordResetRequestController', PasswordResetRequestController);

PasswordResetRequestController.$inject = ['$window', '$state', '$translate', '$log', 'config', 'sessionService', 'mediatorService', 'CONSTANTS', 'grecaptcha', 'localStore'];

function PasswordResetRequestController($window, $state, $translate, $log, config, sessionService, mediatorService, CONSTANTS, grecaptcha, localStore) {
    const $ctrl = this;
    const needsReCaptcha = !config.isMobile && !config.isDevMode;
    let reCaptchaClientId;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            submitInProgress: false,
            reCaptchaPassed: true
        };
        $ctrl.requestData = {};

        if (needsReCaptcha) {
            $ctrl.recaptchaLanguage = $translate.use();

            mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.LANGUAGE_CHANGE, updateReCaptchaLanguage);
        }
    };

    $ctrl.$postLink = () => {
        if (needsReCaptcha) {
            reCaptchaClientId = grecaptcha.render('g-recaptcha', {
                sitekey: localStore.get('E2E_TEST_ENV') ? CONSTANTS.RECAPTCHA.TEST_SITE_KEY : $ctrl.data.key,
                size: 'invisible',
                badge: 'bottomright',
                hl: $ctrl.recaptchaLanguage
            });
        }
    };

    $ctrl.$onDestroy = () => {
        resetData();
    };

    $ctrl.returnToLogin = () => {
        $state.go('anon.landing.login');
    };

    $ctrl.requestPasswordReset = () => {
        if (needsReCaptcha) {
            grecaptcha.execute(reCaptchaClientId, {
                          action: 'password_reset_request'
                      })
                      .then(token => sessionService.getReCaptchaScore({response: token}))
                      .then(response => {
                          $log.debug(response);
                      });
        }

        $ctrl.state.submitInProgress = true;

        sessionService.requestPasswordReset($ctrl.requestData)
                      .then(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              buttonText: 'generic.action.ok',
                              messageKey: 'accountRecovery.passwordResetRequest.notification.emailSent',
                              isPersistent: true,
                              callback: $ctrl.returnToLogin
                          });
                          resetData();
                      })
                      .catch(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: 'accountRecovery.passwordResetRequest.notification.failed',
                              isPersistent: true,
                              callback: resetData
                          });
                      })
                      .finally(() => {
                          $ctrl.state.submitInProgress = false;
                      });
    };

    /**
     * Updates the language of Google reCAPTCHA once it is already rendered
     * @param {string} language The language to use in the translations
     */
    function updateReCaptchaLanguage(language) {
        const reCaptchaIframe = document.querySelector('#g-recaptcha iframe');

        reCaptchaIframe.src = reCaptchaIframe.src.replace(`hl=${$ctrl.recaptchaLanguage}`, `hl=${language}`);
        $ctrl.recaptchaLanguage = language;
    }

    /**
     * Resets user data and form
     */
    function resetData() {
        $ctrl.requestData = {};
        $ctrl.requestPasswordResetForm.$setPristine();
        $ctrl.requestPasswordResetForm.$setUntouched();
    }
}
