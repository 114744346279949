angular.module('everon.stations.station.overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.overview', {
        url: '/overview',
        data: {
            id: 'station-overview-navigation-link',
            title: 'generic.title.overview',
            navigationType: ['context'],
            menuPriority: 1
        },
        resolve: {
            data: ['$q', 'data', 'stationService', 'subscriptionService', 'accountService', 'chargingProfilesService',
                ($q, {id, identityCode}, stationService, subscriptionService, accountService, chargingProfilesService) => {
                return $q.all({
                    station: stationService.getStation(id),
                    subscription: subscriptionService.getStationSubscription(id),
                    accountFeatures: accountService.getFeatureFlags(),
                    transactions: stationService.getOpenTransactions(id),
                    weekDaysInitials: chargingProfilesService.getWeekDaysInitials()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-overview data-data-promise="::$resolve.data" />'
            }
        }
    });
}
