(function (window) {
    window.ga = window.ga || function (...args) {
        (window.ga.q = window.ga.q || []).push(args);
    };

    window.ga.l = new Date().getTime();

    // Cordova needs a different init setup, initialization is done in cordova-init.js
    // no event is sent here, 'onTransitionSuccess' is where we handle GA events
    if (!window.EVERON.isMobile) {
        window.ga('create', 'UA-45893987-11', 'auto');

        // a custom 'dimension' was created as an example, logs the platform.
        // 'web' is set for the browser, mobile or desktop
        window.ga('set', 'dimension1', 'web');
    }
})(window);

(function () {
    angular.module('everon')
           .factory('ga', ga);

    ga.$inject = ['$window'];

    function ga($window) {
        if (!$window.ga) {
            throw new Error('google analytics lib was not added to the project!');
        }

        return $window.ga;
    }
})();
