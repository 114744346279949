angular.module('everon.cards.card.billing-plan.change')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card.billing-plan.change', {
        redirectTo: 'auth.cards.card.billing-plan.change.select',
        data: {
            id: 'card-billing-plan-select-navigation-link',
            title: 'generic.title.billingPlan'
        },
        resolve: {
            data: ['data', data => data]
        },
        views: {
            'content@auth.cards.card': {
                component: 'evcloudCardBillingPlanChange',
                bindings: {
                    data: 'data'
                }
            }
        }
    });
}

