angular.module('everon.tenant-settings.companies')
       .factory('tenantCompanyService', tenantCompanyService);

tenantCompanyService.$inject = ['$http', 'sortingMixin'];

function tenantCompanyService($http, sortingMixin) {
    /**
     * Transforms a company
     * @param {Object} company
     * @returns {Object}
     */
    function transformCompany(company) {
        return _(company).pick(['name', 'id'])
                         .assign({
                             country: company.address.country.name,
                             currency: company.currency.code
                         })
                         .value();
    }

    /**
     * Transforms a company for country creation UI
     * @param {Object} company
     * @returns {Object}
     */
    function transformForCountries(company) {
        return _(company).pick(['id', 'name'])
                         .value();
    }

    /**
     * In case there are no companies, a 204 is returned
     * @param {Object} response
     * @returns {boolean}
     */
    function hasCompanies(response) {
        return response.status === 200;
    }

    return _.assign({}, sortingMixin, {
        /**
         * Returns all legal companies
         * @returns {Promise.<Array>}
         */
        getCompanies() {
            return $http.get('/api/billing/companies')
                        .then(_.partialRight(_.map, transformCompany));
        },

        /**
         * Returns a single company by a given id
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        getCompany(id) {
            return $http.get('/api/billing/companies/:id', {params: {id}});
        },

        /**
         * Saves new company
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        saveCompany(requestData) {
            return $http.post('/api/billing/companies', requestData);
        },

        /**
         * Updates company
         * @param {string} companyId
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        updateCompany(companyId, requestData) {
            return $http.put('/api/billing/companies/:id', requestData, {params: {id: companyId}});
        },

        /**
         * Deletes company
         * @param {string} companyId
         * @returns {Promise.<Object>}
         */
        deleteCompany(companyId) {
            return $http.delete('/api/billing/companies/:id', {params: {id: companyId}});
        },

        /**
         * Verifies if company name exists
         * @param {string} name
         * @returns {Promise.<Object>}
         */
        verifyName(name) {
            return $http.get('/api/billing/companies/verification', {params: {name}});
        },

        /**
         * Returns transformed array of companies for use in supported tenant country creation
         * @returns {Promise.<Array>}
         */
        getCompaniesForCountry() {
            return this.getCompanies()
                       .then(_.partialRight(_.map, transformForCountries));
        },

        /**
         * Checks whether there are any companies created
         * @returns {Promise.<boolean>}
         */
        hasCompanies() {
            return $http.head('/api/billing/companies')
                        .then(hasCompanies);
        }
    });
}
