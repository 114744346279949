angular.module('everon.component.nav-list')
       .factory('navListService', navListService);

navListService.$inject = ['$q', '$injector', 'permissionService', 'stateHelper', 'config', 'sessionService', 'accountService'];

function navListService($q, $injector, permissionService, stateHelper, config, sessionService, accountService) {
    /**
     * Toggles item visibility based on tenant feature flags and user's permissions. If item is `mobileOnly` and app in not running on mobile, the item will be hidden.
     * @param {Object} user
     * @param {Object} item
     * @returns {Object}
     */
    function toggleVisibility(user, item) {
        const isFeatureAvailable = permissionService.resolveFeatures(sessionService.getFeatureFlags(), item.features);
        const isAuthorised = permissionService.resolve(user.permissions, item.permissions);
        const isVisibleInMenu = typeof item.visibleInMenu === 'function' ?
            item.visibleInMenu(user.permissions, {$injector}) :
            item.visibleInMenu === undefined || Boolean(item.visibleInMenu);

        item.show = isVisibleInMenu && isFeatureAvailable && isAuthorised && (config.isMobile || !item.mobileOnly);

        return item;
    }

    /**
     * Checks if state contains children that should be part of its secondary navigation
     * @param {Object} state
     * @returns {boolean}
     */
    function hasChildNavItems(state) {
        return state.getChildren().some(child => _.includes(child.data.navigationType, 'secondary'));
    }

    /**
     * Inserts a separator item between ordinary menu items and tenant level ones. In the menu it appears as `Tenant`
     * @param {Array} menuItems
     * @param {number} insertAtIndex
     * @returns {Array}
     */
    function insertSeparatorItem(menuItems, insertAtIndex) {
        const separatorItem = {
            navSeparator: true,
            primaryNavItem: true,
            secondaryNavItem: false,
            show: true,
            id: _.uniqueId()
        };

        menuItems.splice(insertAtIndex, 0, separatorItem);

        return menuItems;
    }

    function transformUsersOptionToSystemUsersLink(menuItems) {
        return menuItems.map(item => {
            if (item.id === 'users-navigation-link') {
                item = Object.assign({}, item, {
                    hasChildNav: false,
                    sref: 'auth.users.system-users',
                    srefOpts: {
                        reload: 'auth.users.system-users'
                    }
                });
            }

            return item;
        });
    }

    function checkIfIsBusinessAccount() {
        return $q(resolve => {
            if (checkIfIsBusinessAccount.cached !== undefined) {
                resolve(checkIfIsBusinessAccount.cached);

                return;
            }

            accountService.getBusinessAccountStatus().then(isBusiness => {
                checkIfIsBusinessAccount.cached = isBusiness;
                resolve(isBusiness);
            }).catch(() => resolve(false));
        });
    }

    return {
        /**
         * Returns a list of ordered menu items
         * @param {Object|boolean|undefined} filter Filter criteria
         * @param {Object} [params] State params object
         * @returns {Array}
         */
        getMenuItems(filter, params) {
            const menuStates = stateHelper.getStates(filter);

            return _(menuStates).map(_.partial(this.mapMenuItems, params))
                                .orderBy(['priority'], ['asc'])
                                .value();
        },

        /**
         * Maps each state to a menu item
         * @param {Object} [params]
         * @param {Object} state
         * @param {number} index
         * @returns {Object}
         */
        mapMenuItems(params, state, index) {
            return {
                priority: state.data.menuPriority || index,
                id: state.data.id,
                text: state.data.title,
                icon: Object.prototype.hasOwnProperty.call(state.data, 'icon') ? state.data.icon : null,
                primaryNavItem: _.includes(state.data.navigationType, 'primary'),
                secondaryNavItem: _.includes(state.data.navigationType, 'secondary'),
                tenantLevel: Object.prototype.hasOwnProperty.call(state.data, 'tenantLevel') && Boolean(state.data.tenantLevel),
                hasChildNav: hasChildNavItems(state),
                sref: params ? [state.name, '(', JSON.stringify(params), ')'].join('') : state.name,
                srefOpts: {
                    reload: state.name
                },
                mobileOnly: Boolean(state.data.mobileOnly),
                permissions: state.data.permissions,
                features: state.data.features,
                visibleInMenu: Object.prototype.hasOwnProperty.call(state.data, 'visibleInMenu') ? state.data.visibleInMenu : undefined
            };
        },

        /**
         * Sets navigation item visibility based on user permissions and filter criteria. Inserts separator item if needed
         * @param {*} filter Filter criteria
         * @param {Object} user User profile object
         * @param {Object} [params] State params object
         * @returns {Array}
         */
        setItemVisibility(filter, user, params) {
            const menuItems = this.getMenuItems(filter, params)
                                  .map(_.partial(toggleVisibility, user));
            const insertAtIndex = _.findIndex(menuItems, item => item.tenantLevel && item.show);

            if (insertAtIndex !== -1) {
                return insertSeparatorItem(menuItems, insertAtIndex);
            }

            return menuItems;
        },

        /**
         * This is a private method, its here just to allow mocking in the tests.
         */
        checkIfIsBusinessAccount,

        /**
         * Returns an array of filtered items
         * @param {*} filter Filter criteria
         * @param {Object} user User profile object
         * @param {Object} [params] State params object
         * @returns {Array}
         */
        getFilteredItems(filter, user, params) {
            let items = this.setItemVisibility(filter, user, params)
                            .filter(item => item.show);

            if (user.roles.includes('subAccountOwner')) {
                items = transformUsersOptionToSystemUsersLink(items);

                return $q.resolve(items);
            }

            if (!user.roles.includes('accountOwner')) {
                return $q.resolve(items);
            }

            return this.checkIfIsBusinessAccount()
                       .then(isBusinessAccount => {
                           if (!isBusinessAccount) {
                               items = transformUsersOptionToSystemUsersLink(items);
                           }

                           return items;
                       });
        }
    };
}
