angular.module('everon.component.tooltip')
       .directive('evcloudTooltip', tooltip);

tooltip.$inject = ['$window', '$templateRequest', '$rootScope'];

function tooltip($window, $templateRequest, $rootScope) {
    const insertElement = angular.element($window.document.body);
    const clickEvent = $rootScope.app.touch ? 'touchstart' : 'click';
    const hoverEvent = $rootScope.app.touch ? 'touchstart' : 'mouseenter';

    function positionTooltip(tooltipElement, element) {
        const {top, right, left, width, height} = element[0].getBoundingClientRect();
        const viewportDimensions = insertElement[0].getBoundingClientRect();
        const classConstraint = [
            {
                class: 'top',
                constraint: top > 50,
                css: {
                    bottom: `${viewportDimensions.height - top}px`,
                    left: `${left + width / 2}px`,
                    top: 'auto'
                }
            }, {
                class: 'bottom',
                constraint: top < 50,
                css: {
                    top: `${top + height}px`
                }
            }, {
                class: 'right',
                constraint: viewportDimensions.width - right < 50,
                css: {
                    right: `${viewportDimensions.width - right}px`,
                    left: 'auto'
                }
            }, {
                class: 'left',
                constraint: left < 50,
                css: {
                    left: `${left}px`
                }
            }
        ];

        // Gets the default positioning
        tooltipElement.css({
            top: `${top}px`,
            left: `${left + width / 2}px`
        });

        // Overwrites defaults if constraint apply
        classConstraint.forEach(direction => {
            if (direction.constraint) {
                tooltipElement.addClass(direction.class);
                tooltipElement.css(direction.css);
            }
        });
    }

    function createTooltip(element, tooltipElement) {
        if (!tooltipElement.parent().length) {
            positionTooltip(tooltipElement, element);
            insertElement.append(tooltipElement);
        }
    }

    return {
        restrict: 'A',
        link(scope, element, attrs) {
            $templateRequest('components/tooltip/tooltip.html').then(template => {
                const tooltipElement = angular.element(template);
                const textHolder = tooltipElement.find('section');

                const destroyTooltip = () => {
                    element.off(clickEvent, destroyTooltip);
                    tooltipElement.remove();
                };

                textHolder.text(attrs.evcloudTooltip);
                attrs.$observe('evcloudTooltip', val => textHolder.text(val));

                element.on(hoverEvent, () => {
                    if (attrs.evcloudTooltip) {
                        createTooltip(element, tooltipElement);

                        element.on(clickEvent, destroyTooltip);

                        tooltipElement.find('button').on('click', destroyTooltip);
                        tooltipElement.on('mouseleave', event => !element[0].contains(event.relatedTarget) && destroyTooltip());
                    }
                });

                element.on('mouseleave', event => !tooltipElement[0].contains(event.relatedTarget) && destroyTooltip());

                scope.$on('$destroy', destroyTooltip);
            });
        }
    };
}
