angular.module('everon.stations.sites.site-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.sites.site-add', {
        url: '/new',
        data: {
            title: 'stations.action.createSite',
            permissions: ['SITE:CREATE']
        },
        resolve: {
            data: ['$q', 'countryService', 'siteFormModel',
                ($q, countryService, siteFormModel) => {
                    return $q.all({
                        countries: countryService.getTenantCountries(),
                        addressFormModel: siteFormModel.address
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-site-add data-data-promise="::$resolve.data" class="col-12 flex flex-column items-center"/>'
            }
        }
    });
}
