angular.module('everon')
       .factory('sessionService', sessionService);

sessionService.$inject = ['$http', '$q', '$translate', '$log', '$window', 'accountService', 'countryService',
    'localisationService', 'tenantSettingsService', 'utils', 'config', 'oktaSessionService', 'featureToggleService'];

function sessionService($http, $q, $translate, $log, $window, accountService, countryService, localisationService,
                        tenantSettingsService, utils, config, oktaSessionService, featureToggleService) {
    let session = null;

    const oktaUserMgFeature = featureToggleService('@@oktaUserManagement');

    function loadProfile() {
        return $http.get('/api/platform/users/me');
    }

    /**
     * Returns combined user profile data
     * @param {Object} userProfile
     * @returns {Promise.<Object>}
     */
    function setupSession(userProfile) {
        if (!userProfile) {
            return $q.reject('Missing profile info.');
        }

        const {tag = 'en-GB'} = (userProfile.language || {});

        return $q.all({
                     translate: $translate.use(tag)
                                          .catch(languageTag => {
                                              $log.debug(`Failed to load ${languageTag}.json. Check if file is a valid JSON`);
                                          }),
                     tenantFeatureFlags: tenantSettingsService.getFeatureFlags(),
                     accountFeatureFlags: accountService.getFeatureFlags(),
                     accountSetupComplete: accountService.getBillingInfoStatus()
                 })
                 .then(({tenantFeatureFlags, accountFeatureFlags, accountSetupComplete}) => {
                     userProfile.accountSetupComplete = accountSetupComplete;
                     session = {
                         tenantFeatureFlags,
                         accountFeatureFlags,
                         profile: userProfile
                     };

                     localisationService.localise(tag);

                     return session;
                 });
    }

    /**
     * Destroys user and propagates rejected promise
     * @param {Object} response
     * @returns {Promise.<Object>}
     */
    function onRejected(response) {
        return destroyUser().then(() => $q.reject(response));
    }

    /**
     * Destroys `session` on unsuccessful login attempt and on logout
     * @returns {Promise}
     */
    function destroyUser() {
        session = null;

        return $q.resolve();
    }

    const sessionService = {
        createAccount(data) {
            return $http.post('/api/platform/sign-up/registration', data);
        },

        activateAccount(data) {
            return $http.post('/api/platform/sign-up/activation', data);
        },

        updateAndActivateInvitedAccount(data, activationCode) {
            return $http.patch('/api/platform/sign-up/activation/:activationCode', data, {params: {activationCode}});
        },

        /**
         * Logs a user into the application
         * @param {Object} credentials
         * @returns {Promise<Object>}
         */
        login({username, password} = {}) {
            session = null;

            const {isMobile} = config;
            const reqConfig = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };

            let responseHandler = response => {
                const redirectLocation = response.headers('X-Authorize-Location');

                if (redirectLocation) {
                    sessionService.redirectForAuthorization(redirectLocation);

                    return;
                }

                return $q.reject('Invalid login response.');
            };

            if (isMobile) {
                responseHandler = () => this.loadSession();
            }

            const data = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

            return $http.post(`/api/platform/${isMobile ? 'm' : ''}login`, data, reqConfig)
                        .then(responseHandler);
        },

        redirectForAuthorization(authUrl) {
            if (config.isMobile) {
                window.open(authUrl, '_self');

                return;
            }

            window.location.href = authUrl;
        },

        /**
         * Attempts logout and destroys session user in any case
         * @returns {Promise.<Object>}
         */
        logout() {
            return $http.post('/api/platform/logout')
                        .finally(destroyUser);
        },

        requestPasswordReset(data) {
            return $http.post('/api/platform/password-reset/request', data);
        },

        resetPassword(data) {
            return $http.put('/api/platform/password-reset', data);
        },

        /**
         * Returns change password code validity
         * @param {string} code
         * @returns {Promise.<Object>}
         */
        verifyPasswordResetCode(code) {
            return $http.get('/api/platform/password-reset/verification', {params: {code}});
        },

        /**
         * Requests new activation email to be sent out to user's email address
         * @param {string} emailAddress
         * @returns {Promise}
         */
        requestActivation(emailAddress) {
            return $http.put('/api/platform/sign-up/activation-request', {email: emailAddress});
        },

        loadSession(forceReload) {
            if (session && !forceReload) {
                return $q.resolve(session);
            }

            return loadProfile().then(response => setupSession(response.data)).catch(onRejected);
        },

        isAuthenticated() {
            return session !== null;
        },

        /**
         * Returns authenticated user profile
         * @returns {Object}
         */
        getUserProfile() { // TODO: should be renamed to getCurrentUserProfile for more clarity
            return session ? session.profile : {};
        },

        /**
         * Returns available tenant feature flags or empty object if `session` is not resolved
         * @returns {Object}
         */
        getTenantFeatureFlags() {
            return session ? session.tenantFeatureFlags : {};
        },

        /**
         * Returns available account feature flags or empty object if `session` is not resolved
         * @returns {Object}
         */
        getAccountFeatureFlags() {
            return session ? session.accountFeatureFlags : {};
        },

        /**
         * Returns available feature flags, tenant mixed with account
         * @returns {Object}
         */
        getFeatureFlags() {
            return {...this.getTenantFeatureFlags(), ...this.getAccountFeatureFlags()};
        },

        /**
         * Returns reCaptcha site key depending on environment
         * @returns {Promise}
         */
        getReCaptchaSiteKey() {
            return $http.get('/api/platform/google-recaptcha-key');
        },

        /**
         * Makes a call to the back-end which then calls https://www.google.com/recaptcha/api/siteverify to get a response with a score
         * @param {string} response
         * @returns {Promise}
         */
        getReCaptchaScore({response}) {
            return $http.get('/api/platform/recaptcha', {params: {response}});
        },

        /**
         * Makes a call to the back-end to update list of available features
         * @returns {Promise}
         */
        updateFeatureFlags() {
            return $q.all({
                         tenantFeatureFlags: tenantSettingsService.getFeatureFlags(),
                         accountFeatureFlags: accountService.getFeatureFlags()
                     })
                     .then(({tenantFeatureFlags, accountFeatureFlags}) => {
                         session = {...session, tenantFeatureFlags, accountFeatureFlags};
                     });
        },

        // ---------------- Compatibility with okta-session.service ---------------
        getAuthorizationHeader() {
            return '';
        }
    };

    return oktaUserMgFeature.select({
        disabledObject: sessionService,
        enabledObject: oktaSessionService
    });
}
