/**
 * Adds a spinner to `ui-view` element when needed
 *
 * @example <div ui-view="main" evcloud-spinner></div>
 */
angular.module('everon.component.spinner')
       .directive('evcloudSpinner', spinner);

spinner.$inject = ['$transitions', '$state', 'CONSTANTS', 'mediatorService'];

function spinner($transitions, $state, CONSTANTS, mediatorService) {
    return {
        restrict: 'A',
        controller: ['$element', function ($element) {
            const $ctrl = this;
            let eventListeners;

            $ctrl.$postLink = () => {
                eventListeners = [
                    $transitions.onError({}, onTransitionError),
                    mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, toggleState)
                ];
            };

            $ctrl.$onDestroy = () => {
                _.invokeMap(eventListeners, _.call);
            };

            /**
             * If data is loading, delegate to `onTransitionStart`; if data has loaded, delegate to `onTransitionSuccess` to hide the spinner
             * @param {boolean} isLoading
             */
            function toggleState(isLoading) {
                isLoading ? onDataLoading() : onDataLoaded();
            }

            /**
             * Sets `role` attribute to `progressbar` if current loading view is the same as declared `ui-view`
             */
            function onDataLoading() {
                const currentLoadingView = (Object.keys($state.$current.views)[0] || '').split('@').shift();
                const role = ($element.attr('ui-view') === currentLoadingView) || isRedirectedFromParent() ? 'progressbar' : '';

                $element.attr('role', role);
            }

            /**
             * Checks if redirect occurred from parent state to current state
             * @returns {boolean}
             */
            function isRedirectedFromParent() {
                const fromState = $state.router.globals.transitionHistory.peekHead().from();

                return fromState.name && _.get($state.$current, 'parent.includes', [])[fromState.name] && (_.get($state.$current, 'parent.redirectTo') === $state.$current.name);
            }

            /**
             * Sets `role` to empty value to remove the spinner from `ui-view`
             */
            function onDataLoaded() {
                $element.attr('role', '');
            }

            /**
             * If redirecting to another state, we don't hide the spinner. If `redirectTo` is UI-Router's built-in hook function and transition is `redirected`, we show the spinner while
             * redirecting
             * @param {Object} transition
             */
            function onTransitionError(transition) {
                if (_.isFunction(transition.to().redirectTo) && transition.error().redirected) {
                    onDataLoading();
                } else if (!transition.to().redirectTo) {
                    onDataLoaded();
                }
            }
        }]
    };
}
