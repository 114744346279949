angular.module('everon.stations.station-location-form')
       .component('evcloudStationLocationForm', {
           controller: 'StationLocationFormController',
           templateUrl: 'modules/stations/station-location-form/station-location-form.html',
           bindings: {
               parentModel: '<',
               locationData: '<'
           }
       });

