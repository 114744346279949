const filterTypes = {account: 1};

export default function filterActions() {
    return {
        setupFilterActions({dispatch}) {
            dispatch('registerParamChangeListener', {
                action: 'filterHandleUrlParamsChange',
                filter: ['account'],
                immediate: true
            });
        },

        cleanFilterActions({dispatch}) {
            dispatch('unregisterParamChangeListener', 'filterHandleUrlParamsChange');
        },

        filterHandleUrlParamsChange({commit, state}, {params}) {
            const newFilters = generateFilters(params);

            sortFiltersByPriority(newFilters);
            commit('update', ['filters', newFilters]);
        }
    };
}

function generateFilters(filtersInParams) {
    return Object.entries(filtersInParams).reduce((filters, [filterName, filterValue]) => {
        if (filterName in filterTypes && filterValue) {
            filters.push({
                type: filterName,
                value: filterValue.value,
                label: filterValue.label
            });
        }

        return filters;
    }, []);
}

function sortFiltersByPriority(filters = []) {
    return filters.sort((f1, f2) => filterTypes[f1.type] - filterTypes[f2.type]);
}
