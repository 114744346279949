angular.module('everon.account-recovery.password-reset-request')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.account-recovery.password-reset-request', {
        url: '/password-reset-request',
        data: {
            title: 'accountRecovery.passwordResetRequest.title'
        },
        resolve: {
            data: ['$q', 'sessionService', 'config', ($q, sessionService, config) => {
                return !config.isMobile && !config.isDevMode ? sessionService.getReCaptchaSiteKey() : $q.resolve({});
            }]
        },
        views: {
            'content@anon.account-recovery': {
                component: 'evcloudPasswordResetRequest',
                bindings: {
                    data: 'data'
                }
            }
        }
    });
}
