angular.module('everon.roaming.connections.connect-partner')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.connections.connect-partner', {
        url: '/connect-partner',
        redirectTo: 'auth.roaming.connections.connect-partner.details',
        data: {
            title: 'roaming.connections.action.connectPartner',
            permissions: ['ROAMING:CREATE']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/connections/connect-partner' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, [
                                 'everon.roaming.connections.connect-partner',
                                 'everon.roaming.connections.connect-partner.details',
                                 'everon.roaming.connections.connect-partner.token-url'
                             ]);
        },
        resolve: {
            hasSettings: ['data', data => !_.isEmpty(data.settings)]
        },
        // In case settings are not defined and user tried to access `/roaming/connections/connect-partner/` route directly, we redirect to `auth.roaming.connections`
        onEnter: ['$state', 'hasSettings', ($state, hasSettings) => hasSettings || $state.target('auth.roaming.connections')],
        views: {
            'main@auth': {
                template: '<evcloud-roaming-connect-partner class="col-12"/>'
            }
        }
    });
}
