angular.module('everon.cards.card.billing-plan.change.overview')
       .component('evcloudCardBillingPlanOverview', {
           controller: 'CardBillingPlanOverviewController',
           templateUrl: 'modules/cards/card/billing-plan/billing-plan-change/overview/card-billing-plan-overview.html',
           bindings: {
               dataPromise: '<'
           },
           require: {
               cardBillingPlanChangeCtrl: '^^evcloudCardBillingPlanChange'
           }
       });
