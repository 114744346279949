angular.module('everon.cards.card.roaming')
       .controller('CardRoamingController', CardRoamingController);

CardRoamingController.$inject = ['mediatorService', 'CONSTANTS', 'cardService'];

function CardRoamingController(mediatorService, CONSTANTS, cardService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            syncInProgress: false
        };

        $ctrl.columns = [
            {
                property: 'partyId',
                translationKey: 'cards.card.roaming.list.column.partyId',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-2'
            },
            {
                property: 'valid',
                translationKey: 'generic.label.valid',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-2'
            },
            {
                property: 'issuer',
                translationKey: 'generic.label.issuer',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-2'
            },
            {
                property: 'whitelist',
                translationKey: 'cards.card.roaming.list.column.whitelist',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-2'
            },
            {
                property: 'language',
                translationKey: 'cards.card.roaming.list.column.language',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-2'
            },
            {
                property: 'updatedAt',
                translationKey: 'generic.changeHistory.updatedAt',
                desc: true,
                sortable: false,
                active: false,
                cssClass: 'col-1'
            }
        ];

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Synchronizes all connections
     */
    $ctrl.syncPartnerConnections = () => {
        $ctrl.state.syncInProgress = true;
        $ctrl.model.partnerConnections.forEach(syncItem);
    };

    /**
     * Synchronize a single connection
     * @param {Object}  connection
     */
    $ctrl.syncPartnerConnection = connection => {
        syncItem(connection, 0, [connection]);
    };

    /**
     * @param {Object} item
     * @param {number} index
     * @param {Array} items
     */
    function syncItem(item, index, items) {
        item.syncInProgress = true;

        cardService.syncPartnerItem(item.itemPath)
                   .then(response => {
                       updateModel(response);
                   })
                   .catch(response => {
                       item.syncError = true;
                   })
                   .finally(response => {
                       item.syncInProgress = false;

                       if (items.length === index + 1) {
                           $ctrl.state.syncInProgress = false;
                       }
                   });
    }

    /**
     * Gets updated connection data
     * @param {Object} item
     * @param {number} index
     * @param {Array} items
     */
    function getPartnerItem(item, index, items) {
        if (item.error) {
            return;
        }

        item.syncInProgress = true;

        cardService.getPartnerItem(item.itemPath)
                   .then(updateModel)
                   .finally(() => {
                       item.syncInProgress = false;

                       if (items.length === index + 1) {
                           $ctrl.state.syncInProgress = false;
                       }
                   });
    }

    /**
     * Extends model with updated data from the server
     * @param {Object} response
     */
    function updateModel(response) {
        const item = _.find($ctrl.model.partnerConnections, {connectionId: response.connectionId});
        const itemIndex = _.findIndex($ctrl.model.partnerConnections, {connectionId: response.connectionId});
        let updatedItem = _.assign({}, item, response, {syncError: false});

        if (_.isEmpty(response.outOfSyncFields)) {
            updatedItem = _.omit(updatedItem, ['outOfSyncFields', 'outOfSyncFieldsMap']);
        }

        $ctrl.model.partnerConnections.splice(itemIndex, 1, updatedItem);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded(data) {
        $ctrl.model = data;
        $ctrl.state.dataLoaded = true;

        // Initial call to update all items that might be out of sync with the blueprint
        $ctrl.model.partnerConnections.forEach(getPartnerItem);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [data.card.reference || data.card.contractId, 'current']);
    }
}
