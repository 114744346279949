angular.module('everon.welcome')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.welcome', {
        abstract: true,
        data: {
            title: 'welcome.title'
        },
        resolve: {
            profile: ['auth', auth => auth.profile]
        },
        onEnter: ['$state', 'auth', ($state, auth) => auth.profile.accountSetupComplete ? $state.target('auth.dashboard') : true],
        views: {
            'header@auth': {
                template: '<evcloud-inactive-header />'
            },
            'nav@auth': {
                template: null
            },
            'main@auth': {
                template: '<evcloud-welcome class="overflow-hidden col-12 flex flex-column items-center" profile="::$resolve.profile" />'
            }
        }
    });
}
