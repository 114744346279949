angular.module('everon.account.reimbursement')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.account.reimbursement', {
        url: '/reimbursement',
        data: {
            id: 'account-reimbursement-navigation-link',
            title: 'account.reimbursement.title',
            navigationType: ['context'],
            menuPriority: 2
        },
        resolve: {
            data: ['$q', 'accountFormModel', 'accountService', 'countryService', ($q, accountFormModel, accountService, countryService) => {
                return $q.all({
                    reimbursementFormModel: accountFormModel.reimbursement,
                    reimbursement: accountService.getReimbursementSettings(),
                    countryCurrencies: countryService.getTenantCountryCurrencies(),
                    billingOverview: accountService.getBillingOverview()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.account': {
                component: 'evcloudAccountReimbursement',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
