angular.module('everon.component.json-tree')
       .factory('jsonTreeService', jsonTreeService);

jsonTreeService.$inject = [];

function jsonTreeService() {
    /**
     * If the value is an object, than we get a first key/pair as a 'header'
     * @param {any} key
     * @param {any} value
     * @returns {null|Object}
     */
    function getHead(key, value) {
        if (!_.isString(key) && _.isPlainObject(value)) {
            const headKey = _.keys(value)[0];

            return {
                key: headKey,
                value: value[headKey]
            };
        }

        return null;
    }

    /**
     * Converts value to a string
     * If value is an empty object then we should use JSON.stringify to get a proper string
     * @param {any} value
     * @returns {string}
     */
    function toString(value) {
        if (_.isObject(value) && !_.size(value)) {
            return JSON.stringify(value);
        }

        return `${value}`;
    }

    return {
        /**
         * Maps first level of JSON into objects for further rendering
         * @param {Object} obj
         * @returns {Object}
         */
        parseJson(obj) {
            return _.map(obj, (value, key) => {
                const iterable = _.isObject(value) && _.size(value) > 0;
                const head = getHead(key, value);

                key = _.isString(key) ? key : null;

                return {
                    iterable,
                    isExpanded: true,
                    isFlat: Boolean(head),
                    key: head ? head.key : key,
                    value: head ? toString(head.value) : (iterable ? null : toString(value)),
                    children: iterable ? (head ? _.omit(value, head.key) : value) : null
                };
            });
        }
    };
}
