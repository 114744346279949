async function loadModules() {
    // Vue is injected globally with other libs

    const [
        Vuex,
        i18next,
        I18XhrBackend,
        VueI18Next,
        joltPlugin,
        angularInjector,
        componentsRegister
    ] = await Promise.all([
                         import(/* webpackChunkName: 'libs/vuex' */ 'vuex'),
                         import(/* webpackChunkName: 'libs/i18next' */ 'i18next'),
                         import(/* webpackChunkName: 'libs/i18next-xhr-backend' */ 'i18next-xhr-backend'),
                         import(/* webpackChunkName: 'libs/vue-i18next' */ '@panter/vue-i18next'),
                         import(/* webpackChunkName: 'libs/joltPlugin' */ '@everon/jolt'),
                         import(/* webpackChunkName: 'libs/angularInjector' */ './angular-injector'),
                         import(/* webpackChunkName: 'app/components/index' */ './components/vue')
                     ])
                     .then(modules => modules.map(
                         module => module.default
                     ));

    Vue.use(Vuex);

    i18next.use(I18XhrBackend)
           .init({
               lng: localStorage.getItem('NG_TRANSLATE_LANG_KEY'),
               load: 'currentOnly',
               fallbackLng: 'en-GB',
               bindI18n: 'languageChanged',
               debug: localStorage.getItem('devMode') === 'true',
               backend: {// I18XhrBackend config
                   loadPath: `${window.EVERON.cdnUrl || '.'}/assets/translations/{{lng}}.json`,
                   crossDomain: true
               }
           });

    Vue.use(VueI18Next)
       .use(joltPlugin, {i18next})
       .use(angularInjector);

    componentsRegister(Vue);

    // Export Vue and i18n instance to be used in importing vue-index.js files
    return {
        Vue,
        i18n: new VueI18Next(i18next)
    };
}

export default loadModules();
