angular.module('everon.stations.station-add.station-credentials')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add.station-credentials', {
        data: {
            title: 'generic.title.details',
            order: 0
        },
        resolve: {
            data: ['$q', 'accountService', ($q, accountService) => {
                return $q.all({
                    childAccounts: accountService.getChildAccounts({page: 0, size: 1})
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station-add': {
                template: '<evcloud-station-add-credentials data-data-promise="::$resolve.data" class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
