angular.module('everon.accounts.account.invoices')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.accounts.account.invoices', {
        url: '/invoices?page,size',
        params: {
            page: {
                value: null,
                type: 'page',
                squash: true
            },
            size: {
                value: null,
                type: 'int',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'account-invoices-navigation-link',
            title: 'account.invoices.title',
            navigationType: ['context'],
            menuPriority: 4,
            permissions: ['INVOICE:READ_ALL', 'ACCOUNT:READ_PERSONAL_INFO']
        },
        resolve: {
            data: ['$q', '$transition$', 'accountService', 'invoiceService', ($q, $transition$, accountService, invoiceService) => {
                const {id, page, size} = $transition$.params('to');

                return $q.all({
                    invoices: invoiceService.getAccountInvoices(id, {page, size}),
                    billingOverview: accountService.getBillingOverview(id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.accounts.account': {
                template: `<evcloud-account-invoices data-data-promise="::$resolve.data"
                                                     class="flex-auto flex flex-column"/>`
            }
        }
    });
}
