angular.module('everon.account')
       .value('accountFormModel', {
           billingAddress: [
               {
                   name: 'streetName',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   required: true
               },
               {
                   name: 'house',
                   type: 'text',
                   helpText: 'generic.helpText.house',
                   required: true
               },
               {
                   name: 'postcode',
                   type: 'text',
                   required: true
               },
               {
                   name: 'city',
                   type: 'text',
                   required: true
               },
               {
                   name: 'country',
                   accountType: 'universal',
                   type: 'select',
                   cssClass: 'sm-col-6',
                   required: true
               }
           ],
           billingContact: [
               {
                   name: 'name',
                   type: 'text',
                   cssClass: 'sm-col-8',
                   required: true
               },
               {
                   name: 'email',
                   type: 'email',
                   cssClass: 'sm-col-8',
                   required: true
               }
           ],
           invoicingSettings: [
               {
                   name: 'invoiceLanguage',
                   accountType: 'universal',
                   type: 'select',
                   cssClass: 'sm-col-6',
                   required: true
               }
           ],
           businessDetails: [
               {
                   name: 'companyName',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   required: true
               },
               {
                   name: 'vatNumber',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   regex: /^[\d|a-zA-Z]*$/,
                   minlength: 2,
                   maxlength: 15,
                   required: true
               }
           ],
           reimbursement: [
               {
                   name: 'accountName',
                   type: 'text',
                   cssClass: 'sm-col-11',
                   required: true
               },
               {
                   name: 'iban',
                   type: 'text',
                   cssClass: 'sm-col-11',
                   maxlength: 34,
                   asyncValidator: 'asyncIbanValidatorConfig',
                   required: true
               },
               {
                   name: 'bic',
                   type: 'text',
                   cssClass: 'sm-col-8',
                   maxlength: 11,
                   asyncValidator: 'asyncBicValidatorConfig',
                   required: true
               },
               {
                   name: 'rate',
                   type: 'number',
                   cssClass: 'sm-col-8',
                   required: false,
                   regex: /^(\d{1,8})([,|.]\d{1,2})?$/,
                   min: 0.01,
                   max: 99999999.99,
                   step: 0.01,
                   helpText: 'account.reimbursement.form.helpText.rate'
               }
           ],
           advenir: [
               {
                   name: 'enabled',
                   type: 'checkbox'
               },
               {
                   name: 'id',
                   type: 'text',
                   cssClass: 'col-12',
                   maxlength: '20',
                   required: false
               }
           ]
       });
