angular.module('everon.stations.station.billing-plan')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.billing-plan', {
        url: '/billing-plan',
        data: {
            id: 'station-billing-plan-navigation-link',
            title: 'generic.title.billingPlan',
            navigationType: ['context'],
            menuPriority: 50,
            permissions: ['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']
        },
        resolve: {
            data: ['$q', 'data', 'subscriptionService', ($q, data, subscriptionService) => {
                return $q.all({
                    station: data,
                    subscription: subscriptionService.getStationSubscription(data.id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                component: 'evcloudStationBillingPlan',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
