angular.module('everon.accounts.account.billing-information')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.accounts.account.billing-information', {
        url: '/billing-information',
        data: {
            id: 'account-billing-information-navigation-link',
            title: 'generic.title.billingInformation',
            navigationType: ['context'],
            menuPriority: 2,
            permissions: ['ACCOUNT:UPDATE_ALL', 'ACCOUNT:READ_PERSONAL_INFO']
        },
        resolve: {
            data: ['$q', '$transition$', 'accountOwner', 'accountFormModel', 'accountService', 'countryService', 'languageService',
                ($q, $transition$, accountOwner, accountFormModel, accountService, countryService, languageService) => {
                    const {id} = $transition$.params('to');

                    return $q.all({
                        accountOwner,
                        accountFormModel,
                        accountSetupComplete: accountService.getBillingInfoStatus(id),
                        billing: accountService.getBillingInfo(id),
                        countries: countryService.getTenantCountries(),
                        languages: languageService.getTenantLanguages()
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.accounts.account': {
                component: 'evcloudAccountBillingInformation',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
