import {colorService} from '@everon/jolt/dist/services';

angular.module('everon')
       .factory('brandingService', brandingService);

brandingService.$inject = ['$http', '$q', '$window', 'localStore', 'config', 'CONSTANTS', 'brandColorModel'];

function brandingService($http, $q, $window, localStore, config, CONSTANTS, brandColorModel) {
    const colorScheme = [
        {
            name: 'color1',
            value: '#101D83'
        }
    ];

    const schemeExtensions = [
        {
            cssName: 'primary',
            description: 'branding.colors.helpText.color1'
        }
    ];

    /**
     * Extends color scheme with descriptions
     * @param {Array} scheme
     * @returns {Array}
     */
    function getExtendedScheme(scheme) {
        return _.merge([], scheme, schemeExtensions);
    }

    /**
     * Prepends URL with CDN URL
     * @param {string} type
     * @returns {string}
     */
    function resolveUrlPerEnvironment(type) {
        return `${config.cdnUrl}/${CONSTANTS.PATHS.DEFAULT_BRANDING_IMAGES[type.toUpperCase()]}`;
    }

    /**
     * Returns branding data from local storage
     * @returns {Object}
     */
    function getStoredBrandingData() {
        return localStore.get('BRANDING') || {};
    }

    /**
     * Returns transformed branding data
     * @param {Object} data
     * @returns {Object}
     */
    function transformData(data) {
        return _(data).pick('scheme')
                      .assign({images: data.branding.images})
                      .value();
    }

    return {
        /**
         * Returns default Everon color scheme
         * @returns {Array}
         */
        getDefaultScheme() {
            return getExtendedScheme(colorScheme);
        },

        /**
         * Removes unnecessary properties from payload
         * @param {Array} data
         * @returns {Array}
         */
        createSchemePayload(data) {
            return data.map(_.partialRight(_.omit, ['description', 'cssName']));
        },

        /**
         * Saves custom scheme to the server
         * @param {Array} scheme
         * @returns {Promise}
         */
        saveCustomScheme(scheme) {
            return $http.put('/api/platform/branding/colors', this.createSchemePayload(scheme))
                        .then(this.syncLocalScheme);
        },

        /**
         * Gets custom scheme from the server
         * @returns {Promise}
         */
        getCustomScheme() {
            const customScheme = _.get(localStore.get('BRANDING'), 'colors', null);

            return $q.resolve(getExtendedScheme(customScheme));
        },

        /**
         * Generates shades for colors array and updates css variables with it
         * @param {Array} data A list of CSS scheme values
         */
        updateBrandCss(data) {
            _.forEach(data, color => this.updateBrandColorShades(color));
        },

        /**
         * Generates shades for one color and updates css variables with it
         * @param {Object} color An item of CSS scheme values
         */
        updateBrandColorShades({value, cssName}) {
            // Don't add variables for colors that we don't know about
            if (!cssName) {
                return;
            }

            // Updates jolt brand colors config
            colorService.setColorStyles({value}, colorService.getBrandColorModel(), $window);

            // Updates everon brand colors config
            colorService.setColorStyles({value, cssName}, brandColorModel, $window);
        },

        /**
         * Returns branding related data
         * @returns {Promise}
         */
        getBrandingData() {
            return $q.all({
                         branding: $http.get('/api/platform/branding'),
                         scheme: this.getCustomScheme()
                     })
                     .then(transformData);
        },

        /**
         * Deletes brand logo. Default Everon logo will be used instead
         * @returns {Promise}
         */
        deleteLogo() {
            return $http.delete('/api/platform/branding/images/logo');
        },

        /**
         * Deletes brand image. Default Everon image will be used instead
         * @returns {Promise}
         */
        deleteBrandImage() {
            return $http.delete('/api/platform/branding/images/brand');
        },

        /**
         * Deletes icon. Default Everon icon will be used instead
         * @returns {Promise}
         */
        deleteIcon() {
            return $http.delete('/api/platform/branding/images/icon');
        },

        /**
         * Returns default branding image, one of logo, wallpaper or favicon. The image url always points to a GCS bucket
         * @param {string} type
         * @returns {Object}
         */
        getDefaultImage(type) {
            return resolveUrlPerEnvironment(type);
        },

        /**
         * Resolves correct branding image. Stored image is displayed if it is found in local storage, otherwise falls back to default image
         * @param {string} type
         * @returns {Object}
         */
        resolveImage(type) {
            const storedImage = _.get(getStoredBrandingData(), ['images', type].join('.'), null);

            return storedImage || this.getDefaultImage(type);
        },

        /**
         * Resolves correct branding colors. Stored colors if it is found in local storage, otherwise falls back to default ones
         * @param {string} color
         * @returns {Object}
         */
        resolveColor(color) {
            const storedColor = _.get(_.find(getStoredBrandingData().colors, {name: color}), 'value', null);

            return storedColor || _.get(_.find(this.getDefaultScheme(), {name: color}), 'value', null);
        },

        /**
         * Syncs local storage data with updated image
         * @param {string} type
         * @param {string} imageUrl
         * @returns {Object}
         */
        syncLocalImage(type, imageUrl) {
            const storedBrandingData = getStoredBrandingData();

            storedBrandingData.images[type] = imageUrl || null;
            localStore.set('BRANDING', storedBrandingData);

            return storedBrandingData;
        },

        /**
         * Syncs local storage data with updated color scheme
         * @param {Array} scheme
         * @returns {Object}
         */
        syncLocalScheme(scheme) {
            const storedBrandingData = getStoredBrandingData();

            storedBrandingData.colors = scheme || null;
            localStore.set('BRANDING', storedBrandingData);

            return storedBrandingData;
        }
    };
}
