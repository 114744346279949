angular.module('everon.component.action-button')
       .controller('ActionButtonController', ActionButtonController);

ActionButtonController.$inject = [];

function ActionButtonController() {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            open: false
        };
    };

    $ctrl.toggleState = state => {
        $ctrl.state.open = _.isUndefined(state) ? !$ctrl.state.open : state;
    };
}
