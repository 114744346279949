import {toCamelCase} from './../utils';

const CONNECTOR_STATUS = 'connectorStatus';
const CONNECTOR_TYPE = 'connectorType';

export default function connectorActions($ng) {
    const stationService = $ng('stationService');
    const stateHelper = $ng('stateHelper');
    const connectorStatusColors = $ng('CONSTANTS').CPO_DASHBOARD.CONNECTOR_STATUS;

    function buildConnectorsStatusDataset(connectorStatusData) {
        return Object.keys(connectorStatusData).map(key => {
            return {
                label: key,
                value: connectorStatusData[key],
                class: key,
                color: connectorStatusColors[key]
            };
        });
    }

    function buildConnectorTypesDataset(connectorsStandardData) {
        if (connectorsStandardData instanceof Object) {
            const entries = Object.entries(connectorsStandardData);

            return entries.map(entry => {
                const [label, value] = entry;

                return {
                    label: toCamelCase(label),
                    value
                };
            });
        }

        return [];
    }

    return {
        setupConnectorActions({dispatch}) {
            dispatch('registerParamChangeListener', {
                action: 'fetchConnectorsData',
                filter: ['account'],
                immediate: true
            });
        },
        fetchConnectorsData({commit}) {
            const {account} = stateHelper.getStateParams(['account']);
            const accountId = account?.value;

            commit('update', ['connectorsData', {
                statuses: [],
                types: [],
                isConnectorStatusesLoading: true,
                isConnectorTypesLoading: true
            }]);

            stationService.getStationsConnectorsData(CONNECTOR_STATUS, accountId)
                          .then(connectorsStatus => {
                              commit('setConnectorsData', {statuses: buildConnectorsStatusDataset(connectorsStatus), isConnectorStatusesLoading: false});
                          })
                          .catch(() => {
                              commit('setConnectorsData', {isConnectorStatusesLoading: false});
                          });

            stationService.getStationsConnectorsData(CONNECTOR_TYPE, accountId)
                          .then(connectorsTypes => {
                              commit('setConnectorsData', {types: buildConnectorTypesDataset(connectorsTypes), isConnectorTypesLoading: false});
                          })
                          .catch(() => {
                              commit('setConnectorsData', {isConnectorTypesLoading: false});
                          });
        }
    };
}
