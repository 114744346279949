angular.module('everon.stations.station')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station', {
        url: '/:stationId',
        redirectTo: 'auth.stations.station.overview',
        data: {
            title: 'generic.entity.station'
        },
        resolve: {
            data: ['$transition$', 'stationService', ($transition$, stationService) => {
                return stationService.getStation($transition$.params('to').stationId);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-station data-data-promise="::$resolve.data" class="flex flex-auto" />'
            },
            'contextNav@auth.stations.station': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.stations.station\'}"  />'
            }
        }
    });
}
