angular.module('everon.billing-plans.products.product.change-history')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.products.product.change-history', {
        url: '/change-history',
        data: {
            id: 'product-change-history-navigation-link',
            title: 'generic.title.changeHistory',
            permissions: ['BILLING_PLAN:UPDATE'],
            navigationType: ['context'],
            menuPriority: 3
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/products/product/change-history' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.billing-plans.products.product.change-history');
        },
        resolve: {
            data: ['$q', '$transition$', 'subscriptionService', ($q, $transition$, subscriptionService) => {
                    const {id, type} = $transition$.params('to');

                    return $q.all({
                        product: subscriptionService.getProduct(id, type)
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.billing-plans.products.product': {
                component: 'evcloudProductChangeHistory',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
