angular.module('everon.users')
       .factory('userService', userService);

userService.$inject = ['$http', 'searchMixin', 'featureToggleService'];

function userService($http, searchMixin, featureToggleService) {
    const userRolesOrder = [
        'accountOwner',
        'accountAdmin',
        'accountStationAdmin',
        'tenantAdmin',
        'billingPlanAdmin',
        'roamingAdmin',
        'branding',
        'customerSupport',
        'technicalSupport',
        'supportAgent',
        'subAccountOwner',
        'subAccountAdmin',
        'driver'
    ];

    const userManagementFeature = featureToggleService('@@oktaUserManagement');

    return _.assign({}, searchMixin, {
        /**
         * Returns a list of registered users
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getUsers(params) {
            const defaultParams = {
                page: 0,
                size: 30,
                sort: 'name,asc'
            };

            return $http.get('/api/platform/:type', {params: _.defaults(params, defaultParams)});
        },

        /**
         * Returns user profile by id
         * @param {string} id
         * @returns {Promise}
         */
        getUser(id) {
            return $http.get('/api/platform/users/:id', {params: {id}});
        },

        /**
         * Updates user profile
         * @param {string} id
         * @param {Object} payload
         * @returns {Promise}
         */
        updateUser(id, payload) {
            return $http.patch('/api/platform/users/:id', payload, {params: {id}});
        },

        /**
         * Invites a user. User status is `inactive` until the users completes the registration
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        inviteUser(requestData) {
            return userManagementFeature.exec({
                onDisabled: () => $http.post('/api/platform/users', requestData),
                onEnabled: () => {
                    const {roles, email, accountId, isBusiness: business, reimbursementRate} = requestData;

                    $http.post('/api/users/invite', {
                        accountId,
                        business,
                        profile: {
                            email
                        },
                        roles,
                        reimbursementRate
                    });
                }
            });
        },

        /**
         * Returns a list of available roles for a given user type for currently logged in user
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getAvailableRoles(params) {
            return userManagementFeature.exec({
                onDisabled: () => $http.get('/api/platform/:type/roles', {params}),
                onEnabled: () => {
                    let {type} = params;

                    type = type.substring(0, type.length - 1)
                               .toUpperCase()
                               .replace('-', '_');

                    return $http.get('/api/users/roles?invitationType=:type', {params: {type}})
                                .then(result => result.roles);
                }
            });
        },

        /**
         * Return a list of available roles for a given user type and given user
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getAvailableUserRoles(params) {
            return $http.get('/api/platform/:type/:id/roles', {params});
        },

        /**
         * Return a list of available roles for a given user type and given user
         * @param {Object} params
         * @returns {Promise.<Array>}
         */
        getSupportUsers(params) {
            const defaultParams = {
                query: ''
            };

            return $http.get('/api/platform/system-users/support', {params: _.defaults(params, defaultParams)});
        },

        /**
         * Applies custom sorting for the list of roles
         * @param {Array} roles
         * @returns {Array}
         */
        sortUserRoles(roles) {
            if (!roles || roles.length === 0) {
                return [];
            }

            const sortedRoles = userRolesOrder.filter(role => roles.includes(role));
            const otherRoles = roles.filter(role => !sortedRoles.includes(role));

            return sortedRoles.concat(otherRoles);
        }
    });
}
