angular.module('everon.component.datetime-picker')
       .controller('DateTimePickerController', DateTimePickerController);

DateTimePickerController.$inject = ['$timeout', 'dateTimePickerService', 'utils'];

function DateTimePickerController($timeout, dateTimePickerService, utils) {
    const $ctrl = this;
    let model = null;
    let ngModelCtrl = null;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            css: null,
            isTemplateCompiled: false,
            isVisible: false,
            showTime: _.has($ctrl.config, 'showTime'),
            hasFirstDate: _.has($ctrl.config, 'firstDate'),
            hasLastDate: _.has($ctrl.config, 'lastDate')
        };

        $ctrl.data = null;
        $ctrl.config = $ctrl.config || {};
        model = _.isDate($ctrl.ngModel) ? $ctrl.ngModel : ($ctrl.state.hasFirstDate ? cloneDate($ctrl.config.firstDate) : new Date());
        $ctrl.setMonth(0);

        // We assign a directive private method to the config object to make it available in the parent ctrl
        $ctrl.config.setMonth = $ctrl.setMonth;
    };

    $ctrl.setNgModelController = ngModelController => {
        ngModelCtrl = ngModelController;
    };

    $ctrl.setMonth = (index = 0) => {
        const month = model.getMonth() + index;
        const data = dateTimePickerService.getData(model.getFullYear(), month);

        $ctrl.data = _.assign({}, {
            hour: model.getHours(),
            min: model.getMinutes(),
            sec: model.getSeconds()
        }, data);

        model.setMonth(month);
    };

    $ctrl.getCurrentDay = () => {
        return ($ctrl.ngModel || model).getDate();
    };

    $ctrl.isCurrentDate = (day, month, year) => {
        const currentModelDate = $ctrl.ngModel || model;

        return new Date(year, month, day).toDateString() === currentModelDate.toDateString();
    };

    $ctrl.isBeforeFirstDate = (day, month, year) => {
        return utils.getMoment([year, month, day]).isBefore(utils.getMoment($ctrl.config.firstDate));
    };

    $ctrl.isAfterLastDate = (day, month, year) => {
        return utils.getMoment([year, month, day]).isAfter(utils.getMoment($ctrl.config.lastDate));
    };

    $ctrl.isOutOfDatesRange = (...params) => {
        const {hasFirstDate, hasLastDate} = $ctrl.state;

        return (hasFirstDate && $ctrl.isBeforeFirstDate(...params)) || (hasLastDate && $ctrl.isAfterLastDate(...params));
    };

    $ctrl.setCss = css => {
        $ctrl.state.css = css;
    };

    $ctrl.setDate = (day = $ctrl.getCurrentDay()) => {
        $ctrl.ngModel = new Date($ctrl.data.monthView.year, $ctrl.data.monthView.month, day, $ctrl.data.hour, $ctrl.data.min, $ctrl.data.sec);
        ngModelCtrl.$setDirty();
        $ctrl.ngChange && $timeout($ctrl.ngChange, 0, false);
        $ctrl.state.isVisible = $ctrl.state.showTime;
    };

    $ctrl.clear = () => {
        $ctrl.ngModel = null;
        ngModelCtrl.$setDirty();
        $ctrl.ngChange && $timeout($ctrl.ngChange, 0, false);
    };

    /**
     * Clones date instance
     * @param {Date} date
     * @returns {Date}
     */
    function cloneDate(date) {
        return new Date(date.getTime());
    }
}
