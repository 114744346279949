angular.module('everon.stations.station.firmware')
       .controller('FirmwareController', FirmwareController);

FirmwareController.$inject = ['stationService', 'mediatorService', 'CONSTANTS'];

function FirmwareController(stationService, mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            updateSubmitted: false,
            isUnknownFirmware: false,
            isKnownFirmware: false,
            isKnownVersion: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.update = () => {
        $ctrl.state.updateSubmitted = true;

        stationService.updateFirmware($ctrl.data.station.id, $ctrl.selectedFirmware.name)
                      .then(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              messageKey: 'generic.notification.requestAccepted'
                          });
                      })
                      .catch(response => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: response.status === 400 ?
                                  'generic.notification.stationUnavailable' :
                                  (response.status === 502) ? 'generic.notification.requestRejected' : CONSTANTS.ERROR_CODES[response.status],
                              isPersistent: true
                          });
                      })
                      .finally(() => {
                          $ctrl.state.updateSubmitted = false;
                      });
    };

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.data = data;
        $ctrl.currentFirmware = $ctrl.data.chargePoint.firmware;
        $ctrl.state.isUnknownFirmware = !$ctrl.currentFirmware;
        $ctrl.state.isKnownFirmware = _.has($ctrl.currentFirmware, 'id');
        $ctrl.state.isKnownVersion = !$ctrl.state.isKnownFirmware && _.has($ctrl.currentFirmware, 'version');
        $ctrl.selectedFirmware = $ctrl.data.availableFirmwares[0];
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [data.station.reference || data.station.identityCode, 'current']);
    }
}
