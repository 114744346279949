angular.module('everon.stations.station-import-details-form.basic-info')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-import-details.edit.basic-info', {
        data: {
            title: 'generic.entity.station',
            order: 0
        },
        views: {
            'content@auth.stations.station-import-details.edit': {
                template: '<evcloud-station-import-details-form-basic-info class="flex flex-column col-12 max-width-3 overflow-hidden"/>'
            }
        }
    });
}

