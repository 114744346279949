angular.module('everon.stations.station.terminal')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.terminal', {
        url: '/terminal',
        data: {
            id: 'station-terminal-navigation-link',
            title: 'stations.station.terminal.title',
            navigationType: ['context'],
            menuPriority: 120,
            permissions: ['STATION:CONTROL_TERMINAL']
        },
        resolve: {
            data: ['$q', 'data', 'stationService', ($q, data, stationService) => {
                return $q.all({
                    actions: stationService.getOcppActions(data.id),
                    station: data
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                component: 'evcloudStationTerminal',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
