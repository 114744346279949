angular.module('everon.users.system-users.groups')
       .controller('UserGroupsController', UserGroupsController);

UserGroupsController.$inject = ['$state', '$q', 'mediatorService', 'CONSTANTS', 'userGroupsService', 'sessionService', 'stateHelper'];

function UserGroupsController($state, $q, mediatorService, CONSTANTS, userGroupsService, sessionService, stateHelper) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        const hasTenantLevelRole = Boolean(_.intersection(sessionService.getUserProfile().roles, ['tenantAdmin', 'customerSupport']).length);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({
            query: setSearchQuery,
            sort: setSortingParams
        }, getData);

        $ctrl.state = {
            dataLoaded: false,
            pageLoadInProgress: false
        };

        $ctrl.pagination = null;
        $ctrl.searchQuery = $state.params.query;

        $ctrl.columns = [
            {
                property: 'name',
                translationKey: 'generic.label.groupName',
                sortable: true,
                cssClass: 'col-4'
            },
            {
                property: 'manager',
                translationKey: 'generic.roles.manager',
                sortable: true,
                cssClass: 'col-4'
            },
            {
                property: 'members',
                translationKey: 'generic.label.members',
                sortable: false,
                cssClass: 'col-4'
            }
        ];

        $ctrl.sortingParams = stateHelper.getSortingParams();

        $ctrl.noSearchResultsConfig = {
            icon: 'empty-state-search-results',
            heading: 'generic.noResults.heading',
            paragraph: 'generic.noSearchResults.paragraph',
            template: 'partials/search-help.html'
        };

        $ctrl.dataPromise.then(onDataLoaded);

        $ctrl.actions = [
            // Should be only available to TA and Support
            {
                name: 'generic.noResults.heading',
                state: 'auth.users.system-users.groups.user-group-create',
                enabled: hasTenantLevelRole
            }
        ];

        $ctrl.actions = _($ctrl.actions).filter('enabled')
                                        .map(_.partialRight(_.omit, 'enabled'))
                                        .value();
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Updates state with pagination params
     * @param {number} [page]
     */
    $ctrl.goToPage = (page = 0) => {
        const {size} = $ctrl.pagination;

        stateHelper.changeParams({size, page});
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    function setSearchQuery(query) {
        $ctrl.searchQuery = query;
    }

    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
    }

    /**
     * Requests fresh data
     */
    function getData() {
        const params = _.pick($state.params, ['sort', 'query', 'page', 'size']);

        $ctrl.state.pageLoadInProgress = true;

        userGroupsService.getGroups(params)
                   .then(updateData)
                   .catch(onRejected)
                   .finally(() => {
                       $ctrl.state.pageLoadInProgress = false;
                   });
    }

    /**
     * Updates state with search query and zero-pagination
     * @param {string} query
     */
    function onSearch(query) {
        stateHelper.changeParams({query, page: 0});
    }

    /**
     * Updates data model on every change
     * @param {Object} data
     */
    function updateData(data) {
        $ctrl.pagination = userGroupsService.getPagination(data);
        $ctrl.groups = data.content;
    }

    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        updateData(data);
        $ctrl.searchOptions = {
            id: 'user-groups',
            onSearch,
            stateParam: 'query',
            initialQuery: $ctrl.searchQuery,
            disabled: !($ctrl.groups.length || $ctrl.searchQuery)
        };

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['grandparent', 'parent']);
    }
}
