angular.module('everon.billing-plans.products.product-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.products.product-add', {
        url: '/new/:type',
        redirectTo: 'auth.billing-plans.products.product-add.product-basic-info',
        data: {
            title: 'generic.label.product',
            permissions: ['BILLING_PLAN:CREATE']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/products/product-add' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, [
                                 'everon.billing-plans.products.product-add',
                                 'everon.billing-plans.products.product-add.product-basic-info',
                                 'everon.billing-plans.products.product-add.product-charging',
                                 'everon.billing-plans.products.product-add.product-overview'
                             ]);
        },
        resolve: {
            data: ['$q', '$transition$', 'tenantCountryService', 'countryService', ($q, $transition$, tenantCountryService, countryService) => {
                return $q.all({
                    product: {
                        type: $transition$.params('to').type
                    },
                    countries: tenantCountryService.getCountriesOnly(),
                    countryCurrencies: countryService.getTenantCountryCurrencies()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-product-add data-data-promise="::$resolve.data" class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}
