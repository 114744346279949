angular.module('everon.forbidden')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.forbidden', {
        data: {
            title: 'generic.error.forbidden'
        },
        views: {
            'main@auth': {
                templateUrl: 'modules/forbidden/forbidden.html'
            }
        }
    });
}
