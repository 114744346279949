angular.module('everon.component.upload')
       .factory('uploadService', uploadService);

uploadService.$inject = ['$q', 'config', 'CONSTANTS', '$window'];

function uploadService($q, config, CONSTANTS, $window) {
    let xhr;

    /**
     * Appends file to FormData
     * @param {Object.<FormData>} formData
     * @param {Object.<File|Blob>} file
     */
    function appendFile(formData, file) {
        formData.append('file', file, file.name);
    }

    /**
     * Converts response to JS object
     * @param {string?} response
     * @returns {Object}
     */
    function toObject(response) {
        return response ? JSON.parse(response) : {};
    }

    /**
     * Uploads the file to the back-end
     * @param {Object.<FormData>} formData
     * @param {Object} options
     * @param {Object} deferred
     */
    function uploadFile(formData, options, deferred) {
        xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    deferred.resolve(toObject(xhr.response));
                }

                if (xhr.status >= 400) {
                    deferred.reject(_(xhr).pick(['status', 'statusText'])
                                          .assign({response: toObject(xhr.response)})
                                          .value());
                }
            }
        };

        xhr.upload.onprogress = ({lengthComputable, total, loaded}) => {
            if (lengthComputable && _.isFunction(options.progressHandler)) {
                const percentage = Math.round((loaded * 100) / total);

                options.progressHandler(percentage);
            }
        };

        xhr.onerror = _.partial(reject, deferred);
        xhr.onabort = _.partial(reject, deferred);
        xhr.upload.onerror = _.partial(reject, deferred);
        xhr.upload.onabort = _.partial(reject, deferred);
        xhr.ontimeout = _.partial(reject, deferred);

        xhr.open('POST', config.apiUrl + options.url);
        xhr.withCredentials = true;
        xhr.setRequestHeader('tenantId', $window.EVERON.tenantId);
        xhr.timeout = CONSTANTS.HTTP_REQUEST_TIMEOUT; // 1 minute; it has to come after xhr.open due to the way IE has implemented it
        xhr.send(formData);
    }

    /**
     * Rejects the upload promise
     * @param {Object} deferred
     * @param {Event} event
     */
    function reject(deferred, event) {
        deferred.reject(event);
    }

    return {
        /**
         * Creates FormData object and adds files to it
         * @param {Array.<Blob|File>} files
         * @returns {Promise.<Object>}
         */
        createFormData(files) {
            const formData = new FormData();

            _.toArray(files).forEach(_.partial(appendFile, formData));

            return $q.resolve(formData);
        },

        /**
         * Uploads the files
         * @param {Array.<Blob|File>} files
         * @param {Object} options
         * @returns {Promise}
         */
        upload(files, options) {
            const deferred = $q.defer();

            this.createFormData(files)
                .then(_.partialRight(uploadFile, options, deferred));

            return deferred.promise;
        },

        /**
         * Cancels XHR request
         * @returns {Promise}
         */
        cancel() {
            if (xhr) {
                xhr.abort();
            }

            return $q.resolve();
        }
    };
}
