export default {
    statusFilterChanged(state, statusFilter) {
        state.selectedStationsStatusFilter = statusFilter;
    },

    stationsLoadErrorReceived(state, error) {
        state.stationsLoadError = error;
    },

    fetchingStations(state, inProgress) {
        state.fetchingStations = inProgress;
    }
};
