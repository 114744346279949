angular.module('everon.tenant-settings.countries')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.countries', {
        url: '/countries?sort',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'countries-navigation-link',
            title: 'generic.entity.countries',
            menuPriority: 3,
            navigationType: ['secondary']
        },
        resolve: {
            data: ['$q', 'tenantCountryService', 'tenantCompanyService', ($q, tenantCountryService, tenantCompanyService) => {
                return $q.all({
                    countries: tenantCountryService.getCountries(),
                    hasCompanies: tenantCompanyService.hasCompanies(),
                    hasAvailableCountries: tenantCountryService.hasAvailableCountries()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.tenant-settings': {
                component: 'evcloudCountries',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
