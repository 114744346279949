angular.module('everon.users.system-users.groups.user-group-create.user-group-details')
       .controller('UserGroupCreateDetailsController', UserGroupCreateDetailsController);

UserGroupCreateDetailsController.$inject = ['userService', '$q'];

function UserGroupCreateDetailsController(userService, $q) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.members = $ctrl.userGroupCreateCtrl.payload.members;
        $ctrl.manager = $ctrl.userGroupCreateCtrl.payload.manager;

        $ctrl.searchUserOptions = {
            id: 'support-users',
            initialValue: '',
            asyncSearch: true,
            emptyOnSelect: true,
            getDataAsync: getData,
            onSelect: onUserSelected
        };

        $ctrl.assignManagerValue = $ctrl.manager && $ctrl.manager.name ? $ctrl.manager.name : '';

        $ctrl.assignManagerOptions = {
            id: 'manager',
            initialValue: $ctrl.assignManagerValue,
            asyncSearch: true,
            onSelect: onManagerSelected,
            getDataAsync: selectedMembers
        };
    };

    $ctrl.next = () => {
        $ctrl.userGroupCreateCtrl.payload.members = $ctrl.members;
        $ctrl.userGroupCreateCtrl.payload.manager = $ctrl.manager;

        $ctrl.userGroupCreateCtrl.next({users: $ctrl.members.map(member => member.id), manager: $ctrl.manager ? $ctrl.manager.id : null});
    };

    $ctrl.removeMember = id => {
        $ctrl.members = $ctrl.members.filter(member => member.id !== id);

        if ($ctrl.manager && $ctrl.manager.id === id) {
            $ctrl.assignManagerValue = null;
            $ctrl.manager = null;
        }
    };

    /**
     * Extends controller's model with the selected user
     * @param {Object} User
     */
    function onUserSelected({id, primary}) {
        if (primary !== undefined && !$ctrl.members.some(member => member.id === id)) {
            $ctrl.members.push({name: primary, id: id});
        }
    }

    /**
     * Assigns a manager to the group's model
     * @param {Object} User
     */
    function onManagerSelected({id, primary}) {
        $ctrl.manager = {name: primary, id: id};
    }

    function getData(query) {
        return userService.getSupportUsers({query})
                          .then(data => data.content
                                            .filter(({id}) => !$ctrl.members.some(existingMember => existingMember.id === id)) // don't show users who are already in the members list
                                            .map(({id, fullName}) => ({id, primary: fullName}))
                          );
    }

    function selectedMembers() {
        return $q.resolve($ctrl.members.map(({id, name}) => ({id, primary: name})));
    }
}
