angular.module('everon.users')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users', {
        url: '/users',
        redirectTo: 'auth.users.system-users',
        data: {
            id: 'users-navigation-link',
            title: 'generic.entity.users',
            icon: 'icon-users',
            navigationType: ['primary'],
            menuPriority: 4,
            permissions: ['SYSTEM_USER:READ_ALL']
        },
        views: {
            'navSecondary@auth': {
                template: '<evcloud-nav-list filter="{navigationType: \'secondary\', parent: \'auth.users\'}" />'
            }
        }
    });
}
