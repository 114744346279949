angular.module('everon.map')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.map', {
        url: '/map',
        data: {
            id: 'map-navigation-link',
            title: 'map.title',
            navigationType: ['primary'],
            icon: 'icon-map',
            menuPriority: 6,
            permissions: ['STATION:READ']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/map' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, [
                                 'everon.map',
                                 'everon.map.map-filter',
                                 'everon.map.map-stations'
                             ]);
        },
        resolve: {
            data: ['mapService', mapService => mapService.loadMapApi()]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: `<evcloud-map data-data-promise="::$resolve.data"
                                        class="flex col-12 fit-y overflow-hidden"/>`
            }
        }
    });
}
