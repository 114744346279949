angular.module('everon.billing-plans.plans.plan-add.plan-pricing')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans.plan-add.plan-pricing', {
        data: {
            title: 'generic.title.pricing',
            order: 2
        },
        views: {
            'content@auth.billing-plans.plans.plan-add': {
                component: 'evcloudPlanAddPricing'
            }
        }
    });
}
