/**
 * Adds copy to clipboard support to web elements.
 *
 * @example
 * <evcloud-clipboard container-id="connection-token"></evcloud-clipboard>
 */
angular.module('everon.component.clipboard')
       .component('evcloudClipboard', {
           templateUrl: 'components/clipboard/clipboard.html',
           controller: 'ClipboardController',
           bindings: {
               containerId: '@'
           }
       });
