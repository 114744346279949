angular.module('everon.stations.station.charging-features')
       .controller('StationChargingFeaturesController', StationChargingFeaturesController);

StationChargingFeaturesController.$inject = ['mediatorService', 'CONSTANTS', 'stationService', 'permissionService', 'sessionService'];

function StationChargingFeaturesController(mediatorService, CONSTANTS, stationService, permissionService, sessionService) {
    const $ctrl = this;
    const formName = 'stationChargingFeaturesForm';
    const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            hasChanged: false,
            saveInProgress: false,
            canManageReimbursementSettings: false,
            isPublicChargingEnabled: false,
            isRoamingExpanded: false,
            isRoamingTariffSet: false
        };

        $ctrl.regex = {
            tariff: CONSTANTS.REGEX.TARIFF
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.onChange = () => {
        $ctrl.state.isRoamingTariffSet = countRoamingTariffsSet() > 0;
        setChanged(true);
    };

    $ctrl.onPublicChargingEnabledChange = () => {
        if ($ctrl.model.enabled) {
            restoreTariffs();
        } else {
            resetTariffs();
        }

        $ctrl.state.isRoamingTariffSet = countRoamingTariffsSet() > 0;
        $ctrl.toggleTariffs();
        setChanged(true);
    };

    $ctrl.onHiddenStationChange = () => {
        setChanged(true);
    };

    $ctrl.toggleTariffs = () => {
        $ctrl.state.isRoamingExpanded = $ctrl.model.enabled ? !$ctrl.state.isRoamingExpanded : $ctrl.model.enabled;
    };

    /**
     * Saves station public charging tariffs
     */
    $ctrl.save = () => {
        $ctrl.state.saveInProgress = true;

        updateStationTariffs().then(response => {
                                  mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                      type: 'success'
                                  });
                                  $ctrl.state.isPublicChargingEnabled = $ctrl.model.enabled;
                                  $ctrl.state.isRoamingExpanded = false;
                                  resetPreviousTariffs();
                                  $ctrl.modelCopy = JSON.stringify($ctrl.model);
                                  resetFormState();
                                  mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [response.reference || response.identityCode, 'current']);
                              })
                              .catch(response => {
                                  mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                      type: 'error',
                                      messageKey: CONSTANTS.ERROR_CODES[response.status]
                                  });
                              })
                              .finally(() => {
                                  $ctrl.state.saveInProgress = false;
                              });
    };

    /**
     * Resets station public-charging form and updates the state
     */
    $ctrl.cancel = () => {
        restoreModel();
        resetFormState();
        this.state.isRoamingExpanded = false;
    };

    function updateStationTariffs() {
        const requestData = getTariffsPayload($ctrl.model.tariffs);

        requestData.hidden = $ctrl.model.hidden;

        if ($ctrl.model.enabled) {
            return $ctrl.state.isPublicChargingEnabled ?
                stationService.updateStationTariffs($ctrl.model.id, requestData) :
                stationService.enablePublicCharging($ctrl.model.id, requestData);
        }

        return stationService.disablePublicCharging($ctrl.model.id);
    }

    function getTariffsPayload(tariffs) {
        const payload = {};

        tariffs.forEach(tariff => {
            payload[tariff.name] = tariff.enabled && tariff.publicTariff === null ? 0 : tariff.publicTariff;
        });

        return payload;
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
        setChanged(false);
    }

    /**
     * Reverts changes to profile
     */
    function restoreModel() {
        $ctrl.model = JSON.parse($ctrl.modelCopy);
    }

    function extendModel({station, isPublicChargingEnabled, stationTariffs, reimbursementInfo, subscription: {product, plan}}) {
        const roamingCharacteristic = _.find(product.characteristics, {name: 'roaming'}) || {};

        const tariffs = _(roamingCharacteristic.value)
                          .map(item => _.assign(item, {id: _.kebabCase(item.name), publicTariff: _.get(stationTariffs, item.name, null), previousPublicTariff: null}))
                          .value();

        return {
            id: station.id,
            hidden: station.hidden,
            roamingEnabled: Boolean(roamingCharacteristic.enabled),
            enabled: isPublicChargingEnabled,
            currency: plan.currency,
            tariffs,
            reimbursementInfo
        };
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        const modelChanged = hasModelChanged();

        $ctrl.state.hasChanged = hasChanged && modelChanged;

        if (!modelChanged) {
            $ctrl[formName].$setPristine();
        }
    }

    /**
     * Checks whether model changed
     * @returns {boolean}
     */
    function hasModelChanged() {
        return $ctrl.modelCopy !== JSON.stringify($ctrl.model);
    }

    /**
     * Resets all previous public tariff values
     */
    function resetPreviousTariffs() {
        _.map($ctrl.model.tariffs, 'name').forEach(name => {
            const model = _.find($ctrl.model.tariffs, {name});

            model.previousPublicTariff = null;
        });
    }

    /**
     * Resets tariff values to `null` if public charging is disabled
     */
    function resetTariffs() {
        _.map($ctrl.model.tariffs, 'name').forEach(name => {
            const model = _.find($ctrl.model.tariffs, {name});

            model.previousPublicTariff = model.publicTariff;
            model.publicTariff = null;
        });
    }

    /**
     * Restores tariff values when public charging is enabled
     */
    function restoreTariffs() {
        _.map($ctrl.model.tariffs, 'name').forEach(name => {
            const model = _.find($ctrl.model.tariffs, {name});

            model.publicTariff = model.previousPublicTariff;
            model.previousPublicTariff = null;
        });
    }

    /**
     * Counts tariffs which was set for roaming
     * @returns {number}
     */
    function countRoamingTariffsSet() {
        return _.filter($ctrl.model.tariffs, 'publicTariff').length;
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        const {station, isPublicChargingEnabled, isAccountOwner} = data;

        // The user can manage reimbursement settings if has the 'ACCOUNT:UPDATE' permissions and both user and station are created under the same account
        $ctrl.state.canManageReimbursementSettings = checkPermissions(['ACCOUNT:UPDATE']) && isAccountOwner;
        $ctrl.model = extendModel(data);
        $ctrl.modelCopy = JSON.stringify($ctrl.model);

        $ctrl.state.isPublicChargingEnabled = isPublicChargingEnabled;
        $ctrl.state.dataLoaded = true;
        $ctrl.state.isRoamingTariffSet = countRoamingTariffsSet() > 0;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
