angular.module('everon.tenant-settings.countries.country-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.countries.country-add', {
        url: '/new/',
        data: {
            title: 'tenantSettings.countries.action.add'
        },
        resolve: {
            data: ['$q', 'tenantCountryService', 'tenantCompanyService', ($q, tenantCountryService, tenantCompanyService) => {
                return $q.all({
                    companies: tenantCompanyService.getCompanies(),
                    availableCountries: tenantCountryService.getAvailableCountries()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        onEnter: ['$state', 'data', ($state, data) => {
            return data.then(response => response.companies.length && response.availableCountries.length ?
                true :
                $state.target('auth.tenant-settings.countries', null, {reload: 'auth.tenant-settings.countries'})
            );
        }],
        views: {
            'content@auth.tenant-settings': {
                component: 'evcloudCountryAdd',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
