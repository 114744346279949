/**
 *  Implementation of evcloud-date-time-picker directive
 *
 *  A date picker with inputs for hours and minutes.
 *  The input that triggers the directive will be readonly, the user can only update the value by using the picker
 *
 * @example
 *   Usage:
 *
 *   <input type="datetime-local"
 *          evcloud-date-time-picker
 *          ng-model="$ctrl.date"
 *          name="date-example1"
 *          placeholder="yyyy-MM-ddTHH:mm:ss"
 *          required />
 *
 */

angular.module('everon.component.datetime-picker')
       .directive('evcloudDateTimePicker', dateTimePicker);

dateTimePicker.$inject = ['$window', '$compile', '$templateRequest', '$filter', 'CONSTANTS'];

function dateTimePicker($window, $compile, $templateRequest, $filter, CONSTANTS) {
    /**
     * Checks if the browser supports the input type and touch feature.
     * @param {Object} $window
     * @param {string} type
     * @returns {boolean}
     */
    function hasTouchAndDatetimeLocal($window, type) {
        const el = $window.document.createElement('input');

        el.setAttribute('type', type);

        return el.type === type && 'ontouchstart' in $window.document.documentElement;
    }

    /**
     * link function
     * @param {Object} scope
     * @param {Object} element
     * @param {Object} attrs
     * @param {Object} controllers
     */
    function link(scope, element, attrs, controllers) {
        if (hasTouchAndDatetimeLocal($window, attrs.type)) {
            return;
        }

        const [$ctrl, ngModelCtrl] = controllers;
        const insertElement = element.parent();
        const bodyElement = angular.element($window.document.body);
        let calendarElement = angular.element('<div class="absolute bottom-0"></div>');

        element[0].setAttribute('type', 'text');
        element[0].setAttribute('readonly', '');

        appendClearButton();
        $ctrl.setNgModelController(ngModelCtrl);

        ngModelCtrl.$formatters.unshift(value => $filter('date')(value, $ctrl.config.showTime ? CONSTANTS.DATES.DATETIME_FORMATS.LONG_DATE_TIME : CONSTANTS.DATES.DATETIME_FORMATS.DEFAULT_DATE));

        element.on('focus', event => {
            if (!$ctrl.state.isTemplateCompiled) {
                compileTemplate();
            }

            updatePosition(event.target);
        });

        scope.$on('$destroy', () => {
            calendarElement = null;
            bodyElement.off('click', bodyClickHandler);
        });

        /**
         * If `showClearButton` is provided in config, append the button after the input element
         */
        function appendClearButton() {
            if ($ctrl.config.showClearButton) {
                const clearElement = angular.element('<button class="btn-link" role="clear" translate-attr="{title: \'generic.action.clear\'}" ng-show="$ctrl.ngModel" type="button"' +
                    ' ng-click="$ctrl.clear()">✕</button>');

                element.after($compile(clearElement)(scope.$new()));
            }
        }

        /**
         * Loads datetime picker template and compiles it. The `$ctrl.state.isTemplateCompiled` is set to true so next time compilation can be skipped
         */
        function compileTemplate() {
            const templateUrl = 'components/datetime-picker/datetime-picker.html';

            insertElement.append(calendarElement);
            bodyElement.on('click', bodyClickHandler);

            $templateRequest(templateUrl).then(template => {
                                             const templateElement = angular.element(template);

                                             calendarElement.empty().append(templateElement);
                                             $compile(templateElement)(scope);
                                             $ctrl.state.isTemplateCompiled = true;
                                         })
                                         .catch(() => {
                                             $ctrl.state.isTemplateCompiled = false;
                                         });
        }

        /**
         * Updates datetime picker position based on the input element where directive is declared
         * @param {Element} afterElement
         */
        function updatePosition(afterElement) {
            const afterElementCoords = afterElement.getBoundingClientRect();
            const css = {
                width: `${afterElementCoords.width}px`
            };

            // The datepicker needs some space to render, if it isn't available at the bottom we render above
            if ($window.innerHeight - afterElementCoords.top < 400) {
                _.assign(css, {
                    bottom: `${afterElementCoords.height - 1}px`,
                    top: 'auto'
                });
            }

            $ctrl.state.isVisible = true;
            $ctrl.setCss(css);
        }

        /**
         * Closes currently open datetime picker if a click event fired outside it or on another datetime picker.
         * @param {Event} event
         * @returns {boolean}
         */
        function bodyClickHandler(event) {
            const isDatetimePicker = event.target.hasAttribute('evcloud-date-time-picker');
            const isOtherDatetimePicker = isDatetimePicker && event.target !== element[0];
            const containsDatetimePicker = calendarElement[0].contains(event.target);

            if ($ctrl.state.isVisible && (!isDatetimePicker && !containsDatetimePicker) || isOtherDatetimePicker) {
                scope.$applyAsync(() => {
                    $ctrl.state.isVisible = false;
                });
            }

            return false;
        }
    }

    return {
        restrict: 'A',
        scope: {
            config: '=?evcloudDateTimePicker',
            ngModel: '=',
            ngChange: '&?'
        },
        require: ['evcloudDateTimePicker', 'ngModel'],
        controller: 'DateTimePickerController',
        controllerAs: '$ctrl',
        bindToController: true,
        link
    };
}
