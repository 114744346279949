angular.module('everon.tenant-settings')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings', {
        url: '/tenant-settings',
        redirectTo: 'auth.tenant-settings.languages',
        data: {
            id: 'tenant-settings-navigation-link',
            title: 'generic.title.settings',
            navigationType: ['primary'],
            tenantLevel: true,
            icon: 'icon-settings',
            menuPriority: 10,
            permissions: ['TENANT:UPDATE']
        },
        views: {
            'main@auth': {
                templateUrl: 'modules/tenant-settings/tenant-settings.html'
            },
            'navSecondary@auth': {
                template: '<evcloud-nav-list filter="{navigationType: \'secondary\', parent: \'auth.tenant-settings\'}" />'
            }
        }
    });
}
