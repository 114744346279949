angular.module('everon')
       .factory('transactionMixin', transactionMixin);

transactionMixin.$inject = ['utils', 'moment', '$q', '$window', 'config', '$http', 'fileService'];

function transactionMixin(utils, moment, $q, $window, config, $http, fileService) {
    /**
     * Checks whether 2 moments are within the same day or not
     * @param {string} startedAt
     * @param {string} endedAt
     * @returns {boolean}
     */
    function isMultiDay(startedAt, endedAt) {
        const start = utils.toLocalMoment(startedAt);
        const end = utils.toLocalMoment(endedAt);

        return start.day() !== end.day();
    }

    /**
     * Returns period object containing start time, end time, duration, human readable duration and `isMultiDay` flag indicating whether 2 moments span more
     * than 1 day
     * @param {Object} transaction
     * @returns {Object}
     */
    function getPeriod(transaction) {
        return {
            start: transaction.startedAt,
            end: transaction.endedAt,
            humanisedDuration: utils.humaniseDuration(transaction.duration),
            isMultiDay: isMultiDay(transaction.startedAt, transaction.endedAt)
        };
    }

    /**
     * Returns a string of query params for transactions PDF report download
     * @param {Object} paramsObject
     * @returns {string}
     */
    function getQueryParams(paramsObject) {
        return Object.keys(paramsObject)
                     .filter(key => Boolean(paramsObject[key]))
                     .map(key => `${key}=${paramsObject[key]}`)
                     .join('&');
    }

    /**
     * Returns generated PDF invoice
     * @param {string} transactionsType
     * @param {string} id
     * @param {Object} range
     * @param {number} connectorId
     * @returns {Promise}
     */
    function downloadTransactionsPdf(transactionsType, {id, range, connectorId}) {
        const {startDate, endDate} = utils.localDatesToUTC(range);

        return $http.post('/api/pdf-generation/', {
            url: `/api/billing/transactions/${transactionsType}/${id}/report?${getQueryParams({id, connectorId, startDate, endDate})}`
        }, {
            responseType: 'arraybuffer',
            headers: {
                'x-file-name': `${connectorId ? `${connectorId}_` : ''}${startDate.substring(0, 10)}_${endDate.substring(0, 10)}`,
                'content-type': 'application/json',
                accept: 'application/pdf',
                'x-device-type': ($window.EVERON.isMobile ? 'mobile' : 'desktop')
            },
            transformResponse: fileService.transformFileResponse()
        });
    }

    /**
     * Returns generated CSV invoice
     * @param {string} transactionsType
     * @param {string} id
     * @param {Object} range
     * @param {number} connectorId
     * @returns {Promise}
     */
    function downloadTransactionsCsv(transactionsType, {id, range, connectorId}) {
        const {startDate, endDate} = utils.localDatesToUTC(range);

        return $http.get('/api/billing/transactions/:type/:id/report', {
            params: _.omitBy({id, connectorId, startDate, endDate, type: transactionsType}, _.isUndefined),
            responseType: 'arraybuffer',
            headers: {
                accept: 'text/csv'
            },
            transformResponse: fileService.transformFileResponse()
        });
    }

    return {
        /**
         * Transforms transaction details object
         * @param {Object} transaction
         * @returns {Object}
         */
        transformTransactionDetails(transaction) {
            return _.assign({}, transaction, {
                duration: moment.duration(transaction.duration).asMilliseconds(),
                period: getPeriod(transaction),
                showDetails: false,
                loaded: false
            });
        },

        /**
         * Returns default transactions range - 1 month in the past from today's date inclusive
         * @returns {Object}
         */
        getDefaultTransactionsRange() {
            const endDate = utils.getMoment().endOf('day').toDate();
            const startDate = utils.getMoment(endDate).subtract(1, 'month').startOf('month').toDate();

            return {startDate, endDate};
        },

        /**
         * Returns generated PDF or CSV transactions report
         * @param {string} fileType
         * @param {Object} params
         * @param {string} transactionsType
         * @returns {Promise}
         */
        downloadTransactions(fileType, params, transactionsType) {
            const downloadPromise = _.includes(fileType, 'pdf') ? downloadTransactionsPdf(transactionsType, params) : downloadTransactionsCsv(transactionsType, params);

            return downloadPromise.then(fileService.openFile);
        }
    };
}
