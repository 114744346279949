angular.module('everon.component.power-bar')
       .controller('PowerBarController', PowerBarController);

function PowerBarController() {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.model = $ctrl.data;
    };

    $ctrl.$onChanges = () => {
        $ctrl.model = $ctrl.data;
    };
}
