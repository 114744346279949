angular.module('everon.stations.station-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add-on-behalf', {
        url: '/activate-on-behalf',
        redirectTo: 'auth.stations.station-add-on-behalf.station-credentials',
        data: {
            title: 'stations.action.activate',
            permissions: ['STATION:ACTIVATE_ON_BEHALF']
        },
        views: {
            'main@auth': {
                template: '<evcloud-station-add class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}

