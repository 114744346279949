angular.module('everon.users.system-users.groups.user-group-create.user-group-details')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users.groups.user-group-create.user-group-details', {
        data: {
            title: 'generic.title.details',
            order: 0
        },
        views: {
            'content@auth.users.system-users.groups.user-group-create': {
                template: '<evcloud-user-group-create-details class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
