angular.module('everon.cards.card-add')
       .controller('CardAddController', CardAddController);

CardAddController.$inject = ['$q', '$state', 'localStore', 'mediatorService', 'permissionService', 'sessionService', 'CONSTANTS'];

function CardAddController($q, $state, localStore, mediatorService, permissionService, sessionService, CONSTANTS) {
    const $ctrl = this;
    const storageKey = 'newCard';

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        $ctrl.state = {
            dataLoaded: false,
            stepsCompleted: false,
            canActivateCardOnBehalf: checkPermissions(['CARD:ACTIVATE_ON_BEHALF']),
            isConfirmingActivation: false
        };

        $ctrl.states = _($state.$current.parent.children).sortBy('data.order')
                                                         .map(item => ({
                                                             name: item.name,
                                                             title: item.data.title
                                                         }))
                                                         .value();

        $ctrl.payload = localStore.get(storageKey) || {
            card: {
                assignToExisting: false
            },
            billing: {},
            selectedProduct: {},
            params: {}
        };

        // When confirming an activation we need to fetch the card information
        if ($ctrl.dataPromise) {
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);
            $ctrl.dataPromise.then(onDataLoaded);
        } else {
            $ctrl.state.dataLoaded = true;
        }

        updateIndex();
    };

    $ctrl.$onDestroy = () => {
        localStore.remove(storageKey);
    };

    /**
     * Goes to the next step
     * @param {Object} params
     * @returns {Promise}
     */
    $ctrl.next = params => {
        const isLastStep = _.last($ctrl.states).name === $state.current.name;
        const nextState = _.get($ctrl.states[$state.current.data.order + 1], 'name');

        $ctrl.payload.params = _.assign({}, $ctrl.payload.params, params);
        localStore.set(storageKey, $ctrl.payload);

        return isLastStep ? setComplete() : $state.go(nextState, $ctrl.payload.params, {reload: nextState}).then(updateIndex);
    };

    /**
     * Goes to the previous step
     * @param {Object} params
     * @returns {Promise}
     */
    $ctrl.previous = params => {
        const previousState = $ctrl.states[$state.current.data.order - 1].name;

        $ctrl.payload.params = _.assign({}, $ctrl.payload.params, params);
        localStore.set(storageKey, $ctrl.payload);

        return $state.go(previousState, $ctrl.payload.params, {reload: previousState}).then(updateIndex);
    };

    /**
     * Transitions to a given state
     * @param {string} name
     */
    $ctrl.goToStep = name => {
        localStore.set(storageKey, $ctrl.payload);

        $state.go(name, $ctrl.payload.params, {reload: name}).then(updateIndex);
    };

    /**
     * Updates current state index
     */
    function updateIndex() {
        $ctrl.currentStateIndex = _.findIndex($ctrl.states, {name: $state.current.name});
    }

    /**
     * Updates the state to completed and removes card data from local storage
     * @returns {Promise}
     */
    function setComplete() {
        $ctrl.state.stepsCompleted = true;
        localStore.remove(storageKey);

        return $q.resolve();
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({card, subscription, billing, activationToken}) {
        $ctrl.payload.card = _.assign($ctrl.payload.card, _.pick(card, 'contractId', 'id', 'reference', 'status'));

        $ctrl.payload.selectedProduct = {
            plan: subscription ? subscription.plan : null,
            product: subscription ? subscription.product : null
        };

        $ctrl.payload.billing = billing;
        $ctrl.payload.activationToken = activationToken;
        $ctrl.state.isConfirmingActivation = Boolean(activationToken);
        $ctrl.state.isActivationLinkValid = $ctrl.payload.card.status !== 'inactive';
        $ctrl.state.isAlreadyActivated = $ctrl.payload.card.status === 'active';

        $ctrl.cardIsActiveConfig = {
            heading: 'cards.cardActivate.alreadyActivated.heading',
            paragraph: 'cards.cardActivate.alreadyActivated.paragraph',
            icon: 'active-card',
            links: [{
                state: `auth.cards.card({cardId: '${$ctrl.payload.card ? $ctrl.payload.card.id : ''}'})`,
                text: 'cards.cardActivate.alreadyActivated.viewCard'
            }]
        };

        $ctrl.state.dataLoaded = true;
    }
}
