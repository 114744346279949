angular.module('everon.cards.list')
       .controller('CardsListController', CardsListController);

CardsListController.$inject = ['$state', 'permissionService', 'mediatorService', 'CONSTANTS', 'cardService', 'stateHelper'];

function CardsListController($state, permissionService, mediatorService, CONSTANTS, cardService, stateHelper) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), $ctrl.profile.permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({
            query: setSearchQuery,
            status: setCurrentStatus,
            type: setCurrentType,
            sort: setSortingParams
        }, getData);

        const {status, type, query} = $state.params;

        $ctrl.state = {
            dataLoaded: false,
            pageLoadInProgress: false,
            canActivateCard: checkPermissions(['CARD:ACTIVATE']),
            canActivateCardOnBehalf: checkPermissions(['CARD:ACTIVATE_ON_BEHALF']),
            canImportCard: checkPermissions(['CARD:IMPORT']),
            canSearchCards: checkPermissions(['CARD:READ_ALL']) || checkPermissions(['CARD:READ']),
            filterActive: Boolean(status || type || query)
        };

        $ctrl.pagination = null;
        $ctrl.searchQuery = query;
        $ctrl.cardTypes = CONSTANTS.CARD_TYPES;
        $ctrl.cardStates = CONSTANTS.CARD_STATES;
        $ctrl.selectedType = type || $ctrl.cardTypes[0];
        $ctrl.selectedStatus = status || $ctrl.cardStates.iso14443[0];

        $ctrl.columns = getListColumns($ctrl.state.canImportCard);

        $ctrl.actions = [];

        $ctrl.noResultsConfig = {
            heading: 'cards.list.noResults.heading',
            paragraph: 'cards.list.noResults.paragraph',
            icon: 'empty-state-cards'
        };

        $ctrl.noSearchResultsConfig = {
            heading: 'generic.noResults.heading',
            icon: 'empty-state-search-results',
            paragraph: 'generic.noSearchResults.paragraph',
            template: 'partials/search-help.html'
        };

        $ctrl.noFiltersResultsConfig = {
            heading: 'generic.noResults.heading',
            paragraph: 'generic.noFilterResults.paragraph'
        };

        $ctrl.sortingParams = stateHelper.getSortingParams();

        if ($ctrl.state.canImportCard) {
            $ctrl.actions = $ctrl.actions.concat([
                {
                    name: 'cards.action.import',
                    state: 'auth.cards.card-import',
                    icon: 'import'
                },
                {
                    name: 'generic.action.importCsv',
                    state: 'auth.cards.card-import-csv',
                    icon: 'import'
                }
            ]);
        }

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Updates state with pagination params
     * @param {number} page
     */
    $ctrl.goToPage = (page = 0) => {
        const {size} = $ctrl.pagination;

        stateHelper.changeParams({size, page});
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    /**
     * Updates state with selected status and zero-pagination
     */
    $ctrl.onFilterChange = () => {
        const status = $ctrl.selectedStatus === CONSTANTS.CARD_ALL_KEY ? null : $ctrl.selectedStatus;
        const type = $ctrl.selectedType === CONSTANTS.CARD_ALL_KEY ? null : $ctrl.selectedType;

        stateHelper.changeParams({status, type, page: 0});
    };

    function getListColumns(canImportCard) {
        const columns = [
            {
                property: 'visualNumber',
                translationKey: 'generic.label.contractId',
                sortable: true,
                desc: true,
                cssClass: $ctrl.state.canImportCard ? 'col-5 sm-col-2' : 'col-6 sm-col-4'
            },
            {
                property: 'reference',
                translationKey: 'generic.label.reference',
                sortable: true,
                desc: true,
                cssClass: $ctrl.state.canImportCard ? 'col-2 xs-hide' : 'col-3 xs-hide'
            },
            {
                property: 'type',
                translationKey: 'generic.label.type',
                sortable: true,
                desc: true,
                cssClass: $ctrl.state.canImportCard ? 'col-3 sm-col-1' : 'col-4 sm-col-2'
            },
            {
                property: 'accountName',
                translationKey: 'account.billingInformation.form.label.accountName',
                sortable: false,
                desc: true,
                cssClass: 'col-2 xs-hide'
            }
        ];

        if (canImportCard) {
            columns.splice(1, 0, {
                    property: 'status',
                    translationKey: 'generic.label.status',
                    sortable: true,
                    desc: true,
                    cssClass: 'col-3 sm-col-2'
                },
                {
                    property: 'uid',
                    translationKey: 'generic.label.rfid',
                    sortable: true,
                    desc: true,
                    cssClass: 'col-2 xs-hide'
                });
        }

        return columns;
    }

    function setSearchQuery(query) {
        $ctrl.searchQuery = query;
        $ctrl.state.filterActive = isFilterActive();
    }

    function setCurrentStatus(status) {
        $ctrl.selectedStatus = status || CONSTANTS.CARD_ALL_KEY;
        $ctrl.state.filterActive = isFilterActive();
    }

    function setCurrentType(type) {
        $ctrl.selectedType = type || CONSTANTS.CARD_ALL_KEY;
        $ctrl.state.filterActive = isFilterActive();
    }

    function isFilterActive() {
        return Boolean($ctrl.selectedType !== CONSTANTS.CARD_ALL_KEY || $ctrl.selectedStatus !== CONSTANTS.CARD_ALL_KEY || $ctrl.searchQuery);
    }

    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
    }

    /**
     * Requests cards
     */
    function getData() {
        const params = _.pick($state.params, ['status', 'type', 'sort', 'query', 'page', 'size']);

        $ctrl.state.pageLoadInProgress = true;

        cardService.getCards(params)
                   .then(updateData)
                   .catch(onRejected)
                   .finally(() => {
                       $ctrl.state.pageLoadInProgress = false;
                   });
    }

    /**
     * Updates data model on every change
     * @param {Object} data
     */
    function updateData(data) {
        $ctrl.cards = Array.isArray(data.content) ? decorateCardViewAttributes(data.content) : [];
        $ctrl.pagination = cardService.getPagination(data);
    }

    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Updates state with search query and zero-pagination
     * @param {string} query
     */
    function onSearch(query) {
        stateHelper.changeParams({query, page: 0});
    }

    /**
     * Checks if the conditions to show the search are met
     * @returns {boolean}
     */
    function isSearchDisabled() {
        return !($ctrl.state.filterActive || $ctrl.cards && $ctrl.cards.length);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        updateData(data);

        if ($ctrl.state.canActivateCardOnBehalf || $ctrl.state.canActivateCard) {
            $ctrl.actions.push({
                name: 'cards.action.activate',
                state: `auth.cards.card-add${$ctrl.state.canActivateCardOnBehalf ? '-on-behalf' : ''}.card-details`,
                icon: 'cards'
            });
        }

        $ctrl.searchOptions = {
            id: 'cards',
            initialQuery: $ctrl.searchQuery,
            stateParam: 'query',
            onSearch,
            disabled: isSearchDisabled()
        };

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['current']);
    }

    /**
     * Compute card url given the id, type and status
     * @param {Object} card
     * @returns {string} url
     */
    function getCardUrl({id, type, status}) {
        return type === 'virtual' ?
            `auth.cards.virtual-token({cardId: '${id}'})` :
            ['inactive', 'pending'].includes(status) ?
                `auth.cards.card-import-details({cardId: '${id}'})` :
                `auth.cards.card({cardId: '${id}'})`;
    }

    /**
     * Computes the proper css class for card view object
     * @param {string} status
     * @returns {string}
     */
    function getCardStatusCssClass({status}) {
        switch (status) {
            case 'active':
                return 'text-success';
            case 'blocked':
                return 'text-error';
            default:
                return 'text-grey-2';
        }
    }

    /**
     * Decorate the card view object with href and proper css class
     * @param {Array} cards
     * @returns {Array}
     */
    function decorateCardViewAttributes(cards) {
        return cards.map(card => {
            return {
                ...card,
                href: getCardUrl(card),
                statusCssClass: getCardStatusCssClass(card)
            };
        });
    }
}
