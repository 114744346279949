angular.module('everon.component.json-tree')
       .controller('JsonTreeController', JsonTreeController);

JsonTreeController.$inject = ['jsonTreeService'];

function JsonTreeController(jsonTreeService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.parsedJson = jsonTreeService.parseJson($ctrl.json);
    };

    /**
     * Collapse/expand a given field
     * @param {Object} field
     */
    $ctrl.expandField = field => {
        field.isExpanded = !field.isExpanded;
    };
}
