angular.module('everon.accounts.account.settings')
       .controller('AccountSettingsController', AccountSettingsController);

AccountSettingsController.$inject = ['$q', 'mediatorService', 'CONSTANTS', 'accountService', 'modalService'];

function AccountSettingsController($q, mediatorService, CONSTANTS, accountService, modalService) {
    const $ctrl = this;
    let modelCopy = null;
    let modal;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            hasChanged: false,
            saveInProgress: false,
            dataLoaded: false
        };

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController'
        }).then(modalInstance => {
            modal = modalInstance;
        });

        $ctrl.modalConfig = {
            title: 'account.settings.chargingProfilesDisableWarning.title',
            paragraph: 'account.settings.chargingProfilesDisableWarning.paragraph',
            button: {
                ok: 'account.settings.chargingProfilesDisableWarning.disableFeature'
            }
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    $ctrl.onChange = () => {
        $ctrl.state.hasChanged = hasModelChanged();
    };

    $ctrl.cancel = () => {
        $ctrl.model = JSON.parse(modelCopy);
        resetFormState();
    };

    $ctrl.save = () => {
        const chargingProfileSetting = $ctrl.model.accountFeatureSettings.chargingProfile;
        const originalChargingProfileSetting = JSON.parse(modelCopy).accountFeatureSettings.chargingProfile;
        const chargingProfileSettingHasBeenDisabled = !chargingProfileSetting && originalChargingProfileSetting;

        if (chargingProfileSettingHasBeenDisabled) {
            modal.open($ctrl.modalConfig)
                 .then(response => {
                     if (response === true) {
                        updateFeatures();
                     } else {
                         $ctrl.cancel();
                     }
                 });
        } else {
            updateFeatures();
        }
    };

    function updateFeatures() {
        $ctrl.state.saveInProgress = true;

        accountService.updateFeatureFlagsForAccount($ctrl.model.accountFeatureSettings, $ctrl.accountId)
                      .then(data => {
                          modelCopy = JSON.stringify($ctrl.model);

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success'
                          });

                          resetFormState();
                      })
                      .catch(response => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              isPersistent: true,
                              messageKey: CONSTANTS.ERROR_CODES[response.status]
                          });
                      })
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    }

    /**
     * Checks whether the model has changed while editing
     * @returns {boolean}
     */
    function hasModelChanged() {
        return modelCopy !== JSON.stringify($ctrl.model);
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl.settingsForm.$setUntouched();
        $ctrl.settingsForm.$setPristine();
        $ctrl.state.hasChanged = false;
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({accountId, billingOverview, accountFeatureSettings}) {
        $ctrl.accountId = accountId;
        $ctrl.model = _.assign({}, {accountFeatureSettings});

        modelCopy = JSON.stringify($ctrl.model);

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [billingOverview.accountName, 'current']);
    }
}
