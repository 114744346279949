angular.module('everon.component.confirmation-modal-dialog')
       .controller('ConfirmationModalDialogController', ConfirmationModalDialogController);

ConfirmationModalDialogController.$inject = ['close', 'data'];

function ConfirmationModalDialogController(close, data) {
    const $ctrl = this;

    $ctrl.data = data;

    $ctrl.confirm = () => {
        close(true);
    };

    $ctrl.cancel = () => {
        close(false);
    };
}
