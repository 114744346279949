angular.module('everon.cards.list')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.list', {
        url: '?status&type&sort&query&page&size',
        data: {
            title: 'generic.entity.cards'
        },
        params: {
            sort: {
                value: 'visualNumber,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            }
        },
        dynamic: true,
        resolve: {
            data: ['cardService', '$transition$', (cardService, $transition$) => {
                const {status, sort, query, page, size, type} = $transition$.params('to');

                return cardService.getCards({status, sort, query, page, size, type});
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-cards-list data-data-promise="::$resolve.data" profile="::$resolve.auth.profile" class="flex-auto flex flex-column" />'
            }
        }
    });
}
