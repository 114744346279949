angular.module('everon.stations.station-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add', {
        url: '/new',
        redirectTo: 'auth.stations.station-add.station-credentials',
        data: {
            title: 'stations.action.activate',
            permissions: ['STATION:ACTIVATE']
        },
        resolve: {
            mapsApi: ['mapService', mapService => mapService.loadMapApi()]
        },
        views: {
            'main@auth': {
                template: '<evcloud-station-add class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}

