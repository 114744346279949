angular.module('everon.billing-plans.plans.plan.eligibility')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans.plan.eligibility', {
        url: '/eligibility',
        data: {
            title: 'generic.title.eligibility',
            id: 'plan-eligibility-navigation-link',
            navigationType: ['context'],
            menuPriority: 1
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/plans/plan/eligibility' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.billing-plans.plans.plan.eligibility');
        },
        resolve: {
            data: ['$transition$', 'subscriptionService', ($transition$, subscriptionService) => {
                const {type, id} = $transition$.params('to');

                return subscriptionService.getPlan(id, type);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.billing-plans.plans.plan': {
                component: 'evcloudPlanEligibility',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
