angular.module('everon.roaming.connections.connection.overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.connections.connection.overview', {
        url: '/overview',
        data: {
            id: 'roaming-connection-overview-navigation-link',
            title: 'generic.title.overview',
            menuPriority: 1,
            navigationType: ['context']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/connections/connection/overview' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.connections.connection.overview');
        },
        resolve: {
            data: ['roamingService', '$transition$', (roamingService, $transition$) => roamingService.getConnection($transition$.params('to').id)]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                component: 'evcloudRoamingConnectionOverview',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
