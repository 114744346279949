angular.module('everon')
       .factory('dataList', dataList);

dataList.$inject = [];

function dataList() {
    return {
        /**
         * Updates column state based on the active one
         * @param {Object} columns
         * @param {Object} activeColumn
         * @param {boolean} isDesc
         */
        updateColumnState(columns, activeColumn, isDesc) {
            columns.forEach(column => {
                column.active = (column.property === activeColumn.property);
                column.desc = column.active ? isDesc : true;
            });
        }
    };
}
