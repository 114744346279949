angular.module('everon.logout')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.logout', {
        url: '/logout',
        data: {
            id: 'logout-navigation-link',
            title: 'logout.title',
            navigationType: ['user'],
            menuPriority: 10
        },
        resolve: {
            logout: ['$state', 'sessionService', ($state, sessionService) => sessionService.logout()]
        },
        redirectTo($transition$) {
            const logoutResolve = $transition$.injector().getAsync('logout');
            const $state = $transition$.injector().get('$state');

            return logoutResolve.then(() => $state.target('anon.landing.login', null, {location: 'replace'}));
        }
    });
}
