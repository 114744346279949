angular.module('everon.stations.station-add.station-billing-information')
       .controller('StationAddBillingInformationController', StationAddBillingInformationController);

StationAddBillingInformationController.$inject = ['CONSTANTS', 'mediatorService', 'sessionService'];

function StationAddBillingInformationController(CONSTANTS, mediatorService, sessionService) {
    const $ctrl = this;
    let modelCopy;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            accountTypeLocked: false,
            countryChanged: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.previous = () => {
        $ctrl.stationAddCtrl.payload.billing = getModelPayload($ctrl.model);
        $ctrl.stationAddCtrl.previous();
    };

    $ctrl.next = () => {
        const payload = getModelPayload($ctrl.model);

        $ctrl.stationAddCtrl.payload.billing = payload;
        $ctrl.stationAddCtrl.payload.station.features.advenir = isAdvenirEnabled();

        // Reset selected product payload in case country has changed
        if ($ctrl.state.countryChanged) {
            $ctrl.stationAddCtrl.payload.selectedProduct = {};
        }

        const {station: {identityCode}, billing} = $ctrl.stationAddCtrl.payload;

        $ctrl.stationAddCtrl.next({country: billing.billingAddress.country.code, identityCode, accountId: $ctrl.model.accountId});
    };

    $ctrl.onTypeChange = () => {
        if (!$ctrl.model.isBusiness) {
            _.forEach($ctrl.model.businessDetails, (value, key, collection) => {
                collection[key] = null;
            });
        }
    };

    $ctrl.onCountryChange = () => {
        $ctrl.state.countryChanged = $ctrl.model.billingAddress.country.code !== JSON.parse(modelCopy).billingAddress.country.code;
    };

    /**
     * Extends model with additional properties
     * @param {Object} model
     * @returns {Object}
     */
    function extendModel(model) {
        const defaults = {
            businessDetails: {},
            billingAddress: {}
        };

        return _.defaultsDeep({}, defaults, model);
    }

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => {
            item.id = _.kebabCase(item.name);

            return item;
        });
    }

    /**
     * Returns model payload to be stored on the parent controller
     * @param {Object} model
     * @returns {Object}
     */
    function getModelPayload(model) {
        const payload = _.omit(model, ['number', 'isBusiness', 'accountId', 'accountName']);

        if (!model.isBusiness) {
            delete payload.businessDetails;
        }

        return payload;
    }

    /**
     * Checks if Advenir can be enabled regarding billing country selected
     * @returns {boolean}
     */
    function isAdvenirEnabled() {
        return $ctrl.model.billingAddress.country.code === 'FR' && $ctrl.stationAddCtrl.payload.station.features.advenir;
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.state.accountTypeLocked = sessionService.getUserProfile().accountSetupComplete;
        $ctrl.model = _.isEmpty($ctrl.stationAddCtrl.payload.billing) ? extendModel(data.billing) : _.assign({}, data.billing, $ctrl.stationAddCtrl.payload.billing);
        $ctrl.accountFormModel = extendFormModel(data.accountFormModel);
        $ctrl.countries = data.countries;
        modelCopy = JSON.stringify($ctrl.model);

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
