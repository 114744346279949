angular.module('everon.stations.station-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add-on-behalf-confirm', {
        url: '/:stationId/confirm-activation?activationToken',
        redirectTo: 'auth.stations.station-add-on-behalf-confirm.station-address',
        data: {
            title: 'stations.action.activate',
            permissions: ['STATION:ACTIVATE']
        },
        resolve: {
            data: ['$q', '$transition$', 'stationService', 'subscriptionService', 'accountService', 'mapService', ($q, $transition$, stationService, subscriptionService, accountService, mapService) => {
                const {stationId, activationToken} = $transition$.params('to');

                return $q.all({
                    activationToken,
                    station: stationService.getStation(stationId),
                    billing: accountService.getBillingInfo(),
                    subscription: subscriptionService.getStationSubscription(stationId),
                    mapsApi: mapService.loadMapApi()
                }).catch(e => {
                    // Handle the case when a station was put back into inactive state and it's subscription was removed
                    return $q.all({
                        activationToken,
                        station: stationService.getStation(stationId),
                        billing: null,
                        subscription: null
                    });
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-station-add data-data-promise="::$resolve.data" class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}

