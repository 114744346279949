angular.module('everon.account.invoices')
       .factory('invoiceService', invoiceService);

invoiceService.$inject = ['$http', 'fileService', 'searchMixin'];

function invoiceService($http, fileService, searchMixin) {
    return _.assign({}, searchMixin, {
        /**
         * Returns an array of invoice data for the account with specified id
         * @param {string} id
         * @param {Object} pageData
         * @param {number} pageData.size
         * @param {number} pageData.page
         * @returns {Array}
         */
        getAccountInvoices(id, pageData) {
            let {nextPageToken = null, size, page} = pageData || {};

            size = size ? size : 30;
            page = page ? page : 0;

            return $http.get('/api/invoice-provider-proxy/accounts/:id/invoices', {params: {id, nextPageToken, size, page}});
        },

        /**
         * Returns generated PDF invoice
         * @param {Object} invoice data
         * @param {string} invoice.id
         * @param {number} invoice.number
         * @param {string} invoice.date
         * @param {string} accountId - account id
         * @param {string} [nameFormatted=false] - optional param to specify oif file name should be formatted or can be just number
         * @returns {Promise}
         */
        downloadInvoice({id, number, date}, accountId, nameFormatted = false) {
            const fileName = nameFormatted ? this.getFormattedName(date, number) : number;

            return $http.get(`/api/invoice-provider-proxy/accounts/${accountId}/invoices/${id}`, {
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/octet-stream'
                },
                responseType: 'arraybuffer',
                transformResponse: fileService.transformFileResponse(),
                timeout: 2 * 60 * 1000
            }).then(res => {
                if (!res.fileName) {
                    res.fileName = `${fileName}.pdf`;
                }

                fileService.openFile(res);
            });
        },

        /**
         * Returns formatted name
         * @param {string} date
         * @param {string} name
         * @returns {string}
         */
        getFormattedName(date, name) {
            const invoiceDatetime = new Date(date);
            const yy = String(invoiceDatetime.getFullYear()).slice(-2);
            const mm = String(invoiceDatetime.getMonth() + 1).padStart(2, '0');

            return `${mm}${yy}_${name}`;
        }
    });
}
