angular.module('everon.stations.station-import-csv')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-import-csv', {
        url: '/import-csv',
        data: {
            title: 'generic.action.importCsv',
            permissions: ['STATION:IMPORT']
        },
        views: {
            'main@auth': {
                template: '<evcloud-station-import-csv class="sm-flex flex-auto"/>'
            }
        }
    });
}
