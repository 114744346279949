angular.module('everon.cards.card-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card-add-on-behalf', {
        url: '/activate-on-behalf',
        redirectTo: 'auth.cards.card-add-on-behalf.card-details',
        data: {
            title: 'cards.cardActivate.title',
            permissions: ['CARD:ACTIVATE_ON_BEHALF']
        },
        views: {
            'main@auth': {
                template: '<evcloud-card-add class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}
