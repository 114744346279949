angular.module('everon.roaming.settings')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.settings', {
        url: '/settings',
        data: {
            id: 'roaming-settings-navigation-link',
            title: 'generic.title.settings',
            menuPriority: 1,
            navigationType: ['secondary'],
            permissions: ['ROAMING:UPDATE']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/settings' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.settings');
        },
        resolve: {
            data: ['$q', 'countryService', 'roamingService', ($q, countryService, roamingService) => {
                return $q.all({
                    settings: roamingService.getSettings(),
                    countries: countryService.getTenantCountries()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                component: 'evcloudRoamingSettings',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
