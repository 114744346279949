import {strArrayToMap} from '../utils';

export default {
    clearAccountSuggestionList(state) {
        state.accountsSuggestionList = [];
    },

    updateTiles(state, {type, count}) {
        const tileToUpdate = state.tiles.find(tile => tile.type === type);

        if (tileToUpdate) {
            tileToUpdate.count = count;
            tileToUpdate.isLoading = false;
        }
    },

    setLoadingStateForTiles(state) {
        state.tiles.forEach(tile => {
            tile.count = null;
            tile.isLoading = true;
        });
    },

    setCurrentUserProfile(state, sessionInfo) {
        const {permissions, ...rest} = sessionInfo;

        state.currentUserProfile = {...rest, permissions: strArrayToMap(permissions)};
    }
};

