angular.module('everon.cards.card')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card', {
        url: '/:cardId',
        redirectTo: 'auth.cards.card.overview',
        data: {
            title: 'generic.entity.card'
        },
        resolve: {
            data: ['$transition$', 'cardService', ($transition$, cardService) => cardService.getCard($transition$.params('to').cardId)]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-card data-data-promise="::$resolve.data" class="flex flex-auto" />'
            },
            'contextNav@auth.cards.card': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.cards.card\'}"  />'
            }
        }
    });
}
