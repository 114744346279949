angular.module('everon.component.css-loader')
       .controller('CssLoaderController', CssLoaderController);

CssLoaderController.$inject = ['brandingService'];

function CssLoaderController(brandingService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        brandingService.getCustomScheme()
                       .then(data => brandingService.updateBrandCss(data));
    };
}
