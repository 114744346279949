angular.module('everon.users')
       .controller('UsersController', UsersController);

UsersController.$inject = ['$state', '$q', '$translate', '$timeout', '$location', '$anchorScroll', 'mediatorService', 'sessionService', 'permissionService', 'CONSTANTS', 'userService', 'accountService', 'stateHelper'];

function UsersController($state, $q, $translate, $timeout, $location, $anchorScroll, mediatorService, sessionService, permissionService, CONSTANTS, userService, accountService, stateHelper) {
    const $ctrl = this;
    let stateChangeListener;
    let highlightItemTimeout;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);
        const hasTenantLevelRole = Boolean(_.intersection(sessionService.getUserProfile().roles, ['tenantAdmin', 'customerSupport']).length);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({
            query: setSearchQuery,
            sort: setSortingParams
        }, getData);

        $ctrl.userType = $state.current.data.userType;

        $ctrl.state = {
            dataLoaded: false,
            pageLoadInProgress: false,
            hasTenantLevelRole,
            canInviteSystemUsers: $ctrl.userType === 'system-users' && checkPermissions(['SYSTEM_USER:CREATE']),
            canInviteCustomers: $ctrl.userType === 'customers' && checkPermissions(['CUSTOMER:CREATE']),
            showAccountName: false,
            showGroupName: false,
            showLastLogin: false,
            searchVisible: $ctrl.userType === 'customers' && hasTenantLevelRole,
            canSeeGroups: checkPermissions(['USER_GROUP:READ'])
        };

        setupColumns(hasTenantLevelRole);

        $ctrl.pagination = null;
        $ctrl.searchQuery = $state.params.query;
        $ctrl.sortingParams = stateHelper.getSortingParams();

        $ctrl.maxNumberRoles = 2;

        $ctrl.noResultsConfig = {
            heading: 'users.list.noResults.heading',
            paragraph: 'users.list.noResults.paragraph'
        };

        $ctrl.noSearchResultsConfig = {
            icon: 'empty-state-search-results',
            heading: 'generic.noResults.heading',
            paragraph: 'generic.noSearchResults.paragraph',
            template: 'partials/search-help.html'
        };

        if ($ctrl.state.canInviteCustomers && !$ctrl.state.hasTenantLevelRole) {
            accountService.getBusinessAccountStatus()
                          .then(renderActionButtons);
        } else {
            renderActionButtons();
        }

        $ctrl.highlightItem = $state.params.highlightItem;

        if ($ctrl.highlightItem) {
            // scrolls to the modified item
            $timeout(() => {
                $location.hash($ctrl.highlightItem);
                $anchorScroll();
            }, 300);
        }

        // The promise from the customers view is handled here.
        // Not the one from system users / groups which will be undefined here.
        if ($ctrl.dataPromise) {
            $ctrl.dataPromise.then(onDataLoaded);
        }
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
        $timeout.cancel(highlightItemTimeout);
    };

    /**
     * Updates state with pagination params
     * @param {number} [page]
     */
    $ctrl.goToPage = (page = 0) => {
        const {size} = $ctrl.pagination;

        stateHelper.changeParams({size, page});
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    function setupColumns(hasTenantLevelRole) {
        $ctrl.columns = [
            {
                property: 'name',
                translationKey: 'generic.label.name',
                sortable: true,
                cssClass: 'col-3'
            },
            {
                property: 'email',
                translationKey: 'generic.label.email',
                sortable: true,
                cssClass: 'col-3'
            },
            {
                property: 'roles',
                translationKey: 'generic.label.roles',
                sortable: false,
                cssClass: $ctrl.userType === 'customers' ? 'col-2' : 'col-3'
            }
        ];

        if ($ctrl.userType === 'system-users') {
            if (hasTenantLevelRole) {
                $ctrl.state.showGroupName = true;
                $ctrl.columns.push({
                    property: 'userGroup',
                    translationKey: 'generic.label.groupName',
                    sortable: true,
                    cssClass: 'col-3'
                });
            } else {
                $ctrl.state.showAccountName = true;
                $ctrl.columns.push({
                    property: 'accountName',
                    translationKey: 'account.billingInformation.form.label.accountName',
                    sortable: false,
                    cssClass: 'col-3'
                });
            }
        }

        if ($ctrl.userType === 'customers') {
            $ctrl.state.showAccountName = true;
            $ctrl.state.showLastLogin = true;

            $ctrl.columns.push({
                property: 'accountName',
                translationKey: 'account.billingInformation.form.label.accountName',
                sortable: false,
                cssClass: 'col-2'
            }, {
                property: 'lastLoginAt',
                translationKey: 'generic.label.lastLogin',
                sortable: true,
                cssClass: 'col-2'
            });
        }
    }

    function setSearchQuery(query) {
        $ctrl.searchQuery = query;
    }

    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
    }

    /**
     * Renders action buttons based on current user permissions. If `$ctrl.userType === 'customers'`, account owner role can invite customers only if current user's account type is business
     * @param {boolean} isBusinessAccount
     */
    function renderActionButtons(isBusinessAccount = false) {
        $ctrl.actions = [
            // Available only to Account Owner
            {
                name: 'users.action.invite',
                state: 'auth.users.customers.user-invite({customerType: \'end-user\'})',
                isAuthorised: $ctrl.state.canInviteCustomers && isBusinessAccount
            },
            // Available only to Tenant Admin, Customer Support
            {
                name: 'users.action.inviteClient',
                state: 'auth.users.customers.user-invite({customerType: \'client\'})',
                icon: 'users',
                isAuthorised: $ctrl.state.canInviteCustomers && $ctrl.state.hasTenantLevelRole
            },
            {
                name: 'users.action.inviteEndUser',
                state: 'auth.users.customers.user-invite({customerType: \'end-user\'})',
                icon: 'user',
                isAuthorised: $ctrl.state.canInviteCustomers && $ctrl.state.hasTenantLevelRole
            },
            // Available to both user levels
            {
                name: 'users.action.invite',
                state: 'auth.users.system-users.user-invite',
                isAuthorised: $ctrl.state.canInviteSystemUsers
            }
        ];

        $ctrl.actions = _($ctrl.actions).filter('isAuthorised')
                                        .map(_.partialRight(_.omit, 'isAuthorised'))
                                        .value();
    }

    /**
     * Requests fresh data
     */
    function getData() {
        const params = _.pick($state.params, ['sort', 'query', 'page', 'size']);

        $ctrl.state.pageLoadInProgress = true;

        userService.getUsers(_.assign({type: $ctrl.userType}, params))
                   .then(updateData)
                   .catch(onRejected)
                   .finally(() => {
                       $ctrl.state.pageLoadInProgress = false;
                   });
    }

    /**
     * Updates state with search query and zero-pagination
     * @param {string} query
     */
    function onSearch(query) {
        stateHelper.changeParams({query, page: 0});
    }

    /**
     * Updates data model on every change
     * @param {Object} data
     * @returns {Promise}
     */
    function updateData(data) {
        $ctrl.pagination = userService.getPagination(data);

        return $q.all(_.map(data.content, stringifyLocalisedRoles))
                 .then(dataContent => {
                     $ctrl.users = dataContent;
                 });
    }

    /**
     * Adds a new property to the user object with the roles array stringify and comma separated
     * @param {Object} user
     * @returns {Promise}
     * TODO: avoid translations in here by refactoring this method once tooltip component is improved to accept a template and not just plain string
     */
    function stringifyLocalisedRoles(user) {
        return $q.all(_.map(user.roles, role => $translate(`generic.roles.${role}`)))
                 .then(translatedRoles => {
                     user.rolesText = translatedRoles.join(', ');

                     return $q.resolve(user);
                 });
    }

    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**

     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        updateData(data).then(() => {
            $ctrl.searchOptions = {
                id: 'users',
                onSearch,
                stateParam: 'query',
                initialQuery: $ctrl.searchQuery,
                disabled: !($ctrl.users.length || $ctrl.searchQuery)
            };

            $ctrl.state.dataLoaded = true;

            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);

            if ($ctrl.userType === 'system-users') {
                mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['grandparent', 'current']);
            } else {
                mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
            }
        });
    }
}
