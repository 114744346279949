angular.module('everon.welcome')
       .component('evcloudWelcomeAccountBillingInformation', {
           templateUrl: 'modules/welcome/billing-information/billing-information.html',
           controller: 'WelcomeAccountBillingInformationController',
           bindings: {
               dataPromise: '<'
           },
           require: {
               welcomeCtrl: '^^evcloudWelcome'
           }
       });
