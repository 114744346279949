angular.module('everon.accounts.account.overview')
       .controller('AccountOverviewController', AccountOverviewController);

AccountOverviewController.$inject = ['$q', '$translate', 'mediatorService', 'CONSTANTS', 'permissionService', 'sessionService'];

function AccountOverviewController($q, $translate, mediatorService, CONSTANTS, permissionService, sessionService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        const checkPermissions = requiredPermissions => permissionService.resolve(sessionService.getUserProfile().permissions, requiredPermissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            canManageAccount: checkPermissions(['ACCOUNT:UPDATE_ALL']),
            canManageUsers: checkPermissions(['CUSTOMER:READ_ALL', 'SYSTEM_USER:READ_ALL']),
            canManageCards: checkPermissions(['CARD:READ_ALL']),
            canManageStations: checkPermissions(['STATION:READ_ALL']),
            canSeePersonalInfo: checkPermissions(['ACCOUNT:READ_PERSONAL_INFO'])
        };

        $ctrl.maxNumberRoles = 2;
        $ctrl.statusCssClass = CONSTANTS.STATUS.STATION_CONNECTOR;

        $ctrl.columns = {
            users: [
                {
                    property: 'email',
                    translationKey: 'generic.label.email',
                    sortable: false,
                    cssClass: 'col-8'
                },
                {
                    property: 'roles',
                    translationKey: 'generic.label.roles',
                    sortable: false,
                    cssClass: 'col-4'
                }
            ],
            cards: [
                {
                    property: 'contractId',
                    translationKey: 'generic.label.contractId',
                    sortable: false,
                    cssClass: 'col-8'
                },
                {
                    property: 'status',
                    translationKey: 'generic.label.status',
                    sortable: false,
                    cssClass: 'col-4'
                }
            ],
            stations: [
                {
                    property: 'identityCode',
                    translationKey: 'generic.label.stationIdentityCode',
                    sortable: false,
                    cssClass: 'col-8'
                },
                {
                    property: 'status',
                    translationKey: 'generic.label.status',
                    sortable: false,
                    cssClass: 'col-4'
                }
            ]
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Adds a new property to the user object with the roles array stringify and comma separated
     * @param {Object} user
     * @returns {Promise}
     * TODO: avoid translations in here by refactoring this method once tooltip component is improved to accept a template and not just plain string
     */
    function stringifyLocalisedRoles(user) {
        return $q.all(_.map(user.roles, role => $translate(`generic.roles.${role}`)))
                 .then(translatedRoles => _.assign(user, {rolesText: translatedRoles.join(', ')}));
    }

    function transformUsers(users) {
        return $q.all(users.map(stringifyLocalisedRoles));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({accountOwner, billingOverview, users, cards, stations}) {
        $ctrl.accountOwner = accountOwner.status === 204 ? null : accountOwner;
        $ctrl.billingOverview = $ctrl.state.canSeePersonalInfo ? billingOverview : null;
        $ctrl.billingAccountNameForQuery = billingOverview.accountName ? `"${billingOverview.accountName}"` : '';
        $ctrl.cards = cards.content;
        $ctrl.stations = stations.content;
        $ctrl.pagination = {
            numOfUsers: users.numberOfElements,
            totalUsers: users.totalElements,
            numOfCards: cards.numberOfElements,
            totalCards: cards.totalElements,
            numOfStations: stations.numberOfElements,
            totalStations: stations.totalElements
        };
        $ctrl.state.dataLoaded = true;

        if (users?.content) {
            transformUsers(users.content).then(users => {
                $ctrl.users = users;
            });
        }

        if (stations?.content) {
            $ctrl.stations = stations.content;
        }

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [billingOverview.accountName, 'current']);
    }
}
