angular.module('everon.cards.card.details')
       .controller('CardDetailsController', CardDetailsController);

CardDetailsController.$inject = ['$state', 'cardService', 'CONSTANTS', 'mediatorService', 'permissionService', 'sessionService', 'modalService', 'utils'];

function CardDetailsController($state, cardService, CONSTANTS, mediatorService, permissionService, sessionService, modalService, utils) {
    const $ctrl = this;
    const formName = 'cardDetailsForm';
    const statusesMap = {
        block: 'blocked',
        unblock: 'active',
        deactivate: 'deactivated'
    };
    let modal;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            saveInProgress: false,
            deleteInProgress: false,
            blockInProgress: false,
            hasChanged: false,
            canStartRfidTransaction: checkPermissions(['CARD:ENTER_RAW']),
            canImportCard: checkPermissions(['CARD:IMPORT']),
            canBlockCard: checkPermissions(['CARD:UPDATE']),
            isCardStatusInactiveLike: false,
            isInvoicingFeatureEnabled: false
        };

        $ctrl.availableStatuses = [];
        $ctrl.cardStatus = null;

        $ctrl.regex = {
            contractId: CONSTANTS.REGEX.CONTRACT_ID,
            rfid: CONSTANTS.REGEX.CARD_RFID
        };

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController'
        }).then(modalInstance => {
            modal = modalInstance;
        });

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    $ctrl.onChange = () => {
        $ctrl.state.hasChanged = JSON.stringify($ctrl.model) !== $ctrl.modelCopy;
    };

    /**
     * Saves card details
     */
    $ctrl.save = () => {
        const properties = ($ctrl.state.canImportCard && $ctrl.model.status === 'inactive') ? ['contractId', 'rfid'] : ['reference'];
        const requestData = _.pick($ctrl.model, properties);

        $ctrl.state.saveInProgress = true;

        cardService.updateCard($ctrl.model.id, requestData)
                   .then(response => {
                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                           type: 'success'
                       });

                       $ctrl.model = response;
                       $ctrl.modelCopy = JSON.stringify($ctrl.model);
                       resetFormState();

                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [response.reference || response.contractId, 'current']);
                   })
                   .catch(onRejected)
                   .finally(() => {
                       $ctrl.state.saveInProgress = false;
                   });
    };

    /**
     * Deletes inactive card and redirect to cards list
     */
    $ctrl.delete = () => {
        modal.open({
                 title: 'cards.action.delete',
                 paragraph: 'cards.card.details.form.heading.deleteConfirmation',
                 button: {
                     ok: 'generic.action.delete'
                 }
             })
             .then(attemptDelete);
    };

    /**
     * Triggers card status change confirmation dialog and reacts to it
     */
    $ctrl.toggleStatus = () => {
        const {action} = $ctrl.cardStatus;

        if (action) {
            modal.open({
                     title: `cards.action.${action}`,
                     paragraph: `cards.card.details.form.heading.${action}Confirmation`,
                     button: {
                         ok: `generic.action.${action}`
                     }
                 })
                 .then(attemptSetStatus);
        }
    };

    /**
     * Triggers canceling of deactivation confirmation dialog and reacts to it
     */
    $ctrl.cancelDeactivation = () => {
        modal.open({
                 title: 'cards.action.stopDeactivation',
                 paragraph: 'cards.card.details.form.heading.stopDeactivationConfirmation',
                 button: {
                     ok: 'generic.action.stop'
                 }
             })
             .then(attemptCancelDeactivation);
    };

    /**
     * Discards the changes
     */
    $ctrl.cancel = () => {
        if ($ctrl.model.status === 'inactive') {
            $state.go('auth.cards', null, {reload: 'auth.cards'});
        } else {
            restoreModel();
            resetFormState();
        }
    };

    $ctrl.cancelActivation = () => {
        cardService.cancelPendingCardActivation($ctrl.model.id)
                   .then(() => {
                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                           type: 'success'
                       });
                       $state.go('auth.cards', null, {reload: 'auth.cards'});
                   })
                   .catch(response => showError(response.status));
    };

    function onRejected(response) {
        restoreModel();
        resetFormState();
        showError(response.status);
    }

    function showError(code) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[code]
        });
    }

    /**
     * Attempt plan delete
     * @param {boolean} canProceed
     */
    function attemptDelete(canProceed) {
        if (canProceed) {
            $ctrl.state.deleteInProgress = true;

            cardService.deleteCard($ctrl.model.id)
                       .then(() => {
                           mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                               type: 'success'
                           });

                           $state.go('auth.cards', null, {reload: 'auth.cards'});
                       })
                       .catch(onRejected)
                       .finally(() => {
                           $ctrl.state.deleteInProgress = false;
                       });
        }
    }

    /**
     * Attempt to set card status
     * @param {boolean} canProceed
     */
    function attemptSetStatus(canProceed) {
        const {action} = $ctrl.cardStatus;

        if (canProceed) {
            changeStatus({action});
        } else {
            $ctrl.cardStatus = {status: $ctrl.model.status};
        }
    }

    /**
     * Attempt canceling of deactivation process
     * @param {boolean} canProceed
     */
    function attemptCancelDeactivation(canProceed) {
        if (canProceed) {
            changeStatus({action: 'cancel'});
        }
    }

    /**
     * Attempt card status change
     * @param {Object} payload
     */
    function changeStatus(payload) {
        $ctrl.state.blockInProgress = true;

        cardService.updateCard($ctrl.model.id, payload)
                   .then(response => {
                       const {status, userActions} = response;

                       $ctrl.model = response;
                       $ctrl.cardStatus = {status};
                       $ctrl.availableStatuses = getAvailableStatuses(userActions);
                       $ctrl.deactivationDate = getDeactivationDate();
                       $ctrl.modelCopy = JSON.stringify($ctrl.model);
                       resetFormState();
                   })
                   .catch(onRejected)
                   .finally(() => {
                       $ctrl.state.blockInProgress = false;
                   });
    }

    /**
     * Resets the form to untouched pristine state
     */
    function resetFormState() {
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
        $ctrl.onChange();
    }

    /**
     * Reverts changes to model
     */
    function restoreModel() {
        $ctrl.model = JSON.parse($ctrl.modelCopy);
    }

    /**
     * Converts user actions into corresponding statuses and merges with current one
     * @param {Array} userActions
     * @returns {Array}
     */
    function getAvailableStatuses(userActions) {
        const availableStatuses = _.without(userActions, 'cancel').map(action => ({action, status: statusesMap[action]}));

        return [$ctrl.cardStatus, ...availableStatuses];
    }

    function getDeactivationDate() {
        return $ctrl.model.deactivationDate ? utils.toLocalMoment($ctrl.model.deactivationDate).toDate() : null;
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({card, tenantFeatureFlags}) {
        $ctrl.model = card;
        $ctrl.modelCopy = JSON.stringify($ctrl.model);
        $ctrl.cardStatus = {status: $ctrl.model.status};
        $ctrl.deactivationDate = getDeactivationDate();
        $ctrl.availableStatuses = getAvailableStatuses(card.userActions);
        $ctrl.state.isInvoicingFeatureEnabled = tenantFeatureFlags.invoicing;
        $ctrl.state.dataLoaded = true;
        $ctrl.state.isCardStatusInactiveLike = ['pending', 'inactive'].includes($ctrl.model.status);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [card.reference || card.contractId || card.rfid, 'current']);
    }
}
