angular.module('everon.tenant-settings.languages')
       .controller('LanguagesController', LanguagesController);

LanguagesController.$inject = ['CONSTANTS', 'languageService', 'mediatorService'];

function LanguagesController(CONSTANTS, languageService, mediatorService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            hasChanged: false,
            showAvailableLanguages: false,
            saveInProgress: false
        };

        $ctrl.dragControl = {
            clone: false,
            orderChanged() {
                setChanged(true);
            }
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.toggleAvailableLanguages = show => {
        $ctrl.state.showAvailableLanguages = show;
    };

    $ctrl.save = () => {
        $ctrl.state.saveInProgress = true;
        languageService.updateTenantLanguages($ctrl.languages.active)
                       .then(data => {
                           setChanged(false);
                           updateActiveCopy(data);
                           mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                               type: 'success'
                           });
                       })
                       .finally(() => {
                           $ctrl.state.saveInProgress = false;
                       });
    };

    /**
     * Adds item to the bottom of active languages list and removes it from available languages list. Also updates the state
     * @param {Object} lang
     * @param {number} index
     */
    $ctrl.add = (lang, index) => {
        $ctrl.languages.active.push(lang);
        $ctrl.languages.available.splice(index, 1);
        setChanged(true);
    };

    /**
     * Updates `$ctrl.state.changed` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        $ctrl.state.hasChanged = hasChanged && hasIdentityChanged();
    }

    /**
     * Checks whether the array of active languages is the same as it was on controller initialisation
     * @returns {boolean}
     */
    function hasIdentityChanged() {
        return $ctrl.languages.activeCopy !== JSON.stringify($ctrl.languages.active);
    }

    /**
     * Updates an active languages list copy with new data returned from the server
     * @param {Array} data
     */
    function updateActiveCopy(data) {
        $ctrl.languages.activeCopy = JSON.stringify(data);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({tenantLanguages, platformLanguages}) {
        $ctrl.languages = {
            active: tenantLanguages,
            activeCopy: JSON.stringify(tenantLanguages),
            available: _.differenceBy(platformLanguages, tenantLanguages, 'tag')
        };
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
