angular.module('everon.stations.station-import-details-form.overview')
       .controller('StationImportDetailsFormOverviewController', StationImportDetailsFormOverviewController);

StationImportDetailsFormOverviewController.$inject = ['stationService', 'CONSTANTS', 'mediatorService', '$state', 'stationFormModel'];

function StationImportDetailsFormOverviewController(stationService, CONSTANTS, mediatorService, $state, stationFormModel) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.formModel = extendFormModel(stationFormModel);
        $ctrl.connectorSettings = {
            cards: stationService.mapConnectors($ctrl.stationImportCtrl.payload.connectorSpecs),
            partial: 'modules/stations/station-import-details-form/connectors/connector-setup-card-content-partial.html',
            widthPx: '240'
        };

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent']);
    };

    $ctrl.previous = () => {
        $ctrl.stationImportCtrl.previous();
    };

    $ctrl.import = () => {
        $ctrl.stationImportCtrl.save();
    };

    $ctrl.update = () => {
        $ctrl.stationImportCtrl.update();
    };

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => {
            item.id = _.kebabCase(item.name);

            return item;
        });
    }
}
