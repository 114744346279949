/**
 * Checks if one date is before another
 *
 * @example
 *
 * <input type="datetime-local" ng-model="$ctrl.dateFrom" evcloud-is-before-date="$ctrl.isBeforeDate" evcloud-is-before-date-offset="86400000">
 * <input type="datetime-local" ng-model="$ctrl.dateUntil">
 */
angular.module('everon.component.is-before-date')
       .directive('evcloudIsBeforeDate', isBeforeDate);

isBeforeDate.$inject = ['utils'];

function isBeforeDate(utils) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.isBeforeDate = modelValue => {
                const toDate = attributes.evcloudIsBeforeDate && JSON.parse(attributes.evcloudIsBeforeDate);

                return (!modelValue || !toDate) || isBefore(utils.toLocalMoment(toDate).toDate(), modelValue);
            };

            attributes.$observe('evcloudIsBeforeDate', () => {
                if (ngModel.$viewValue) {
                    ngModel.$validate();
                }
            });

            /**
             * Checks whether one date is before another, taking time offset into consideration if necessary
             * @param {Date} to
             * @param {Date} from
             * @returns {boolean}
             */
            function isBefore(to, from) {
                const offset = Number(attributes.evcloudIsBeforeDateOffset);
                const moment = utils.getMoment;

                return offset ? moment(to).diff(moment(from)) >= offset : moment(from).isBefore(moment(to));
            }
        }
    };
}
