angular.module('everon.roaming.cdrs')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.cdrs', {
        url: '/cdrs',
        redirectTo: 'auth.roaming.cdrs.accepted',
        data: {
            id: 'cdrs-navigation-link',
            title: 'roaming.cdrs.title',
            menuPriority: 5,
            navigationType: ['secondary'],
            permissions: ['ROAMING:UPDATE']
        },
        lazyLoad() {
            return import(/* webpackChunkName: 'app/roaming/cdrs' */ './index-lazy.js');
        },
        views: {
            'contextNav@auth.roaming': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.roaming.cdrs\'}"  />'
            }
        }
    });
}
