angular.module('everon.stations.station-add.station-plan')
       .controller('StationAddPlanController', StationAddPlanController);

StationAddPlanController.$inject = ['CONSTANTS', 'mediatorService'];

function StationAddPlanController(CONSTANTS, mediatorService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.onSelect = model => {
        $ctrl.selectedProduct = model;
    };

    $ctrl.previous = () => {
        $ctrl.stationAddCtrl.payload.selectedProduct = $ctrl.selectedProduct;

        $ctrl.stationAddCtrl.previous();
    };

    $ctrl.next = () => {
        $ctrl.stationAddCtrl.payload.selectedProduct = $ctrl.selectedProduct;
        $ctrl.stationAddCtrl.payload.selectedProduct.purchaseOrderNumber = $ctrl.purchaseOrderNumber;

        $ctrl.stationAddCtrl.next();
    };

    /**
     * Returns first available product and plan or empty object if there are none
     * @param {Array} products
     * @returns {Object}
     */
    function getFirstAvailable(products) {
        return products.length ? {
            product: products[0],
            plan: products[0].plans[0]
        } : {};
    }

    /**
     * Extends `selectedProduct` with fresh data from the server. Selected product might no longer be available so we need to fallback to the first available one
     * @param {Object} selectedProduct
     * @param {Array} products
     * @returns {Object}
     */
    function extendSelectedProduct(selectedProduct, products) {
        const product = _.find(products, {id: selectedProduct.product.id});

        return _.assign(selectedProduct, product ? {product, plan: _.find(product.plans, {id: selectedProduct.plan.id})} : getFirstAvailable(products));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded(data) {
        $ctrl.products = data;

        if (!$ctrl.products.length) {
            $ctrl.selectedProduct = $ctrl.stationAddCtrl.payload.selectedProduct = {}; // Reset selected product if there is none to select from
        } else {
            // We always extend `selectedProduct` with fresh data from the server, in cases new plans have been created using that product at the same time as card registration takes place
            $ctrl.selectedProduct = _.isEmpty($ctrl.stationAddCtrl.payload.selectedProduct) ?
                getFirstAvailable($ctrl.products) :
                extendSelectedProduct($ctrl.stationAddCtrl.payload.selectedProduct, $ctrl.products);
        }

        $ctrl.purchaseOrderNumber = $ctrl.stationAddCtrl.payload.selectedProduct.purchaseOrderNumber;
        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
