angular.module('everon')
       .factory('oktaSessionService', oktaSessionService);

oktaSessionService.$inject = ['$state', '$q', '$http', '$translate', '$log', 'oktaWidgetWrapper', 'tenantSettingsService', 'accountService', 'localisationService'];

function oktaSessionService($state, $q, $http, $translate, $log, oktaWidgetWrapper, tenantSettingsService, accountService, localisationService) {
    const tokenRenewalAnticipation = 10000; // ms = 10 secs

    let accessTokenContainer = null;
    let currentUserProfile = null;
    let tenantFeatureFlags = null;
    let accountFeatureFlags = null;

    function isAuthenticated() {
        return Boolean(accessTokenContainer) && !hasExpired(accessTokenContainer.expiresAt);
    }

    /**
     * Refresh the access token under the hood in case it expired.
     * @param {boolean}redirectOnFailure default to true
     * @returns {*}
     */
    function silentlyAuth(redirectOnFailure = true) {
        return oktaWidgetWrapper.renewToken()
                                .then(handleLoginSuccess)
                                .catch(e => {
                                    if (redirectOnFailure) {
                                        $state.go('anon.landing.login-oauth');
                                    }

                                    return $q.reject(e);
                                });
    }

    function handleLoginSuccess(tokens) {
        accessTokenContainer = tokens.find(tokenWrapper => Boolean(tokenWrapper.accessToken));

        return loadProfileMetadata(accessTokenContainer);
    }

    function loadProfileMetadata(tokenContainer) {
        return $q.all({
            profile: loadProfileInfo(tokenContainer.userinfoUrl),
            tenantFeatureFlagsResponse: tenantSettingsService.getFeatureFlags(),
            accountFeatureFlagsResponse: accountService.getFeatureFlags(),
            accountSetupComplete: accountService.getBillingInfoStatus()
        }).then(response => {
            const {profile, tenantFeatureFlagsResponse, accountFeatureFlagsResponse, accountSetupComplete} = response;
            const {payload: tokenInfo} = oktaWidgetWrapper.decodeToken(tokenContainer.accessToken);
            const permissions = (tokenInfo.permissions || []).map(perm => perm.toUpperCase());

            currentUserProfile = {
                ...profile,
                id: tokenInfo.everonUserId,
                oktaId: tokenInfo.uid,
                accountId: tokenInfo.accountId,
                accountSetupComplete,
                roles: tokenInfo.roles,
                permissions
            };

            tenantFeatureFlags = tenantFeatureFlagsResponse;
            accountFeatureFlags = accountFeatureFlagsResponse;

            $translate.use(profile.language)
                      .catch(languageTag => {
                          $log.debug(`Failed to load ${languageTag}.json. Check if file is a valid JSON`);
                      });
            localisationService.localise(profile.language);

            return {
                tenantFeatureFlags,
                accountFeatureFlags,
                profile: currentUserProfile
            };
        });
    }

    function loadProfileInfo(userInfoUrl) {
        return $http.get(userInfoUrl, {
            headers: {
                Authorization: `Bearer ${accessTokenContainer.accessToken}`
            }
        }).then(profile => ({
            firstName: profile.given_name,
            lastName: profile.family_name,
            language: profile.locale,
            email: profile.preferred_username
        }));
    }

    /**
     * Check whether the current time is past the access token's expiry time
     * @param {number}expiresAt
     * @returns {boolean}
     */
    function hasExpired(expiresAt) {
        return (expiresAt * 1000 - tokenRenewalAnticipation) <= Date.now();
    }

    const instance = {
        isAuthenticated,
        handleAuthentication() {
            return oktaWidgetWrapper.parseFromUrl()
                                    .then(handleLoginSuccess);
        },
        logout() {
            accessTokenContainer = null;
            currentUserProfile = null;
            tenantFeatureFlags = null;
            accountFeatureFlags = null;

            return $q((resolve, reject) => oktaWidgetWrapper.signOut()
                                                            .then(resolve)
                                                            .catch(reject));
        },
        loadSession(forceReload) {
            if (forceReload) {
                tenantFeatureFlags = accountFeatureFlags = currentUserProfile = null;
            }

            if (isAuthenticated()) {
                if (tenantFeatureFlags && accountFeatureFlags && currentUserProfile) {
                    return $q.resolve(({
                        tenantFeatureFlags: {...tenantFeatureFlags},
                        accountFeatureFlags: {...accountFeatureFlags},
                        profile: {...currentUserProfile}
                    }));
                }

                return loadProfileMetadata(accessTokenContainer);
            }

            return silentlyAuth(true);
        },
        getUserProfile() {
            return {...currentUserProfile};
        },
        /**
         * Returns available tenant feature flags or empty object if `auth` is not resolved
         * @returns {Object}
         */
        getTenantFeatureFlags() {
            return {...tenantFeatureFlags};
        },
        /**
         * Returns available account feature flags or empty object if `auth` is not resolved
         * @returns {Object}
         */
        getAccountFeatureFlags() {
            return {...accountFeatureFlags};
        },
        /**
         * Returns available feature flags, tenant mixed with account
         * @returns {Object}
         */
        getFeatureFlags() {
            return {...tenantFeatureFlags, ...accountFeatureFlags};
        },
        getAuthorizationHeader() {
            return isAuthenticated() ? `Bearer ${accessTokenContainer.accessToken}` : '';
        },
        updateAndActivateInvitedAccount(data, activationCode) {
            const {password, languageTag, ...rest} = data;

            return $http.post('/api/users/profiles/activate', {
                password,
                token: activationCode,
                profile: {
                    language: languageTag,
                    ...rest
                }
            });
        },
        /**
         * Makes a call to the back-end which then calls https://www.google.com/recaptcha/api/siteverify to get a response with a score
         * @param {string} response
         * @returns {Promise}
         */
        getReCaptchaScore({response}) {
            return $http.get('/api/platform/recaptcha', {params: {response}});
        }
    };

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development') {
        instance.__testOnlyInit = (options = {}) => {
            accessTokenContainer = options.accessTokenContainer;
            currentUserProfile = options.currentUserProfile;
            tenantFeatureFlags = options.tenantFeatureFlags;
            accountFeatureFlags = options.accountFeatureFlags;
        };
    }

    return instance;
}
