/**
 * Adds validation icons to inputs with the attribute "evcloud-validation-icons", the input must have a form as parent.
 */
angular.module('everon.component.validation-icons')
       .directive('evcloudValidationIcons', validation);

validation.$inject = ['$compile'];

function validation($compile) {
    return {
        require: '^form',
        restrict: 'A',
        priority: 'terminal',
        link(scope, elem, attrs, ctrl) {
            const name = `${_.includes(attrs.evcloudValidationIcons, '$ctrl.') ? attrs.evcloudValidationIcons : ctrl.$name}.${attrs.name}`;
            const valid = `${name}.$valid ? `;
            const className = `${valid}'fill-success' : 'fill-error'`;
            const iconName = `${valid}'icon-success' : 'icon-error'`;

            const iconElement = angular.element(`<svg class="icon validation xs-hide"><use ng-class="${
                className}" xlink:href ng-href="#{{${iconName}}}"></use></svg>`);

            $compile(iconElement)(scope);
            elem.after(iconElement);
        }
    };
}
