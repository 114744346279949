angular.module('everon.cards')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards', {
        url: '/cards',
        redirectTo: 'auth.cards.list',
        data: {
            id: 'cards-navigation-link',
            title: 'generic.entity.cards',
            navigationType: ['primary'],
            icon: 'icon-cards',
            menuPriority: 2,
            permissions: ['CARD:READ']
        }
    });
}
