angular.module('everon.stations.sites')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.sites', {
        url: '/sites',
        redirectTo: 'auth.stations'
    });
}
