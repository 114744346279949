angular.module('everon.stations.station.details')
       .controller('StationDetailsController', StationDetailsController);

StationDetailsController.$inject = ['$state', 'mediatorService', 'CONSTANTS', 'stationService', 'permissionService', 'sessionService', 'modalService', 'stationFormModel'];

function StationDetailsController($state, mediatorService, CONSTANTS, stationService, permissionService, sessionService, modalService, stationFormModel) {
    const $ctrl = this;
    const formName = 'stationDetailsForm';
    const modals = {};

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            saveInProgress: false,
            deleteInProgress: false,
            hasChanged: false,
            canViewChargePoint: checkPermissions(['STATION:CONTROL_FIRMWARE']),
            canImportStation: checkPermissions(['STATION:IMPORT']),
            canDeactivateStation: checkPermissions(['STATION:DEACTIVATE']),
            isStationStateInactiveLike: false
        };

        $ctrl.regex = {
            identityCode: CONSTANTS.REGEX.STATION_IDENTITY_CODE,
            securityCode: CONSTANTS.REGEX.STATION_SECURITY_CODE,
            articleNumber: CONSTANTS.REGEX.STATION_ARTICLE_NUMBER
        };

        $ctrl.stationStatus = null;
        $ctrl.availableStatuses = ['active', 'deactivated'];
        $ctrl.formModel = extendFormModel(stationFormModel);

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.$onDestroy = () => {
        _.forEach(modals, modalService.destroy);
    };

    $ctrl.onChange = () => {
        $ctrl.state.hasChanged = JSON.stringify($ctrl.model) !== $ctrl.modelCopy;
    };

    /**
     * Triggers station status change confirmation dialog and reacts to it
     */
    $ctrl.toggleStatus = () => {
        modals.deactivateModal.open().then(attemptToDeactivate);
    };

    /**
     * Saves station details
     */
    $ctrl.save = () => {
        const requestPayload = ($ctrl.state.canImportStation && $ctrl.model.status === 'inactive') ?
            _($ctrl.model).pick(['identityCode', 'securityCode', 'manufacturer', 'connector', 'articleNumber'])
                          .assign({connectivityTypes: stationService.getConnectivityTypes($ctrl.model.selectedConnectivityType)})
                          .value() :
            _.pick($ctrl.model, ['reference', 'articleNumber']);

        $ctrl.state.saveInProgress = true;

        stationService.updateStation($ctrl.model.id, requestPayload)
                      .then(response => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success'
                          });

                          $ctrl.model = extendModel(response);
                          $ctrl.modelCopy = JSON.stringify($ctrl.model);
                          $ctrl.state.hasChanged = false;
                          resetFormState();

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [response.reference || response.identityCode, 'current']);
                      })
                      .catch(onRejected)
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    /**
     * Deletes inactive station and redirect to stations list
     */
    $ctrl.delete = () => {
        modals.deleteModal.open().then(attemptDelete);
    };

    /**
     * Discards the changes
     */
    $ctrl.cancel = () => {
        if ($ctrl.model.status === 'inactive') {
            $state.go('auth.stations', null, {reload: 'auth.stations'});
        } else {
            restoreModel();
            resetFormState();
        }
    };

    /**
     * Saves station details changes for inactive station
     */
    $ctrl.editInactiveStationDetails = () => {
        $state.go('auth.stations.station-import-details.edit');
    };

    $ctrl.cancelActivation = () => {
        stationService.cancelPendingStationActivation($ctrl.model.id)
                      .then(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success'
                          });

                          $state.go('auth.stations', null, {reload: 'auth.stations'});
                      })
                      .catch(onRejected);
    };

    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Attempt station delete
     * @param {boolean} canProceed
     */
    function attemptDelete(canProceed) {
        if (canProceed) {
            $ctrl.state.deleteInProgress = true;

            stationService.deleteStation($ctrl.model.id)
                          .then(() => {
                              mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                  type: 'success'
                              });

                              $state.go('auth.stations', null, {reload: 'auth.stations'});
                          })
                          .catch(onRejected)
                          .finally(() => {
                              $ctrl.state.deleteInProgress = false;
                          });
        }
    }

    /**
     * Attempt station status change
     * @param {boolean} canProceed
     */
    function attemptToDeactivate(canProceed) {
        if (canProceed) {
            attemptDelete(true);
        } else {
            $ctrl.stationStatus = $ctrl.model.status;
        }
    }

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => {
            item.id = _.kebabCase(item.name);

            return item;
        });
    }

    /**
     * Resets the form to untouched pristine state
     */
    function resetFormState() {
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
    }

    /**
     * Reverts changes to model
     */
    function restoreModel() {
        $ctrl.model = JSON.parse($ctrl.modelCopy);
    }

    /**
     * Extends model with connectivity types
     * @param {Object} model
     * @returns {Object}
     */
    function extendModel(model) {
        return _.assign({}, model, {
            connectorSettings: {
                cards: stationService.mapConnectors(model.connectorSpecs),
                partial: 'modules/stations/station-import-details-form/connectors/connector-setup-card-content-partial.html'
            },
            selectedConnectivityType: _.find(stationService.extendConnectivityTypes(model.connectivityTypes), 'enabled')
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({station, chargePoint}) {
        $ctrl.model = extendModel(station);
        $ctrl.stationStatus = $ctrl.model.status;
        $ctrl.state.isStationStateInactiveLike = ['inactive', 'pending'].includes($ctrl.model.status);

        $ctrl.chargePoint = chargePoint;
        $ctrl.modelCopy = JSON.stringify($ctrl.model);

        if ($ctrl.stationStatus === 'active') {
            modalService.register({
                templateUrl: 'modules/stations/station/details/station-status-change-modal/station-status-change-modal.html',
                controller: 'StationStatusChangeModalCtrl',
                data: {
                    title: 'stations.station.details.form.heading.deactivate',
                    paragraph: 'stations.station.details.form.heading.deactivateConfirmation',
                    button: {
                        ok: 'generic.action.deactivate'
                    }
                }
            }).then(modalInstance => {
                modals.deactivateModal = modalInstance;
            });
        } else {
            modalService.register({
                templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
                controller: 'ConfirmationModalDialogController',
                data: {
                    title: 'stations.station.details.form.heading.delete',
                    paragraph: 'stations.station.details.form.heading.deleteConfirmation',
                    button: {
                        ok: 'generic.action.delete'
                    }
                }
            }).then(modalInstance => {
                modals.deleteModal = modalInstance;
            });
        }

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
