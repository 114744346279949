angular.module('everon.stations.station.details')
       .controller('StationStatusChangeModalCtrl', StationStatusChangeModalCtrl);

StationStatusChangeModalCtrl.$inject = ['close', 'data'];

function StationStatusChangeModalCtrl(close, data) {
    const $ctrl = this;

    $ctrl.confirmationChecks = [
        {
            name: 'item1',
            selected: false
        },
        {
            name: 'item2',
            selected: false
        },
        {
            name: 'item3',
            selected: false
        }
    ];

    $ctrl.data = data;

    $ctrl.confirm = () => {
        close(true);
    };

    $ctrl.cancel = () => {
        $ctrl.confirmationChecks.forEach(item => {
            item.selected = false;
        });
        close(false);
    };
}
