import './account-type-partial.html';
import './billing-address-partial.html';
import './billing-plan.html';
import './business-details-partial.html';
import './card-import-partial.html';
import './change-history-partial.html';
import './charging-profile-static-day-schedules-partial.html';
import './plan-basic-info-partial.html';
import './plan-eligibility-partial.html';
import './plan-pricing-partial.html';
import './product-basic-info-partial.html';
import './product-charging-partial.html';
import './purchase-order-number.html';
import './search-help.html';
import './single-button-modal-dialog.html';
import './site-details-partial.html';
import './station-import-partial.html';
