angular.module('everon')
       .factory('markerClusterer', markerClusterer);

markerClusterer.$inject = ['mediatorService', 'CONSTANTS', 'utils'];

function markerClusterer(mediatorService, CONSTANTS, utils) {
    /**
     * Creates new instance of MarkerClusterer
     * @param {google.maps.Map} map
     * @param {google.maps} mapsApi
     * @returns {MarkerClusterer}
     */
    function createClusterer(map, mapsApi) {
        class MarkerClusterer extends mapsApi.OverlayView {
            /**
             * A marker clusterer constructor
             * @param {google.maps.Map} map
             * @param {google.maps} mapsApi
             */
            constructor(map, mapsApi) {
                super();

                this.map = map;
                this.mapsApi = mapsApi;
                this.markers = [];
                this.clusters = [];

                this.setMap(map);
            }

            /**
             * Adds an array of clusters to the clusterer
             * @param {Array} clusters
             * @returns {Object.<MarkerClusterer>}
             */
            addClusters(clusters) {
                if (!clusters.length) {
                    return this;
                }

                clusters.forEach(cluster => this.addCluster(cluster));

                return this;
            }

            /**
             * Adds a cluster to the clusterer
             * @param {Object} cluster
             */
            addCluster(cluster) {
                this.clusters.push(cluster);
            }

            /**
             * Adds an array of markers to the clusterer
             * @param {Array.<google.maps.Marker>} markers
             * @returns {Object.<MarkerClusterer>}
             */
            addMarkers(markers) {
                if (!markers.length) {
                    return this;
                }

                markers.forEach(marker => this.addMarker(marker));

                return this;
            }

            /**
             * Adds a marker to the clusterer and redraws if needed
             * @param {google.maps.Marker} marker
             * @returns {Object.<MarkerClusterer>}
             */
            addMarker(marker) {
                this.markers.push(marker);

                return this;
            }

            /**
             * Returns a marker by id
             * @param {string} id
             * @returns {Marker}
             */
            getMarker(id) {
                return _.find(this.markers, {id}, null);
            }

            /**
             * Removes a marker from the clusterer
             * @param {google.maps.Marker} marker
             * @returns {Object.<MarkerClusterer>}
             */
            removeMarker(marker) {
                _.pull(this.markers, marker);
                marker.setMap(null);

                return this;
            }

            /**
             * Renders clusters and markers on the map. Offsets duplicate marker coordinates if needed. For performance reasons, we only check for duplicates if there are less than 20 markers
             */
            render() {
                const offsetFactor = 100000;

                this.clusters.forEach(cluster => {
                    cluster.setMap(this.map);
                });

                this.markers.forEach((marker, index) => {
                    let lat = marker.getPosition().lat();
                    let lng = marker.getPosition().lng();

                    if (this.markers.length < 20 && this.isMarkerPositionEqual({lat, lng}, this.markers.slice(index + 1))) {
                        lat = lat + (Math.abs(Math.random()) / (offsetFactor + this.markers.length));
                        lng = lng + (Math.abs(Math.random()) / (offsetFactor + this.markers.length));

                        marker.setPosition({lat, lng});
                    }

                    marker.setMap(this.map);
                });
            }

            /**
             * Renders a single marker on the map
             * @param {google.maps.Marker} marker
             */
            renderMarker(marker) {
                marker.setMap(this.map);
            }

            /**
             * Clears all markers and clusters and resets the viewport
             * @returns {Object.<MarkerClusterer>}
             */
            clearMarkers() {
                this.resetViewport();
                this.resetMarkerState();
                this.markers.length = 0;
                this.clusters.length = 0;

                return this;
            }

            /**
             * Resets viewport removing any clusters and individual markers on the map
             * @returns {Object.<MarkerClusterer>}
             */
            resetViewport() {
                this.clusters.forEach(cluster => {
                    cluster.setMap(null);
                });

                this.markers.forEach(marker => {
                    marker.setMap(null);
                });

                return this;
            }

            /**
             * Divides station count by 10 until the right size for the icon is determined. The end result is then multiplied by 10 and `viewBoxSizeIncrement` is added. To change the size of
             * a cluster icon, adjust `viewBoxSizeIncrement` variable.
             * @param {number} stationCount
             * @returns {number}
             */
            getIconSize(stationCount) {
                const viewBoxSizeIncrement = 20;
                let quotient = stationCount;
                let size = 0;

                while (quotient !== 0) {
                    quotient = parseInt(quotient / 10, 10);
                    size += 1;
                }

                return Math.min(size, 5) * 10 + viewBoxSizeIncrement;
            }

            /**
             * Returns correct label size for a given size icon
             * @param {number} iconSize
             * @returns {string}
             */
            getIconLabelSize(iconSize) {
                return `${Math.max(iconSize / 70, 0.7)}rem`;
            }

            /**
             * Returns formatted location count. If `count < 1000`, no formatting is applied, otherwise thousands are expressed as k values with a max of 1 decimal point
             * @param {number} count
             * @returns {string}
             */
            getFormattedLocationCount(count) {
                const shouldFormat = count >= 1000;
                const number = shouldFormat ? parseFloat(count / 1000) : count;

                return shouldFormat ? `${utils.roundToDecimalPrecision(number, 1)}k` : number.toString();
            }

            /**
             * Zooms in one level and centers the map on the clicked cluster location
             * @param {google.maps.Marker} marker
             */
            zoomInAndCenterMap(marker) {
                this.map.setCenter(marker.latLng);
                this.map.setZoom(this.map.getZoom() + 1);
            }

            /**
             * Resets z-index and removes event subscriptions on all markers
             * @returns {Object.<MarkerClusterer>}
             */
            resetMarkerState() {
                this.markers.forEach(marker => marker.unsubscribe().setZIndex(1));

                return this;
            }

            /**
             * Extends a bounds object by the grid size
             * @param {google.maps.LatLngBounds} bounds
             * @returns {google.maps.LatLngBounds}
             */
            getExtendedBounds(bounds) {
                const projection = this.getProjection();

                // Turn the bounds into latlng.
                const topRight = new this.mapsApi.LatLng(bounds.getNorthEast().lat(), bounds.getNorthEast().lng());
                const bottomLeft = new this.mapsApi.LatLng(bounds.getSouthWest().lat(), bounds.getSouthWest().lng());

                // Convert the points to pixels and the extend out by the grid size.
                const topRightPixels = projection.fromLatLngToDivPixel(topRight);

                topRightPixels.x += 5;
                topRightPixels.y -= 5;

                const bottomLeftPixels = projection.fromLatLngToDivPixel(bottomLeft);

                bottomLeftPixels.x -= 5;
                bottomLeftPixels.y += 5;

                // Convert the pixel points back to LatLng
                const northEast = projection.fromDivPixelToLatLng(topRightPixels);
                const southWest = projection.fromDivPixelToLatLng(bottomLeftPixels);

                // Extend the bounds to contain the new bounds
                bounds.extend(northEast);
                bounds.extend(southWest);

                return bounds;
            }

            /**
             * Checks whether provided position coordinates are the same as any of the marker's coordinates
             * @param {google.maps.LatLng} {lat, lng}
             * @param {Array.<google.maps.Marker>} markers
             * @returns {boolean}
             */
            isMarkerPositionEqual({lat, lng}, markers) {
                return markers.some(marker => {
                    const latitude = marker.getPosition().lat();
                    const longitude = marker.getPosition().lng();

                    return lat === latitude && lng === longitude;
                });
            }
        }

        return new MarkerClusterer(map, mapsApi);
    }

    /**
     * Creates new instance of Marker
     * @param {string} id
     * @param {Object} [options]
     * @param {google.maps} mapsApi
     * @returns {Marker}
     */
    function createMarker(id, options, mapsApi) {
        class Marker extends mapsApi.Marker {
            constructor(id, options) {
                super(options);

                this.id = id;
                this.unsubscribeFn = null;
            }

            /**
             * Updates marker's z-index and icon size to the width and height provided or the default size otherwise
             * @param {number} [width = 33]
             * @param {number} [height = 45]
             * @param {number} [zIndex = 1]
             * @returns {Object.<Marker>}
             */
            resizeIcon(width = 33, height = 45, zIndex = 1) {
                this.setIcon({
                    url: this.icon.url,
                    scaledSize: {
                        width,
                        height,
                        widthUnit: '%',
                        heightUnit: '%'
                    }
                });
                this.setZIndex(zIndex);

                return this;
            }

            /**
             * Subscribes to marker resize event
             * @returns {Marker}
             */
            subscribeToResizeEvent() {
                this.unsubscribeFn = mediatorService.subscribe(`${CONSTANTS.EVENTS.ACTION.RESIZE_MARKER}_${this.id}`, () => this.resizeIcon());

                return this;
            }

            /**
             * Unsubscribes from marker resize event
             * @returns {Marker}
             */
            unsubscribe() {
                if (_.isFunction(this.unsubscribeFn)) {
                    this.unsubscribeFn();
                }

                return this;
            }
        }

        return new Marker(id, options);
    }

    return {
        createClusterer,
        createMarker
    };
}
