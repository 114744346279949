angular.module('everon.tenant-settings.countries')
       .controller('CountriesController', CountriesController);

CountriesController.$inject = ['CONSTANTS', 'mediatorService', 'stateHelper', 'tenantCountryService'];

function CountriesController(CONSTANTS, mediatorService, stateHelper, tenantCountryService) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({sort: setSortingParams});

        $ctrl.state = {
            dataLoaded: false,
            hasCompanies: false
        };

        $ctrl.noResultsConfig = {
            countries: {
                heading: 'tenantSettings.countries.noResults.countries.heading',
                paragraph: 'tenantSettings.countries.noResults.countries.paragraph',
                links: [{
                    state: 'auth.tenant-settings.countries.country-add',
                    text: 'tenantSettings.countries.action.add'
                }]
            },
            companies: {
                icon: 'empty-state-configuration',
                heading: 'generic.noResults.setupIncomplete',
                paragraph: 'tenantSettings.countries.noResults.companies.paragraph',
                links: [{
                    state: 'auth.tenant-settings.companies.company-add',
                    text: 'tenantSettings.legalEntities.action.add'
                }]
            }
        };

        $ctrl.columns = [
            {
                property: 'name',
                translationKey: 'generic.entity.country',
                sortable: true,
                cssClass: 'col-5'
            },
            {
                property: 'company',
                translationKey: 'generic.label.companyName',
                sortable: true,
                cssClass: 'col-5'
            }
        ];

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Set sorting params from the state and re-orders transactions accordingly
     */
    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
        $ctrl.countries = tenantCountryService.orderBy($ctrl.countries, $ctrl.sortingParams);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({countries, companies, hasCompanies, hasAvailableCountries}) {
        $ctrl.countries = countries;
        $ctrl.companies = companies;
        $ctrl.state.hasCompanies = Boolean(hasCompanies);
        setSortingParams();

        if ($ctrl.state.hasCompanies && hasAvailableCountries) {
            $ctrl.actions = [
                {
                    name: 'tenantSettings.countries.action.add',
                    state: 'auth.tenant-settings.countries.country-add'
                }
            ];
        }

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
