angular.module('everon.stations.station-import-details-form.basic-info')
       .controller('StationImportDetailsFormBasicInfoController', StationImportDetailsFormBasicInfoController);

StationImportDetailsFormBasicInfoController.$inject = ['stationService', 'CONSTANTS', 'mediatorService', '$state', 'stationFormModel'];

function StationImportDetailsFormBasicInfoController(stationService, CONSTANTS, mediatorService, $state, stationFormModel) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.regex = {
            identityCode: CONSTANTS.REGEX.STATION_IDENTITY_CODE,
            securityCode: CONSTANTS.REGEX.STATION_SECURITY_CODE,
            articleNumber: CONSTANTS.REGEX.STATION_ARTICLE_NUMBER
        };

        $ctrl.formModel = extendFormModel(stationFormModel);
        $ctrl.model = _.assign({}, _.pick(stationService.getStationCapabilities(), ['connectivityTypes', 'selectedConnectivityType']), $ctrl.stationImportCtrl.payload.basicInfo || {});
        $ctrl.modelCopy = JSON.stringify($ctrl.model);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent']);
    };

    $ctrl.next = () => {
        $ctrl.stationImportCtrl.payload.basicInfo = $ctrl.model;
        $ctrl.stationImportCtrl.next(getModelPayload($ctrl.model));
    };

    /**
     * Returns model payload to be stored on the parent controller
     * @param {Object} model
     * @returns {Object}
     */
    function getModelPayload(model) {
        return _.pick(model, ['articleNumber', 'identityCode', 'manufacturer', 'securityCode']);
    }

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => {
            item.id = _.kebabCase(item.name);

            return item;
        });
    }
}
