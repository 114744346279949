angular.module('everon.stations.station.charging-features')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.charging-features', {
        url: '/charging-features',
        data: {
            id: 'station-charging-features-navigation-link',
            title: 'generic.title.chargingFeatures',
            navigationType: ['context'],
            menuPriority: 55,
            permissions: ['STATION:UPDATE']
        },
        resolve: {
            data: ['$q', 'stationService', 'accountService', 'subscriptionService', 'auth', 'data', 'permissionService',
                ($q, stationService, accountService, subscriptionService, auth, data, permissionService) => {
                    const isPublicChargingEnabledPromise = stationService.isPublicChargingEnabled(data.id);
                    const isAccountOwner = auth.profile.accountId === data.accountId;
                    const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), auth.profile.permissions);

                    return $q.all({
                        station: stationService.getStation(data.id),
                        isPublicChargingEnabled: isPublicChargingEnabledPromise,
                        stationTariffs: isPublicChargingEnabledPromise.then(isEnabled => isEnabled ? stationService.getStationTariffs(data.id) : {}),
                        subscription: subscriptionService.getStationSubscription(data.id),
                        isAccountOwner,
                        reimbursementInfo: (checkPermissions(['ACCOUNT:READ']) && isAccountOwner) || checkPermissions(['ACCOUNT:READ_ALL']) ?
                            accountService.getReimbursementSettings(data.accountId) :
                            null
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-charging-features data-data-promise="::$resolve.data" />'
            }
        }
    });
}
