angular.module('everon.dashboard')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.dashboard', {
        url: '/dashboard',
        data: {
            id: 'dashboard-navigation-link',
            title: 'dashboard.title',
            navigationType: ['primary'],
            icon: 'icon-dashboard',
            menuPriority: 1
        },
        resolve: {
            profile: ['sessionService', sessionService => sessionService.getUserProfile()],
            data: ['dashboardService', dashboardService => dashboardService.getDashboardConfig()]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-dashboard data-data-promise="::$resolve.data" class="flex-auto" profile="::$resolve.profile" />'
            }
        }
    });
}
