angular.module('everon.welcome')
       .controller('WelcomeLanguageDetailsController', WelcomeLanguageDetailsController);

WelcomeLanguageDetailsController.$inject = ['$state', '$translate', 'profileService', 'mediatorService', 'CONSTANTS'];

function WelcomeLanguageDetailsController($state, $translate, profileService, mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            saveInProgress: false
        };

        $ctrl.welcomeCtrl.currentStateIndex = _.findIndex($ctrl.welcomeCtrl.states, {name: $state.current.name});

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Immediately updates language on the UI
     */
    $ctrl.setLanguage = () => {
        $translate.use($ctrl.languageTag);
    };

    $ctrl.next = () => {
        $ctrl.state.saveInProgress = true;

        profileService.updateProfile({languageTag: $ctrl.languageTag}, $ctrl.profile.oktaId)
                      .then($ctrl.welcomeCtrl.next)
                      .catch(response => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              isPersistent: true,
                              messageKey: response.status ? CONSTANTS.ERROR_CODES[response.status] : CONSTANTS.ERROR_CODES[408]
                          });
                      })
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.profile = data.profile;
        $ctrl.languages = data.languages;
        $ctrl.languageTag = data.profile.language.tag;

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
