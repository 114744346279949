angular.module('everon.stations.sites.site.smatch-integration')
       .controller('SiteSmatchIntegrationController', SiteSmatchIntegrationController);

SiteSmatchIntegrationController.$inject = ['stationService', 'mediatorService', 'utils', 'CONSTANTS'];

function SiteSmatchIntegrationController(stationService, mediatorService, utils, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            syncInProgress: false,
            savingSettingsInProgress: false,
            settingsHasChanged: false,
            dataLoaded: false
        };

        $ctrl.settingsModelCopy = JSON.stringify({});
        $ctrl.availableSmatchApis = [
            {
                id: 'test',
                name: 'Test environment',
                url: 'https://api-test.mylaborelec.com'
            },
            {
                id: 'production',
                name: 'Prod environment',
                url: 'https://api.mylaborelec.com'
            }
        ];

        $ctrl.columns = [
            {
                property: 'identityCode',
                translationKey: 'generic.entity.station',
                desc: false,
                sortable: false,
                cssClass: 'col-3'
            },
            {
                property: 'reference',
                translationKey: 'generic.label.reference',
                desc: false,
                sortable: false,
                cssClass: 'col-6'
            },
            {
                property: 'clusterId',
                translationKey: 'sites.site.smatchIntegration.list.label.clusterId',
                desc: false,
                sortable: false,
                cssClass: 'col-3'
            }
        ];

        $ctrl.dataPromise
             .then(onDataLoaded);
    };

    /**
     * Gets Smatch redirect link from server and opens it in a new tab
     */
    $ctrl.getSmatchRedirectLink = () => {
        $ctrl.state.syncInProgress = true;

        stationService.getSmatchRedirectLink($ctrl.model.id)
                      .then(response => {
                          utils.openExternalLink(response.redirectLink);
                      }).catch(error => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: CONSTANTS.ERROR_CODES[error.status],
                              isPersistent: true
                          });
                      })
                      .finally(() => {
                          $ctrl.state.syncInProgress = false;
                      });
    };

    /**
     * Invokes service method to save the Smatch settings
     */
    $ctrl.saveSettings = () => {
        $ctrl.state.savingSettingsInProgress = true;

        stationService.updateSmatchSettings($ctrl.model.id, $ctrl.model.settings)
                      .then(() => {
                          $ctrl.settingsModelCopy = JSON.stringify($ctrl.model.settings);

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              messageKey: 'sites.site.smatchIntegration.notification.updateSettingsSuccess'
                          });
                      })
                      .catch(response => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: CONSTANTS.ERROR_CODES[response.status]
                          });
                      })
                      .finally(() => {
                          $ctrl.state.savingSettingsInProgress = false;
                          $ctrl.onSettingsChange();
                      });
    };

    /**
     * Updates settingsHasChanged state property
     */
    $ctrl.onSettingsChange = () => {
        $ctrl.state.settingsHasChanged = JSON.stringify($ctrl.model.settings) !== $ctrl.settingsModelCopy;
    };

    /**
     * Updates state and makes data available to the template
     * @param {Object} site
     */
    function onDataLoaded(site) {
        $ctrl.model = site;
        $ctrl.model.stations = _.assign([], site.stations).sort((a, b) => a.identityCode.localeCompare(b.identityCode));
        $ctrl.model.settings = {};

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [$ctrl.model.name, 'current']);
    }
}
