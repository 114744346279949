angular.module('everon.accounts')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    function canReadPersonalInfo(userPermissions, {$injector}) {
        const $location = $injector.get('$location');

        return userPermissions.includes('ACCOUNT:READ_PERSONAL_INFO') || /.+\/(overview|smatch|reimbursement)/.test($location.url());
    }

    $stateProvider.state('auth.accounts', {
        url: '/accounts?type,country,sort,page,size',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'accounts-navigation-link',
            title: 'generic.entity.accounts',
            navigationType: ['primary'],
            icon: 'icon-accounts',
            menuPriority: 5,
            permissions: ['ACCOUNT:READ_ALL', canReadPersonalInfo],
            visibleInMenu: (_, {$injector}) => {
                const sessionService = $injector.get('sessionService');

                return !sessionService.getUserProfile().roles.includes('supportAgent');
            }
        },
        resolve: {
            data: ['$q', 'accountService', 'countryService', '$transition$', ($q, accountService, countryService, $transition$) => {
                const params = _.pick($transition$.params('to'), ['type', 'country', 'sort', 'page', 'size']);

                return $q.all({
                    accounts: accountService.getAccounts(params),
                    countries: countryService.getTenantCountries()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-accounts data-data-promise="::$resolve.data" class="flex-auto flex flex-column" />'
            }
        }
    });
}
