angular.module('everon.component.data-list')
       .component('evcloudDataList', {
           templateUrl: 'components/data-list/data-list.html',
           controller: 'DataListController',
           bindings: {
               columns: '<',
               onChange: '&?',
               sortingParams: '<?'
           }
       });
