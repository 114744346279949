angular.module('everon.stations.station-add.station-address')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add.station-address', {
        data: {
            title: 'generic.title.location',
            order: 1
        },
        resolve: {
            data: ['$q', 'countryService', 'accountService', 'stationLocationFormModel', 'mapService', ($q, countryService, accountService, stationLocationFormModel, mapService) => {
                return $q.all({
                    countries: countryService.getTenantCountries(),
                    accountFeatures: accountService.getFeatureFlags(),
                    stationLocationForm: stationLocationFormModel,
                    mapsApi: mapService.loadMapApi()
                });
            }]
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'stations/station-add/station-address' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.stations.station-add.station-address');
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station-add': {
                component: 'evcloudStationAddAddress',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
