import {structureContent} from '../utils';

const URL_PARAMS = ['size', 'page', 'query'];
const FILTER_PARAMS = ['account', 'status'];
const FILTER_PARAMS_TRANSFORM = {account: 'accountId'};

export default function stationsActions($ng) {
    const stationService = $ng('stationService');
    const stateHelper = $ng('stateHelper');

    return {
        setupStationActions({dispatch, commit}) {
            dispatch('registerParamChangeListener', {
                action: 'getStations',
                filter: [...URL_PARAMS, ...FILTER_PARAMS],
                immediate: true
            });

            commit('updateRemovableUrlParams', URL_PARAMS);
        },

        async getStations({commit, dispatch, state}) {
            commit('fetchingStations', true);

            const {view, ...urlParams} = stateHelper.getStateParams([...URL_PARAMS, ...FILTER_PARAMS, 'view']);

            if (urlParams.status === 'all') {
                delete urlParams.status;
            }

            const apiRequestParams = transformFiltersInUrlToApiRequestParams(urlParams, FILTER_PARAMS_TRANSFORM);

            try {
                const {stations, pagination} = await stationService.getStationsByType(view, apiRequestParams);

                commit('stationsLoadErrorReceived', null);
                commit('update', ['stations', structureContent(stations)]);
                commit('update', ['pagination', {...state.pagination, ...pagination}]);
                commit('statusFilterChanged', urlParams.status);
            } catch (error) {
                commit('stationsLoadErrorReceived', error);
            } finally {
                commit('fetchingStations', false);
            }
        },

        changePagination({dispatch}, params = {}) {
            dispatch('updateUrlParams', params);
        },

        cleanStationActions({dispatch, commit}) {
            dispatch('unregisterParamChangeListener', 'getStations');
            commit('update', ['stations', []]);
            commit('update', ['pagination', {}]);
            commit('statusFilterChanged', null);
        }
    };
}

function transformFiltersInUrlToApiRequestParams(urlParams, paramsToTransform) {
    return Object.entries(urlParams).reduce((apiParams, [paramName, paramValue]) => {
        if (paramValue === undefined) {
            return apiParams;
        }

        if (paramName in paramsToTransform) {
            apiParams[paramsToTransform[paramName]] = paramValue?.value;
        } else {
            apiParams[paramName] = paramValue;
        }

        return apiParams;
    }, {});
}
