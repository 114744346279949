angular.module('everon.users.system-users')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users', {
        url: '/system-users',
        redirectTo: 'auth.users.system-users.system-users',
        data: {
            id: 'system-users-navigation-link',
            title: 'generic.entity.systemUsers',
            navigationType: ['secondary'],
            menuPriority: 2,
            permissions: ['SYSTEM_USER:READ_ALL'],
            userType: 'system-users'
        },
        views: {
            'main@auth': {
                template: '<evcloud-system-users class="flex-auto flex flex-column col-12" /></div>'
            },
            'contextNav@auth.users.system-users': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.users.system-users\'}"  />'
            }
        }
    });
}
