angular.module('everon.roaming.connections.connect-partner.token-url')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.connections.connect-partner.token-url', {
        data: {
            title: 'roaming.connections.connectPartner.steps.1.heading',
            order: 1
        },
        views: {
            'content@auth.roaming.connections.connect-partner': {
                component: 'evcloudRoamingConnectPartnerTokenUrl'
            }
        }
    });
}
