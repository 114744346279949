angular.module('everon.cards.card.overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card.overview', {
        url: '/overview',
        data: {
            id: 'card-overview-navigation-link',
            title: 'generic.title.overview',
            navigationType: ['context'],
            menuPriority: 1
        },
        resolve: {
            data: ['$q', 'data', 'cardService', 'subscriptionService', ($q, {id}, cardService, subscriptionService) => {
                return $q.all({
                    card: cardService.getCard(id),
                    subscription: subscriptionService.getCardSubscription(id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.cards.card': {
                template: '<evcloud-card-overview data-data-promise="::$resolve.data" />'
            }
        }
    });
}
