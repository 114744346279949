angular.module('everon')
       .value('brandColorModel', [
           {
               name: 'brand'
           },
           {
               name: 'brand-light-1',
               shade: 5
           },
           {
               name: 'brand-light-2',
               shade: 15
           },
           {
               name: 'brand-light-3',
               shade: 30
           },
           {
               name: 'brand-dark',
               shade: -5
           },
           {
               name: 'brand-contrast'
           }
       ]);
