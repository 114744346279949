angular.module('everon.cards.card.details')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card.details', {
        url: '/details',
        data: {
            id: 'card-details-navigation-link',
            title: 'generic.title.details',
            navigationType: ['context'],
            menuPriority: 2,
            permissions: ['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']
        },
        resolve: {
            data: ['$q', '$transition$', 'cardService', 'tenantSettingsService', ($q, $transition$, cardService, tenantSettingsService) => {
                return $q.all({
                    card: cardService.getCard($transition$.params('to').cardId),
                    tenantFeatureFlags: tenantSettingsService.getFeatureFlags()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.cards.card': {
                template: '<evcloud-card-details data-data-promise="::$resolve.data" />'
            }
        }
    });
}
