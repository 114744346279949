/**
 *  Implementation of ClickOutside
 *
 *  A click event listener is registered in document.body.
 *  The Handler then checks if the event target is outside the directive trigger, if true the passed callback will be called.
 *
 * @example
 *   Usage:
 *   <div evcloud-click-outside="vm.myMethod">
 *
 *   </div>
 *
 *   OR
 *
 *   <div evcloud-click-outside="vm.mymodel = myvalue">
 *
 *   </div>
 */

angular.module('everon.component.click-outside')
       .directive('evcloudClickOutside', clickOutside);

clickOutside.$inject = ['$window'];

function clickOutside($window) {
    return {
        restrict: 'A',
        scope: {
            evcloudClickOutside: '&'
        },
        link(scope, element) {
            angular.element($window.document.body).on('click touchstart', event => {
                const contains = element[0].contains(event.target);

                if (!contains) {
                    scope.$applyAsync(scope.evcloudClickOutside);
                }
            });
        }
    };
}
