angular.module('everon.stations.station-import-details-form')
       .factory('stationFormModel', stationFormModel);

stationFormModel.$inject = ['CONSTANTS'];

function stationFormModel(CONSTANTS) {
    return {
        station: [
            {
                name: 'identityCode',
                type: 'text',
                placeholder: 'generic.placeholder.stationIdentityCode',
                helpText: 'generic.helpText.stationIdentityCode',
                pattern: CONSTANTS.REGEX.STATION_IDENTITY_CODE,
                maxlength: 60,
                error: 'stations.error.identityCode',
                cssClass: 'sm-col-10',
                required: true
            },
            {
                name: 'securityCode',
                type: 'text',
                helpText: 'generic.helpText.securityCode',
                pattern: CONSTANTS.REGEX.STATION_SECURITY_CODE,
                maxlength: 36,
                error: 'stations.station.error.securityCode',
                cssClass: 'sm-col-10',
                required: true
            },
            {
                name: 'manufacturer',
                type: 'text',
                maxlength: 255,
                cssClass: 'sm-col-10',
                required: true
            }
        ],
        connector: [
            {
                name: 'voltage',
                type: 'number',
                min: 200,
                max: 1000,
                step: 1,
                required: true
            },
            {
                name: 'amperage',
                type: 'number',
                min: 16,
                max: 500,
                step: 1,
                required: true
            }
        ]
    };
}
