angular.module('everon.stations.station', [
    'everon.stations.station.overview',
    'everon.stations.station.details',
    'everon.stations.station.location',
    'everon.stations.station.billing-plan',
    'everon.stations.station.charging-features',
    'everon.stations.station.charging-profile',
    'everon.stations.station.transactions',
    'everon.stations.station.logs',
    'everon.stations.station.assistance',
    'everon.stations.station.firmware',
    'everon.stations.station.terminal',
    'everon.stations.station.configuration'
]);
