angular.module('everon')
       .factory('localisationService', localisationService);

localisationService.$inject = ['$locale', '$translate', 'moment', 'CONSTANTS'];

function localisationService($locale, $translate, moment, CONSTANTS) {
    /**
     * Returns localised array of week day names
     * @returns {Array}
     */
    function getDays() {
        return CONSTANTS.DATES.DAYS.map(day => $translate.instant(`generic.datetime.days.${day}`));
    }

    /**
     * Returns localised array of month names
     * @returns {Array}
     */
    function getMonths() {
        return CONSTANTS.DATES.MONTHS.map(month => $translate.instant(`generic.datetime.months.${month}`));
    }

    /**
     * Returns localised array of short week day names
     * @returns {Array}
     */
    function getShortDays() {
        return CONSTANTS.DATES.DAYS.map(day => $translate.instant(`generic.datetime.shortDays.${day}`));
    }

    /**
     * Returns localised array of short month names
     * @returns {Array}
     */
    function getShortMonths() {
        return CONSTANTS.DATES.MONTHS.map(month => $translate.instant(`generic.datetime.shortMonths.${month}`));
    }

    /**
     * Sets date and time formats to be used with date filter
     */
    function setDateTimeFormats() {
        $locale.DATETIME_FORMATS.time = CONSTANTS.DATES.DATETIME_FORMATS.TIME;
        $locale.DATETIME_FORMATS.timeSeconds = CONSTANTS.DATES.DATETIME_FORMATS.TIME_SECONDS;
        $locale.DATETIME_FORMATS.longMonth = CONSTANTS.DATES.DATETIME_FORMATS.LONG_MONTH;
        $locale.DATETIME_FORMATS.longMonthYear = CONSTANTS.DATES.DATETIME_FORMATS.LONG_MONTH_YEAR;
        $locale.DATETIME_FORMATS.longDateFullMonth = CONSTANTS.DATES.DATETIME_FORMATS.LONG_DATE_FULL_MONTH;
        $locale.DATETIME_FORMATS.longDateTime = CONSTANTS.DATES.DATETIME_FORMATS.LONG_DATE_TIME;
        $locale.DATETIME_FORMATS.fullDateTime = CONSTANTS.DATES.DATETIME_FORMATS.LONG_DATE_FULL_TIME;
        $locale.DATETIME_FORMATS.longDateTimeWithSeconds = CONSTANTS.DATES.DATETIME_FORMATS.LONG_DATE_TIME_SECONDS;
        $locale.DATETIME_FORMATS.weekDayMonthDay = CONSTANTS.DATES.DATETIME_FORMATS.WEEK_DAY_MONTH_DAY;
        $locale.DATETIME_FORMATS.weekDayMonthDayTime = CONSTANTS.DATES.DATETIME_FORMATS.WEEK_DAY_MONTH_DAY_TIME;
        $locale.DATETIME_FORMATS.weekDayShortMonthDayTime = CONSTANTS.DATES.DATETIME_FORMATS.WEEK_DAY_SHORT_MONTH_DAY_TIME;
        $locale.DATETIME_FORMATS.weekDayShortFullTime = CONSTANTS.DATES.DATETIME_FORMATS.WEEK_DAY_SHORT_FULL_TIME;

        // Default date format, used by date filter if no other format is specified
        $locale.DATETIME_FORMATS.mediumDate = CONSTANTS.DATES.DATETIME_FORMATS.DEFAULT_DATE;
    }

    /**
     * Resolves correct decimal and group separators for a given locale. We always append a fallback locale 'en-GB', just in case the provided one is not available.
     * @param {boolean} [isDecimal]
     * @returns {string}
     */
    function resolveSeparator(isDecimal) {
        return new Intl.NumberFormat([navigator.language, 'en-GB']).format(10000.11).charAt(isDecimal ? 6 : 2);
    }

    return {
        /**
         * Sets locale options based on provided language `tag` settings
         * @param {string} tag
         */
        localise(tag) {
            setDateTimeFormats();
            this.setLanguage(tag);
            this.localiseCurrency();
            this.localiseDateTime();
        },

        /**
         * Sets language id in `$locale` service and moment.locale
         * @param {string} languageTag
         * @returns {Object.<localisationService>}
         */
        setLanguage(languageTag) {
            $locale.id = languageTag;
            $locale.localeID = languageTag.replace('-', '_');

            if (!_.includes(moment.locales(), languageTag.substring(0, 2))) {
                throw new Error(`Moment.js locale file "${languageTag.substring(0, 2)}.js" for ${languageTag} is missing`);
            }

            moment.locale(languageTag);

            return this;
        },

        /**
         * Returns current user profile's language tag
         * @returns {string}
         */
        getLanguageTag() {
            return $locale.id;
        },

        /**
         * Localises decimal and group separator formatting based on browser's locale
         * @returns {Object.<localisationService>}
         */
        localiseCurrency() {
            $locale.NUMBER_FORMATS.DECIMAL_SEP = resolveSeparator(true);
            $locale.NUMBER_FORMATS.GROUP_SEP = resolveSeparator();

            return this;
        },

        /**
         * Localises date and time using $translate service
         * @returns {Object.<localisationService>}
         */
        localiseDateTime() {
            $locale.DATETIME_FORMATS.DAY = getDays();
            $locale.DATETIME_FORMATS.MONTH = getMonths();
            $locale.DATETIME_FORMATS.SHORTDAY = getShortDays();
            $locale.DATETIME_FORMATS.SHORTMONTH = getShortMonths();

            return this;
        },

        getDatesLocalisation() {
            return {
                months: $locale.DATETIME_FORMATS.MONTH,
                days: _.range(7).map((value, index) => {
                    const dayIndex = (index + CONSTANTS.DATES.FIRST_DAY_OF_WEEK) % 7;

                    return {
                        fullName: $locale.DATETIME_FORMATS.DAY[dayIndex],
                        shortName: $locale.DATETIME_FORMATS.SHORTDAY[dayIndex]
                    };
                }),
                errorMessages: {
                    invalidDay: $translate.instant('generic.datetime.errors.invalidDay'),
                    invalidMonth: $translate.instant('generic.datetime.errors.invalidMonth'),
                    earlyDate: $translate.instant('generic.datetime.errors.earlyDate'),
                    laterDate: $translate.instant('generic.datetime.errors.laterDate'),
                    wrongFormat: $translate.instant('generic.datetime.errors.wrongFormat')
                },
                prev: $translate.instant('generic.action.previous'),
                next: $translate.instant('generic.action.next'),
                clear: $translate.instant('generic.action.clear'),
                time: $translate.instant('generic.label.time'),
                dayPlaceholderChar: $translate.instant('generic.datetime.day')[0],
                monthPlaceholderChar: $translate.instant('generic.datetime.month')[0],
                yearPlaceholderChar: $translate.instant('generic.datetime.year')[0]
            };
        }
    };
}
