angular.module('everon.billing-plans.products.product')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.products.product', {
        url: '/:id',
        redirectTo: 'auth.billing-plans.products.product.basic-info',
        data: {
            title: 'generic.label.product',
            permissions: ['BILLING_PLAN:UPDATE']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/products/product' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.billing-plans.products.product');
        },
        resolve: {
            data: ['$q', '$transition$', 'subscriptionService',
                ($q, $transition$, subscriptionService) => {
                    const {id, type} = $transition$.params('to');

                    return subscriptionService.getProduct(id, type);
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-product data-data-promise="::$resolve.data" class="flex flex-auto" />'
            },
            'contextNav@auth.billing-plans.products.product': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.billing-plans.products.product\'}"  />'
            }
        }
    });
}
