angular.module('everon.stations.station-add.station-credentials')
       .component('evcloudStationAddCredentials', {
           controller: 'StationAddCredentialsController',
           templateUrl: 'modules/stations/station-add/station-credentials/station-credentials.html',
           bindings: {
               dataPromise: '<?'
           },
           require: {
               stationAddCtrl: '^^evcloudStationAdd'
           }
       });
