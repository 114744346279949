angular.module('everon.cards.card.billing-plan.change.select')
       .controller('CardBillingPlanSelectController', CardBillingPlanSelectController);

CardBillingPlanSelectController.$inject = [];

function CardBillingPlanSelectController() {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        const {
            products,
            subscription,
            selectedProduct = subscription
        } = $ctrl.cardBillingPlanChangeCtrl.payload;

        $ctrl.currentSubscription = subscription;
        $ctrl.products = products;
        $ctrl.selectedProduct = getSelectedProduct(selectedProduct, $ctrl.products);
        $ctrl.purchaseOrderNumber = $ctrl.cardBillingPlanChangeCtrl.payload.selectedProduct ? $ctrl.cardBillingPlanChangeCtrl.payload.selectedProduct.purchaseOrderNumber : null;
    };

    $ctrl.onSelect = model => {
        $ctrl.selectedProduct = model;
    };

    $ctrl.next = () => {
        $ctrl.cardBillingPlanChangeCtrl.payload.selectedProduct = $ctrl.selectedProduct;
        $ctrl.cardBillingPlanChangeCtrl.payload.selectedProduct.purchaseOrderNumber = $ctrl.purchaseOrderNumber;

        $ctrl.cardBillingPlanChangeCtrl.next();
    };

    /**
     * Sync `selectedProduct` with data from the server.
     * @param {Object} selectedProduct
     * @param {Array} products
     * @returns {Object}
     */
    function getSelectedProduct(selectedProduct, products) {
        const product = _.find(products, {id: selectedProduct.product.id});
        const plan = _.find(product.plans, {id: selectedProduct.plan.id});

        return {product, plan};
    }
}
