angular.module('everon')
       .factory('moment', moment);

moment.$inject = ['$window'];

function moment($window) {
    if (!$window.moment) {
        throw new Error('moment.js lib was not added to the project!');
    }

    return $window.moment;
}
