angular.module('everon.profile')
       .factory('profileService', profileService);

profileService.$inject = ['$http', '$q', 'sessionService', 'featureToggleService'];

function profileService($http, $q, sessionService, featureToggleService) {
    const oktaUserMgFeature = featureToggleService('@@oktaUserManagement');

    return {
        /**
         * Returns user profile object
         * @returns {Promise.<Object>}
         */
        getProfile() {
            return $q.resolve(sessionService.getUserProfile());
        },

        /**
         * Updates user profile
         * @param {Object} profile
         * @param {string} userId
         * @returns {Promise.<Object>}
         */
        updateProfile(profile, userId) {
            return oktaUserMgFeature.exec({
                onDisabled: () => $http.patch('/api/platform/users/me', profile),
                onEnabled: () => {
                    const {languageTag: language, ...rest} = profile;

                    return $http.patch(`/api/users/profiles/${userId}`, {...rest, language});
                }
            });
        },

        /**
         * Delete the profile specified by the id received.
         * @param {string}id
         * @returns {Promise.<Object>}
         */
        deleteProfile(id) {
            return $http.delete(`/api/platform/customers/${id}`);
        },

        /**
         * Block a user profile.
         * @param {string}id
         * @param {string}newStatus can be 'block' or 'unblock'
         * @returns {*}
         */
        updateProfileStatus(id, newStatus) {
            return $http.patch(`/api/platform/users/${id}/${newStatus}`);
        },

        /**
         * Verify if a user have the privileges to block other user.
         * @param {Object}currentProfile
         * @returns {boolean}
         */
        hasPrivilegeToBlockAccount(currentProfile) {
            return currentProfile.permissions.includes('USER:BLOCK');
        }
    };
}
