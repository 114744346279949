angular.module('everon.component.header')
       .controller('HeaderController', HeaderController);

HeaderController.$inject = ['CONSTANTS', 'mediatorService', '$state'];

function HeaderController(CONSTANTS, mediatorService, $state) {
    const $ctrl = this;
    const subscribers = [];

    $ctrl.$onInit = () => {
        $ctrl.$state = $state;

        $ctrl.state = {
            hasChanged: false,
            userNavVisible: false
        };

        $ctrl.userInitials = getInitials($ctrl.profile);
        $ctrl.breadcrumbs = [];

        subscribers.push(mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.PROFILE_CHANGE, onProfileChange));
        subscribers.push(mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, onContextTitleChange));
    };

    /**
     * Toggles navigation panels visibility
     */
    $ctrl.toggleNav = () => {
        $ctrl.state.navVisible = !$ctrl.state.navVisible;
        mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.TOGGLE_NAV);
    };

    /**
     * Toggles user navigation panel visibility or sets if flag is passed
     * @param {boolean} flag
     */
    $ctrl.toggleUserNav = flag => {
        $ctrl.state.userNavVisible = _.isUndefined(flag) ? !$ctrl.state.userNavVisible : flag;
    };

    $ctrl.$onDestroy = () => {
        _.invokeMap(subscribers, _.call);
    };

    function mapBreadcrumb(item) {
        const title = _.isPlainObject(item) ? item.title : item;
        const isKey = _.get(item, 'isKey', false);
        const isStateTitle = ['parent', 'grandparent', 'current'].includes(title);
        const stateHierarchy = {
            current: _.get($state, 'current'),
            parent: _.get($state.$current, 'parent'),
            grandparent: _.get($state.$current, 'parent.parent')
        };

        return {
            title: isStateTitle ? stateHierarchy[title].data.title : title,
            isKey: isKey || isStateTitle
        };
    }

    /**
     * Gets the user's initials.
     * If both first and last name are missing then return a '?' so that the user navigation isn't just an empty circle
     * @param {Object} profile
     * @returns {string}
     */
    function getInitials(profile) {
        const firstName = profile.firstName || '';
        const lastName = profile.lastName || '';
        const initials = firstName.charAt(0) + lastName.charAt(0);

        return initials || '?';
    }

    function onContextTitleChange(data) {
        if (!data) {
            return;
        }

        $ctrl.breadcrumbs = data.map(mapBreadcrumb);
    }

    function onProfileChange(profile) {
        $ctrl.userInitials = getInitials(profile);
    }
}
