angular.module('everon')
       .factory('mapService', mapService);

mapService.$inject = ['$q', '$http', '$window', '$timeout', 'config', 'CONSTANTS', 'googleMapsLoaderService', 'markerClusterer', 'sessionService', 'utils', 'geolocation', 'mediatorService', '$log'];

function mapService($q, $http, $window, $timeout, config, CONSTANTS, googleMapsLoaderService, markerClusterer, sessionService, utils, geolocation, mediatorService, $log) {
    const xmlSerializer = new XMLSerializer();

    /**
     * Transforms tariffs adding icon name for use in the template
     * @param {Object} tariffs
     * @returns {Object}
     */
    function transformTariffs(tariffs) {
        const transformedTariffs = {};

        Object.keys(tariffs)
              .forEach(key => {
                  transformedTariffs[key] = _.assign({icon: _.kebabCase(key)}, tariffs[key]);
              });

        return transformedTariffs;
    }

    /**
     * Transforms station data for display in station details view on the map
     * @param {Object} station
     * @returns {Object}
     */
    function transformStationForMap(station) {
        const connectorGroups = station.connectorGroups.map(group => _.assign(group, {
            icon: formatConnectorType(group.type),
            type: _.camelCase(group.type),
            pricing: group.pricing ? _.assign(group.pricing, {tariffCount: _.size(group.pricing.tariffs), tariffs: transformTariffs(group.pricing.tariffs)}) : null,
            maxPower: formatPowerUnit(group.maxPower)
        }));

        return _.assign(station, {
            connectorGroups,
            connectorCount: station.connectorGroups.reduce((accumulator, current) => accumulator + _.sum(_.values(current.statuses)), 0)
        });
    }

    /**
     * Transforms stations for list view
     * @param {Array} content
     * @param {number} nextOffset
     * @returns {Object}
     */
    function transformStationsForList({content, nextOffset}) {
        const stations = content.map(station => _.assign(station, {
            connectorTypes: _.uniq(_.map(station.connectors, connector => connector.type)),
            connectors: _(station.connectors).map(connector => _.assign(connector, {
                                                 type: formatConnectorType(connector.type),
                                                 id: _.uniqueId('connector-')
                                             }))
                                             .sortBy('status')
                                             .value()
        }));

        return {
            stations,
            nextOffset
        };
    }

    function formatConnectorType(type) {
        return type.toLowerCase().replace(/_/g, '-');
    }

    /**
     * Formats the power based on its value
     * @param {number} value
     * @returns {number}
     */
    function formatPowerUnit(value) {
        return value < 10 ? Math.round(value * 10) / 10 : Math.round(value);
    }

    /**
     * Converts SVG element node to string and base64 encodes it
     * @param {Element} svg
     * @returns {string}
     */
    function serialiseSvg(svg) {
        return $window.btoa(xmlSerializer.serializeToString(svg));
    }

    /**
     * Creates SVG element of a given size
     * @param {number} size
     * @param {string} namespace
     * @returns {SVGElement}
     */
    function createSvgElement(size, namespace) {
        const svg = document.createElementNS(namespace, 'svg');
        const viewBoxPosition = `-${size / 2}`;

        svg.setAttribute('width', `${size}`);
        svg.setAttribute('height', `${size}`);
        svg.setAttribute('viewBox', `${viewBoxPosition} ${viewBoxPosition} ${size} ${size}`);

        return svg;
    }

    /**
     * Returns coordinates for the path to be drawn
     * @param {number} percent
     * @returns {Array}
     */
    function getCoordinatesForPercent(percent) {
        return [Math.cos(2 * Math.PI * percent), Math.sin(2 * Math.PI * percent)];
    }

    return {
        apis: {},

        /**
         * Loads Maps API and caches it
         * @returns {Promise.<google.maps>}
         */
        loadMapApi() {
            const options = {
                language: sessionService.getUserProfile()?.language?.tag || null,
                libraries: ['geometry', 'places']

            };

            if (this.apis.maps && this.apis.geoCoder) {
                return $q.resolve(this.apis.maps);
            }

            return googleMapsLoaderService.load(options)
                                          .then(mapsApi => {
                                              this.apis.maps = mapsApi;
                                              this.apis.geoCoder = new mapsApi.Geocoder();

                                              return mapsApi;
                                          })
                                          .catch(error => {
                                              $log.debug(error);
                                        });
        },

        /**
         * Creates a new instance of Places Autocomplete widget
         * @param {Element} inputElement
         * @param {Object} options
         * @returns {Object}
         */
        createPlacesAutocomplete(inputElement, options) {
            if (!this.apis.maps.places) {
                throw new Error('Places Autocomplete library not loaded');
            }

            return new this.apis.maps.places.Autocomplete(inputElement, options);
        },

        /**
         * Creates new Map instance
         * @param {Element} mapHolder
         * @param {Object} options
         * @returns {Object}
         */
        createMap(mapHolder, options) {
            return new this.apis.maps.Map(mapHolder, _.assign({
                zoom: 15,
                minZoom: 2,
                maxZoom: 22,
                scaleControl: true,
                streetViewControl: true,
                mapTypeControl: true,
                streetViewControlOptions: {
                    position: this.apis.maps.ControlPosition.LEFT_BOTTOM
                },
                controlSize: 29
            }, options));
        },

        /**
         * Creates new cluster Marker instance
         * @param {Object} [options]
         * @returns {Object}
         */
        createClusterMarker(options) {
            return new this.apis.maps.Marker(options);
        },

        /**
         * Creates new Marker instance
         * @param {string} [id]
         * @param {Object} [options]
         * @returns {Object}
         */
        createMarker(id, options) {
            return markerClusterer.createMarker(id, _.merge({
                clickable: false,
                icon: {
                    url: CONSTANTS.PATHS.MAP_MARKER_ICON_DEFAULT,
                    scaledSize: {
                        width: 30,
                        height: 30
                    }
                }
            }, options), this.apis.maps);
        },

        /**
         * Creates new InfoWindow
         * @returns {Object}
         */
        createInfoWindow() {
            return new this.apis.maps.InfoWindow();
        },

        /**
         * Returns a clusterer instance which contains clusters of markers. To decrease the number of clusters and improve performance, `gridSize` can be increased
         * @param {google.maps.Map} map
         * @returns {Object}
         */
        createClusterer(map) {
            return markerClusterer.createClusterer(map, this.apis.maps);
        },

        /**
         * Creates custom control in the map inside the wrapper element
         * @param {google.maps.Map} map
         * @param {HTMLElement} controlContent - custom control template
         * @param {string} controlPosition
         * @returns {HTMLElement} control content element to be used to attach desired action
         */
        createCustomControl(map, controlContent, controlPosition) {
            map.controls[this.apis.maps.ControlPosition[controlPosition]].push(controlContent);

            return controlContent;
        },

        /**
         * Handles click events on a cluster of markers and offsets duplicate coordinates. This overwrites the default handler behaviour in marker clusterer by setting `zoomOnClick` option to
         * `false`
         * @param {Object} cluster
         */
        handleClusterClick(cluster) {
            const clusterer = cluster.getMarkerClusterer();
            const maxZoom = clusterer.getMaxZoom();
            const originalZoom = clusterer.getMap().getZoom();
            const bounds = cluster.getBounds();
            const markerCount = cluster.getSize();

            function isEqual({lat, lng}, markers) {
                return markers.some(marker => {
                    const latitude = marker.getPosition().lat();
                    const longitude = marker.getPosition().lng();

                    return lat === latitude && lng === longitude;
                });
            }

            function getCurrentZoom() {
                return Math.max(clusterer.getMap().getZoom(), originalZoom + 1);
            }

            // Space out markers that have exactly the same coordinates. To not degrade performance, we only check for duplicates if number of markers is less than 20
            if (markerCount > 1 && markerCount < 20) {
                const markers = cluster.getMarkers();

                markers.forEach((marker, index) => {
                    let lat = marker.getPosition().lat();
                    let lng = marker.getPosition().lng();

                    if (isEqual({lat, lng}, markers.slice(index + 1))) {
                        lat = lat + (Math.abs(Math.random()) / (100000 + markerCount));
                        lng = lng + (Math.abs(Math.random()) / (100000 + markerCount));

                        marker.setPosition({lat, lng});
                    }
                });

                clusterer.getMap().fitBounds(bounds);
                clusterer.getMap().setZoom(getCurrentZoom());
            } else {
                clusterer.getMap().fitBounds(bounds);

                // This ensures that map is not zoomed in more than the `maxZoom` option set at the time of creating marker clusterer
                setTimeout(() => {
                    const currentZoom = getCurrentZoom();

                    // Don't zoom beyond the max zoom level if `maxZoom` specified or ensure we zoom at least one level over original zoom level.
                    const zoom = (maxZoom !== null && (currentZoom > maxZoom) ? maxZoom + 1 : currentZoom);

                    clusterer.getMap().setZoom(zoom);
                }, 100);
            }
        },

        /**
         * Creates new latitude and longitude pair
         * @param {number} [lat = 0]
         * @param {number} [lng = 0]
         * @returns {Object}
         */
        latLng(lat = 0, lng = 0) {
            if (!this.isValidLatLng(lat, lng)) {
                throw new Error(`Invalid latitude or longitude provided; lat: ${lat}, lng: ${lng}`);
            }

            return new this.apis.maps.LatLng(lat, lng);
        },

        /**
         * Attempts to resolve coordinates for a given address
         * @param {string} address
         * @returns {Promise.<Object|Error>}
         */
        getAddressCoordinates(address) {
            return $q((resolve, reject) => {
                this.apis.geoCoder.geocode({address}, (results, status) => {
                    if (status === 'OK') {
                        resolve({
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        });
                    } else {
                        reject(new Error(`Geocoder failed to get coordinates for address: ${address}`));
                    }
                });
            });
        },

        /**
         * Attempts to resolve address for given coordinates
         * @param {Object} latLng
         * @returns {Promise.<Object|Error>}
         */
        getCoordinatesAddress(latLng) {
            return $q((resolve, reject) => {
                this.apis.geoCoder.geocode({location: latLng}, (results, status) => {
                    if (status === 'OK') {
                        resolve(results[0].formatted_address);
                    } else {
                        reject(new Error(`Geocoder failed to get address for coordinates ${latLng} due to: ${status}`));
                    }
                });
            });
        },

        /**
         * Attempts to resolve address components for given coordinates
         * @param {Object} latLng
         * @returns {Promise.<Object|Error>}
         */
        getCoordinatesAddressComponents(latLng) {
            return $q((resolve, reject) => {
                this.apis.geoCoder.geocode({location: latLng}, (results, status) => {
                    if (status === 'OK') {
                        resolve(results[0].address_components);
                    } else {
                        reject(new Error(`Geocoder failed to get address for coordinates ${latLng} due to: ${status}`));
                    }
                });
            });
        },

        /**
         * Extracts lat and lng from geoCoordinates string
         * @param {string} geoCoordinatesString
         * @returns {Array}
         */
        getLatLngFromGeoCoordinatesString(geoCoordinatesString) {
            return geoCoordinatesString.split(',').map(Number);
        },

        /**
         * Extracts geoCoordinates from Google Maps LatLng object into a string with format 'latitude, longitude'
         * @param {Object} position
         * @returns {string}
         */
        getGeoCoordinatesStringFromLatLng(position) {
            return `${position.lat()}, ${position.lng()}`;
        },

        /**
         * Get the shortest distance between 'from' and 'to' coordinates
         * @param {LatLng} from
         * @param {LatLng} to
         * @returns {number} distance in metres
         */
        getDistanceBetween(from, to) {
            return this.apis.maps.geometry.spherical.computeDistanceBetween(from, to);
        },

        /**
         * Returns formatted distance. If `distance >= 1000`, we convert to km and round to 1 decimal place, otherwise we format in metres: if less than 25 m then round to whole number, else
         * round to the nearest 50 m.
         * @param {Object} from
         * @param {Object} to
         * @returns {string}
         */
        getFormattedDistance(from, to) {
            const distance = this.getDistanceBetween(this.latLng(...Object.values(from)), this.latLng(...Object.values(to)));
            const shouldFormat = distance >= 1000;
            const number = shouldFormat ? parseFloat(distance / 1000) : distance;

            return shouldFormat ? `${utils.roundToDecimalPrecision(number, 1)} km` : `${number < 50 ? Math.round(number) : (Math.round(number / 50) * 50)} m`;
        },

        /**
         * Validates latitude and longitude
         * @param {number} lat
         * @param {number} lng
         * @returns {boolean}
         */
        isValidLatLng(lat, lng) {
            return (isFinite(lat) && Math.abs(lat) <= 90) && (isFinite(lng) && Math.abs(lng) <= 180);
        },

        /**
         * Removes event listener from the map
         * @param {Function} listener
         */
        removeListener(listener) {
            this.apis.maps.event.removeListener(listener);
        },

        /**
         * Returns station geo locations. If `viewport` is provided, only stations within the bounds of that viewport will be returned. `gridSize` defines the size of the grid cell to contain
         * a cluster - the bigger the number, the smaller the grid cell will be. `maxClusters` defines the maximum number of clusters that will be returned
         * @param {Object} [params={}]
         * @returns {Promise.<Array>}
         */
        getStationGeolocations(params = {}) {
            const defaultParams = {
                gridSize: 5,
                maxClusters: 80
            };

            params = {params: _.assign({}, defaultParams, params)};

            return $http.get('/api/poi/map', params);
        },

        /**
         * Returns a list of stations within a specified search area; offset param is used to indicate a page number; page limit is 50 items by default. Filter params can also be used
         * @param {Object} [params={}]
         * @returns {*}
         */
        getStationsList(params = {}) {
            const defaultParams = {
                offset: 0
            };

            params = {params: _.assign({}, defaultParams, params)};

            return $http.get('/api/poi/map/list', params)
                        .then(transformStationsForList);
        },

        /**
         * Gets station's details object for the map station details
         * @param {string} chargePointId
         * @returns {Promise.<Object>}
         */
        getStation(chargePointId) {
            return $http.get('/api/poi/map/:chargePointId', {params: {chargePointId}})
                        .then(transformStationForMap);
        },

        /**
         * Creates SVG cluster circle icons with segments for each status representation. The SVG is then converted to a data URI for use with google Marker instance.
         * @param {Object} location
         * @param {number} size
         * @returns {string}
         */
        createDataUrlFromSvg(location, size) {
            const svgNS = 'http://www.w3.org/2000/svg';
            const svg = createSvgElement(size, svgNS);
            const groupElement = document.createElementNS(svgNS, 'g');
            const circleElement = document.createElementNS(svgNS, 'circle');
            const segments = _.map(location.statuses, (value, key) => ({color: CONSTANTS.MAP_CLUSTER_ICON_SEGMENT[key], value}));
            const thickness = 0.8;
            const scaledSize = size / 2;
            let cumulativePercentage = 0;

            circleElement.setAttribute('fill', '#ffffff');
            circleElement.setAttribute('r', `${scaledSize}`);
            groupElement.setAttribute('transform', `scale(${scaledSize})`);

            segments.forEach(segment => {
                const [startX, startY] = getCoordinatesForPercent(cumulativePercentage);

                // Each slice starts where the last slice ended, so keep a cumulative percent
                cumulativePercentage += segment.value;

                const [endX, endY] = getCoordinatesForPercent(cumulativePercentage);

                // If the slice is more than 50%, take the large arc (the long way around)
                const largeArcFlag = segment.value > 0.5 ? 1 : 0;

                const pathData = [
                    `M ${startX} ${startY}`, // Move to x and y point
                    `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Draw an arc from x to y
                    `L ${endX * thickness} ${endY * thickness}`, // Draw a line
                    `A ${thickness} ${thickness} 0 ${largeArcFlag} 0 ${startX * thickness} ${startY * thickness}` // Draw another arc to complete the segment
                ].join(' ');

                const pathElement = document.createElementNS(svgNS, 'path');

                pathElement.setAttribute('d', pathData);
                pathElement.setAttribute('fill', segment.color);
                groupElement.appendChild(pathElement);
            });

            svg.appendChild(circleElement);
            svg.appendChild(groupElement);

            return `data:image/svg+xml;base64,${serialiseSvg(svg)}`;
        },

        /**
         * Generates URI encoded addresses and builds the URL to get directions from origin to destination on Google Maps
         * @param {Object} addresses
         * @returns {string}
         */
        buildDirectionsToStationUrl({originAddress, destinationAddress}) {
            return `https://www.google.com/maps/dir/?api=1&origin=${$window.encodeURI(originAddress)}&destination=${$window.encodeURI(destinationAddress)}&travelmode=driving`;
        },

        /**
         * Gets device position, builds needed information and deep links to Google Maps app - browser as fallback - to get directions to station
         * @param {string} destinationAddress
         * @param {function} linkHandler
         * @returns {Object}
         */
        navigateToStation({streetName, postcode, city, country}, linkHandler) {
            const destinationAddress = `${streetName}, ${postcode}, ${city}, ${country.name}`;

            return geolocation.getCurrentPosition()
                              .then(({lat, lng}) => this.getCoordinatesAddress(this.latLng(lat, lng)))
                              .then(currentPositionAddress => {
                                  utils.openExternalLink(this.buildDirectionsToStationUrl({originAddress: currentPositionAddress, destinationAddress}));

                                  return {currentPosition: geolocation.getStoredPosition(), currentPositionAddress};
                              })
                              .catch(error => {
                                  if (error.code === error.PERMISSION_DENIED) {
                                      mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                          type: 'alert',
                                          messageKey: 'map.notification.locationPermissionDenied.message',
                                          link: {
                                              text: 'map.notification.locationPermissionDenied.linkText',
                                              handlerFn: linkHandler
                                          },
                                          isPersistent: true
                                      });
                                  }
                              });
        },

        formatPowerUnit
    };
}
