angular.module('everon.billing-plans.products.product-add.product-basic-info')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.products.product-add.product-basic-info', {
        data: {
            title: 'generic.title.basicInformation',
            order: 0
        },
        views: {
            'content@auth.billing-plans.products.product-add': {
                component: 'evcloudProductAddBasicInfo'
            }
        }
    });
}
