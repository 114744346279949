/**
 * Removes itself from the DOM if the passed conditions are false. It can be used to remove unwanted DOM nodes if the device has no use for
 * them.
 *
 * Implementation of IfDevice
 * The device is queried about screen sizes and touch capabilities, if one of them isn't true the element gets removed from
 * the DOM.
 *
 * @example
 *   Usage:
 *   <evcloud-if-device query="(min-device-width: 375px)" touch="false">
 *
 *   </evcloud-if-device >
 */

angular.module('everon.component.if-device')
       .directive('evcloudIfDevice', ifDevice);

ifDevice.$inject = ['$window'];

function ifDevice($window) {
    return {
        restrict: 'E',
        scope: {
            query: '@',
            touch: '='
        },
        link(scope, element) {
            const mQuerie = $window.matchMedia(scope.query).matches;
            const hasTouch = 'ontouchstart' in $window;
            const touch = scope.touch || false;

            if (!mQuerie || hasTouch !== touch) {
                element.remove();
            }
        }
    };
}
