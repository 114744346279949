/**
 * Creates and configures async validator.
 * `asyncValidatorConfig` object contains 2 properties: `name` of async validator and `validatorFn` - a function that performs async validation, returning a
 * promise.
 *
 * @example
 *
 * <input type="text" evcloud-async-validator="$ctrl.asyncValidatorConfig">
 */

angular.module('everon.component.async-validator')
       .directive('evcloudAsyncValidator', asyncValidator);

asyncValidator.$inject = [];

function asyncValidator() {
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            evcloudAsyncValidator: '<'
        },
        link(scope, element, attributes, ngModel) {
            ngModel.$asyncValidators[scope.evcloudAsyncValidator.name] = scope.evcloudAsyncValidator.validatorFn;
        }
    };
}
