angular.module('everon.account-recovery')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.account-recovery', {
        abstract: true,
        views: {
            'main@anon': {
                template: '<evcloud-account-recovery class="flex flex-auto" />'
            }
        }
    });
}
