angular.module('everon.roaming.connections.connection.handshake-logs')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.connections.connection.handshake-logs', {
        url: '/handshake-logs',
        data: {
            id: 'roaming-connection-handshake-logs-navigation-link',
            title: 'roaming.connections.connection.handshakeLogs.title',
            menuPriority: 2,
            navigationType: ['context']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/connections/connection/handshake-logs' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.connections.connection.handshake-logs');
        },
        resolve: {
            data: ['$q', 'roamingService', '$transition$', ($q, roamingService, $transition$) => {
                const {id} = $transition$.params('to');

                return $q.all({
                    overview: roamingService.getConnection(id),
                    handshakeLogs: roamingService.getHandshakeLogs(id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                component: 'evcloudRoamingConnectionHandshakeLogs',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
