angular.module('everon.dashboard')
       .factory('dashboardService', dashboardService);

dashboardService.$inject = ['$http', '$q'];

function dashboardService($http, $q) {
    /**
     * Returns null if data object is empty,
     * otherwise clone of transaction field
     * @param {Object} data
     * @returns {null|Object}
     */
    function transformTransaction(data) {
        return _.isEmpty(data) ? null : data.transaction;
    }

    /**
     * Merges configurations from multiple promises
     * into one dashboard configuration
     * @param {Array} configs array of dashboard configurations
     * @returns {Object} merged configurations
     */
    function transformDashboardConfig(configs) {
        return configs.reduce((result, current) => Object.assign(result, current));
    }

    return {
        /**
         * Returns dashboard config
         * @returns {Promise}
         */
        getDashboardConfig() {
            return $q.all([$http.get('/api/platform/dashboard/config'), $http.get('/api/tokens/dashboard/config')])
                .then(transformDashboardConfig);
        },

        /**
         * Returns dashboard widget data
         * @param {string} path
         * @returns {Promise}
         */
        getWidgetData(path) {
            return $http.get(path);
        },

        /**
         * Returns latest transaction data by card/station id
         * @param {string} path
         * @param {string} id
         * @returns {Promise}
         */
        getLatestTransaction(path, id) {
            return $http.get(path, {params: {id}})
                        .then(transformTransaction);
        }
    };
}
