angular.module('everon.users.customers.user')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.customers.user', {
        url: '/:id',
        data: {
            title: 'users.user.title'
        },
        resolve: {
            data: ['$q', '$transition$', 'userService', 'languageService', ($q, $transition$, userService, languageService) => {
                const {id} = $transition$.params('to');

                return $q.all({
                    profile: userService.getUser(id),
                    languages: languageService.getTenantLanguages(),
                    availableRoles: userService.getAvailableUserRoles({type: 'customers', id})
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-profile data-data-promise="::$resolve.data" class="flex-auto" show-account-status="true" />'
            }
        }
    });
}
