angular.module('everon.stations.station.assistance')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.assistance', {
        url: '/assistance',
        data: {
            id: 'station-assistance-navigation-link',
            title: 'stations.station.assistance.title',
            navigationType: ['context'],
            menuPriority: 90,
            permissions: ['TRANSACTION:CREATE']
        },
        resolve: {
            data: ['$q', '$transition$', 'data', 'stationService', 'cardService', 'permissionService', 'auth', ($q, $transition$, data, stationService, cardService, permissionService, auth) => {
                    return $q.all({
                        station: data,
                        transactions: stationService.getOpenTransactions(data.id),
                        resetStatus: stationService.getChargePointResetStatus(data.id),
                        configuration: stationService.getStationConfiguration(data.id),
                        cards: permissionService.resolve(auth.profile.permissions, ['CARD:READ']) ? cardService.getCards({
                            size: 1000,
                            sort: 'visualNumber,asc',
                            status: 'active'
                        }) : []
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-assistance data-data-promise="::$resolve.data" class="sm-mx-auto sm-col-9 md-col-12" />'
            }
        }
    });
}
