angular.module('everon.cards.card-import')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card-import-details', {
        url: '/:cardId/import-details',
        data: {
            id: 'card-details-navigation-link',
            title: 'generic.title.details',
            permissions: ['CARD:IMPORT']
        },
        resolve: {
            data: ['$q', '$transition$', 'cardService', 'tenantSettingsService', ($q, $transition$, cardService, tenantSettingsService) => {
                return $q.all({
                    card: cardService.getCard($transition$.params('to').cardId),
                    tenantFeatureFlags: tenantSettingsService.getFeatureFlags()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-card-details data-data-promise="::$resolve.data" class="col-12 md-col-10 mx-auto flex flex-column" />'
            }
        }
    });
}
