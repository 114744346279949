angular.module('everon.component.notification-modal')
       .controller('NotificationModalController', NotificationModalController);

NotificationModalController.$inject = ['$timeout', 'CONSTANTS', 'mediatorService'];

function NotificationModalController($timeout, CONSTANTS, mediatorService) {
    const $ctrl = this;
    const notifications = [
        {
            type: 'success',
            messageKey: 'generic.notification.success',
            isPersistent: false,
            delay: 3000
        },
        {
            type: 'error',
            messageKey: 'generic.notification.error',
            isPersistent: false,
            delay: 4000
        },
        {
            type: 'alert',
            messageKey: '',
            isPersistent: false,
            delay: 3000
        }
    ];
    let subscribers;
    let timeoutId;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            show: false
        };
        $ctrl.notification = null;
        subscribers = [
            mediatorService.subscribe(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, onNotification),
            mediatorService.subscribe(CONSTANTS.EVENTS.ACTION.CLOSE_NOTIFICATION, onCloseNotification)
        ];
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION_COMPONENT_READY);
    };

    $ctrl.$onDestroy = () => {
        _.invokeMap(subscribers, _.call);
    };

    $ctrl.close = () => {
        if (_.isFunction($ctrl.notification.callback)) {
            setTimeout(() => {
                $ctrl.notification.callback();
                $ctrl.notification = null;
            }, 0);
        }

        $timeout(() => {
            $ctrl.state.show = false;
        });
    };

    /**
     * Notification event handler. Merges default notification with event data and shows the notification modal
     * @param {Object} data
     */
    function onNotification(data) {
        const defaultNotification = _.find(notifications, {type: data.type});

        $ctrl.notification = _.assign({}, defaultNotification, data);
        $ctrl.state.show = true;

        if (!$ctrl.notification.isPersistent) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout($ctrl.close, $ctrl.notification.delay);
        }
    }

    /**
     * Closes persistent notification after transition to a different state
     */
    function onCloseNotification() {
        if ($ctrl.notification && $ctrl.notification.isPersistent) {
            $ctrl.state.show = false;
            $ctrl.notification = null;
        }
    }
}
