angular.module('everon.stations.sites.site')
       .value('siteFormModel', {
           address: [
               {
                   name: 'streetName',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   required: true
               },
               {
                   name: 'house',
                   type: 'text',
                   helpText: 'generic.helpText.house',
                   required: true
               },
               {
                   name: 'postcode',
                   type: 'text',
                   required: true
               },
               {
                   name: 'city',
                   type: 'text',
                   required: true
               },
               {
                   name: 'country',
                   type: 'select',
                   cssClass: 'sm-col-6',
                   required: true
               }
           ]
       });
