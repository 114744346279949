angular.module('everon.stations.charging-profiles.charging-profile-form')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.charging-profiles.charging-profile-update', {
        url: '/:chargingProfileId',
        data: {
            title: 'stations.chargingProfiles.title.update',
            permissions: ['CHARGING_PROFILE:UPDATE']
        },
        resolve: {
            data: ['$transition$', 'chargingProfilesService', ($transition$, chargingProfilesService) => {
                return chargingProfilesService.getChargingProfile($transition$.params('to').chargingProfileId);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-charging-profile-form data-data-promise="::$resolve.data" class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}
