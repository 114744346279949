angular.module('everon.component.search-dropdown')
       .controller('SearchDropdownController', SearchDropdownController);

SearchDropdownController.$inject = ['$element', '$timeout'];

function SearchDropdownController($element, $timeout) {
    const $ctrl = this;
    let searchInput;
    let searchResultsContainer;
    let activeElementIndex = -1;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            searching: false,
            autosuggestVisible: false
        };

        $ctrl.query = $ctrl.options.initialValue || '';

        searchInput = $element.find('input')[0];
        searchResultsContainer = $element.find('ul')[0];
    };

    /**
     * Updates results with the items that match the search query - for both primary and secondary properties
     */
    $ctrl.onChange = () => {
        const query = _.trim($ctrl.query).toLowerCase();

        if ($ctrl.options.asyncSearch) {
            if (!query) {
                $ctrl.onReset();

                return;
            }

            $ctrl.state.searching = true;

            $ctrl.options.getDataAsync(query).then(data => {
                $ctrl.state.searching = false;
                renderResults(data);
                $ctrl.toggleResultsVisibility(true);
                preSelectFirstItem();
            });
        } else {
            renderResults($ctrl.options.data, query);
            $ctrl.toggleResultsVisibility(true);
            preSelectFirstItem();
        }
    };

    /**
     * Handles keyboard events
     * @param {Object} event
     */
    $ctrl.onKeyDown = event => {
        switch (event.keyCode) {
            case 13: // enter
                event.preventDefault();

                if (activeElementIndex !== -1) {
                    $ctrl.onSelect($ctrl.results[activeElementIndex]);
                    activeElementIndex = -1;
                }

                break;
            case 27: // esc
                $ctrl.onReset();
                break;
            case 38: // arrow up
                move(-1);
                break;
            case 40: // arrow down
                move(1);
                break;
            default:
                break;
        }
    };

    /**
     * Selects the item and updated the input value with it
     * @param {Object} item
     */
    $ctrl.onSelect = item => {
        $ctrl.query = $ctrl.options.emptyOnSelect ? '' : item.primary;
        $ctrl.options.onSelect(item);
        $ctrl.toggleResultsVisibility(false);
    };

    /**
     * Resets search query, calls parent component callback and focuses search input
     */
    $ctrl.onReset = () => {
        $ctrl.query = '';
        $ctrl.options.onSelect({id: null});
        $ctrl.toggleResultsVisibility(false);
        activeElementIndex = -1;
        searchInput.focus();
        updateResultsFocusState();
    };

    /**
     * Toggles result list visibility
     * @param {boolean} visible
     */
    $ctrl.toggleResultsVisibility = visible => {
        $ctrl.state.autosuggestVisible = visible;
    };

    /**
     * Pre-selects the first item in the list of search results
     */
    function preSelectFirstItem() {
        $timeout(() => {
            activeElementIndex = -1;
            move(1);
        }, 0, false);
    }

    /**
     * Renders results matching the search query if search is synchronous. For async search we just render the data because it's already filtered
     * @param {Array} data
     * @param {string} [query]
     */
    function renderResults(data, query) {
        const itemsMatchingQuery = _.filter(data, item => {
            const primaryPartMatchesQuery = _.startsWith(item.primary.toLowerCase(), query);
            const secondaryPartMatchesQuery = item.secondary && _.startsWith(item.secondary.toLowerCase(), query);

            return primaryPartMatchesQuery || secondaryPartMatchesQuery;
        });

        $ctrl.results = query ? itemsMatchingQuery : data;
    }

    /**
     * Adds/removes hover state CSS class from list items
     */
    function updateResultsFocusState() {
        Array.from(searchResultsContainer.children)
             .forEach((elem, index) => {
                 if (index === activeElementIndex) {
                     elem.classList.add('evcloud-search-dropdown-active');
                     elem.scrollIntoView(false);
                 } else {
                     elem.classList.remove('evcloud-search-dropdown-active');
                 }
             });
    }

    /**
     * Moves focus on to the next or previous list item
     * @param {number} direction
     */
    function move(direction) {
        const itemCount = searchResultsContainer.childElementCount;

        activeElementIndex += direction;

        if (activeElementIndex >= itemCount) {
            activeElementIndex = 0;
        }

        if (activeElementIndex < 0) {
            activeElementIndex = itemCount - 1;
        }

        updateResultsFocusState();
    }
}
