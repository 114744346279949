angular.module('everon.cards.virtual-token.transactions')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.virtual-token.transactions', {
        url: '/transactions?startDate,endDate,sort',
        data: {
            id: 'virtual-token-transactions-navigation-link',
            title: 'generic.title.transactions',
            navigationType: ['context'],
            menuPriority: 4
        },
        params: {
            startDate: {
                value: ['cardService', cardService => cardService.getDefaultTransactionsRange().startDate],
                type: 'date',
                squash: true
            },
            endDate: {
                value: ['cardService', cardService => cardService.getDefaultTransactionsRange().endDate],
                type: 'date',
                squash: true
            },
            sort: {
                value: 'endedAt,desc',
                squash: true
            }
        },
        dynamic: true,
        resolve: {
            data: ['$transition$', 'data', 'cardService', ($transition$, data, cardService) => {
                const {startDate, endDate} = $transition$.params('to');

                return cardService.setupTransactionsData(startDate, endDate, data);
            }],
            profile: ['auth', auth => auth.profile]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.cards.virtual-token': {
                template: `<evcloud-card-transactions
                                data-profile="::$resolve.profile"
                                data-data-promise="::$resolve.data"
                                class="flex-auto flex flex-column"
                            />`
            }
        }
    });
}
