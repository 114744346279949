angular.module('everon.account.advenir')
       .controller('AccountAdvenirController', AccountAdvenirController);

AccountAdvenirController.$inject = ['$q', 'CONSTANTS', 'accountService', 'validationService', 'mediatorService'];

function AccountAdvenirController($q, CONSTANTS, accountService, validationService, mediatorService) {
    const $ctrl = this;
    let modelCopy = null;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            hasChanged: false,
            saveInProgress: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state
     */
    $ctrl.onChange = () => {
        setChanged(true);
    };

    /**
     * Cleanup account model and attempt an update
     */
    $ctrl.save = () => {
        if (!$ctrl.state.hasChanged) {
            resetFormState();

            return;
        }

        const model = _.assign({}, $ctrl.model);

        $ctrl.state.saveInProgress = true;

        accountService.updateAdvenirSettings(model)
                      .then(data => {
                          $ctrl.model = data;
                          modelCopy = JSON.stringify($ctrl.model);

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success'
                          });

                          resetFormState();
                      })
                      .catch(response => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              isPersistent: true,
                              messageKey: CONSTANTS.ERROR_CODES[response.status]
                          });
                      })
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    /**
     * Restore account model data and reset form
     */
    $ctrl.cancel = () => {
        restoreModel(JSON.parse(modelCopy));
        resetFormState();
    };

    /**
     * Stringifies model, replacing all `null` values with empty strings to be consistent with ngModel defaults
     * @param {Object} model
     * @returns {*}
     */
    function stringifyModel(model) {
        return JSON.stringify(model).replace(/null/g, '""');
    }

    /**
     * Checks whether account details have changed while editing
     * @returns {boolean}
     */
    function hasModelChanged() {
        return modelCopy !== stringifyModel($ctrl.model);
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        $ctrl.state.hasChanged = hasChanged && hasModelChanged();
    }

    /**
     * Restores the original model
     * @param {Object} model
     */
    function restoreModel(model) {
        $ctrl.model = model;
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl.advenirForm.$setUntouched();
        $ctrl.advenirForm.$setPristine();
        setChanged(false);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.state.isUpdate = !_.isEmpty(data.advenir);
        $ctrl.model = data.advenir;

        modelCopy = stringifyModel($ctrl.model);
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
