angular.module('everon.stations')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations', {
        url: '/stations',
        redirectTo: 'auth.stations.list',
        data: {
            title: 'generic.entity.stations',
            navigationType: ['primary'],
            icon: 'icon-evse',
            menuPriority: 3,
            permissions: ['STATION:READ']
        },
        views: {
            'navSecondary@auth': {
                template: '<evcloud-nav-list filter="{navigationType: \'secondary\', parent: \'auth.stations\'}" />'
            }
        }
    });
}
