angular.module('everon')
       .factory('fileService', fileService);

fileService.$inject = ['$q', '$http', '$window', 'config'];

function fileService($q, $http, $window, config) {
    /**
     * Triggers file download with provided blob in all supported platforms and browsers
     * @param {Blob} blob
     * @param {string} fileName
     * @returns {Promise}
     */
    function triggerFileDownload(blob, fileName) {
        return $q((resolve, reject) => {
            let isSuccessFull = false;

            // Check for dinosaurs
            if ($window.navigator.msSaveOrOpenBlob) {
                isSuccessFull = $window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                const link = $window.document.createElement('a');

                link.href = $window.URL.createObjectURL(blob);
                link.download = fileName;
                isSuccessFull = link.dispatchEvent(new MouseEvent('click'));
            }

            if (isSuccessFull) {
                resolve();
            } else {
                reject(new Error(`Failed to download file ${fileName}`));
            }
        });
    }

    /**
     * Appends new transform function to the existing ones if any
     * @param {Function|Array} defaults
     * @param {Function} transform
     * @returns {Array}
     */
    function appendTransform(defaults, transform) {
        // We can't guarantee that the default transformation is an array
        defaults = Array.isArray(defaults) ? defaults : [defaults];

        // Append the new transformation to the defaults
        return defaults.concat(transform);
    }

    /**
     * Transforms pdf service response
     * @param {ArrayBuffer} data
     * @param {Function} headersGetter
     * @returns {Object}
     */
    function transformResponse(data, headersGetter) {
        const headers = headersGetter();

        return {
            data,
            fileName: headers['x-file-name'],
            contentType: headers['content-type']
        };
    }

    return {
        openFile({data, contentType, fileName}) {
            return $q((resolve, reject) => {
                const blob = new Blob([data], {type: contentType});

                if (config.isMobile) {
                    $window.EVERON.cordovaOpenFile(blob, fileName, error => error ? reject(error) : resolve());
                } else {
                    triggerFileDownload(blob, fileName).then(resolve)
                                                       .catch(reject);
                }
            });
        },

        transformFileResponse() {
            return appendTransform($http.defaults.transformResponse, transformResponse);
        }
    };
}
