angular.module('everon')
       .factory('modernizr', modernizr);

modernizr.$inject = ['$window'];

function modernizr($window) {
    if (!$window.Modernizr) {
        throw new Error('modernizr.js lib was not added to the project!');
    }

    return $window.Modernizr;
}
