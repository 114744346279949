angular.module('everon.stations.station-import-details-form')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-import', {
        url: '/import',
        redirectTo: 'auth.stations.station-import.basic-info',
        data: {
            title: 'stations.action.import',
            permissions: ['STATION:IMPORT']
        },
        views: {
            'main@auth': {
                template: '<evcloud-station-import-details-form class="col-12 md-col-10 mx-auto flex flex-column"/>'
            }
        }
    });
}
