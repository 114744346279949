angular.module('everon.account.billing-information')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.account.billing-information', {
        url: '/billing-information',
        data: {
            id: 'account-billing-information-navigation-link',
            title: 'generic.title.billingInformation',
            navigationType: ['context'],
            menuPriority: 1
        },
        resolve: {
            data: ['$q', 'profileService', 'accountFormModel', 'accountService', 'countryService', 'languageService',
                ($q, profileService, accountFormModel, accountService, countryService, languageService) => {
                    return $q.all({
                        accountSetupComplete: accountService.getBillingInfoStatus(),
                        accountFormModel,
                        billing: accountService.getBillingInfo(),
                        countries: countryService.getTenantCountries(),
                        languages: languageService.getTenantLanguages()
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.account': {
                component: 'evcloudAccountBillingInformation',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
