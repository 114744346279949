angular.module('everon.cards.card.overview')
       .controller('CardOverviewController', CardOverviewController);

CardOverviewController.$inject = ['mediatorService', 'CONSTANTS', 'sessionService', 'permissionService'];

function CardOverviewController(mediatorService, CONSTANTS, sessionService, permissionService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            canUpdateReimbursementRate: checkPermissions(['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']),
            cardIsInMyAccount: true
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.model = data;
        $ctrl.state.dataLoaded = true;
        $ctrl.state.cardIsInMyAccount = $ctrl.model.card.accountId === sessionService.getUserProfile().accountId;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [data.card.reference || data.card.contractId, 'current']);
    }
}
