/**
 * Compares the input model value with the model passed in the attribute.
 * Sets the "matches" validation error in the form element.
 *
 * @example
 *
 * <input type="password" ng-model="$ctrl.password">
 * <input type="password" ng-model="$ctrl.confirmPassword" evcloud-compare-input="$ctrl.password">
 */
angular.module('everon.component.compare-input')
       .directive('evcloudCompareInput', compareInput);

compareInput.$inject = [];

function compareInput() {
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            evcloudCompareInput: '<'
        },
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.matches = modelValue => !modelValue && !scope.evcloudCompareInput || modelValue === scope.evcloudCompareInput;

            // Watches other model for changes and validates current model against it
            scope.$watch('evcloudCompareInput', () => {
                if (ngModel.$viewValue) {
                    ngModel.$validate();
                }
            });
        }
    };
}
