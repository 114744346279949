angular.module('everon')
       .factory('mediatorService', mediatorService);

mediatorService.$inject = [];

function mediatorService() {
    const events = {};

    return {
        subscribe(event, callback, priority) {
            if (!Array.isArray(events[event])) {
                events[event] = [];
            }

            events[event].push({
                callback,
                priority: priority || 0
            });

            return this.unsubscribe.bind(this, event, callback);
        },

        subscribeOnce(event, callback, priority) {
            if (events[event] && events[event].length) {
                return;
            }

            if (!Array.isArray(events[event])) {
                events[event] = [];
            }

            events[event].push({
                callback,
                priority: priority || 0,
                once: true
            });

            return this.unsubscribe.bind(this, event, callback);
        },

        dispatch(event, data) {
            if (!events[event]) {
                return;
            }

            // Sort subscribers by priority in descending order
            events[event] = _.orderBy(events[event], ['priority'], ['desc']);
            _.invokeMap(events[event], 'callback', data);

            events[event].forEach((subscriber, index) => {
                if (subscriber.once) {
                    events[event].splice(index, 1);
                }
            });
        },

        unsubscribe(event, callback) {
            if (!events[event]) {
                return;
            }

            events[event].forEach((subscriber, index) => {
                if (subscriber.callback === callback) {
                    events[event].splice(index, 1);
                }
            });
        },

        unsubscribeAll(event) {
            if (!events[event]) {
                return;
            }

            events[event].length = 0;
        }
    };
}
