angular.module('everon.landing.registration')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.landing.registration', {
        url: '/registration?email&activationCode',
        data: {
            title: 'generic.action.signUp'
        },
        resolve: {
            data: ['$q', '$transition$', 'tenantSettingsService', ($q, $transition$, tenantSettingsService) => {
                const {email, activationCode} = $transition$.params('to');

                return $q.all({
                    params: {email, activationCode},
                    supportLinks: tenantSettingsService.getSupportLinks()
                });
            }],
            reCaptcha: ['$q', 'sessionService', 'config', ($q, sessionService, config) => {
                return !config.isMobile && !config.isDevMode ? sessionService.getReCaptchaSiteKey() : $q.resolve({});
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@anon.landing': {
                component: 'evcloudRegistration',
                bindings: {
                    dataPromise: 'data',
                    reCaptcha: 'reCaptcha'
                }
            }
        }
    });
}
