angular.module('everon.roaming.whitelisted-cards')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.whitelisted-cards', {
        url: '/whitelisted-cards?status&connectionId&issuer&query&sort&page&size',
        params: {
            sort: {
                value: 'externalId,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'roaming-whitelisted-cards-navigation-link',
            title: 'roaming.whitelistedCards.title',
            menuPriority: 3,
            navigationType: ['secondary']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/whitelisted-cards' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.whitelisted-cards');
        },
        resolve: {
            data: ['$q', 'roamingService', '$transition$', ($q, roamingService, $transition$) => {
                const params = _.pick($transition$.params('to'), ['status', 'connectionId', 'issuer', 'sort', 'query', 'page', 'size']);

                return $q.all({
                    cards: roamingService.getWhitelist(params),
                    connections: roamingService.getIssuers()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                template: '<evcloud-roaming-whitelisted-cards data-data-promise="::$resolve.data" class="flex-auto flex" />'
            }
        }
    });
}
