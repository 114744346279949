angular.module('everon.stations.station-add.station-plan')
       .component('evcloudStationAddPlan', {
           controller: 'StationAddPlanController',
           templateUrl: 'modules/stations/station-add/station-plan/station-plan.html',
           bindings: {
               dataPromise: '<'
           },
           require: {
               stationAddCtrl: '^^evcloudStationAdd'
           }
       });
