angular.module('everon.cards.card.transactions')
       .controller('CardTransactionsController', CardTransactionsController);

CardTransactionsController.$inject = ['$state', 'permissionService', 'CONSTANTS', 'mediatorService', 'cardService', 'utils', 'config', '$translate', '$q', '$rootScope', '$log', '$timeout', 'stateHelper'];

function CardTransactionsController($state, permissionService, CONSTANTS, mediatorService, cardService, utils, config, $translate, $q, $rootScope, $log, $timeout, stateHelper) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({
            startDate: setRange,
            endDate: setRange,
            sort: setSortingParams
        }, getData);

        $ctrl.range = stateHelper.getDateRangeParams();

        $ctrl.rangeDatePickers = [{
            name: 'startDate',
            config: {
                lastDate: utils.getMoment().startOf('day').toDate()
            }
        }, {
            name: 'endDate',
            config: {
                firstDate: $ctrl.range.startDate,
                // should be a constant value, otherwise config.lastDate changes dynamically.
                lastDate: utils.getMoment().endOf('day').toDate()
            }
        }];

        $ctrl.downloadButtonConfig = {
            title: 'generic.action.download',
            actions: [{
                title: 'PDF',
                type: 'application/pdf'
            }]
        };

        // CSV files have no associated apps on mobile by default
        if (!config.isMobile) {
            $ctrl.downloadButtonConfig.actions.unshift({
                title: 'CSV',
                type: 'text/csv'
            });
        }

        $ctrl.state = {
            dataLoaded: false,
            invoicingEnabled: permissionService.resolve($ctrl.profile.permissions, ['INVOICE:READ']),
            reloadInProgress: false,
            downloadButtonDisabled: false,
            downloadInProgress: false,
            showReimbursableColumn: false,
            isRoleAllowedToSeeReimbursable: false
        };

        $ctrl.columns = [
            {
                property: 'endedAt',
                translationKey: 'generic.label.endedAt',
                sortable: true,
                cssClass: 'col-3 flex-grow-1'
            },
            {
                property: 'duration',
                translationKey: 'generic.label.duration',
                sortable: true,
                cssClass: 'col-3 flex-grow-1'
            },
            {
                property: 'consumption',
                translationKey: 'generic.units.kwh',
                sortable: true,
                cssClass: 'col-2 flex-grow-1'
            }
        ];

        if ($ctrl.state.invoicingEnabled) {
            addColumn('netAmount', 'col-3 flex-grow-1');
        }

        $ctrl.noResultsConfig = {
            heading: 'generic.noResults.noTransactions',
            paragraph: 'cards.card.transactions.noResults.paragraph',
            icon: 'empty-state-cards'
        };

        $q.all([
            $ctrl.dataPromise,
            $translate('stations.station.transactions.tools.infoMessage')
        ]).then(onDataLoaded);
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    $ctrl.toggleDetails = transaction => {
        transaction.showDetails = !transaction.showDetails;
        transaction.loaded = true;
    };

    /**
     * Updates state with dates range
     */
    $ctrl.onFiltersChange = () => {
        stateHelper.changeParams($ctrl.range);
    };

    /**
     * Downloads transactions as PDF or CSV file
     * @param {Object} action
     */
    $ctrl.download = ({type}) => {
        const cardId = $ctrl.card.id;

        $log.info(`Downloading card ${cardId} transactions`);
        $ctrl.state.downloadInProgress = true;

        cardService.downloadCardTransactions(type, {id: cardId, range: $ctrl.range})
                   .catch(error => {
                       $log.debug(error);

                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                           type: 'error',
                           messageKey: 'generic.notification.downloadFileError'
                       });
                   })
                   .finally(() => $timeout(() => {
                       $ctrl.state.downloadInProgress = false;
                   }));
    };

    $ctrl.copyMeterValue = meterValueId => {
        if (meterValueId) {
            utils.copyToClipboard(`meter-value-${meterValueId}`);
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                type: 'success',
                messageKey: 'generic.notification.measurementCopied'
            });
        }
    };

    function setRange() {
        $ctrl.range = stateHelper.getDateRangeParams();

        const {startDate, endDate} = $ctrl.range;
        const startOfEndDate = utils.getMoment(endDate).startOf('day').toDate();
        const today = utils.getMoment().startOf('day').toDate();

        $ctrl.rangeDatePickers[1].config.firstDate = startDate;
        $ctrl.rangeDatePickers[0].config.lastDate = startOfEndDate >= today ? startOfEndDate : (startDate < today ? startOfEndDate : startDate);

        /**
         * Check date range immediately after it changed to prevent clicking on download button with invalid range.
         * Later, after transactions fetched, check again if we got any transactions and they are within the past year.
         */
        checkDownloadButtonAvailability();
    }

    /**
     * Set sorting params from the state and re-orders transactions accordingly
     */
    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
        $ctrl.transactions = cardService.orderBy($ctrl.transactions, $ctrl.sortingParams);
    }

    /**
     * Fetches filtered data from the server
     * @param {Object} changedParams
     */
    function getData(changedParams) {
        if ($ctrl.filtersForm.$invalid || !_(changedParams).omit('sort')
                                                           .size()) {
            return;
        }

        $ctrl.state.reloadInProgress = true;

        cardService.getClosedTransactions($ctrl.card.id, $ctrl.range)
                   .then(onDataUpdated);
    }

    /**
     * Checks if selected range is within the past year and transactions are not empty. The start date and current date are included in 1 year range for transaction download, hence we subtract 1
     * day for moment diff to pass.
     */
    function checkDownloadButtonAvailability() {
        const startDateMoment = utils.getMoment($ctrl.range.startDate);
        const endDateMoment = utils.getMoment($ctrl.range.endDate).subtract(1, 'day');

        $ctrl.state.downloadButtonDisabled = endDateMoment.diff(startDateMoment, 'year') > 0 || !$ctrl.transactions.length || $ctrl.filtersForm.$invalid;
    }

    /**
     * Updates transactions
     * @param {Array} data
     */
    function onDataUpdated(data) {
        $ctrl.transactions = cardService.orderBy(data, $ctrl.sortingParams);
        checkDownloadButtonAvailability();
        $ctrl.state.reloadInProgress = false;
    }

    /**
     * Updates columns config with passed name column
     * @param {string} colName
     * @param {string} [cssClass=col-2]
     */
    function addColumn(colName, cssClass = 'col-2') {
        $ctrl.columns.push({
            property: colName,
            translationKey: `generic.label.${colName}`,
            sortable: true,
            cssClass
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded([{transactions, card, reimbursement, isRoleAllowedToSeeReimbursable}, downloadButtonTooltip]) {
        $ctrl.card = card;
        $ctrl.transactions = transactions;
        setSortingParams();

        if ($rootScope.app.touch) {
            $ctrl.downloadButtonTooltipMobile = downloadButtonTooltip;
        } else {
            $ctrl.downloadButtonTooltipDesktop = downloadButtonTooltip;
        }

        $ctrl.state.showReimbursableColumn = isRoleAllowedToSeeReimbursable && Boolean(reimbursement.rate);
        $ctrl.state.isRoleAllowedToSeeReimbursable = isRoleAllowedToSeeReimbursable;

        if ($ctrl.state.showReimbursableColumn) {
            addColumn('reimbursableAmount', 'col-3 flex-grow-1');
        }

        $ctrl.state.downloadButtonDisabled = !transactions.length;
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [card.reference || card.contractId || card.rfid, 'current']);
    }
}
