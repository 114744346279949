angular.module('everon')
       .factory('searchMixin', searchMixin);

searchMixin.$inject = [];

function searchMixin() {
    return {
        /**
         * Returns pagination data
         * @param {Object} data
         * @returns {Object}
         */
        getPagination(data) {
            return _.pick(data, ['totalElements', 'totalPages', 'last', 'first', 'number', 'size']);
        }
    };
}
