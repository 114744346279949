angular.module('everon.landing.login-callback')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.landing.login-callback', {
        url: '/callback',
        data: {
            title: 'landing.login.title',
            sourceState: null,
            sourceParams: null
        },
        params: {
            activationCompleted: false,
            activationExpired: false,
            registrationSent: false
        },
        views: {
            'content@anon.landing': 'evcloudLoginOAuthCallback'
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/landing/login-callback' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.landing.login-callback');
        }
    });
}
