angular.module('everon.component.product-catalog')
       .controller('ProductCatalogController', ProductCatalogController);

ProductCatalogController.$inject = ['$window', '$element', '$timeout'];

function ProductCatalogController($window, $element, $timeout) {
    const $ctrl = this;
    const productsListSelector = '#product-catalog-products';
    const plansListSelector = '#product-catalog-plans';
    let productsScroller, plansScroller;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            products: {
                scrollArrowsHidden: {
                    left: true,
                    right: true
                }
            },
            plans: {
                scrollArrowsHidden: {
                    left: true,
                    right: true
                }
            }
        };

        $ctrl.model = $ctrl.selectedProduct;

        if (!$ctrl.model) {
            $ctrl.model = {
                product: $ctrl.products[0],
                plan: $ctrl.products[0].plans[0]
            };
        }

        updateSelectedIndexes();
    };

    $ctrl.$postLink = () => {
        productsScroller = $element[0].querySelector(productsListSelector);
        plansScroller = $element[0].querySelector(plansListSelector);

        productsScroller.addEventListener('scroll', () => updateScrollers(productsScroller));
        plansScroller.addEventListener('scroll', () => updateScrollers(plansScroller));

        $window.addEventListener('resize', () => updateScrollers(productsScroller));

        scrollSelectedInToView(productsScroller);
        scrollSelectedInToView(plansScroller);
    };

    $ctrl.$onDestroy = () => {
        productsScroller.removeEventListener('scroll', updateScrollers);
        $window.removeEventListener('resize', updateScrollers);
    };

    $ctrl.selectProduct = product => {
        $ctrl.model = {
            product,
            plan: product.plans[0]
        };

        $ctrl.onSelect({model: $ctrl.model});
        updateSelectedIndexes();
        scrollSelectedInToView(productsScroller, 0);
        updateScrollers(plansScroller);
    };

    $ctrl.selectPlan = plan => {
        $ctrl.model.plan = plan;
        $ctrl.onSelect({model: $ctrl.model});
        updateSelectedPlanIndex();
        scrollSelectedInToView(plansScroller, 0);
    };

    $ctrl.scrollProducts = direction => {
        scroll(productsScroller, direction);
    };

    $ctrl.scrollPlans = direction => {
        scroll(plansScroller, direction);
    };

    /**
     * Requests element to scroll in a specific directions
     * @param {Object} [scroller] the list container where to apply the scroll
     * @param {string} direction The scrolling direction
     */
    function scroll(scroller, direction) {
        let scrollUnit = scroller.scrollWidth / scroller.children.length * 1.5;

        scrollUnit = (direction === 'right') ? scrollUnit : -scrollUnit;
        scroller.scrollLeft += scrollUnit;
    }

    function updateSelectedIndexes() {
        $ctrl.currentIndex = {
            product: _.findIndex($ctrl.products, {id: $ctrl.model.product.id}),
            plan: _.findIndex($ctrl.model.product.plans, {id: $ctrl.model.plan.id})
        };
    }

    function updateSelectedPlanIndex() {
        $ctrl.currentIndex.plan = _.findIndex($ctrl.model.product.plans, {id: $ctrl.model.plan.id});
    }

    /**
     * Scrolls selected element into view
     * @param {Object} [scroller] parent containing the element to scroll to
     * @param {number} [delay] Can be scrolled immediately for when the element is in the DOM already
     */
    function scrollSelectedInToView(scroller, delay) {
        $timeout(() => {
            const selected = scroller.querySelector('[aria-selected="true"]');

            // scroll to the center
            scroller.scrollLeft = selected.offsetLeft - scroller.parentElement.offsetWidth / 2 + selected.offsetWidth / 2;
            updateScrollers(scroller);
        }, delay || 200);
    }

    /**
     * Calculates the state for the scrolling button
     * @param {Object} [scroller] the list container where to update
     */
    function updateScrollers(scroller) {
        const elWidth = scroller.clientWidth;
        let throttle = false;
        const stateProperty = scroller.id.slice((scroller.id.lastIndexOf('-') + 1));

        if (!throttle) {
            $window.requestAnimationFrame(() => {
                $timeout(() => {
                    $ctrl.state[stateProperty].scrollArrowsHidden = {
                        left: !scroller.scrollLeft,
                        right: scroller.scrollWidth <= scroller.scrollLeft + elWidth
                    };
                }, 0);

                throttle = false;
            });

            throttle = true;
        }
    }
}
