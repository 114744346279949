angular.module('everon')
       .factory('languageService', languageService);

languageService.$inject = ['$http'];

function languageService($http) {
    /**
     * Transforms an array of language objects into a languages map
     * @param {Array} data
     * @returns {Object}
     */
    function transformData(data) {
        const map = {};

        data.forEach(item => {
            map[item.tag] = item.name;
        });

        return map;
    }

    /**

     * Adds a property to each language containing the country short code

     * @param {Array} data

     * @returns {Array}

     */

    function addShortCodeKey(data) {
        return data.map(item => {
            item.shortCode = item.tag && item.tag.split('-')[0].toUpperCase();

            return item;
        });
    }

    return {
        /**
         * Returns an array of all available languages
         * @returns {Array}
         */
        getPlatformLanguages() {
            return $http.get('/api/platform/languages');
        },

        /**
         * Returns a map of tenant languages where key is a language and country tag (en-GB) and value is a language name (English)
         * @returns {Object}
         */
        getTenantLanguagesMap() {
            return this.getTenantLanguages()
                       .then(transformData);
        },

        /**
         * Returns an array of tenant language objects consisting of tag and name properties.
         * @returns {Array}
         */
        getTenantLanguages() {
            return $http.get('/api/platform/tenant-settings/language').then(addShortCodeKey);
        },

        /**
         * Sends an array of updated language tags to the server
         * @param {Array} data
         * @returns {Array}
         */
        updateTenantLanguages(data) {
            return $http.put('/api/platform/tenant-settings/language', _.map(data, 'tag'));
        }
    };
}
