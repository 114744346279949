angular.module('everon.stations.station.logs')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station.logs', {
        url: '/logs?query,from,to',
        data: {
            id: 'station-logs-navigation-link',
            title: 'stations.station.logs.title',
            navigationType: ['context'],
            menuPriority: 100,
            permissions: ['STATION:CONTROL_LOG']
        },
        params: {
            from: {
                value: ['stationService', stationService => stationService.getDefaultLogsRange().from],
                type: 'date',
                squash: true
            },
            to: {
                value: ['stationService', stationService => stationService.getDefaultLogsRange().to],
                type: 'date',
                squash: true
            }
        },
        dynamic: true,
        resolve: {
            data: ['$q', '$transition$', 'data', 'stationService', ($q, $transition$, data, stationService) => {
                const {query, from, to} = $transition$.params('to');

                return $q.all({
                    station: data,
                    logs: stationService.getStationLogs({stationId: data.id}, {query, from, to})
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.stations.station': {
                template: '<evcloud-station-logs data-data-promise="::$resolve.data" />'
            }
        }
    });
}
