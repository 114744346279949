angular.module('everon.welcome')
       .controller('WelcomeAccountBillingInformationController', WelcomeAccountBillingInformationController);

WelcomeAccountBillingInformationController.$inject = ['$q', 'CONSTANTS', 'accountService', 'sessionService', 'mediatorService', '$state', 'validationService'];

function WelcomeAccountBillingInformationController($q, CONSTANTS, accountService, sessionService, mediatorService, $state, validationService) {
    const $ctrl = this;
    const subAccountRoles = CONSTANTS.ROLES.SUB_ACCOUNT_INVITATION;
    let modelCopy = null;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            hasChanged: false,
            saveInProgress: false,
            transitionInProgress: false,
            accountTypeLocked: false,
            isDriverAccount: false,
            hasAccountName: false
        };

        $ctrl.asyncIbanValidatorConfig = {
            name: 'iban',
            validatorFn: verifyIban
        };

        $ctrl.asyncBicValidatorConfig = {
            name: 'bic',
            validatorFn: verifyBic
        };

        $ctrl.welcomeCtrl.currentStateIndex = _.findIndex($ctrl.welcomeCtrl.states, {name: $state.current.name});

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.previous = () => {
        $ctrl.state.transitionInProgress = true;
        $ctrl.welcomeCtrl.previous().finally(() => {
            $ctrl.state.transitionInProgress = false;
        });
    };

    /**
     * Updates state
     */
    $ctrl.onChange = () => {
        setChanged(true);
    };

    $ctrl.onTypeChange = () => {
        if (!$ctrl.model.isBusiness) {
            _.forEach($ctrl.model.businessDetails, (value, key, collection) => {
                collection[key] = null;
            });
        }

        setChanged(true);
    };

    /**
     * Cleanup account model and attempt an update
     */
    $ctrl.save = () => {
        const {billingAddress, billingContact, invoiceLanguage, accountName} = $ctrl.model;

        let billingInfo = {
            billingAddress,
            billingContact,
            invoiceLanguage
        };

        if ($ctrl.model.isBusiness) {
            billingInfo = {
                ...billingInfo,
                businessDetails: $ctrl.model.businessDetails
            };
        }

        if (!$ctrl.state.hasAccountName) {
            billingInfo = {
                ...billingInfo,
                accountName
            };
        }

        $ctrl.state.saveInProgress = true;

        accountService.saveBillingInfo(billingInfo)
                      .then(() => $ctrl.state.accountTypeLocked ? accountService.updateReimbursementSettings($ctrl.model.reimbursement) : $q.resolve())
                      .then(onFulfilled)
                      .catch(onRejected)
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    /**
     * Extends model with additional properties
     * @param {Object} billing
     * @param {Object} reimbursement
     * @returns {Object}
     */
    function extendModel(billing, reimbursement) {
        const defaults = {
            businessDetails: {},
            billingAddress: {},
            billingContact: buildBillingContactInformation(),
            invoiceLanguage: $ctrl.profile.language.tag,
            isBusiness: Boolean(billing.isBusiness),
            reimbursement
        };

        return _.defaultsDeep({}, defaults, billing);
    }

    /**
     * Stringifies model, replacing all `null` values with empty strings to be consistent with ngModel defaults
     * @param {Object} model
     * @returns {*}
     */
    function stringifyModel(model) {
        return JSON.stringify(model).replace(/null/g, '""');
    }

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => _.assign(item, {id: _.kebabCase(item.name)}));
    }

    /**
     * Builds the billingContact object
     * @returns {Object}
     */
    function buildBillingContactInformation() {
        return {
            name: `${$ctrl.profile.firstName} ${$ctrl.profile.lastName}`,
            email: $ctrl.profile.email
        };
    }

    /**
     * Checks whether account details have changed while editing
     * @returns {boolean}
     */
    function hasModelChanged() {
        return modelCopy !== stringifyModel($ctrl.model);
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        $ctrl.state.hasChanged = hasChanged && hasModelChanged();
    }

    /**
     * Verifies if IBAN is valid
     * @param {string} modelValue
     * @param {string} viewValue
     * @returns {Promise}
     */
    function verifyIban(modelValue, viewValue) {
        if (!modelValue || $ctrl.modelCopy && JSON.parse($ctrl.modelCopy).iban === viewValue) {
            return $q.resolve();
        }

        return validationService.verifyIban(modelValue.replace(/\s/g, ''));
    }

    /**
     * Verifies if SWIFT/BIC is valid
     * @param {string} modelValue
     * @param {string} viewValue
     * @returns {Promise}
     */
    function verifyBic(modelValue, viewValue) {
        if (!modelValue || $ctrl.modelCopy && JSON.parse($ctrl.modelCopy).bic === viewValue) {
            return $q.resolve();
        }

        return validationService.verifyBic(modelValue.replace(/\s/g, ''));
    }

    /**
     * Successful account set up
     * @returns {Function}
     */
    function onFulfilled() {
        $ctrl.welcomeCtrl.state.stepsCompleted = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.CLOSE_NOTIFICATION);

        return sessionService.loadSession(true); // Need to update the session with correct data
    }

    /**
     * Failed account set up
     * @param {Object} response
     */
    function onRejected({status}) {
        const errorMessageMap = {
            409: 'account.notification.accountNameExistsError',
            417: 'generic.error.invalidAddress'
        };

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            isPersistent: true,
            messageKey: errorMessageMap[status] || CONSTANTS.ERROR_CODES[status]
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({accountFormModel, billing, reimbursement, countries, profile}) {
        $ctrl.profile = profile;
        $ctrl.model = extendModel(billing, reimbursement);
        $ctrl.countries = countries;
        $ctrl.accountFormModel = extendFormModel(accountFormModel);
        $ctrl.state.isDriverAccount = profile.roles.includes(CONSTANTS.ROLES.DRIVER);
        $ctrl.state.hasAccountName = Boolean(billing.accountName);
        modelCopy = stringifyModel($ctrl.model);

        // Sub-account owner and Driver role can't select account type and enter reimbursement rate
        $ctrl.state.accountTypeLocked = _.intersection(profile.roles, subAccountRoles).length;

        // TODO: https://myevbox.atlassian.net/browse/DTS-841
        if ($ctrl.state.accountTypeLocked) {
            $ctrl.currency = {
                code: 'EUR',
                symbol: '€'
            };
        }

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
    }
}
