angular.module('everon.billing-plans.plans.plan-add.plan-eligibility')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans.plan-add.plan-eligibility', {
        data: {
            title: 'generic.title.eligibility',
            order: 1
        },
        views: {
            'content@auth.billing-plans.plans.plan-add': {
                component: 'evcloudPlanAddEligibility'
            }
        }
    });
}
