angular.module('everon.component.data-list')
       .controller('DataListController', DataListController);

DataListController.$inject = ['dataList'];

function DataListController(dataList) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.extendedColumns = $ctrl.columns.map(column => _.assign({}, column, {ariaLabeledBy: _.includes(column.property, '.') ? _.camelCase(column.property) : column.property}));

        // If sortingParams provided, then the component sets an initially active column
        if ($ctrl.sortingParams) {
            setActiveColumn($ctrl.sortingParams);
        }
    };

    /**
     * Reacts to the sorting changes from the parent component
     * @param {Object} changes
     */
    $ctrl.$onChanges = changes => {
        const {sortingParams} = changes;

        if (sortingParams && !sortingParams.isFirstChange()) {
            setActiveColumn(sortingParams.currentValue);
        }
    };

    /**
     * Orders data by a given column in list view
     * @param {Object} column
     */
    $ctrl.orderBy = column => {
        dataList.updateColumnState($ctrl.extendedColumns, column, !column.desc);

        if (_.isFunction($ctrl.onChange)) {
            const {property, desc} = column;
            const order = desc ? 'desc' : 'asc';

            // Parent component's callback
            $ctrl.onChange({sortingParams: {property, order}});
        }
    };

    /**
     * Sets a column with given property to be active.
     * @param {Object} sortingParams
     */
    function setActiveColumn({property, order}) {
        const activeColumn = _.find($ctrl.extendedColumns, {property});
        const isDesc = order === 'desc';

        dataList.updateColumnState($ctrl.extendedColumns, activeColumn, isDesc);
    }
}
