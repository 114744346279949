export default {
    update(state, [prop, value]) {
        state[prop] = value;
    },

    updateRemovableUrlParams(state, params) {
        const {removableUrlParams} = state;
        const paramsToAdd = params.filter(param => !removableUrlParams.includes(param));

        state.removableUrlParams = [...removableUrlParams, ...paramsToAdd];
    }
};
