angular.module('everon.cards.card')
       .controller('CardController', CardController);

CardController.$inject = ['mediatorService', 'CONSTANTS'];

function CardController(mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        $ctrl.card = data;
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [data.reference || data.contractId, 'current']);
    }
}
