angular.module('everon.profile')
       .controller('PasswordConfirmationModalController', PasswordConfirmationModalController);

PasswordConfirmationModalController.$inject = ['CONSTANTS', 'close', 'data'];

function PasswordConfirmationModalController(CONSTANTS, close, data) {
    const $ctrl = this;

    $ctrl.state = {
        passwordVisible: false
    };

    $ctrl.data = data;

    $ctrl.modalData = {};

    $ctrl.togglePasswordVisibility = () => {
        $ctrl.state.passwordVisible = !$ctrl.state.passwordVisible;
    };

    $ctrl.submit = event => {
        close($ctrl.modalData);
        resetState();

        event.stopPropagation();
    };

    $ctrl.cancel = () => {
        close();
        resetState();
    };

    /**
     * Resets controller's state so that subsequent openings of the modal start fresh
     */
    function resetState() {
        $ctrl.state.passwordVisible = false;
        $ctrl.modalData = {};
        $ctrl.passwordConfirmationForm.$setPristine();
        $ctrl.passwordConfirmationForm.$setUntouched();
    }
}
