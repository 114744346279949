angular.module('everon.stations.list')
       .component('evcloudStationsList', {
           controller: 'StationsListController',
           templateUrl: 'modules/stations/stations-list/stations-list.html',
           bindings: {
               dataPromise: '<',
               profile: '<'
           }
       });

