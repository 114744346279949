angular.module('everon.cards.card-import')
       .controller('CardImportController', CardImportController);

CardImportController.$inject = ['cardService', 'CONSTANTS', 'mediatorService', '$state'];

function CardImportController(cardService, CONSTANTS, mediatorService, $state) {
    const $ctrl = this;
    const formName = 'cardDetailsForm';
    const subscribers = [];

    $ctrl.$onInit = () => {
        $ctrl.state = {
            importAnother: false,
            saveInProgress: false
        };

        $ctrl.regex = {
            contractId: CONSTANTS.REGEX.CONTRACT_ID,
            rfid: CONSTANTS.REGEX.CARD_RFID
        };

        $ctrl.model = {};
        $ctrl.modelCopy = JSON.stringify($ctrl.model);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    };

    $ctrl.$onDestroy = () => {
        _.invokeMap(subscribers, _.call);
    };

    /**
     * Saves station details
     */
    $ctrl.save = () => {
        $ctrl.state.saveInProgress = true;

        cardService.importCard($ctrl.model)
                   .then(() => {
                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                           type: 'success'
                       });

                       if (!$ctrl.state.importAnother) {
                           $state.go('auth.cards', null, {reload: 'auth.cards'});
                       }

                       resetFormState();
                   })
                   .catch(response => {
                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                           type: 'error',
                           messageKey: (response.status === 409) ? 'cards.cardImport.notification.cardExists' : CONSTANTS.ERROR_CODES[response.status]
                       });
                   })
                   .finally(() => {
                       $ctrl.state.saveInProgress = false;
                   });
    };

    $ctrl.onCancel = () => {
        $state.go('auth.cards', null, {reload: 'auth.cards'});
    };

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
        $ctrl.model = {};
    }
}
