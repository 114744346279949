angular.module('everon.tenant-settings.companies.company')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.companies.company', {
        url: '/:id',
        data: {
            title: 'generic.entity.legalEntity'
        },
        resolve: {
            data: ['$q', '$transition$', 'countryService', 'financialService', 'tenantCompanyService', ($q, $transition$, countryService, financialService, tenantCompanyService) => {
                return $q.all({
                    currencies: financialService.getCurrencies(),
                    countries: countryService.getCountries(),
                    company: tenantCompanyService.getCompany($transition$.params('to').id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.tenant-settings': {
                component: 'evcloudCompany',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
