angular.module('everon.component.upload')
       .controller('UploadController', UploadController);

UploadController.$inject = ['$scope', '$element', 'uploadService', 'CONSTANTS', 'mediatorService'];

function UploadController($scope, $element, uploadService, CONSTANTS, mediatorService) {
    const $ctrl = this;
    let inputElement;

    $ctrl.$onInit = () => {
        inputElement = $element.find('input');
        $ctrl.config = _.defaults($ctrl.config, {
            id: `upload-${$ctrl.config.url.split('/').pop()}`,
            onError: angular.noop
        });
        $ctrl.progress = 0;
        $ctrl.state = {
            enableProgress: $ctrl.config.enableProgress || false,
            inProgress: false,
            done: false,
            error: false
        };
        $ctrl.file = {};

        inputElement.off('change', onFileChange)
                    .on('change', onFileChange);
    };

    /**
     * Cancel any pending uploads and remove event listeners
     */
    $ctrl.$onDestroy = () => {
        uploadService.cancel();
        inputElement.off('change', onFileChange);
    };

    /**
     * Updates progress indicator
     * @param {number} percentage
     */
    function progressHandler(percentage) {
        $scope.$evalAsync(() => {
            $ctrl.progress = percentage;
        });
    }

    /**
     * Gets the files and delegates to the service for actual uploading
     * @param {Event} event
     */
    function onFileChange({target}) {
        const {files} = target;

        if (!files.length) {
            return;
        }

        $ctrl.file.name = files.length > 1 ? _.map(files, 'name').join(', ') : files[0].name;
        $ctrl.state.inProgress = true;
        $ctrl.state.done = false;

        // If an error was shown during a previous attempt we dismiss it
        if ($ctrl.state.error) {
            mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.CLOSE_NOTIFICATION);
            $ctrl.state.error = false;
        }

        uploadService.upload(files, {
                         url: $ctrl.config.url,
                         progressHandler: $ctrl.config.enableProgress ? progressHandler : null
                     })
                     .then(response => {
                         $ctrl.state.done = true;
                         $ctrl.config.onUpload(response);
                     })
                     .catch(response => {
                         // ontimeout event returns ProgressEvent with type='timeout' when uploading is timed out.
                         const status = response.type === 'timeout' ? '-1' : response.status;

                         $ctrl.state.error = true;
                         $ctrl.config.onError(response.response);
                         mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                             type: 'error',
                             messageKey: ($ctrl.config.validationMessages &&
                                 $ctrl.config.validationMessages[status]) ?
                                 $ctrl.config.validationMessages[status] :
                                 CONSTANTS.ERROR_CODES[status],
                             isPersistent: true
                         });
                     })
                     .finally(() => {
                         $ctrl.state.inProgress = false;
                         $ctrl.progress = 0;
                         target.value = null; // To allow for multiple uploads of the same file
                     });
    }
}
