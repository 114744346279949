angular.module('everon.billing-plans.plans')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans', {
        url: '/plans?sort',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'plans-navigation-link',
            title: 'billingPlans.plans.title',
            menuPriority: 2,
            navigationType: ['secondary']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/plans' */ './index-lazy.js');

                return transition.injector()
                                 .get('lazyLoadService')
                                 .loadModule(importPromise, 'everon.billing-plans.plans');
        },
        resolve: {
            data: ['$q', 'subscriptionService', ($q, subscriptionService) => {
                return $q.all({
                    hasCardProducts: subscriptionService.hasProducts('card'),
                    hasStationProducts: subscriptionService.hasProducts('station'),
                    plans: subscriptionService.getPlans()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.billing-plans': {
                template: '<evcloud-plans data-data-promise="::$resolve.data" class="flex-auto flex flex-column" />'
            }
        }
    });
}
