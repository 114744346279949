angular.module('everon.billing-plans.products.product-add.product-overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.products.product-add.product-overview', {
        data: {
            title: 'generic.title.overview',
            order: 2
        },
        views: {
            'content@auth.billing-plans.products.product-add': {
                component: 'evcloudProductAddOverview'
            }
        }
    });
}
