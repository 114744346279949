angular.module('everon')
    .factory('featureToggleService', featureToggleService);

featureToggleService.$inject = ['localStore'];

function featureToggleService(localStore) {
    const noop = () => 0;

    function newFeature(isEnabled) {
        const cacheStore = {};

        return {
            get() {
                return isEnabled();
            },
            select({disabledObject, enabledObject}) {
                return isEnabled() ? enabledObject : disabledObject;
            },
            exec({onDisabled = noop, onEnabled = noop}) {
                return isEnabled() ? onEnabled() : onDisabled();
            },
            cache(key, val) {
                if (arguments.length === 1) {
                    return cacheStore[key];
                }

                cacheStore[key] = val;

                return val;
            }
        };
    }

    function localStorageGetter(name) {
        return () => localStore.get(name);
    }

    const features = {
        '@@oktaUserManagement': localStorageGetter('$okta_umg$'),
        '@@useStationsList': localStorageGetter('USE_STATIONS_LIST')
    };

    return feature => {
        if (!(feature in features)) {
            throw new Error(`toggle.service: Feature '${feature}' not supported.`);
        }

        return newFeature(features[feature]);
    };
}
