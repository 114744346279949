angular.module('everon.component.clipboard')
       .controller('ClipboardController', ClipboardController);

ClipboardController.$inject = ['$timeout', 'utils'];

function ClipboardController($timeout, utils) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            copied: false
        };

        if (!$ctrl.containerId) {
            throw new Error('No value provided to `evcloud-clipboard` component attribute');
        }
    };

    $ctrl.copy = event => {
        utils.copyToClipboard($ctrl.containerId);
        $ctrl.state.copied = true;

        $timeout(() => {
            $ctrl.state.copied = false;
        }, 1000);

        event.stopPropagation();
    };
}
