angular.module('everon.stations.charging-profiles')
       .factory('chargingProfilesService', chargingProfilesService);

chargingProfilesService.$inject = ['$q', '$http', '$translate', 'CONSTANTS', 'sessionService'];

function chargingProfilesService($q, $http, $translate, CONSTANTS, sessionService) {
    const daysInAWeek = 7;
    const singlePhaseVoltage = 230;
    const threePhaseVoltage = 400;

    /**
     * Transforms a day schedule from {offset, current} to {percentage, opacity} notation.
     * An array with 2 offsets will become an array with three sections, totalling 100%.
     * @param {Array} schedule
     * @param {number} maxCurrent
     * @returns {Array}
     */
    function dayScheduleToPowerBar(schedule, maxCurrent) {
        return schedule.map((range, index, schedule) => {
            return {
                percentage: (schedule.length > index + 1 ? (schedule[index + 1].offset - range.offset) : (CONSTANTS.TIMES.SECONDS_PER_DAY - range.offset)) / CONSTANTS.TIMES.SECONDS_PER_DAY * 100,
                opacity: range.current / maxCurrent
            };
        });
    }

    function dayScheduleStartsAtMidnight(schedule) {
        return schedule[0].offset === 0;
    }

    /**
     * Creates a label for the hour
     * @param {number} hours (0-23)
     * @returns {string} label (0-12:00 AM/PM)
     */
    function hourLabel(hours) {
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;

        return `${hours}:00 ${ampm}`;
    }

    return {
        /**
         * Returns a blank charging profile
         * @returns {Object}
         */
        getEmptyProfile() {
            return {};
        },

        /**
         * Get a charging profile based on its id
         * @param {string} id - profile id
         * @returns {Promise.Object} Charging profile
         */
        getChargingProfile(id) {
            return $http.get('/api/platform/charging-profiles/:id', {params: {id}});
        },

        /**
         * Get all charging profiles visible to the current user
         * @returns {Promise.Object} Object of charging profiles grouped by ownership level
         */
        getChargingProfilesForCurrentAccount() {
            return $http.get('/api/platform/charging-profiles');
        },

        /**
         * Creates a new charging profile
         * @param {Object} params
         * @returns {Promise}
         */
        createChargingProfile(params) {
            return $http.post('/api/platform/charging-profiles', params);
        },

        /**
         * Updates a charging profile
         * @param {string} id
         * @param {Object} params
         * @returns {Promise}
         */
        updateChargingProfile(id, params) {
            return $http.put('/api/platform/charging-profiles/:id', params, {params: {id}});
        },

        /**
         * Attempts to delete the specified charging profile.
         * It will fail with a 409 HTTP error status if the charging profile is assigned to any station.
         * @param {string} id
         * @returns {Promise}
         */
        deleteChargingProfile(id) {
            return $http.delete('/api/platform/charging-profiles/:id', {params: {id}});
        },

        /**
         * Overrides charging profile's scheduled max current to use station's max current
         * @param {Object} params - composed by the station's and connector's identity codes and the transaction id
         * @returns {Promise}
         */
        overrideMaxCurrent(params) {
            return $http.post('/api/platform/charging-profiles/override', params);
        },

        /**
         * Generate 7 daily schedules
         * @param {Object} profile - complete charging schedule for a week
         * @returns {Object} with 7 day schedules
         */
        generateDaySchedules(profile) {
            const schedules = {};

            [...Array(daysInAWeek).keys()].forEach(dayIndex => schedules[dayIndex] = this.getScheduleByDay(profile, dayIndex));

            return schedules;
        },

        /**
         * Gets a day schedule from a complete charging profile.
         * An offset 0 (midnight) is always added.
         * @param {Object} profile - complete charging profile for a week
         * @param {number} dayIndex - 0-6
         * @returns {Array} all offsets relative to a single day (0 to 86400 seconds)
         */
        getScheduleByDay(profile, dayIndex) {
            if (!Array.isArray(profile.schedule)) {
                throw new TypeError('Week schedule should be an array');
            }

            const dayOffset = dayIndex * CONSTANTS.TIMES.SECONDS_PER_DAY;
            const dayEnd = dayOffset + CONSTANTS.TIMES.SECONDS_PER_DAY;
            const daySchedule = [];

            profile.schedule.forEach(chargingInterval => {
                if (chargingInterval.offset >= dayOffset && chargingInterval.offset < dayEnd) {
                    const interval = _.assign({}, chargingInterval);

                    interval.offset -= dayOffset;
                    daySchedule.push(interval);
                }
            });

            if (daySchedule.length === 0 || !dayScheduleStartsAtMidnight(daySchedule)) {
                daySchedule.unshift({offset: 0, current: profile.maxCurrent});
            }

            return daySchedule;
        },

        /**
         * Builds default weekDays object
         * @returns {Array}
         */
        buildDefaultWeekDaysObject() {
            return _.range(daysInAWeek).map(index => {
                const dayIndex = (index + CONSTANTS.DATES.FIRST_DAY_OF_WEEK) % daysInAWeek;

                return {
                    name: CONSTANTS.DATES.DAYS[dayIndex],
                    index: index,
                    isExpanded: false
                };
            });
        },

        /**
         * Builds schedules payload from daySchedules object
         * @param {Object} daySchedules - complete charging profile schedules for a week
         * @returns {Array}
         */
        buildSchedulesPayload(daySchedules) {
            return _(daySchedules).map((daySchedule, index) => _.map(daySchedule, schedule => ({offset: (index * CONSTANTS.TIMES.SECONDS_PER_DAY) + schedule.offset, current: schedule.current})))
                                  .flatten()
                                  .value();
        },

        /**
         * Create a power bar for each day in the week
         * @param {Object} daySchedules
         * @param {number} maxCurrent
         * @returns {Object} Dictionary with day indexes for each power bar
         */
        createPowerBarsFromDaySchedules(daySchedules, maxCurrent) {
            const powerBars = {};

            Object.keys(daySchedules).forEach(dayIndex => powerBars[dayIndex] = dayScheduleToPowerBar(daySchedules[dayIndex], maxCurrent));

            return powerBars;
        },

        /**
         * Creates offset options per interval per day of the week
         * @param {Object} daySchedules
         * @param {number} numberIntervals
         * @returns {Object}
         */
        createOffsetOptions(daySchedules, numberIntervals) {
            const defaultOptions = _.range(numberIntervals).map(hour => {
                return {
                    option: hourLabel(hour),
                    value: hour * CONSTANTS.TIMES.SECONDS_PER_HOUR
                };
            });

            return _.range(daysInAWeek).map(day => {
                const excludedOptions = _.map(daySchedules[day], 'offset');
                const allOptions = _.range(numberIntervals).map(option => option * CONSTANTS.TIMES.SECONDS_PER_HOUR); // should be moved outside the loop
                const includedOptions = _.difference(allOptions, excludedOptions);

                return _.map(daySchedules[day], schedule => {
                    let options = [];

                    options.push(...includedOptions, schedule.offset);
                    options.sort((a, b) => a - b);
                    options = _.filter(defaultOptions, option => options.includes(option.value));

                    const selected = _.find(defaultOptions, option => option.value === schedule.offset);

                    return {options, selected};
                });
            });
        },

        /**
         * Translates the week days into the current language, gets the first character and transform it to upper case
         * @returns {Promise.Array}
         */
        getWeekDaysInitials() {
            return $q.all(_.range(daysInAWeek).map(index => {
                const dayIndex = (index + CONSTANTS.DATES.FIRST_DAY_OF_WEEK) % daysInAWeek;

                return $translate(`generic.datetime.days.${CONSTANTS.DATES.DAYS[dayIndex]}`).then(translatedWeeDay => {
                    return translatedWeeDay.slice(0, 1).toUpperCase();
                });
            }));
        },

        /**
         * Translates the charging profile group names based on the user roles
         * @param {string} stationAccountName
         * @returns {Promise.Array}
         */
        translateChargingProfileGroupNames(stationAccountName) {
            const isTenantUser = this.currentUserCanEditTenantProfiles();
            const tenantTranslationKey = `stations.chargingProfiles.group.${isTenantUser ? 'byAccountName' : 'predefined'}`;
            const accountTranslationKey = `stations.chargingProfiles.group.${isTenantUser ? 'byAccountName' : 'yours'}`;
            const userAccountName = sessionService.getUserProfile().accountName;

            return $q.all({
                tenantGroupName: $translate(tenantTranslationKey, {accountName: isTenantUser ? userAccountName : undefined}),
                accountGroupName: $translate(accountTranslationKey, {accountName: isTenantUser ? stationAccountName : undefined})
            });
        },

        /**
         * Calculates power for single and three phase connector based on the current value for the whole day
         * @param {Array} daySchedule
         * @returns {Array}
         */
        calculatePowerValuesForDay(daySchedule) {
            return _.map(daySchedule, interval => this.calculatePowerValues(interval.current));
        },

        /**
         * Calculates power for single and three phase connector based on the current value. Return '-' when the current is undefined
         * @param {number} current
         * @returns {Object}
         */
        calculatePowerValues(current) {
            const currentValueIsValid = current >= 0;
            const undefinedCurrentValue = '-';

            return {
                singlePhase: currentValueIsValid ? parseFloat((current * singlePhaseVoltage / 1000).toFixed(1)) : undefinedCurrentValue,
                threePhase: currentValueIsValid ? parseFloat((current * Math.sqrt(3) * threePhaseVoltage / 1000).toFixed(1)) : undefinedCurrentValue
            };
        },

        /**
         * Translates the charging profile group names based on the user roles
         * @returns {boolean}
         */
        currentUserCanEditTenantProfiles() {
            const rolesAllowedToEditTenantProfiles = ['tenantAdmin'];
            const userRoles = sessionService.getUserProfile().roles;

            return Boolean(_.intersection(userRoles, rolesAllowedToEditTenantProfiles).length);
        }
    };
}
