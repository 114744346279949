angular.module('everon.stations.station-location-form')
       .controller('StationLocationFormController', StationLocationFormController);

StationLocationFormController.$inject = ['$timeout', 'mapService'];

function StationLocationFormController($timeout, mapService) {
    const $ctrl = this;
    let geoCoordinatesCountry;
    let addressObjectFromGeoCoordinates;

    $ctrl.$onInit = () => {
        $ctrl.model = Object.assign($ctrl.parentModel, {
            location: {
                address: {},
                geoCoordinates: {},
                directions: ''
            },
            features: {
                advenir: false
            }
        });

        $ctrl.state = {
            locationInFrance: false,
            addressFormModelVisible: false,
            geoCoordinatesFormModelVisible: false,
            matchingCountries: false,
            mapDirectionsDisabled: true,
            mapApiLoaded: false
        };

        if (geoCoordinatesAvailable()) {
            getGeoCoordinatesCountry().then(countryObject => {
                geoCoordinatesCountry = countryObject;
            });
        }

        mapService.loadMapApi()
                  .then(() => $ctrl.state.mapApiLoaded = true);

        $ctrl.locationFormModel = extendFormModel(_.pick($ctrl.locationData.stationLocationForm, ['address', 'geoCoordinates']));
        $ctrl.countries = $ctrl.locationData.countries;
        $ctrl.geoCoordinatesForMap = undefined;
    };

    /**
     * Manages selected country change and validates that countries from address and geo-coords are matching
     */
    $ctrl.onCountryChange = () => {
        const countryCode = _.get($ctrl.model.location.address, 'country.code');

        if (verifyCountryMatch(countryCode, 'address')) {
            manageLocationInFrance(countryCode);

            $ctrl.addressInputBlur();

            return;
        }

        $ctrl.state.mapDirectionsDisabled = false;
    };

    /**
     * Sets the visibility of the specific form
     * @param {string} formName
     */
    $ctrl.toggleFormVisibility = formName => {
        const formVisibilityKey = `${formName}FormModelVisible`;

        $ctrl.state[formVisibilityKey] = !$ctrl.state[formVisibilityKey];

        if (!$ctrl.state[formVisibilityKey]) {
            manageModelOnCollapseForm(formName);
        } else if (formName === 'address') {
            manageModelOnExpandAddress();
        }
    };

    /**
     * Manages geo-coordinates input blur, resolves address from them and updates the state
     * @param {string} geoCoordinates
     */
    $ctrl.geoCoordinatesInputBlur = (geoCoordinates = undefined) => {
        if (!geoCoordinatesAvailable() && !geoCoordinates) {
            manageUndefinedGeoCoordinates();

            return;
        }

        getGeoCoordinatesAddressComponents().then(addressComponents => {
            geoCoordinatesCountry = addressComponents.find(component => component.types.includes('country'));

            if (verifyCountryMatch(geoCoordinatesCountry.short_name, 'geoCoordinates')) {
                manageLocationInFrance(geoCoordinatesCountry.short_name);

                if ($ctrl.state.geoCoordinatesFormModelVisible) {
                    $ctrl.geoCoordinatesForm.geoCoordinates.$setValidity('inCountry', true);
                }
            }

            updateMapInfoAndState(geoCoordinates ? geoCoordinates : $ctrl.model.location.geoCoordinates.geoCoordinates, false);

            if (!$ctrl.state.addressFormModelVisible) {
                addressObjectFromGeoCoordinates = buildAddressFromAddressComponents(addressComponents);
            }
        }).catch(() => {
            $ctrl.geoCoordinatesForm.geoCoordinates.$setValidity('inCountry', false);
            $ctrl.geoCoordinatesForm.geoCoordinates.$setDirty();
        });
    };

    /**
     * Manages address inputs blur, checks for minimum information for enable the map and resolves geo-coords from address, and updates the state
     */
    $ctrl.addressInputBlur = () => {
        const {streetName, house, postcode, city, country} = $ctrl.model.location.address;
        const infoToResolvePresent = Boolean(streetName && postcode && city && country);

        if (!infoToResolvePresent) {
            updateMapInfoAndState(undefined, true);

            return;
        }

        addressObjectFromGeoCoordinates = $ctrl.model.location.address;

        mapService.getAddressCoordinates(`${streetName} ${house}, ${postcode}, ${city}, ${country.name}`)
                  .then(({lat, lng}) => {
                      $ctrl.model.location.geoCoordinates = {geoCoordinates: `${lat}, ${lng}`};

                      updateMapInfoAndState($ctrl.model.location.geoCoordinates.geoCoordinates, false);
                  });
    };

    /**
     * Updates the model with the position coming from drag-and-drop-map component
     * @param {Object} position - LatLng object from Google Maps API
     */
    $ctrl.onPositionChanged = position => {
        const processedPosition = mapService.getGeoCoordinatesStringFromLatLng(position);

        if ($ctrl.state.geoCoordinatesFormModelVisible) {
            $ctrl.geoCoordinatesInputBlur(processedPosition);
        }

        $ctrl.model.location.geoCoordinates.geoCoordinates = processedPosition;
    };

    /**
     * Prevents sub-form to be 'submitted' -expanded/collapsed- on Enter keydown
     * @param {Object} $event - which contains a keyCode property
     */
    $ctrl.preventSubmitFormOnEnter = ({keyCode}) => {
        if (keyCode === 13) {
            event.preventDefault();
        }
    };

    /**
     * Extends location form model with `id` property
     * @param {Object} formModel
     * @returns {Array}
     */
    function extendFormModel(formModel) {
        return _.map(formModel, (form, key) => {
            return {
                name: _.camelCase(key),
                formItems: form.map(value => Object.assign(value, {id: _.kebabCase(value.name)}))
            };
        });
    }

    /**
     * Checks that country form address and geo-coordinates is the same when toggling sub-form visibility
     * @returns {boolean}
     */
    function verifyCountryMatchOnToggle() {
        if (!isManageCountryMatchNeeded()) {
            return true;
        }

        const geoCoordinatesCountryCode = _.get(geoCoordinatesCountry, 'short_name');

        return manageCountryMatch(geoCoordinatesCountryCode === _.get($ctrl.model.location.address, 'country.code'));
    }

    /**
     * Checks that country form address and geo-coordinates is the same
     * @param {string} countryCode
     * @param {string} formName
     * @returns {boolean}
     */
    function verifyCountryMatch(countryCode, formName) {
        if (!isManageCountryMatchNeeded()) {
            return true;
        }

        const countriesMatching = (formName === 'address' ? _.get(geoCoordinatesCountry, 'short_name') : _.get($ctrl.model.location.address, 'country.code')) === countryCode;

        return manageCountryMatch(countriesMatching);
    }

    /**
     * Checks if invoking manageCountryMatch is needed by verifying that both sub-forms are expanded and country and geo-coordinates fields specified
     * @returns {boolean}
     */
    function isManageCountryMatchNeeded() {
        const {addressFormModelVisible, geoCoordinatesFormModelVisible} = $ctrl.state;
        const {address} = $ctrl.model.location;

        return addressFormModelVisible && geoCoordinatesFormModelVisible && _.has(address, 'country') && geoCoordinatesAvailable();
    }

    /**
     * Manage reaction to country match verification based on the match attribute by updating the sub-forms' country related inputs state
     * @param {boolean} match
     * @returns {boolean}
     */
    function manageCountryMatch(match) {
        $ctrl.addressForm.country.$setValidity('unique', match);
        $ctrl.geoCoordinatesForm.geoCoordinates.$setValidity('unique', match);

        if (!match) {
            $ctrl.addressForm.country.$setDirty();
            $ctrl.geoCoordinatesForm.geoCoordinates.$setDirty();
        }

        return match;
    }

    /**
     * Manages locationInFrance state property and advenir feature property
     * @param {string} countryCode
     */
    function manageLocationInFrance(countryCode = '') {
        $ctrl.state.locationInFrance = countryCode === 'FR';
        $ctrl.model.features.advenir = $ctrl.state.locationInFrance;
    }

    /**
     * Attempts to resolve country  for coordinates
     * @returns {Promise.<Object|Error>}
     */
    function getGeoCoordinatesCountry() {
        return getGeoCoordinatesAddressComponents().then(addressComponents => addressComponents.find(component => component.types.includes('country')));
    }

    /**
     * Attempts to resolve country  for coordinates
     * @param {Object} latLng
     * @returns {Promise.<Object|Error>}
     */
    function getGeoCoordinatesAddressComponents() {
        const [lat, lng] = mapService.getLatLngFromGeoCoordinatesString($ctrl.model.location.geoCoordinates.geoCoordinates);

        return mapService.getCoordinatesAddressComponents(mapService.latLng(lat, lng));
    }

    /**
     * Checks if geo-coordinates information is available in the model
     * @returns {boolean}
     */
    function geoCoordinatesAvailable() {
        return Boolean(_.get($ctrl.model.location.geoCoordinates, 'geoCoordinates'));
    }

    /**
     * Updates model when collapsing a form
     * @param {string} formName
     */
    function manageModelOnCollapseForm(formName) {
        if (!$ctrl.state.addressFormModelVisible && !$ctrl.state.geoCoordinatesFormModelVisible) {
            geoCoordinatesCountry = null;
            addressObjectFromGeoCoordinates = null;
            $ctrl.model.location.geoCoordinates = null;
            $ctrl.model.location.address = null;
            $ctrl.geoCoordinatesForMap = null;
            $ctrl.state.mapDirectionsDisabled = true;

            return;
        }

        if (formName === 'geoCoordinates') {
            geoCoordinatesCountry = null;
            $ctrl.addressInputBlur();
        } else {
            $ctrl.model.location.address = null;
        }

        manageCountryMatch(true);
    }

    /**
     * Updates model when expanding address form and verifies country matching
     */
    function manageModelOnExpandAddress() {
        if (addressObjectFromGeoCoordinates) {
            $ctrl.model.location.address = _.cloneDeep(addressObjectFromGeoCoordinates);
        }

        if (geoCoordinatesCountry) {
            $timeout(verifyCountryMatchOnToggle);
        }
    }

    /**
     * Manages case when geo-coordinates are undefined and updates state
     */
    function manageUndefinedGeoCoordinates() {
        $ctrl.state.mapDirectionsDisabled = true;

        manageLocationInFrance();
    }

    /**
     * Builds address object from addressComponents coming from Google Maps API
     * @param {Object} addressComponents
     * @returns {Object}
     */
    function buildAddressFromAddressComponents(addressComponents) {
        const streetNameObject = addressComponents.find(component => component.types.includes('route'));
        const houseObject = addressComponents.find(component => component.types.includes('street_number'));
        const postcodeObject = addressComponents.find(component => component.types.includes('postal_code'));
        const cityObject = addressComponents.find(component => component.types.includes('locality'));
        const countryObject = addressComponents.find(component => component.types.includes('country'));

        return {
            streetName: streetNameObject && streetNameObject.long_name,
            house: houseObject && houseObject.long_name,
            postcode: postcodeObject && postcodeObject.long_name,
            city: cityObject && cityObject.long_name,
            country: countryObject && {name: countryObject.long_name, code: countryObject.short_name}
        };
    }

    /**
     * Updates geo-coordinates for the map and set map/directions state
     * @param {string} geoCoordinates
     * @param {boolean} disabled
     */
    function updateMapInfoAndState(geoCoordinates, disabled) {
        $ctrl.geoCoordinatesForMap = geoCoordinates;
        $ctrl.state.mapDirectionsDisabled = disabled;
    }
}
