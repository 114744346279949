angular.module('everon.account.billing-information')
       .controller('AccountBillingInformationController', AccountBillingInformationController);

AccountBillingInformationController.$inject = ['CONSTANTS', 'accountService', 'mediatorService', 'sessionService'];

function AccountBillingInformationController(CONSTANTS, accountService, mediatorService, sessionService) {
    const $ctrl = this;
    let modelCopy = null;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            hasChanged: false,
            saveInProgress: false,
            accountTypeLocked: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state
     */
    $ctrl.onChange = () => {
        setChanged(true);
    };

    $ctrl.onTypeChange = () => {
        if (!$ctrl.model.isBusiness) {
            _.forEach($ctrl.model.businessDetails, (value, key, collection) => {
                collection[key] = null;
            });
        }

        setChanged(true);
    };

    /**
     * Clean up account model and attempt an update
     */
    $ctrl.save = () => {
        if (!$ctrl.state.hasChanged) {
            resetFormState();

            return;
        }

        const account = _($ctrl.model).omit(['number', 'isBusiness', 'accountId'])
                                      .assign({invoiceLanguage: $ctrl.model.invoiceLanguage.tag})
                                      .value();

        if (!$ctrl.model.isBusiness) {
            delete account.businessDetails;
        }

        $ctrl.state.saveInProgress = true;

        accountService.saveBillingInfo(account, $ctrl.model.accountId)
                      .then(onFulfilled)
                      .catch(onRejected)
                      .finally(() => {
                          $ctrl.state.saveInProgress = false;
                      });
    };

    $ctrl.cancel = () => {
        restoreModel(JSON.parse(modelCopy));
        resetFormState();
    };

    /**
     * Extends model with additional properties
     * @param {Object} model
     * @returns {Object}
     */
    function extendModel(model) {
        const defaults = {
            businessDetails: {},
            billingContact: {},
            billingAddress: {}
        };

        return _.defaultsDeep({}, defaults, model);
    }

    /**
     * Stringifies model, replacing all `null` values with empty strings to be consistent with ngModel defaults
     * @param {Object} model
     * @returns {*}
     */
    function stringifyModel(model) {
        return JSON.stringify(model).replace(/null/g, '""');
    }

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => _.assign(item, {id: _.kebabCase(item.name)}));
    }

    /**
     * Checks whether account details have changed while editing
     * @returns {boolean}
     */
    function hasModelChanged() {
        return modelCopy !== stringifyModel($ctrl.model);
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        $ctrl.state.hasChanged = hasChanged && hasModelChanged();
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl.billingInformationForm.$setUntouched();
        $ctrl.billingInformationForm.$setPristine();
        setChanged(false);
    }

    /**
     * Restores the original model
     * @param {Object} model
     */
    function restoreModel(model) {
        $ctrl.model = model;
    }

    /**
     * Successful account billing-information save
     * @param {Object} data
     * @returns {Function}
     */
    function onFulfilled(data) {
        $ctrl.model = extendModel(data);
        modelCopy = JSON.stringify($ctrl.model);
        $ctrl.state.accountTypeLocked = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'success'
        });

        resetFormState();

        // Gets updated account features since a change in the country may change them
        return sessionService.updateFeatureFlags().then(() => {
            mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.NAV_UPDATE);
        });
    }

    /**
     * Failed account billing-information save
     * @param {Object} response
     */
    function onRejected({status}) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: status === 409 ? 'account.notification.accountNameExistsError' : (status === 417 ? 'generic.error.invalidAddress' : CONSTANTS.ERROR_CODES[status])
        });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({accountOwner, accountSetupComplete, accountFormModel, billing, countries, languages}) {
        $ctrl.model = extendModel(billing);
        modelCopy = stringifyModel($ctrl.model);
        $ctrl.accountFormModel = extendFormModel(accountFormModel);
        $ctrl.countries = countries;
        $ctrl.languages = languages;

        // Prevent account type modification
        $ctrl.state.accountTypeLocked = accountSetupComplete;
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [accountOwner && accountOwner.id !== sessionService.getUserProfile().id ? billing.accountName : 'parent', 'current']);
    }
}
