angular.module('everon.core')
       .config(stateProviderDecorator);

stateProviderDecorator.$inject = ['$stateProvider'];

function stateProviderDecorator($stateProvider) {
    const internalStates = {};

    /**
     * Inherits permissions from parent state definition
     * @param {Object} parent
     * @param {Object} state
     * @returns {Array}
     */
    function inheritParentPermissions(parent, state) {
        return _.has(state, 'data.permissions') ? _.uniq(state.data.permissions.concat(parent.data.permissions)) : parent.data.permissions;
    }

    function handlePermissions(parent, state) {
        if (_.has(parent, 'data.permissions')) {
            if (!state.self.data) {
                state.self.data = {};
            }

            state.self.data.permissions = inheritParentPermissions(parent, state.self);
        }
    }

    /**
     * Decorates parent state and current state
     * @param {Object} state
     * @param {Function} builderFn
     * @returns {Object}
     */
    function decorateParent(state, builderFn) {
        const parent = builderFn(state);

        if (parent) {
            internalStates[parent.self.name] = parent;

            if (!Array.isArray(internalStates[parent.self.name].children)) {
                internalStates[parent.self.name].children = [];
            }

            internalStates[parent.self.name].children.push(state.self);

            state.self.getChildren = () => {
                return _.get(internalStates[state.self.name], 'children', []);
            };

            handlePermissions(parent, state);
        }

        return parent;
    }

    // Decorates `state` by adding an array of immediate children states to it
    $stateProvider.decorator('parent', decorateParent);
}
