angular.module('everon.accounts.account.settings')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.accounts.account.settings', {
        url: '/settings',
        data: {
            id: 'account-settings-navigation-link',
            title: 'generic.title.settings',
            navigationType: ['context'],
            menuPriority: 5,
            permissions: ['ACCOUNT:UPDATE_ALL']
        },
        resolve: {
            data: ['$q', '$transition$', 'accountService', ($q, $transition$, accountService) => {
                    const accountId = $transition$.params('to').id;

                    return $q.all({
                        accountId,
                        billingOverview: accountService.getBillingOverview(accountId),
                        accountFeatureSettings: accountService.getFeatureFlagsForAccount(accountId)
                    });
                }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.accounts.account': {
                component: 'evcloudAccountSettings',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
