angular.module('everon')
       .factory('countryService', countryService);

countryService.$inject = ['$http'];

function countryService($http) {
    return {
        /**
         * Returns an array of all available countries
         * @returns {Array}
         */
        getCountries() {
            return $http.get('/api/platform/countries');
        },

        /**
         * Returns a list of tenant supported countries - the countries that where defined under tenant settings
         * @returns {Promise.<Array>}
         */
        getTenantCountries() {
            return $http.get('/api/billing/settings/supported-countries');
        },

        /**
         * Returns a list of country and currency maps. The currency is derived from tenant countries/companies setup under tenant settings
         * @returns {Promise.<Array>}
         */
        getTenantCountryCurrencies() {
            return $http.get('/api/billing/settings/country-currencies');
        }
    };
}
