angular.module('everon.component.nav-main')
       .controller('NavMainController', NavMainController);

NavMainController.$inject = ['CONSTANTS', 'mediatorService', 'localStore', 'brandingService'];

function NavMainController(CONSTANTS, mediatorService, localStore, brandingService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.logo = brandingService.resolveImage('logo');
        mediatorService.subscribe(CONSTANTS.EVENTS.ACTION.LOGO_UPDATE, onLogoUpdate);
    };

    $ctrl.toggleNav = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.ACTION.TOGGLE_NAV);
    };

    function onLogoUpdate(logo) {
        $ctrl.logo = logo || brandingService.getDefaultImage('logo');
    }
}
