angular.module('everon.cards.card-add.card-billing-information')
       .component('evcloudCardAddBillingInformation', {
           templateUrl: 'modules/cards/card-add/card-billing-information/card-billing-information.html',
           controller: 'CardAddBillingInformationController',
           bindings: {
               dataPromise: '<'
           },
           require: {
               cardAddCtrl: '^^evcloudCardAdd'
           }
       });
