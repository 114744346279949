angular.module('everon.cards.card-add.card-details')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card-add-on-behalf.card-details', {
        url: '?contractId',
        data: {
            title: 'generic.title.details',
            order: 0
        },
        views: {
            'content@auth.cards.card-add-on-behalf': {
                template: '<evcloud-card-add-details class="flex flex-column overflow-hidden"/>'
            }
        }
    });
}
