import vueModules from '../../index-vue';

export default async function createVueApp(dataPromise) {
    const {Vue, i18n} = await vueModules;

    /* eslint-disable-next-line no-new */
    new Vue({
        el: '#support',

        components: {
            EvoSupport: () => import(/* webpackChunkName: 'app/support' */ './support.vue')
        },

        data: {
            dataPromise
        },

        i18n
    });
}
