angular.module('everon.stations.station-add.station-overview')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-add-on-behalf.station-overview', {
        data: {
            title: 'generic.title.overview',
            order: 2
        },
        views: {
            'content@auth.stations.station-add-on-behalf': {
                template: '<evcloud-station-add-overview class="flex flex-column overflow-hidden col-12"/>'
            }
        }
    });
}
