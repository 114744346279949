angular.module('everon.stations')
       .factory('stationService', stationService);

stationService.$inject = ['$http', '$q', 'utils', 'transactionMixin', 'stationEligibilityMixin', 'searchMixin', 'sortingMixin', 'CONSTANTS', 'fileService'];

function stationService($http, $q, utils, transactionMixin, stationEligibilityMixin, searchMixin, sortingMixin, CONSTANTS, fileService) {
    const DEFAULT_CONNECTOR_STATUS = 'unknown';

    /**
     * Extends a single station with location, isHub and connectorStatus properties
     * @param {Object} station
     * @returns {Object}
     */
    function extendStation(station) {
        const {
            status,
            address = {},
            evses
        } = station;

        return Object.assign({}, station, {
            location: (!['inactive', 'pending'].includes(status)) ? `${address.streetName} ${address.house}, ${address.city}` : null,
            isHub: Array.isArray(evses) && evses.length > 1,
            connectorStatus: null
        });
    }

    /**
     * Extends single station by adding location, isHub and connectorStatus properties and transforms it renaming evses to connectors
     * @param {Object} station
     * @returns {Object}
     */
    function transformStation(station) {
        const {evses: connectors, ...stationWithoutEvses} = extendStation(station);

        return {connectors, ...stationWithoutEvses};
    }

    /**
     * Transforms station logs adding `isExpanded` property to each log item and settings `payload` to `null` if it is an empty object
     * @param {Object} logs
     * @returns {Object}
     */
    function transformStationLogs(logs) {
        logs.items = logs.items.map(item => _.assign({}, item, {isExpanded: false, payload: (_.isEmpty(item.payload) ? null : item.payload)}));

        return logs;
    }

    /**
     * Transforms the array of status. If the connector exists in the Platform but doesn't in the OCPP, the status is DEFAULT_CONNECTOR_STATUS
     * @param {Array} connectors
     * @param {Array} statuses
     * @returns {Array}
     */
    function transformConnectorStatuses(connectors, statuses) {
        const statusByIdentityCode = _.keyBy(statuses, 'evseIdentityCode');

        return _.map(connectors, connector => statusByIdentityCode[connector.identityCode] || {identityCode: connector.identityCode, status: DEFAULT_CONNECTOR_STATUS});
    }

    /**
     * Creates a map of objects - key/value pairs where key is a station identity code and value is an array containing objects with connector status and count
     * @param {Array} connectorStatuses
     * @param {Array} stations
     * @returns {Object}
     */
    function mapStatus(connectorStatuses, stations) {
        const statusByStation = {};

        Object.keys(connectorStatuses)
              .forEach(stationIdentityCode => {
                  const station = _.find(stations, {identityCode: stationIdentityCode});

                  statusByStation[stationIdentityCode] = getStatusByStation(connectorStatuses[stationIdentityCode], station);
              });

        return statusByStation;
    }

    /**
     * Returns and array of single station evse/connector statuses including their count
     * @param {Array} connectorStatus
     * @param {Object} station
     * @returns {Array}
     */
    function getStatusByStation(connectorStatus, station) {
        const connectorCountByStatus = _.countBy(transformConnectorStatuses(station.evses || station.connectors, connectorStatus), 'status');

        return _(connectorCountByStatus).keys()
                                        .map(status => ({
                                            status,
                                            count: connectorCountByStatus[status]
                                        }))
                                        .orderBy(['status', 'asc'])
                                        .value();
    }

    /**
     * Returns charge point status info
     * @param {Object} data
     * @returns {Object}
     */
    function getChargePointStatus(data) {
        return _.pick(data, ['resetAt']);
    }

    /**
     * Builds stations list params for communicating to the BE from received and default params
     * @param {Object} params
     * @returns {Object}
     */
    function buildStationsListParams(params) {
        const defaultParams = {
            page: 0,
            size: 30
        };

        return {
            params: _(params).pickBy()
                             .defaults(defaultParams)
                             .value()
        };
    }

    /**
     * Extends transaction object
     * @param {string} transaction
     * @returns {Object}
     */
    function extendTransaction(transaction) {
        return _.assign({}, transaction, {
            open: true,
            duration: utils.formatDuration(transaction.duration, CONSTANTS.DATES.DATETIME_FORMATS.TIME_SECONDS)
        });
    }

    /**
     * Returns transformed product
     * @param {Object} product
     * @returns {Object}
     */
    function transformProductCatalog(product) {
        return _.assign({}, product, {
            defaultCurrency: product.plans && product.plans[0].currency,
            hasPublicTransactionFee: _.some(product.plans, plan => {
                return !_.isEmpty(plan.publicTransactionFee);
            }),
            characteristics: _.filter(product.characteristics, 'enabled'),
            price: _(product.plans).map('price')
                                   .sortBy(_.identity)
                                   .first()
        });
    }

    /**
     * Appends new transform function to the existing ones if any
     * @param {Function|Array} defaults
     * @param {Function} transform
     * @returns {Array}
     */
    function appendConfigurationTransform(defaults, transform) {
        // We can't guarantee that the default transformation is an array
        defaults = angular.isArray(defaults) ? defaults : [defaults];

        // Append the new transformation to the defaults
        return defaults.concat(transform);
    }

    /**
     * Transforms pdf service response
     * @param {ArrayBuffer} data
     * @param {Function} headersGetter
     * @returns {Object}
     */
    function transformConfigurationResponse(data, headersGetter) {
        return {
            lastModified: utils.toLocalMoment(headersGetter()['last-modified']).toDate(),
            groups: data
        };
    }

    /**
     * Updates configuration on load and on refresh
     * @param {Object} data
     * @returns {Object}
     */
    function updateConfiguration(data) {
        return {
            groups: data.status === 204 ? [] : transformConfiguration(data.groups),
            lastModified: data.lastModified
        };
    }

    /**
     * Assigns extra props to each group and transforms into an array
     * @param {Object} configuration
     * @returns {Array}
     */
    function transformConfiguration(configuration) {
        return _.map(configuration, (groupKeys, key) => ({
            key,
            isExpanded: false,
            items: castKeyValues(groupKeys)
        }));
    }

    /**
     * Prepares some types for the view and keeps a copy of the current set value
     * @param {Array} groupKeys
     * @returns {Array}
     */
    function castKeyValues(groupKeys) {
        groupKeys.forEach(key => {
            // keeping a copy of the original value
            key.currentValue = JSON.stringify(key.value);

            switch (key.type) {
                case 'integer':
                    key.value = parseInt(key.value, 10); // BE can't make sure it is a number
                    break;

                case 'multiselect':
                    if (key.readOnly) {
                        key.value = key.value.join(', '); // Just to display something nicer then an array
                    }

                    break;
                default:
                    break;
            }
        });

        return groupKeys;
    }

    /**
     * In case there are no associated tariffs, a 204 is returned
     * @param {Object} response
     * @returns {boolean}
     */
    function isPublicChargingEnabled(response) {
        return response.status === 200;
    }

    function extendStationsList(data) {
        return Object.assign(data, {content: data.content.map(extendStation)});
    }

    function transformStationCountResponse(type) {
        return ({data}) => ({type, count: data});
    }

    /**
     * Normalizes evse object to common structure.
     * Stations list evses contains identityCode while statuses from /statuses endpoint has evseIdentityCode.
     * @param {Object} obj - EVSE
     * @param {string} obj.status - EVSE status
     * @param {string} obj.identityCode - EVSE identity code in the stations list response
     * @param {string} obj.evseIdentityCode - EVSE identity code in the statuses response
     * @returns {Object}
     */
    function normalizeEvse({status, identityCode, evseIdentityCode}) {
        return {
            status,
            evseIdentityCode: evseIdentityCode || identityCode || ''
        };
    }

    /**
     * Takes evse statuses from the list of stations
     * @param {Array} stations
     * @returns {Object}
     */
    function getStatusesFromStationsList(stations) {
        const statusesByIdentityCode = stations.reduce((acc, station) => {
            const {identityCode = '', evses = []} = station;

            if (evses.length) {
                acc[identityCode] = evses.map(normalizeEvse);
            }

            return acc;
        }, {});

        return mapStatus(statusesByIdentityCode, stations);
    }

    return Object.assign({}, transactionMixin, stationEligibilityMixin, searchMixin, sortingMixin, {
        /**
         * Verifies if station with a given identity code exists and if it has an owner
         * @param {string} identityCode
         * @returns {Promise.<Object>}
         */
        verifyStation(identityCode) {
            return $http.get('/api/platform/stations/verification', {params: {identityCode}});
        },

        /**
         * Verifies if station security code is valid
         * @param {string} identityCode
         * @param {string} securityCode
         * @returns {Promise.<Object>}
         */
        verifySecurityCode(identityCode, securityCode) {
            return $http.get('/api/platform/stations/verification', {params: {identityCode, securityCode}});
        },

        /**
         * Returns paginated stations
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getStations(params) {
            return $http.get('/api/platform/stations', buildStationsListParams(params))
                        .then(data => _.assign(data, {content: _.map(data.content, transformStation)}));
        },

        /**
         * Imports a station. Station status is `inactive`
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        importStation(requestData) {
            return $http.post('/api/platform/stations/import', requestData);
        },

        /**
         * Activates inactive station on behalf of another user
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        activateStationOnBehalf(requestData) {
            return $http.post('/api/platform/stations/activation-on-behalf', requestData);
        },

        /**
         * Activates inactive station
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        activateStation(requestData) {
            return $http.post('/api/platform/stations/activation', requestData);
        },

        /**
         * Activates pending station
         * @param {string} id
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        confirmStationActivation(id, requestData) {
            return $http.patch('/api/platform/stations/:id/confirm-activation', requestData, {params: {id}});
        },

        /**
         * Returns a single station by a given id
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        getStation(id) {
            return $http.get('/api/platform/stations/:id', {params: {id}})
                        .then(station => {
                            station.connectors = station.evses;

                            return _.omit(station, 'evses');
                        });
        },

        /**
         * Updates station
         * @param {string} id
         * @param {Object} requestData
         * @returns {Promise.<Object>}
         */
        updateStation(id, requestData) {
            return $http.patch('/api/platform/stations/:id', requestData, {params: {id}});
        },

        /**
         * Deletes inactive station with a given ID
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        deleteStation(id) {
            return $http.delete('/api/platform/stations/:id', {params: {id}});
        },

        /**
         * Returns a pending station with a given ID back to the inactive state
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        cancelPendingStationActivation(id) {
            return $http.delete('/api/platform/stations/:id/pending-activation', {params: {id}});
        },

        /**
         * Returns station logs for a given station
         * @param {Object} params
         * @param {Object} range
         * @returns {Promise.<Object>}
         */
        getStationLogs(params, {query, from, to}) {
            return $http.get('/api/platform/stations/:stationId/ocpp-logs', {params: _.assign(params, {query}, utils.localDatesToUTC({from, to}))})
                        .then(transformStationLogs);
        },

        /**
         * Returns an array of all transactions for a given station
         * @param {string} stationId
         * @param {Object} range
         * @returns {Promise.<Array>}
         */
        getClosedTransactions(stationId, range) {
            return $http.get('/api/billing/transactions/stations/:stationId', {params: _.assign({stationId, status: 'closed'}, utils.localDatesToUTC(range))})
                        .then(_.partialRight(_.map, this.transformTransactionDetails));
        },

        /**
         * Returns an array of open transactions for a given station
         * @param {string} stationId
         * @returns {Promise.<Array>}
         */
        getOpenTransactions(stationId) {
            return $http.get('/api/platform/stations/:stationId/transactions', {params: {stationId}})
                        .then(_.partialRight(_.map, extendTransaction));
        },

        /**
         * Returns an array of all transactions for a given station connector
         * @param {string} stationId
         * @param {string} connectorId
         * @param {Object} range
         * @returns {Promise.<Array>}
         */
        getClosedTransactionsByConnector(stationId, connectorId, range) {
            return $http.get('/api/billing/transactions/stations/:stationId/evses/:connectorId', {
                            params: _.assign({
                                stationId,
                                connectorId,
                                status: 'closed'
                            }, utils.localDatesToUTC(range))
                        })
                        .then(_.partialRight(_.map, this.transformTransactionDetails));
        },

        /**
         * This endpoint checks whether the card is internal or external so that we could decide if we need to show the link to card view on station assistance page. If card is found
         * (internal), response is 200 with card view object, otherwise - 404 is returned
         * @param {string} contractId
         * @returns {Promise}
         */
        getCardByContractId(contractId) {
            return $http.get('/api/tokens/contract/:contractId', {params: {contractId}})
                        .catch(() => ({}));
        },

        /**
         * Finds station connector by given id
         * @param {Object} station
         * @param {string} connectorId
         * @returns {Object}
         */
        getConnectorById(station, connectorId) {
            return _.find(station.connectors, {id: connectorId});
        },

        /**
         * Returns a list of station products where each products contains a list of plans
         * @param {string} country
         * @param {string} identityCode
         * @param {string} accountId
         * @returns {Promise.<Array>}
         */
        getProductCatalog(country, identityCode, accountId) {
            return $http.get('/api/billing/product-catalog/stations', {params: {country, identityCode, accountId}})
                        .then(_.partialRight(_.map, transformProductCatalog));
        },

        /**
         * Gets OCPP charge point object
         * @param {string} id
         * @returns {Promise.<Object>}
         */
        getChargePointData(id) {
            return $http.get('/api/platform/stations/metadata/:id', {params: {id}});
        },

        /**
         * Gets OCPP charge point data and extracts status info from it
         * @param {string} id
         * @returns {Promise}
         */
        getChargePointResetStatus(id) {
            return this.getChargePointData(id)
                       .then(getChargePointStatus);
        },

        /**
         * Returns a map of station identity codes and their connector statuses
         * @param {Array} stations
         * @returns {Promise}
         */
        getStationStatus(stations) {
            const stationIds = _(stations).filter({status: 'active'})
                                          .map('id')
                                          .value();

            return stationIds ?
                $http.get('/api/platform/stations/statuses', {params: {stationId: stationIds}})
                     .then(_.partialRight(mapStatus, stations)) :
                $q.resolve({});
        },

        /**
         * Returns the station connectors status
         * @param {Object} station
         * @returns {Promise}
         */
        getStationConnectorsStatus(station) {
            return $http.get('/api/platform/stations/:stationId/evses/statuses', {params: {stationId: station.id}})
                        .then(_.partial(transformConnectorStatuses, station.connectors));
        },

        /**
         * Resets the station with a given id
         * @param {string} stationId
         * @returns {Promise}
         */
        reset(stationId) {
            return $http.post('/api/platform/stations/:stationId/reset', null, {params: {stationId: stationId, wait: true}});
        },

        /**
         * Sets the station's maximum current to the provided value
         * @param {string} stationId
         * @param {number} maxCurrent
         * @returns {Promise}
         */
        setStationMaxCurrent(stationId, maxCurrent) {
            const payload = {key: 'evb_MaximumStationCurrent', value: maxCurrent};

            return this.setStationConfigurationKey(stationId, payload);
        },

        /**
         * Sets the connector's phase rotations
         * @param {string} stationId
         * @param {string} phaseRotations
         * @returns {Promise}
         */
        setConnectorPhaseRotations(stationId, phaseRotations) {
            const payload = {key: 'ConnectorPhaseRotation', value: phaseRotations};

            return this.setStationConfigurationKey(stationId, payload);
        },

        /**
         * Returns a list of available firmwares
         * @returns {Promise.<Array>}
         */
        getFirmwares() {
            return $http.get('/api/platform/stations/firmwares');
        },

        /**
         * Updates firmware with a given id
         * @param {string} stationId
         * @param {string} firmwareName
         * @returns {Promise}
         */
        updateFirmware(stationId, firmwareName) {
            return $http.post('/api/platform/stations/:stationId/firmware/:firmwareName', null, {
                params: {
                    stationId,
                    firmwareName,
                    wait: true
                }
            });
        },

        /**
         * Returns a list of predefined OCPP action names
         * @param {string} stationId
         * @returns {Promise}
         */
        getOcppActions(stationId) {
            return $http.get('/api/platform/stations/:stationId/actions', {params: {stationId}});
        },

        /**
         * Sends OCPP message to the station
         * @param {string} stationId
         * @param {Object} data
         * @returns {Promise}
         */
        sendOcppMessage(stationId, data) {
            return $http.post('/api/platform/stations/:stationId/message', data, {params: {stationId, wait: true}});
        },

        /**
         * Remotely unlocks station connector
         * @param {string} stationId
         * @param {string} evseId
         * @returns {Promise}
         */
        unlockConnector(stationId, evseId) {
            return $http.post('/api/platform/stations/:stationId/evses/:evseId/unlock', null, {
                params: {
                    stationId,
                    evseId,
                    wait: true
                }
            });
        },

        /**
         * Remotely stops an open transaction
         * @param {string} stationId
         * @param {string} transactionId
         * @returns {Promise}
         */
        stopTransaction(stationId, transactionId) {
            return $http.post('/api/platform/stations/:stationId/transactions/:transactionId/stop', null, {
                params: {
                    stationId,
                    transactionId,
                    wait: true
                }
            });
        },

        /**
         * Remotely starts an transaction
         * @param {string} stationId
         * @param {string} connectorId
         * @param {string} rfid
         * @returns {Promise}
         */
        startTransaction(stationId, connectorId, rfid) {
            return $http.post('/api/platform/stations/:stationId/transactions/start',
                {
                    evseIdentityCode: connectorId,
                    idTag: rfid
                },
                {
                    params: {
                        stationId,
                        wait: true
                    }
                });
        },

        /**
         * Returns default logs range - from beginning of the day os yesterday to midnight of today (tomorrow at 0:00 hours)
         * @returns {Object}
         */
        getDefaultLogsRange() {
            const to = utils.getMoment().endOf('day').toDate();
            const from = utils.getMoment(to).subtract(1, 'day').startOf('day').toDate();

            return {from, to};
        },

        /**
         * Returns station capabilities
         * @param {Object} [model]
         * @returns {Object}
         */
        getStationCapabilities(model) {
            const connectivityTypes = this.extendConnectivityTypes(_.get(model, 'connectivityTypes'));

            return {
                connectivityTypes,
                selectedConnectivityType: model ? _.find(connectivityTypes, 'enabled') : connectivityTypes[0],
                standards: ['CHADEMO', 'DOMESTIC_A', 'DOMESTIC_B', 'DOMESTIC_C', 'DOMESTIC_D', 'DOMESTIC_E', 'DOMESTIC_F', 'DOMESTIC_G', 'DOMESTIC_H', 'DOMESTIC_I', 'DOMESTIC_J', 'DOMESTIC_K',
                    'DOMESTIC_L', 'IEC_62196_T1', 'IEC_62196_T1_COMBO', 'IEC_62196_T2', 'IEC_62196_T2_COMBO', 'IEC_62196_T3A', 'IEC_62196_T3C', 'TESLA_R', 'TESLA_S'],
                formats: ['CABLE', 'SOCKET'],
                powerTypes: ['AC_1_PHASE', 'AC_3_PHASE', 'DC'],
                connector: {
                    standard: model ? model.connector.standard : 'IEC_62196_T2',
                    format: model ? model.connector.format : 'CABLE',
                    powerType: model ? model.connector.powerType : 'AC_1_PHASE',
                    voltage: model ? model.connector.voltage : 200,
                    amperage: model ? model.connector.amperage : 16
                }
            };
        },

        /**
         * Returns the configuration model
         * @param {string} stationId
         * @param {boolean} reload
         * @returns {Promise}
         */
        getStationConfiguration(stationId, reload) {
            return $http.get('/api/platform/stations/:stationId/configuration', {
                params: {
                    stationId,
                    reload
                },
                transformResponse: appendConfigurationTransform($http.defaults.transformResponse, transformConfigurationResponse)
            }).then(data => updateConfiguration(data));
        },

        /**
         * Creates connectors payload for the actionable-cards component
         * @param {Array} connectors
         * @returns {Array}
         */
        mapConnectors(connectors) {
            return _.map(connectors, (connector, index) => {
                return {
                    id: index,
                    title: {
                        default: `Connector settings ${index + 1}`,
                        translationKey: 'stations.stationImport.connectorTitle',
                        translationValues: {index: index + 1}
                    },
                    content: [
                        {
                            name: {
                                default: 'Standard',
                                translationKey: 'generic.label.standard'
                            },
                            value: {
                                default: connector.standard,
                                icon: connector.standard.toLowerCase().replace(/_/g, '-')
                            }
                        },
                        {
                            name: {
                                default: 'Format',
                                translationKey: 'generic.label.format'
                            },
                            value: {
                                default: connector.format,
                                translationKey: `stations.stationImport.form.format.${connector.format}`
                            }
                        },
                        {
                            name: {
                                default: 'Power type',
                                translationKey: 'generic.label.powerType'
                            },
                            value: {
                                default: connector.powerType,
                                translationKey: `stations.stationImport.form.powerType.${connector.powerType}`
                            }
                        },
                        {
                            name: {
                                default: 'Power',
                                translationKey: 'generic.label.power'
                            },
                            value: {
                                default: `${connector.voltage} V | ${connector.amperage} A`
                            }
                        }
                    ]
                };
            });
        },

        /**
         * Sends OCPP setting to the station
         * @param {string} stationId
         * @param {Object} data
         * @returns {Promise}
         */
        setStationConfigurationKey(stationId, data) {
            return $http.put('/api/platform/stations/:stationId/configuration', data, {params: {stationId: stationId}});
        },

        /**
         * Returns tariffs resource associated with station id.
         * @param {string} id
         * @returns {Promise}
         */
        getStationTariffs(id) {
            return $http.get('/api/billing/tariffs/:id', {params: {id}});
        },

        /**
         * Updates station tariff.
         * @param {string} id
         * @param {Object} data
         * @returns {Promise}
         */
        updateStationTariffs(id, data) {
            return $http.put('/api/billing/tariffs/:id', data, {params: {id}});
        },

        /**
         * Creates station tariffs entity.
         * @param {string} id
         * @param {Object} data
         * @returns {Promise}
         */
        enablePublicCharging(id, data) {
            return $http.post('/api/billing/tariffs/:id', data, {params: {id}});
        },

        /**
         * Removes station tariff entity.
         * @param {string} id
         * @returns {Promise}
         */
        disablePublicCharging(id) {
            return $http.delete('/api/billing/tariffs/:id', {params: {id}});
        },

        /**
         * Checks does station tariff entity exist.
         * @param {string} id
         * @returns {Promise}
         */
        isPublicChargingEnabled(id) {
            return $http.head('/api/billing/tariffs/:id', {params: {id}})
                        .then(isPublicChargingEnabled);
        },

        downloadStationTransactions(...args) {
            return this.downloadTransactions(...args, 'stations');
        },

        downloadStationLogs(stationId, {from, to}) {
            to = utils.getMoment(to).add(1, 'day').startOf('day').toDate();

            return $http.get('/api/platform/stations/:stationId/ocpp-logs/download', {
                            params: _.assign(stationId, utils.localDatesToUTC({from, to})),
                            responseType: 'arraybuffer',
                            headers: {
                                accept: 'application/json'
                            },
                            transformResponse: fileService.transformFileResponse()
                        })
                        .then(fileService.openFile);
        },

        /**
         * Updates site association information on the station
         * @param {string} id - The id of the station which site association information is going to be updated
         * @param {string} siteId - The id of the site the station is going to be assigned to, `null` when un-assigning
         * @returns {Promise}
         */
        updateSiteAssociation(id, siteId) {
            return $http.put('/api/platform/stations/:id/site', {id: siteId}, {params: {id}});
        },

        /**
         * Gets the list of available sites the specified station can be assigned to, which are the sites of the account that the station belongs to.
         * @param {string} id
         * @returns {Promise}
         */
        getAvailableSitesForStation(id) {
            return $http.get('/api/platform/stations/:id/available-sites', {params: {id}});
        },

        /**
         * Gets the list of sites for the current logged in user
         * @returns {Promise}
         */
        getSites() {
            return $http.get('/api/platform/sites');
        },

        /**
         * Gets a site
         * @param {string} id - site id
         * @returns {Promise.<Object>}
         */
        getSite(id) {
            return $http.get('/api/platform/sites/:id', {params: {id}});
        },

        /**
         * Creates a new site.
         * @param {Object} requestData
         * @returns {Promise}
         */
        createSite(requestData) {
            return $http.post('/api/platform/sites', requestData);
        },

        /**
         * Updates a site
         * @param {string} id - site id
         * @param {Object} data - site data
         * @returns {Promise}
         */
        updateSite(id, data) {
            return $http.put('/api/platform/sites/:id', data, {params: {id}});
        },

        /**
         * Deletes a site
         * @param {string} id - id of the site to be deleted
         * @returns {Promise}
         */
        deleteSite(id) {
            return $http.delete('/api/platform/sites/:id', {params: {id}});
        },

        /**
         * Gets the list of available charging profiles the specified station can be assigned to, which are the charging profiles of the account that the station belongs to plus the predefined by
         * its tenant.
         * @param {string} id
         * @returns {Promise}
         */
        getAvailableChargingProfilesForStation(id) {
            return $http.get('/api/platform/stations/:id/available-charging-profiles', {params: {id}});
        },

        /**
         * Updates charging profile information on the station
         * @param {string} id - The id of the station which charging profile information is going to be updated
         * @param {string} chargingProfileId - The id of the charging profile the station is going to be assigned to, `null` when un-assigning
         * @returns {Promise}
         */
        updateChargingProfile(id, chargingProfileId) {
            return $http.put('/api/platform/stations/:id/charging-profile', {id: chargingProfileId}, {params: {id}});
        },

        /**
         * Gets Smatch redirect link
         * @param {string} id - id of the site
         * @returns {Promise}
         */
        getSmatchRedirectLink(id) {
            return $http.get('/api/platform/sites/:id/smatch/redirect-link', {params: {id}});
        },

        /**
         * Updates Smatch settings minimum current and API
         * @param {string} id - id of the site
         * @param {Object} settings
         * @returns {Promise}
         */
        updateSmatchSettings(id, settings) {
            // TODO: sync with Roman to know what is the new Smatch service URL I should use in here
            // return $http.put('/api/platform/sites/:id/smatch/??????', settings, {params: {id}});
            // Story: https://myevbox.atlassian.net/browse/EVS-359

            // Mocked response
            return $q.resolve({status: 204});
        },

        getStationsCount(accountId) {
            return $http.get('/api/platform/cpo-dashboard/stations/count', {params: {accountId}})
                        .then(transformStationCountResponse('total'));
        },

        getOfflineStationsCount(accountId) {
            return $http.get('/api/platform/cpo-dashboard/stations/offline/count', {params: {accountId}})
                        .then(transformStationCountResponse('offline'));
        },

        getRecentlyAddedStationsCount(accountId) {
            const dateRange = utils.getDateRangeFromLastGivenDays(CONSTANTS.DAYS_IN_WEEK);

            return $http.get('/api/platform/cpo-dashboard/stations/count', {params: {...dateRange, accountId}})
                        .then(transformStationCountResponse('recent'));
        },

        /**
         * Returns paginated stations for the CPO dashboard
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getAllStations(params) {
            return $http.get('/api/platform/cpo-dashboard/stations', buildStationsListParams(params))
                        .then(extendStationsList);
        },

        /**
         * Returns paginated offline stations for the CPO dashboard
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getOfflineStations(params) {
            return $http.get('/api/platform/cpo-dashboard/stations/offline', buildStationsListParams(params))
                        .then(extendStationsList);
        },

        /**
         * Returns paginated recently added stations for the CPO dashboard
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getRecentlyAddedStations(params) {
            const dateRange = utils.getDateRangeFromLastGivenDays(CONSTANTS.DAYS_IN_WEEK);

            return $http.get('/api/platform/cpo-dashboard/stations', buildStationsListParams({...params, ...dateRange}))
                        .then(extendStationsList);
        },

        /**
         * Returns connector information group by the `countBy` argument
         *
         * @typedef {"connectorStatus" | "connectorType" | "stationStatus"} CountByFormat
         *
         * @param  {CountByFormat} countBy
         * @param {string} [accountId]
         * @returns {Promise.<Object>}
         */
        getStationsConnectorsData(countBy, accountId) {
            const queryParams = {params: {countBy, accountId}};

            return $http.get('/api/platform/cpo-dashboard/stations/connectors/count', queryParams);
        },

        /**
         * Returns paginated stations by search query
         * @param {Object} [params]
         * @returns {Promise.<Array>}
         */
        getStationsByQuery(params) {
            return $http.get('/api/platform/stations', buildStationsListParams(params))
                        .then(extendStationsList);
        },

        /**
         * Proxies stations list loading depending on the type.
         * Returns structured object with stations list and pagination
         * @param {string} [type]
         * @param {Object} [params]
         * @returns {Object}
         */
        async getStationsByType(type, params = {}) {
            let data;
            let statuses = null;

            switch (type) {
                case 'offline':
                    data = await this.getOfflineStations(params);
                    statuses = getStatusesFromStationsList(data.content);
                    break;
                case 'recent':
                    data = await this.getRecentlyAddedStations(params);
                    break;
                case 'search':
                    data = await this.getStationsByQuery(params);
                    break;
                default:
                    data = await this.getAllStations(params);
            }

            return {
                stations: (data && data.content) || [],
                pagination: this.getPagination(data),
                statuses
            };
        }
    });
}
