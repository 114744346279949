angular.module('everon.component.language-selector')
       .component('evcloudLanguageSelector', {
           templateUrl: 'components/language-selector/language-selector.html',
           controller: LanguageSelectorController,
           bindings: {
               tenantLanguages: '<'
           }
       });

LanguageSelectorController.$inject = ['$translate', '$window', 'mediatorService', 'CONSTANTS'];

function LanguageSelectorController($translate, $window, mediatorService, CONSTANTS) {
    const $ctrl = this;

    $ctrl.isActive = false;
    $ctrl.selectedLanguage = {
        tag: null,
        name: null
    };

    $ctrl.setActive = val => {
        $ctrl.isActive = val;
    };

    $ctrl.setLanguage = language => {
        $ctrl.setActive(false);
        $ctrl.selectedLanguage = _.assign({}, language);

        $translate.use($ctrl.selectedLanguage.tag)
                  .then(response => {
                      mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.LANGUAGE_CHANGE, response);
                  });
    };

    /**
     * Always preselect default tenant's language
     */
    $ctrl.$onInit = () => {
        if (!$ctrl.tenantLanguages.length) {
            return;
        }

        // We check if there is a stored lang, if so we make sure it is contained in the tenantLanguages and use it
        const storedPreferredLanguage = _.findIndex($ctrl.tenantLanguages, lang => lang.tag === $window.localStorage.getItem('NG_TRANSLATE_LANG_KEY'));

        $ctrl.selectedLanguage = $ctrl.tenantLanguages[storedPreferredLanguage > -1 ? storedPreferredLanguage : 0];
        $translate.use($ctrl.selectedLanguage.tag);
    };
}
