angular.module('everon.component.search')
       .controller('SearchController', SearchController);

SearchController.$inject = ['$document', 'stateHelper'];

function SearchController($document, stateHelper) {
    const $ctrl = this;
    let previousQuery;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        $ctrl.options = extendOptions($ctrl.options);

        const {initialQuery, stateParam} = $ctrl.options;

        $ctrl.query = initialQuery;
        previousQuery = initialQuery;

        // If stateParam provided, listens to its changes and updates current query
        if (stateParam) {
            stateChangeListener = stateHelper.subscribeToParamsChange({[stateParam]: updateQuery});
        }
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        if (stateChangeListener) {
            stateChangeListener.resolve();
        }
    };

    $ctrl.$postLink = () => {
        $document.off('keyup', keyUpHandler)
                 .on('keyup', keyUpHandler);
    };

    /**
     * Search logic on the back-end is implemented as follows:
     * - if `query` param is empty or not present, all results are returned
     * - if `query` is not empty and matches `[a-zA-Z\d_]{2,}` RegExp, a matching set of results is returned, otherwise an empty results set is returned
     */
    $ctrl.onSearch = () => {
        const currentQuery = _.trim($ctrl.query);

        if ($ctrl.options.ignoreSameQuery && previousQuery === currentQuery) {
            return;
        }

        previousQuery = currentQuery;
        $ctrl.options.onSearch(currentQuery);
    };

    $ctrl.onChange = () => {
        $ctrl.focusToggle(!$ctrl.query);
    };

    /**
     * Handles focus and blur events
     * @param {boolean} flag
     */
    $ctrl.focusToggle = flag => {
        if (_.isFunction($ctrl.options.onFocusToggle)) {
            $ctrl.options.onFocusToggle(flag);
        }
    };

    $ctrl.onReset = () => {
        $ctrl.query = '';
        $ctrl.onSearch();
    };

    /**
     * Handles `keyup` events on the document and reset search if ESC key was pressed
     * @param {Object} event
     */
    function keyUpHandler(event) {
        if (event.keyCode === 27) {
            $ctrl.onReset();
        }
    }

    /**
     * Extends provided options with defaults
     * @param {Object} options
     * @returns {Object}
     */
    function extendOptions(options) {
        return _.defaults(options, {
            placeholder: 'component.search.placeholder',
            ariaLabel: 'component.search.ariaLabel',
            disabled: false,
            ignoreSameQuery: true,
            initialQuery: ''
        });
    }

    /**
     * Updates query
     * @param {string} query
     */
    function updateQuery(query) {
        $ctrl.query = query;
    }
}
