angular.module('everon.component.form-change')
       .directive('onFormChange', onFormChange);

onFormChange.$inject = ['$parse', '$timeout'];

/**
 * Detects `keyup` events on forms and invokes a callback every 200 milliseconds. Can only be used on form elements
 * @param {Function} $parse
 * @param {Function} $timeout
 * @returns {Object}
 */
function onFormChange($parse, $timeout) {
    return {
        restrict: 'A',
        require: 'form',
        link(scope, element, attrs, form) {
            const callback = $parse(attrs.onFormChange);

            const eventHandler = () => {
                if (form.$pristine) {
                    return;
                }

                $timeout(() => {
                    callback(scope);
                });
            };

            const debouncedEventHandler = _.debounce(eventHandler, 100, {leading: true});

            element.off('change keyup mouseup', debouncedEventHandler)
                   .on('change keyup mouseup', debouncedEventHandler);
        }
    };
}
