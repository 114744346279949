angular.module('everon.welcome')
       .component('evcloudWelcomeLanguageDetails', {
           controller: 'WelcomeLanguageDetailsController',
           templateUrl: 'modules/welcome/language-details/language-details.html',
           bindings: {
               dataPromise: '<'
           },
           require: {
               welcomeCtrl: '^^evcloudWelcome'
           }
       });
