angular.module('everon.stations.station.billing-plan')
       .controller('StationBillingPlanController', StationBillingPlanController);

StationBillingPlanController.$inject = ['mediatorService', 'CONSTANTS', 'subscriptionService'];

function StationBillingPlanController(mediatorService, CONSTANTS, subscriptionService) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            withKnownRenewalDate: true
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({station, subscription}) {
        $ctrl.subscription = _.cloneDeep(subscription);
        $ctrl.subscription.product.characteristics = subscriptionService.filterCharacteristics(subscription.product.characteristics);
        $ctrl.state.withKnownRenewalDate = $ctrl.subscription.renewalDate !== null;
        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
