angular.module('everon.roaming.connections.connection')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.connections.connection', {
        url: '/:id',
        redirectTo: 'auth.roaming.connections.connection.overview',
        data: {
            title: 'roaming.connections.connection.title'
        },
        views: {
            'contextNav@auth.roaming': {
                template: '<evcloud-nav-list filter="::{navigationType: \'context\', parent: \'auth.roaming.connections.connection\'}"  />'
            }
        }
    });
}
