angular.module('everon.welcome')
       .controller('WelcomeController', WelcomeController);

WelcomeController.$inject = ['$state', 'stateHelper', 'mediatorService', 'CONSTANTS', 'permissionService', 'sessionService'];

function WelcomeController($state, stateHelper, mediatorService, CONSTANTS, permissionService, sessionService) {
    const $ctrl = this;
    let defaultState;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), sessionService.getUserProfile().permissions);

        defaultState = stateHelper.getDefaultStateName($ctrl.profile.permissions, {navigationType: 'primary'});

        $ctrl.state = {
            stepsCompleted: false,
            canActivateAssets: checkPermissions(['CARD:ACTIVATE']) || checkPermissions(['STATION:ACTIVATE'])
        };

        $ctrl.states = _($state.$current.parent.children).sortBy('data.order')
                                                         .map(item => ({
                                                             name: item.name,
                                                             title: item.data.title
                                                         }))
                                                         .value();

        $ctrl.currentStateIndex = 0;
    };

    /**
     * Goes to the next step
     * @returns {Promise}
     */
    $ctrl.next = () => {
        return $state.go($ctrl.states[$state.current.data.order + 1].name).then(updateState);
    };

    /**
     * Goes to the previous step
     * @returns {Promise}
     */
    $ctrl.previous = () => {
        return $state.go($ctrl.states[$state.current.data.order - 1].name).then(updateState);
    };

    /**
     * Goes to a specific state
     * @param {string} state
     * @returns {Promise}
     */
    $ctrl.goToStep = state => {
        return $state.go(state.name);
    };

    /**
     * Completes the setup
     * @returns {Promise}
     */
    $ctrl.completeSetup = () => {
        return $state.go(defaultState);
    };

    function updateState() {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);
    }
}
