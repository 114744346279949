angular.module('everon.users.system-users')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.system-users.groups', {
        url: '/groups?sort&&query&page&size',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            },
            highlightItem: null
        },
        dynamic: true,
        data: {
            id: 'groups-navigation-link',
            title: 'generic.entity.groups',
            navigationType: ['context'],
            menuPriority: 2,
            permissions: ['USER_GROUP:READ']
        },
        resolve: {
            data: ['userGroupsService', '$transition$', (userGroupsService, $transition$) => {
                const params = _.pick($transition$.params('to'), ['query', 'sort', 'page', 'size']);

                return userGroupsService.getGroups(params);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.users.system-users': {
                template: '<evcloud-user-groups data-data-promise="::$resolve.data" class="flex-auto flex flex-column col-12" />'
            }
        }
    });
}
