angular.module('everon')
       .factory('stationEligibilityMixin', stationEligibilityMixin);

stationEligibilityMixin.$inject = [];

function stationEligibilityMixin() {
    const availableConnectivityTypes = ['gsm', 'wifi'];
    const availablePowerTypes = ['ac', 'dc'];

    return {
        /**
         * Gets available station connectivity types
         * @param {Object} selectedConnectivityType
         * @returns {Array}
         */
        getConnectivityTypes(selectedConnectivityType) {
            return availableConnectivityTypes.map(type => (
                {
                    name: type,
                    enabled: selectedConnectivityType.name === 'both' || selectedConnectivityType.name === type
                }
            ));
        },

        /**
         * Transforms station connectivity types to radio-input form.
         * @param {Array} connectivityTypes
         * @returns {Array}
         */
        extendConnectivityTypes(connectivityTypes) {
            const hasBothEnabled = connectivityTypes ? _.every(connectivityTypes, 'enabled') : false;

            return [
                ...availableConnectivityTypes.map(name => ({
                    name,
                    enabled: connectivityTypes ? _.find(connectivityTypes, {name}).enabled && !hasBothEnabled : false
                })),
                {
                    name: 'both',
                    enabled: hasBothEnabled
                }
            ];
        },

        /**
         * Transforms station power types to checkbox-input form.
         * @param {Array} powerTypes
         * @returns {Array}
         */
        extendPowerTypes(powerTypes) {
            return availablePowerTypes.map(name => ({
                    name,
                    enabled: powerTypes ? _.find(powerTypes, {name}).enabled : false
                }));
        },

        getEligibilityCriteria({connectivityTypes, powerTypes}) {
            return {
                connectivityTypes: _.filter(this.extendConnectivityTypes(connectivityTypes), o => o.name !== 'both'),
                powerTypes: this.extendPowerTypes(powerTypes)
            };
        }
    };
}
