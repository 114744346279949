angular.module('everon.stations.station-import-details-form.connectors')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.station-import-details.edit.connectors', {
        data: {
            title: 'generic.entity.connectors',
            order: 1
        },
        views: {
            'content@auth.stations.station-import-details.edit': {
                template: '<evcloud-station-import-details-form-connectors class="flex flex-column col-12 max-width-3 overflow-hidden"/>'
            }
        }
    });
}

