angular.module('everon.stations.list')
       .controller('StationsListController', StationsListController);

StationsListController.$inject = ['$log', '$state', '$q', 'stationService', 'permissionService', 'mediatorService', 'CONSTANTS', 'stateHelper', 'sessionService', 'localStore'];

function StationsListController($log, $state, $q, stationService, permissionService, mediatorService, CONSTANTS, stateHelper, sessionService, localStore) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), $ctrl.profile.permissions);
        const isFeatureEnabled = _.partial(permissionService.resolveFeatures.bind(permissionService), sessionService.getTenantFeatureFlags());

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({
            query: setSearchQuery,
            status: setCurrentStatus
        }, getData);

        const {status, query} = $state.params;

        $ctrl.state = {
            dataLoaded: false,
            pageLoadInProgress: false,
            canActivateStation: checkPermissions(['STATION:ACTIVATE']),
            canActivateStationOnBehalf: checkPermissions(['STATION:ACTIVATE_ON_BEHALF']),
            canImportStation: checkPermissions(['STATION:IMPORT']),
            canSearchStations: checkPermissions(['STATION:READ_ALL']) || checkPermissions(['STATION:READ']),
            canCreateSite: checkPermissions(['SITE:CREATE']),
            canUpdateSite: checkPermissions(['SITE:UPDATE']),
            filterActive: Boolean(status || query),
            canGoToCpoDashboard: isFeatureEnabled(['cpoDashboard']) && checkPermissions(['STATION:DASHBOARD_READ'])
        };

        $ctrl.pagination = null;
        $ctrl.searchQuery = query;
        $ctrl.stationStates = ['all', 'active', 'inactive', 'pending'];
        $ctrl.selectedFilter = status || $ctrl.stationStates[0];
        $ctrl.statusCssClass = CONSTANTS.STATUS.STATION_CONNECTOR;

        $ctrl.columns = [
            {
                property: 'identityCode',
                translationKey: 'generic.entity.station',
                desc: false,
                sortable: false,
                cssClass: 'col-3'
            },
            {
                property: 'status',
                translationKey: 'generic.label.status',
                desc: true,
                sortable: false,
                cssClass: 'col-1 xs-hide'
            },
            {
                property: 'reference',
                translationKey: 'generic.label.reference',
                desc: true,
                sortable: false,
                cssClass: 'col-2 xs-hide'
            },
            {
                property: 'accountName',
                translationKey: 'account.billingInformation.form.label.accountName',
                desc: true,
                sortable: false,
                cssClass: 'col-2 xs-hide'
            },
            {
                property: 'location',
                translationKey: 'generic.title.location',
                desc: true,
                sortable: false,
                cssClass: 'col-4 xs-hide'
            }
        ];

        $ctrl.actions = generateMenuItems($ctrl.state);

        $ctrl.noResultsConfig = {
            heading: 'stations.list.noResults.heading',
            paragraph: 'stations.list.noResults.paragraph',
            icon: 'empty-state-stations'
        };

        $ctrl.noSearchResultsConfig = {
            icon: 'empty-state-search-results',
            heading: 'generic.noResults.heading',
            paragraph: 'generic.noSearchResults.paragraph',
            template: 'partials/search-help.html'
        };

        $ctrl.noFiltersResultsConfig = {
            heading: 'generic.noResults.heading',
            paragraph: 'generic.noFilterResults.paragraph'
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Populate action menu items on the list controller
     */

    function generateMenuItems(state) {
        let actions = [];

        if (state.canCreateSite) {
            actions = actions.concat([
                {
                    name: 'stations.action.createSite',
                    state: 'auth.stations.sites.site-add',
                    icon: 'site'
                }
            ]);
        }

        if (state.canImportStation) {
            actions = actions.concat([
                {
                    name: 'stations.action.import',
                    state: 'auth.stations.station-import',
                    icon: 'import'
                },
                {
                    name: 'generic.action.importCsv',
                    state: 'auth.stations.station-import-csv',
                    icon: 'import'
                }
            ]);
        }

        if (state.canActivateStationOnBehalf || state.canActivateStation) {
            actions.push({
                name: 'stations.action.activate',
                state: `auth.stations.station-add${state.canActivateStationOnBehalf ? '-on-behalf' : ''}.station-credentials`,
                icon: 'evse'
            });
        }

        return actions;
    }

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Updates state with pagination params
     * @param {Object} newState
     */
    $ctrl.changePagination = newState => {
        stateHelper.changeParams(newState);
    };

    /**
     * Updates state with selected status and zero-pagination
     */
    $ctrl.onFilterChange = () => {
        const status = $ctrl.selectedFilter === 'all' ? null : $ctrl.selectedFilter;

        stateHelper.changeParams({status, page: 0});
    };

    /**
     * Sets preference to CPO Dashboard into localstorage
     */
    $ctrl.useNewDashboard = () => {
        localStore.set('USE_STATIONS_LIST', false);
    };

    function setSearchQuery(query) {
        $ctrl.state.filterActive = true;
        $ctrl.searchQuery = query;
    }

    function setCurrentStatus(status) {
        $ctrl.selectedFilter = status || 'all';
        $ctrl.state.filterActive = Boolean(status || $ctrl.searchQuery);
    }

    /**
     * Requests stations
     */
    function getData() {
        const params = _.pick($state.params, ['status', 'query', 'page', 'size']);

        $ctrl.state.pageLoadInProgress = true;

        stationService.getStations(params)
                      .then(updateData)
                      .catch(onRejected)
                      .finally(() => {
                          structureContent($ctrl.content);

                          $ctrl.state.pageLoadInProgress = false;
                      });
    }

    /**
     * Updates data model on every change
     * @param {Object} data
     * @returns {Promise}
     */
    function updateData(data) {
        $ctrl.pagination = {
            ...stationService.getPagination(data),
            availableSizes: CONSTANTS.PAGINATION.SIZES
        };
        $ctrl.content = data.content;

        return $ctrl.pagination.totalElements ? getStationStatus($ctrl.content) : $q.resolve();
    }

    /**
     * In this function should be done all the transformations required for the stations.
     * @param {Array} stations
     * @returns {*}
     */
    function transformStations(stations) {
        return stations.map(station => {
            return Object.assign({}, station, {
                detailsLink: ['inactive', 'pending'].includes(station.status) ? 'auth.stations.station-import-details(::{stationId: station.id})' : 'auth.stations.station(::{stationId: station.id})'
            });
        });
    }

    /**
     * Gets station connector status and updates the UI
     * @param {Array} stations
     * @returns {Promise}
     */
    function getStationStatus(stations) {
        return stationService.getStationStatus(stations)
                             .then(_.partialRight(setConnectorStatus, stations))
                             .catch(error => $log.error('Failed to get the status for the following stations: %s', _.map(stations, 'identityCode').join(', '), error));
    }

    /**
     * Transforms a single site
     * @param {Object} stationsGroup
     * @returns {Object}
     */
    function setSiteData(stationsGroup) {
        return _.assign({}, stationsGroup[0].site, {
            stations: stationsGroup,
            location: stationsGroup[0].address ? `${stationsGroup[0].address.streetName} ${stationsGroup[0].address.house}, ${stationsGroup[0].address.city}` : '',
            accountName: stationsGroup[0].accountName
        });
    }

    /**
     * Structures the content coming from server into stations and sites arrays
     * @param {Object} content
     */
    function structureContent(content) {
        const {sites = [], stations = []} = $ctrl.pagination.totalElements ? groupBySite(content) : {};

        $ctrl.stations = transformStations(stations);
        $ctrl.sites = sites;
    }

    /**
     * Structures the content property on the response
     * @param {Object} stations - the original list of stations returned by BE
     * @returns {Object}
     */
    function groupBySite(stations) {
        const stationsAssignedToSite = stations.filter(station => Boolean(station.site) && station.status !== 'inactive');

        return {
            sites: _(stationsAssignedToSite).groupBy('site.name').map(setSiteData).value(),
            stations: _.difference(stations, stationsAssignedToSite)
        };
    }

    /**
     * Sets connector status for a given station
     * @param {Array} connectorStatus
     * @param {Array} stations
     */
    function setConnectorStatus(connectorStatus, stations) {
        stations.forEach(station => {
            station.connectorStatus = connectorStatus[station.identityCode];
        });
    }

    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Checks if the conditions to show the search are met
     * @returns {boolean}
     */
    function isSearchDisabled() {
        return !$ctrl.pagination.totalElements && !$ctrl.state.filterActive;
    }

    /**
     * Updates state with search query and zero-pagination
     * @param {string} query
     */
    function onSearch(query) {
        stateHelper.changeParams({query, page: 0});
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded(data) {
        updateData(data).finally(() => {
            structureContent($ctrl.content);

            $ctrl.searchOptions = {
                id: 'stations',
                onSearch,
                stateParam: 'query',
                initialQuery: $ctrl.searchQuery,
                disabled: isSearchDisabled()
            };

            $ctrl.state.dataLoaded = true;

            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['current']);
        });
    }
}
