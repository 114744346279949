angular.module('everon.stations.charging-profiles')
       .controller('ChargingProfilesListController', ChargingProfilesListController);

ChargingProfilesListController.$inject = ['$state', '$q', 'chargingProfilesService', 'mediatorService', 'CONSTANTS', 'sessionService'];

function ChargingProfilesListController($state, $q, chargingProfilesService, mediatorService, CONSTANTS, sessionService) {
    const $ctrl = this;
    let weekDaysInitials = [];

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);
        const profile = sessionService.getUserProfile();
        const actionableCardsConfiguration = {
            actions: [
                {
                    icon: 'edit',
                    translationKey: 'generic.action.edit',
                    callback: $ctrl.onEditClick
                },
                {
                    icon: 'trash',
                    translationKey: 'generic.action.delete',
                    callback: $ctrl.onDeleteClick
                }
            ],
            partial: 'modules/stations/charging-profiles/charging-profile-power-bars-partial.html',
            widthPx: 280
        };

        $ctrl.state = {
            dataLoaded: false,
            deletionInProgress: false,
            isTenantAdmin: chargingProfilesService.currentUserCanEditTenantProfiles()
        };

        $ctrl.accountName = profile.accountName;
        $ctrl.actions = [];

        $ctrl.accountChargingProfileCards = actionableCardsConfiguration;
        $ctrl.tenantChargingProfileCards = _.assign({}, actionableCardsConfiguration, $ctrl.state.isTenantAdmin ? {} : {actions: []});

        $ctrl.noResultsConfig = {
            noAccountProfilesCreated: {
                icon: 'empty-state-generic',
                heading: 'stations.chargingProfiles.noResults.noAccountProfilesCreated.heading',
                paragraph: 'stations.chargingProfiles.noResults.noAccountProfilesCreated.paragraph',
                links: [
                    {
                        state: 'auth.stations.charging-profiles.charging-profile-add',
                        text: 'stations.chargingProfiles.title.create'
                    }
                ]
            },
            accountProfilesNotEnabled: {
                icon: 'empty-state-generic',
                heading: 'stations.chargingProfiles.noResults.accountProfilesNotEnabled.heading',
                paragraph: 'stations.chargingProfiles.noResults.accountProfilesNotEnabled.paragraph'
            }
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Navigates to the details view of the selected charging profile
     * @param {Object} chargingProfile
     */
    $ctrl.onEditClick = chargingProfile => {
        $state.go('auth.stations.charging-profiles.charging-profile-update', {chargingProfileId: chargingProfile.id});
    };

    /**
     * Navigates to the details view of the selected charging profile
     * @param {Object} chargingProfile
     */
    $ctrl.onDeleteClick = chargingProfile => {
        $ctrl.state.deletionInProgress = true;

        chargingProfilesService.deleteChargingProfile(chargingProfile.id)
                               .then(() => {
                                   mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                       type: 'success',
                                       messageKey: 'generic.notification.success'
                                   });

                                   return chargingProfilesService.getChargingProfilesForCurrentAccount();
                               }).then(onChargingProfilesChange)
                               .catch(error => {
                                   mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                       type: 'error',
                                       messageKey: error.status === 409 ? 'stations.chargingProfiles.notification.delete.chargingProfileInUse' : CONSTANTS.ERROR_CODES[error.status],
                                       translateValues: error.status === 409 ? {
                                           name: chargingProfile.title.default,
                                           count: _.get(error, 'data.properties.COUNT')
                                       } : {}
                                   });
                               })
                               .finally(() => {
                                   $ctrl.state.deletionInProgress = false;
                               });
    };

    /**
     * Transforms charging profiles information to be used on the view
     * @param {Array} chargingProfiles
     */
    function onChargingProfilesChange(chargingProfiles) {
        const {accountProfiles, tenantProfiles} = chargingProfiles;

        $ctrl.accountChargingProfileCards.cards = generateCardData(accountProfiles);
        $ctrl.tenantChargingProfileCards.cards = generateCardData(tenantProfiles);
    }

    /**
     * Generates the actionable-cards setup from the list of charging profiles
     * @param {Array} profiles
     * @returns {Array} card data to be used while rendering the <evcloud-actionable-cards> component
     */
    function generateCardData(profiles) {
        if (profiles) {
            return profiles.map(profile => {
                const daySchedules = chargingProfilesService.generateDaySchedules(profile);
                const powerBars = chargingProfilesService.createPowerBarsFromDaySchedules(daySchedules, profile.maxCurrent);
                const weekDays = _.map(powerBars, (powerBar, index) => ({dayInitial: weekDaysInitials[index], powerBar: powerBar}));

                return {
                    id: profile.id,
                    weekDays,
                    title: {default: profile.name}
                };
            });
        }

        return [];
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({chargingProfiles, translatedWeekDaysInitials, accountFeatures}) {
        weekDaysInitials = translatedWeekDaysInitials;

        $ctrl.state.hasChargingProfilesEnabled = accountFeatures.chargingProfile;

        if ($ctrl.state.hasChargingProfilesEnabled || $ctrl.state.isTenantAdmin) {
            $ctrl.actions = [
                {
                    name: 'stations.chargingProfiles.title.create',
                    state: 'auth.stations.charging-profiles.charging-profile-add'
                }
            ];
        }

        onChargingProfilesChange(chargingProfiles);

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['current']);
    }
}
