angular.module('everon.stations.charging-profiles')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.charging-profiles', {
        url: '/charging-profiles',
        data: {
            id: 'charging-profiles-navigation-link',
            title: 'generic.title.chargingProfiles',
            navigationType: ['secondary'],
            menuPriority: 2,
            permissions: ['CHARGING_PROFILE:READ']
        },
        resolve: {
            data: ['$q', 'chargingProfilesService', 'accountService', ($q, chargingProfilesService, accountService) => {
                return $q.all({
                    chargingProfiles: chargingProfilesService.getChargingProfilesForCurrentAccount(),
                    translatedWeekDaysInitials: chargingProfilesService.getWeekDaysInitials(),
                    accountFeatures: accountService.getFeatureFlags()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-charging-profiles data-data-promise="::$resolve.data" class="flex-auto flex flex-column" />'
            }
        }
    });
}
