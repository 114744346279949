angular.module('everon.component.dropdown-button')
       .controller('DropdownButtonController', DropdownButtonController);

DropdownButtonController.$inject = [];

function DropdownButtonController() {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            open: false
        };
    };

    $ctrl.toggleState = (state = !$ctrl.state.open) => {
        $ctrl.state.open = state;
    };

    $ctrl.triggerAction = (event, action) => {
        event.stopPropagation();
        $ctrl.toggleState(false);
        $ctrl.onActionTriggered({action});
    };
}
