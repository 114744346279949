angular.module('everon.cards.card-add.card-plan')
       .component('evcloudCardAddPlan', {
           controller: 'CardAddPlanController',
           templateUrl: 'modules/cards/card-add/card-plan/card-plan.html',
           bindings: {
               dataPromise: '<'
           },
           require: {
               cardAddCtrl: '^^evcloudCardAdd'
           }
       });
