angular.module('everon.tenant-settings.countries.country')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.countries.country', {
        url: '/:id',
        data: {
            title: 'tenantSettings.countries.country.title'
        },
        resolve: {
            data: ['$q', '$transition$', 'tenantCountryService', 'tenantCompanyService', ($q, $transition$, tenantCountryService, tenantCompanyService) => {
                return $q.all({
                    companies: tenantCompanyService.getCompaniesForCountry(),
                    tenantCountry: tenantCountryService.getCountry($transition$.params('to').id)
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.tenant-settings': {
                component: 'evcloudCountry',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
