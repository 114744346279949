angular.module('everon.cards.card-add.card-details')
       .component('evcloudCardAddDetails', {
           controller: 'CardAddDetailsController',
           templateUrl: 'modules/cards/card-add/card-details/card-details.html',
           bindings: {
               dataPromise: '<?'
           },
           require: {
               cardAddCtrl: '^^evcloudCardAdd'
           }
       });
