angular.module('everon.component.dropdown-button')
       .component('evcloudDropdownButton', {
           templateUrl: 'components/dropdown-button/dropdown-button.html',
           controller: 'DropdownButtonController',
           bindings: {
               config: '<',
               loading: '<?',
               buttonDisabled: '<?',
               tabindexBase: '<?',
               onActionTriggered: '&'
           }
       });
