angular.module('everon')
       .factory('localStore', localStore);

localStore.$inject = ['$window'];

function localStore($window) {
    const storage = $window.localStorage;
    const namespace = 'EV_BOX.';

    return {
        set(key, value) {
            storage.setItem(namespace + key, JSON.stringify(value));
        },

        get(key) {
            return JSON.parse(storage.getItem(namespace + key)) || null;
        },

        remove(key) {
            storage.removeItem(namespace + key);
        },

        clear() {
            storage.clear();
        }
    };
}
