angular.module('everon.landing.login')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('anon.landing.authorization_callback', {
        url: '/auth_callback',
        redirectTo($transition$) {
            const $injector = $transition$.injector();
            const $state = $injector.get('$state');
            const {tenantId} = $injector.get('$location').search();

            // This is required by the e2e tests as the tenantId is set programmatically before calling /login.
            // The BE will add it to the callback url in order to keep it consistent with the value specified by the e2e.
            if (tenantId) {
                window.EVERON.tenantId = tenantId;
            }

            return $injector.get('sessionService')
                            .loadSession()
                            .then(() => $state.target('auth.dashboard', null, {reload: 'auth.dashboard'}))
                            .catch(() => $state.target('anon.landing.login', null, {reload: 'anon.landing.login'}));
        }
    });

    $stateProvider.state('anon.landing.login', {
        url: '/',
        data: {
            title: 'landing.login.title',
            sourceState: null,
            sourceParams: null
        },
        params: {
            activationCompleted: false,
            activationExpired: false,
            registrationSent: false
        },
        resolve: {
            data: ['$q', 'tenantSettingsService', ($q, tenantSettingsService) => {
                return $q.all({
                    supportLinks: tenantSettingsService.getSupportLinks()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@anon.landing': {
                component: 'evcloudLogin',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
