/**
 * Checks if one date is after another optional offset can be provided
 *
 * @example
 *
 * <input type="datetime-local" ng-model="$ctrl.dateUntil" evcloud-is-after-date="$ctrl.isAfterDate" evcloud-is-after-date-offset="86400000">
 * <input type="datetime-local" ng-model="$ctrl.dateFrom">
 */
angular.module('everon.component.is-after-date')
       .directive('evcloudIsAfterDate', isAfterDate);

isAfterDate.$inject = ['utils'];

function isAfterDate(utils) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.isAfterDate = modelValue => {
                const fromDate = attributes.evcloudIsAfterDate && JSON.parse(attributes.evcloudIsAfterDate);

                return (!modelValue || !fromDate) || isAfter(utils.toLocalMoment(fromDate).toDate(), modelValue);
            };

            attributes.$observe('evcloudIsAfterDate', () => {
                if (ngModel.$viewValue) {
                    ngModel.$validate();
                }
            });

            /**
             * Checks whether one date is after another, taking time offset into consideration if necessary
             * @param {Date} from
             * @param {Date} to
             * @returns {boolean}
             */
            function isAfter(from, to) {
                const offset = Number(attributes.evcloudIsAfterDateOffset);
                const moment = utils.getMoment;

                return offset ? moment(to).diff(moment(from)) >= offset : moment(to).isAfter(moment(from));
            }
        }
    };
}
