angular.module('everon.account.invoices')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.account.invoices', {
        url: '/invoices?page,size',
        params: {
            page: {
                value: null,
                type: 'page',
                squash: true
            },
            size: {
                value: null,
                type: 'int',
                squash: true
            }
        },
        dynamic: true,
        data: {
            id: 'account-invoices-navigation-link',
            title: 'account.invoices.title',
            navigationType: ['context'],
            menuPriority: 3,
            permissions: ['INVOICE:READ']
        },
        resolve: {
            data: ['$q', 'invoiceService', 'accountService', '$transition$', ($q, invoiceService, accountService, $transition$) => {
                return accountService.getBillingOverview()
                                     .then(({id}) => {
                                         const params = _.pick($transition$.params('to'), ['page', 'size']);

                                         return $q.all({
                                             accountId: id,
                                             invoices: invoiceService.getAccountInvoices(id, params)
                                         });
                                     });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.account': {
                template: `<evcloud-account-invoices data-data-promise="::$resolve.data"
                                                     class="flex-auto flex flex-column"/>`
            }
        }
    });
}
