angular.module('everon.tenant-settings.languages')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.languages', {
        url: '/languages',
        data: {
            id: 'languages-settings-navigation-link',
            title: 'tenantSettings.languages.title',
            menuPriority: 1,
            navigationType: ['secondary']
        },
        resolve: {
            data: ['$q', 'languageService', ($q, languageService) => {
                return $q.all({
                    tenantLanguages: languageService.getTenantLanguages(),
                    platformLanguages: languageService.getPlatformLanguages()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.tenant-settings': {
                template: '<evcloud-languages data-data-promise="::$resolve.data" />'
            }
        }
    });
}
