angular.module('everon.stations.station-import-details-form.connectors')
       .controller('StationImportDetailsFormConnectorsController', StationImportDetailsFormConnectorsController);

StationImportDetailsFormConnectorsController.$inject = ['stationService', 'CONSTANTS', 'mediatorService', '$state', 'stationFormModel'];

function StationImportDetailsFormConnectorsController(stationService, CONSTANTS, mediatorService, $state, stationFormModel) {
    const $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.formModel = extendFormModel(stationFormModel);
        $ctrl.model = _.assign({}, stationService.getStationCapabilities(), {connectorSpecs: $ctrl.stationImportCtrl.payload.connectorSpecs});

        $ctrl.state = {
            formVisible: _.isEmpty($ctrl.model.connectorSpecs),
            saveButtonDisabled: false,
            errorMessageVisible: false
        };

        $ctrl.connectorSettings = {
            actions: [
                {
                    name: 'edit',
                    icon: 'edit',
                    translationKey: 'generic.action.edit',
                    callback: $ctrl.onEdit
                },
                {
                    name: 'delete',
                    icon: 'trash',
                    translationKey: 'generic.action.delete',
                    callback: $ctrl.onDelete
                }
            ],
            cards: stationService.mapConnectors($ctrl.model.connectorSpecs),
            partial: 'modules/stations/station-import-details-form/connectors/connector-setup-card-content-partial.html',
            widthPx: 250
        };

        $ctrl.modelCopy = JSON.stringify($ctrl.model);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent']);
    };

    $ctrl.previous = () => {
        $ctrl.stationImportCtrl.payload.connectorSpecs = $ctrl.model.connectorSpecs;
        $ctrl.stationImportCtrl.previous();
    };

    $ctrl.next = () => {
        $ctrl.stationImportCtrl.payload.connectorSpecs = $ctrl.model.connectorSpecs;
        $ctrl.stationImportCtrl.next({connectorSpecs: $ctrl.model.connectorSpecs});
    };

    $ctrl.saveSettings = () => {
        const selected = $ctrl.connectorSettings.selected;
        const connector = _.assign({}, $ctrl.model.connector);

        if (selected !== undefined) {
            $ctrl.model.connectorSpecs[selected] = connector;
            $ctrl.connectorSettings.selected = undefined;
        } else {
            $ctrl.model.connectorSpecs.push(connector);
        }

        $ctrl.stationImportCtrl.storeConnectors($ctrl.model.connectorSpecs);
        $ctrl.connectorSettings.cards = stationService.mapConnectors($ctrl.model.connectorSpecs);
        $ctrl.state.formVisible = false;
    };

    $ctrl.cancelSettings = () => {
        const selected = $ctrl.connectorSettings.selected;

        if (selected !== undefined) {
            $ctrl.connectorSettings.cards[selected].actionsDisabled = false;
            $ctrl.connectorSettings.selected = undefined;
            $ctrl.model.connector = {};
        }

        $ctrl.state.formVisible = false;
    };

    $ctrl.toggleFormVisibility = () => {
        $ctrl.model.connector = stationService.getStationCapabilities().connector;
        $ctrl.stationImportConnectorsForm.$setPristine();
        $ctrl.updateStateProperties();
        $ctrl.state.formVisible = !$ctrl.state.formVisible;
    };

    $ctrl.onEdit = connector => {
        $ctrl.cancelSettings();
        $ctrl.connectorSettings.selected = connector.id;
        $ctrl.connectorSettings.cards[connector.id].actionsDisabled = true;
        $ctrl.model.connector = _.assign({}, $ctrl.model.connectorSpecs[connector.id]);
        $ctrl.state.formVisible = true;
        $ctrl.state.saveButtonDisabled = true;
    };

    $ctrl.onDelete = connector => {
        $ctrl.model.connectorSpecs.splice(connector.id, 1);
        $ctrl.connectorSettings.cards = stationService.mapConnectors($ctrl.model.connectorSpecs);
        $ctrl.stationImportCtrl.storeConnectors($ctrl.model.connectorSpecs);
        $ctrl.state.formVisible = _.isEmpty($ctrl.model.connectorSpecs);
    };

    /**
     * Update state properties to manage visibility of error message and disability of the save settings button
     */
    $ctrl.updateStateProperties = () => {
        const connectorIsDuplicated = Boolean(_.find($ctrl.model.connectorSpecs, $ctrl.model.connector));

        $ctrl.state.saveButtonDisabled = connectorIsDuplicated;
        $ctrl.state.errorMessageVisible = connectorIsDuplicated && !$ctrl.stationImportConnectorsForm.$pristine;
    };

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => {
            item.id = _.kebabCase(item.name);

            return item;
        });
    }
}
