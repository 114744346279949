angular.module('everon.tenant-settings.support-links')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.support-links', {
        url: '/support-links',
        data: {
            id: 'support-links-navigation-link',
            title: 'tenantSettings.supportLinks.title',
            menuPriority: 4,
            navigationType: ['secondary']
        },
        resolve: {
            data: ['tenantSettingsService', tenantSettingsService => tenantSettingsService.getSupportLinks()]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.tenant-settings': {
                template: '<evcloud-support-links data-data-promise="::$resolve.data" />'
            }
        }
    });
}
