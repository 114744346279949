angular.module('everon.tenant-settings.countries.country-form')
       .component('evcloudCountryForm', {
           templateUrl: 'modules/tenant-settings/countries/country-form/country-form.html',
           controller: 'CountryFormController',
           bindings: {
               tenantCountry: '<?',
               availableCountries: '<',
               companies: '<',
               onCancel: '&'
           }
       });
