angular.module('everon.billing-plans')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans', {
        url: '/billing-plans',
        redirectTo: 'auth.billing-plans.products',
        data: {
            id: 'propositions-navigation-link',
            title: 'billingPlans.title',
            menuPriority: 30,
            icon: 'icon-billing',
            navigationType: ['primary'],
            tenantLevel: true,
            permissions: ['BILLING_PLAN:READ']
        },
        views: {
            'main@auth': {
                templateUrl: 'modules/billing-plans/billing-plans.html'
            },
            'navSecondary@auth': {
                template: '<evcloud-nav-list filter="{navigationType: \'secondary\', parent: \'auth.billing-plans\'}"  />'
            }
        }
    });
}
