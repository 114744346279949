angular.module('everon.roaming.locations.location')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.locations.location', {
        url: '/:id',
        data: {
            title: 'roaming.location.title'
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/roaming/locations/location' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, 'everon.roaming.locations.location');
        },
        resolve: {
            data: ['$transition$', 'roamingService', ($transition$, roamingService) => {
                const {id} = $transition$.params('to');

                return roamingService.getLocation(id);
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.roaming': {
                template: '<evcloud-location data-data-promise="::$resolve.data" class="flex-auto flex" />'
            }
        }
    });
}
