angular.module('everon.cards.card-import-csv')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card-import-csv', {
        url: '/import-csv',
        data: {
            title: 'generic.action.importCsv',
            permissions: ['CARD:IMPORT']
        },
        views: {
            'main@auth': {
                template: '<evcloud-card-import-csv class="sm-flex flex-auto"/>'
            }
        }
    });
}
