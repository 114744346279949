angular.module('everon.roaming.connections.connect-partner.details')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.roaming.connections.connect-partner.details', {
        data: {
            title: 'generic.title.details',
            order: 0
        },
        views: {
            'content@auth.roaming.connections.connect-partner': {
                component: 'evcloudRoamingConnectPartnerDetails'
            }
        }
    });
}
