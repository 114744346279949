angular.module('everon.tenant-settings.companies.company-form')
       .controller('CompanyFormController', CompanyFormController);

CompanyFormController.$inject = ['$state', '$q', 'CONSTANTS', 'mediatorService', 'companyFormModel', 'tenantCompanyService', 'modalService', 'validationService'];

function CompanyFormController($state, $q, CONSTANTS, mediatorService, companyFormModel, tenantCompanyService, modalService, validationService) {
    const $ctrl = this;
    const formName = 'companyForm';
    let modal;

    $ctrl.$onInit = () => {
        $ctrl.state = {
            saveInProgress: false,
            deleteInProgress: false,
            hasChanged: false,
            isUpdate: _.has($ctrl.company, 'id')
        };

        $ctrl.formModel = extendFormModel(companyFormModel);

        if ($ctrl.state.isUpdate) {
            $ctrl.model = $ctrl.company;
            $ctrl.modelCopy = JSON.stringify($ctrl.model);
        }

        if (!$ctrl.state.isUpdate) {
            $ctrl.model = {
                invoicingNumbering: _.cloneDeep($ctrl.formModel.invoicingNumbering),
                billingNumbering: _.cloneDeep($ctrl.formModel.billingNumbering)
            };
        }

        $ctrl.asyncIbanValidatorConfig = {
            name: 'iban',
            validatorFn: verifyIban
        };

        $ctrl.asyncBicValidatorConfig = {
            name: 'bic',
            validatorFn: verifyBic
        };

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController',
            data: {
                title: 'tenantSettings.legalEntities.legalEntity.form.heading.deleteLegalEntity',
                paragraph: 'tenantSettings.legalEntities.legalEntity.form.heading.deleteConfirmation',
                button: {
                    ok: 'generic.action.delete'
                }
            }
        }).then(modalInstance => {
            modal = modalInstance;
        });
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    /**
     * Triggered when a form elements changes
     */
    $ctrl.onChange = () => {
        setChanged(true);
    };

    $ctrl.save = () => {
        if (!$ctrl.state.hasChanged) {
            resetFormState();

            return;
        }

        const model = _($ctrl.model).pick(['address', 'bic', 'iban', 'name', 'number', 'vatNumber'])
                                    .assign({currencyCode: $ctrl.model.currency.code})
                                    .value();

        $ctrl.state.saveInProgress = true;

        return ($ctrl.state.isUpdate ? update(model) : save(model)).finally(onSettled);
    };

    /**
     * Ask for company delete confirmation, if user confirms we attempt delete
     */
    $ctrl.delete = () => {
        modal.open()
             .then(attemptDelete);
    };

    function save(payload) {
        payload.invoicingNumbering = mapNumbering($ctrl.model.invoicingNumbering);
        payload.billingNumbering = mapNumbering($ctrl.model.billingNumbering);

        return tenantCompanyService.saveCompany(payload)
                                   .then(_.partialRight(onFulfilled, 'legalEntityAddSuccess'))
                                   .catch(onRejected);
    }

    function update(payload) {
        return tenantCompanyService.updateCompany($ctrl.model.id, payload)
                                   .then(_.partialRight(onFulfilled, 'legalEntityUpdateSuccess'))
                                   .catch(onRejected);
    }

    /**
     * Attempt company delete
     * @param {boolean} canProceed
     */
    function attemptDelete(canProceed) {
        if (canProceed) {
            $ctrl.state.deleteInProgress = true;

            tenantCompanyService.deleteCompany($ctrl.model.id)
                                .then(_.partialRight(onFulfilled, 'legalEntityDeleteSuccess'))
                                .catch(() => {
                                    mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                                        type: 'error',
                                        isPersistent: true,
                                        messageKey: 'tenantSettings.legalEntities.notification.legalEntityDeleteError'
                                    });
                                })
                                .finally(onSettled);
        }
    }

    /**
     * Successful company creation or update callback
     * @param {Object} response
     * @param {string} messageSuffix
     */
    function onFulfilled(response, messageSuffix) {
        $state.go('auth.tenant-settings.companies', null, {reload: 'auth.tenant-settings.companies'})
              .then(() => {
                  mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                      type: 'success',
                      messageKey: `tenantSettings.legalEntities.notification.${messageSuffix}`
                  });
              });
    }

    /**
     * Rejected compnay creation promise callback
     * @param {Object} response
     */
    function onRejected(response) {
        const errorMap = {
            409: 'generic.error.nameExists',
            417: 'generic.error.invalidAddress'
        };

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: errorMap[response.status] || CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Reset the state after the promise is resolved
     */
    function onSettled() {
        $ctrl.state.saveInProgress = false;
        $ctrl.state.deleteInProgress = false;
    }

    /**
     * Extends formModel with additional properties
     * @param {Object} formModel
     * @returns {Object}
     */
    function extendFormModel(formModel) {
        const keys = Object.keys(formModel);

        return _.zipObject(keys, _.map(keys, _.partial(addProperty, formModel)));
    }

    /**
     * Remaps arrays items by adding additional property to them
     * @param {Object} formModel
     * @param {string} key
     * @returns {Array}
     */
    function addProperty(formModel, key) {
        return _.map(formModel[key], item => {
            item.id = _.kebabCase(item.name);

            return item;
        });
    }

    /**
     * Updates `$ctrl.state.hasChanged` which is used to toggle disabled state of the Save button
     * @param {boolean} hasChanged
     */
    function setChanged(hasChanged) {
        const modelChanged = hasModelChanged();

        $ctrl.state.hasChanged = hasChanged && hasModelChanged();

        if (!modelChanged) {
            $ctrl[formName].$setPristine();
        }
    }

    /**
     * Resets the form to untouched pristine state and sets `$ctrl.state.hasChanged` to `false`
     */
    function resetFormState() {
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
        setChanged(false);
    }

    /**
     * Checks whether the model has changed since controller's initialisation
     * @returns {boolean}
     */
    function hasModelChanged() {
        // In case of adding a new company, `$ctrl.modelCopy` is always undefined
        return $ctrl.modelCopy ? ($ctrl.modelCopy !== JSON.stringify($ctrl.model)) : true;
    }

    /**
     * Verifies if IBAN is valid
     * @param {string} modelValue
     * @param {string} viewValue
     * @returns {Promise}
     */
    function verifyIban(modelValue, viewValue) {
        if ($ctrl.modelCopy && JSON.parse($ctrl.modelCopy).iban === viewValue) {
            return $q.resolve();
        }

        return validationService.verifyIban(modelValue.replace(/\s/g, ''));
    }

    /**
     * Verifies if SWIFT/BIC is valid
     * @param {string} modelValue
     * @param {string} viewValue
     * @returns {Promise}
     */
    function verifyBic(modelValue, viewValue) {
        if ($ctrl.modelCopy && JSON.parse($ctrl.modelCopy).bic === viewValue) {
            return $q.resolve();
        }

        return validationService.verifyBic(modelValue.replace(/\s/g, ''));
    }

    /**
     * Prepares numbering models for submission
     * @param {Array} section
     * @returns {Array}
     */
    function mapNumbering(section) {
        return section.map(group => ({
            name: group.name,
            members: group.members.map(({name, value}) => ({
                name,
                value
            }))
        }));
    }
}
