angular.module('everon.billing-plans.plans.plan-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.billing-plans.plans.plan-add', {
        url: '/new/:type',
        redirectTo: 'auth.billing-plans.plans.plan-add.plan-basic-info',
        data: {
            title: 'billingPlans.plans.plan.title',
            permissions: ['BILLING_PLAN:CREATE']
        },
        lazyLoad(transition) {
            const importPromise = import(/* webpackChunkName: 'app/billing-plans/plans/plan-add' */ './index-lazy.js');

            return transition.injector()
                             .get('lazyLoadService')
                             .loadModule(importPromise, [
                                 'everon.billing-plans.plans.plan-add',
                                 'everon.billing-plans.plans.plan-add.plan-basic-info',
                                 'everon.billing-plans.plans.plan-add.plan-eligibility',
                                 'everon.billing-plans.plans.plan-add.plan-overview',
                                 'everon.billing-plans.plans.plan-add.plan-pricing'
                             ]);
        },
        resolve: {
            data: ['$q', '$transition$', 'subscriptionService', 'countryService', ($q, $transition$, subscriptionService, countryService) => {
                const {type} = $transition$.params('to');

                return $q.all({
                    products: subscriptionService.getProductsByType(type, true),
                    countryCurrencies: countryService.getTenantCountryCurrencies(),
                    type
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-plan-add data-data-promise="::$resolve.data" class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}
