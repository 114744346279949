angular.module('everon.stations.charging-profiles.charging-profile-form')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.charging-profiles.charging-profile-add', {
        url: '/new',
        data: {
            title: 'stations.chargingProfiles.title.create'
        },
        params: {
            createAnotherChecked: $stateProvider.createAnotherChecked
        },
        views: {
            'main@auth': {
                template: '<evcloud-charging-profile-form class="overflow-hidden col-12 flex flex-column items-center"/>'
            }
        }
    });
}
