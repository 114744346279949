angular.module('everon')
       .factory('googleMapsLoaderService', googleMapsLoaderService);

googleMapsLoaderService.$inject = ['$q', '$http', 'CONSTANTS'];

function googleMapsLoaderService($q, $http, CONSTANTS) {
    let apiKey;
    let isGoogleMapsScriptInjected = false;

    /**
     * Injects Google Maps API into DOM
     * @param {Object} [options]
     */
    function injectGoogleMapsApiScript(options = {}) {
        if (isGoogleMapsScriptInjected) {
            throw new Error('Google Maps API is already loaded.');
        }

        const optionsQuery = new URLSearchParams(options).toString();

        const url = `${CONSTANTS.EXTERNAL_URLS.GOOGLE_MAPS_API}?${optionsQuery}`;

        const script = document.createElement('script');

        script.setAttribute('src', url);
        script.setAttribute('async', '');
        script.setAttribute('defer', '');

        document.head.appendChild(script);

        isGoogleMapsScriptInjected = true;
    }

    return {
        /**
         * Returns Google API key
         * @returns {string}
         */
        getApiKey() {
            return apiKey ? $q.resolve(apiKey) : $http.get('/api/platform/google-api-key')
                                                      .then(api => {
                                                          apiKey = api.key;

                                                          return apiKey;
                                                      });
        },

        /**
         * Loads Google Maps API with `options` object for Google Maps API specific settings
         * Returns Google Maps API if it is already injected and loaded
         * @param {Object} [options]
         * @returns {Promise.<Object>}
         */
        async load(options) {
            if (this.googleMapsApi) {
                return $q.resolve(this.googleMapsApi);
            }

            apiKey = await this.getApiKey();

            return new Promise((resolve, reject) => {
                try {
                    window.onGoogleMapsApiLoaded = resolve;

                    injectGoogleMapsApiScript({
                        key: apiKey,
                        callback: 'onGoogleMapsApiLoaded',
                        ...options
                    });
                } catch (error) {
                    reject(error);
                }
            }).then(() => {
                this.googleMapsApi = window.google.maps;

                return window.google.maps;
            });
        }
    };
}
