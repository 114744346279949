angular.module('everon.components', [
    'everon.component.click-outside',
    'everon.component.if-device',
    'everon.component.nav-main',
    'everon.component.nav-list',
    'everon.component.anon-header',
    'everon.component.inactive-header',
    'everon.component.header',
    'everon.component.language-selector',
    'everon.component.datetime-picker',
    'everon.component.block-action',
    'everon.component.block-ui',
    'everon.component.scroll-to',
    'everon.component.notification-modal',
    'everon.component.upload',
    'everon.component.validation-icons',
    'everon.component.google-maps',
    'everon.component.breadcrumb',
    'everon.component.compare-input',
    'everon.component.async-validator',
    'everon.component.spinner',
    'everon.component.no-results',
    'everon.component.action-button',
    'everon.component.data-list',
    'everon.component.css-loader',
    'everon.component.convert-case',
    'everon.component.modal-dialog',
    'everon.component.clipboard',
    'everon.component.accordion',
    'everon.component.is-before-date',
    'everon.component.is-after-date',
    'everon.component.is-one-checked',
    'everon.component.product-catalog',
    'everon.component.data-list-pagination',
    'everon.component.column-chart',
    'everon.component.donut-chart',
    'everon.component.donut-text',
    'everon.component.form-change',
    'everon.component.search',
    'everon.component.dropdown-button',
    'everon.component.tooltip',
    'everon.component.confirmation-modal-dialog',
    'everon.component.pull-refresh',
    'everon.component.loader',
    'everon.component.json-tree',
    'everon.component.search-dropdown',
    'everon.component.actionable-cards',
    'everon.component.password-strength-indicator',
    'everon.component.power-bar',
    'everon.component.vue-component'
]);
