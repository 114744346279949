angular.module('everon')
       .factory('sortingMixin', sortingMixin);

sortingMixin.$inject = [];

function sortingMixin() {
    /**
     * Returns lowercase item property string
     * @param {string|number} property
     * @param {Object} item
     * @returns {string}
     */
    function iterator(property, item) {
        const value = _.get(item, property);

        return _.isString(value) ? value.toLowerCase() : value;
    }

    return {
        /**
         * Sorts data by property in ascending or descending order
         * @param {Array} data
         * @param {string} property
         * @param {string} order
         * @returns {Array}
         */
        orderBy(data, {property, order}) {
            return _.orderBy(data, [_.partial(iterator, property)], [order]);
        }
    };
}
