angular.module('everon')
       .factory('permissionService', permissionService);

permissionService.$inject = ['$injector'];

function permissionService($injector) {
    const isEmptyArray = array => !Array.isArray(array) || !array.length;

    return {
        /**
         * Resolves user permissions and checks whether a current user is authorized or not. If there are no required permissions, then user is authorized by default
         * @param {Array} userPermissions
         * @param {Array | Function} requiredPermissions the set of permissions the user must present in order to be granted the access. The array can be a combination
         *        of strings and functions, the functions will receive the user permissions as the first argument and an options object with the $injector field (angular injector service).
         * @returns {boolean}
         */
        resolve(userPermissions, requiredPermissions) {
            if (isEmptyArray(userPermissions)) {
                return false;
            }

            if (typeof requiredPermissions === 'function') {
                return requiredPermissions(userPermissions, {$injector});
            }

            if (isEmptyArray(requiredPermissions)) {
                return true;
            }

            const strPermissions = [];
            const fnPermissions = [];

            requiredPermissions.forEach(reqPerm => {
                switch (typeof reqPerm) {
                    case 'function':
                        fnPermissions.push(reqPerm);
                        break;
                    case 'string':
                        strPermissions.push(reqPerm);
                        break;
                    default:
                        throw new TypeError(`permission.service#resolve(): invalid permission type '${typeof reqPerm}', expected 'string' or 'Function'.`);
                }
            });

            return strPermissions.every(reqPerm => userPermissions.includes(reqPerm)) &&
                fnPermissions.every(fnReqPerm => fnReqPerm(userPermissions, {$injector}));
        },

        /**
         * Resolves tenant features. If there are no required features defined then a user has access to all features by default
         * @param {Object} featureFlags
         * @param {Array} requiredFeatures
         * @returns {*}
         */
        resolveFeatures(featureFlags, requiredFeatures) {
            return !isEmptyArray(requiredFeatures) ? this.isFeatureEnabled(featureFlags, requiredFeatures) : true;
        },

        /**
         * Checks whether required tenant features are enabled or not
         * @param {Object} tenantFeatureFlags
         * @param {Array} [requiredFeatures=[]]
         * @returns {boolean}
         */
        isFeatureEnabled(tenantFeatureFlags, requiredFeatures = []) {
            return requiredFeatures.every(feature => tenantFeatureFlags[feature]);
        }
    };
}
