angular.module('everon.stations.station-add.station-billing-information')
       .component('evcloudStationAddBillingInformation', {
           templateUrl: 'modules/stations/station-add/station-billing-information/station-billing-information.html',
           controller: 'StationAddBillingInformationController',
           bindings: {
               dataPromise: '<'
           },
           require: {
               stationAddCtrl: '^^evcloudStationAdd'
           }
       });
