angular.module('everon.cards.card.billing-plan')
       .controller('CardBillingPlanController', CardBillingPlanController);

CardBillingPlanController.$inject = ['mediatorService', 'CONSTANTS', 'subscriptionService', 'modalService', 'utils'];

function CardBillingPlanController(mediatorService, CONSTANTS, subscriptionService, modalService, utils) {
    const $ctrl = this;
    let modal;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            canChangeBillingPlan: false,
            showChangeBillingPlanButton: false,
            withKnownRenewalDate: true
        };

        modalService.register({
            templateUrl: 'components/confirmation-modal-dialog/confirmation-modal-dialog.html',
            controller: 'ConfirmationModalDialogController'
        }).then(modalInstance => {
            modal = modalInstance;
        });

        $ctrl.updatePlanLink = 'auth.cards.card.billing-plan.change';
        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.$onDestroy = () => {
        modalService.destroy(modal);
    };

    $ctrl.cancelPlanUpdate = () => {
        modal.open({
            title: 'component.product.plan.cancelUpdateTitle',
            paragraph: 'component.product.plan.cancelUpdateBody',
            translateValues: {
                planName: $ctrl.subscription.scheduledUpdate.planName
            },
            button: {
                ok: 'component.product.plan.cancelUpdateConfirm'
            }
        }).then(cancelScheduledUpdate);
    };

    function cancelScheduledUpdate(canProceed) {
        if (!canProceed) {
            return;
        }

        subscriptionService.updateCardSubscription($ctrl.card.id, {planId: $ctrl.subscription.plan.id})
                           .then(subscription => {
                               $ctrl.subscription = subscription;
                           });
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({card, subscription, products, canUpdatePlan}) {
        $ctrl.card = card;
        $ctrl.subscription = _.cloneDeep(subscription);
        $ctrl.subscription.product.characteristics = subscriptionService.filterCharacteristics(subscription.product.characteristics);
        $ctrl.planHasEnded = subscription.endedAt && utils.getMoment().diff(utils.getMoment(subscription.endedAt), 'days') >= 0;
        $ctrl.state.canChangeBillingPlan = canUpdatePlan && products.length > 0;
        $ctrl.state.showChangeBillingPlanButton = subscription.product.type === 'card' && card.status !== 'deactivated';
        $ctrl.state.withKnownRenewalDate = $ctrl.subscription.renewalDate !== null;

        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [card.reference || card.contractId, 'current']);
    }
}
