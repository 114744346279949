angular.module('everon.tenant-settings.companies.company-form')
       .component('evcloudCompanyForm', {
           templateUrl: 'modules/tenant-settings/companies/company-form/company-form.html',
           controller: 'CompanyFormController',
           bindings: {
               company: '<?',
               currencies: '<',
               countries: '<',
               onCancel: '&'
           }
       });
