const INVOICING_NUMBERING_REGEXP = /^(?=[0-9]{1,8}$)(0*[1-9]+[0-9]*)$/;
const BILLING_NUMBERING_REGEXP = /^(?=[0-9]{1,10}$)(0*[1-9]+[0-9]*)$/;

angular.module('everon.tenant-settings.companies.company-form')
       .value('companyFormModel', {
           business: [
               {
                   name: 'companyNumber',
                   type: 'text',
                   helpText: 'generic.helpText.alphanumericOnly',
                   regex: /^[\d|a-zA-Z]*$/,
                   maxlength: 255,
                   cssClass: 'sm-col-10',
                   required: false
               },
               {
                   name: 'vatNumber',
                   type: 'text',
                   helpText: 'generic.helpText.alphanumericOnly',
                   regex: /^[\d|a-zA-Z]*$/,
                   minlength: 2,
                   maxlength: 15,
                   cssClass: 'sm-col-10',
                   required: true
               }
           ],
           address: [
               {
                   name: 'streetName',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   required: true,
                   label: true,
                   maxlength: 255,
                   placeholder: 'account.billingInformation.form.placeholder.streetName'
               },
               {
                   name: 'streetName2',
                   type: 'text',
                   cssClass: 'sm-col-10',
                   required: false,
                   label: false,
                   maxlength: 255,
                   placeholder: 'account.billingInformation.form.placeholder.streetName2'
               },
               {
                   name: 'house',
                   type: 'text',
                   helpText: 'generic.helpText.house',
                   required: false,
                   maxlength: 255,
                   label: true
               },
               {
                   name: 'postcode',
                   type: 'text',
                   required: true,
                   maxlength: 255,
                   label: true
               },
               {
                   name: 'city',
                   type: 'text',
                   required: true,
                   maxlength: 255,
                   label: true
               },
               {
                   name: 'country',
                   type: 'select',
                   cssClass: 'sm-col-6',
                   required: true,
                   label: true
               }
           ],
           billing: [
               {
                   name: 'iban',
                   type: 'text',
                   cssClass: 'sm-col-11',
                   maxlength: 34,
                   asyncValidator: 'asyncIbanValidatorConfig',
                   required: true
               },
               {
                   name: 'bic',
                   type: 'text',
                   cssClass: 'sm-col-8',
                   maxlength: 11,
                   asyncValidator: 'asyncBicValidatorConfig',
                   required: true
               }
           ],
           invoicingNumbering: [
               {
                   name: 'subscription',
                   members: [
                       {
                           name: 'nextNumber',
                           type: 'text',
                           min: 1,
                           max: 8,
                           regex: INVOICING_NUMBERING_REGEXP,
                           required: true,
                           cssClass: 'sm-col-8',
                           placeholder: '00000001',
                           helpText: 'tenantSettings.legalEntities.legalEntity.form.label.helpText.subscription'
                       }
                   ]
               },
               {
                   name: 'station',
                   members: [
                       {
                           name: 'nextNumber',
                           type: 'text',
                           min: 1,
                           max: 8,
                           regex: INVOICING_NUMBERING_REGEXP,
                           required: true,
                           cssClass: 'sm-col-8',
                           placeholder: '0000001'
                       }
                   ]
               },
               {
                   name: 'card',
                   members: [
                       {
                           name: 'nextNumber',
                           type: 'text',
                           min: 1,
                           max: 8,
                           regex: INVOICING_NUMBERING_REGEXP,
                           required: true,
                           cssClass: 'sm-col-8',
                           placeholder: '0000001'
                       }
                   ]
               }
           ],
           billingNumbering: [
               {
                   name: 'customer',
                   members: [
                       {
                           name: 'nextNumber',
                           type: 'text',
                           min: 1,
                           max: 10,
                           regex: BILLING_NUMBERING_REGEXP,
                           required: true,
                           cssClass: 'sm-col-8',
                           placeholder: '0000001'
                       }
                   ]
               }
           ]
       });
