angular.module('everon.users.user-invite')
       .controller('UserInviteController', UserInviteController);

UserInviteController.$inject = ['$state', 'userService', 'sessionService', 'mediatorService', 'CONSTANTS', 'accountFormModel', 'accountService'];

function UserInviteController($state, userService, sessionService, mediatorService, CONSTANTS, accountFormModel, accountService) {
    const $ctrl = this;
    const formName = 'userInviteForm';
    const subAccountRoles = CONSTANTS.ROLES.SUB_ACCOUNT_INVITATION;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            saveInProgress: false,
            inviteAnother: false,
            rolesSelected: false,
            canInviteSubAccountUser: false,
            isTenantLevelUser: Boolean(_.intersection(sessionService.getUserProfile().roles, ['tenantAdmin', 'customerSupport']).length)
        };

        $ctrl.customerType = $state.params.customerType;
        $ctrl.isInvitingEndUser = $ctrl.customerType === 'end-user';
        $ctrl.reimbursementRateModel = _.find(accountFormModel.reimbursement, {name: 'rate'});
        $ctrl.selectedRoles = {};
        $ctrl.model = {
            isBusiness: false
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.save = () => {
        const {email, isBusiness, reimbursementRate, accountName, accountId} = $ctrl.model;

        let model = {roles: getSelectedRoles(), email, accountId};

        if ($ctrl.state.canInviteSubAccountUser && subAccountRoles.includes($ctrl.selectedRole)) {
            model = {
                ...model,
                isBusiness,
                reimbursementRate,
                accountName
            };
        }

        $ctrl.state.saveInProgress = true;

        userService.inviteUser(model)
                   .then(() => {
                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                           type: 'success'
                       });

                       if (!$ctrl.state.inviteAnother) {
                           $state.go('^', null, {reload: '^'});
                       }

                       resetFormState();
                   })
                   .catch(error => {
                       const {status, data} = error;

                       let messageKey = status ? CONSTANTS.ERROR_CODES[status] : CONSTANTS.ERROR_CODES[500];

                       if (status === 409) {
                           messageKey = data?.properties?.errorCode === 'already_existent_account_with_name' ?
                               'users.inviteUser.notification.accountNameAlreadyInUse' : 'users.inviteUser.notification.emailAlreadyInUse';
                       }

                       mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                           type: 'error',
                           messageKey,
                           isPersistent: true
                       });
                   })
                   .finally(() => {
                       $ctrl.state.saveInProgress = false;
                   });
    };

    $ctrl.onCancel = () => {
        $state.go('^', null, {reload: '^'});
    };

    $ctrl.onChange = () => {
        $ctrl.state.rolesSelected = $ctrl.roles.some(role => $ctrl.selectedRoles[role]);
    };

    /**
     * Users with Account Owner, Account Admin role can invite one of child Account Owner or Driver
     * @returns {Array}
     */
    function getSelectedRoles() {
        return $ctrl.state.canInviteSubAccountUser && $ctrl.isInvitingEndUser ? [$ctrl.selectedRole] : _.keys(_.pickBy($ctrl.selectedRoles, Boolean));
    }

    /**
     * Returns roles available for selection based on `customerType` and whether a user can invite sub-account roles or not
     * @param {Array} roles
     * @returns {Array}
     */
    function getAvailableRoles(roles) {
        if ($ctrl.state.canInviteSubAccountUser) {
            return $ctrl.isInvitingEndUser ? subAccountRoles : _.xor(roles, subAccountRoles);
        }

        return roles;
    }

    /**
     * Resets the form to untouched pristine state and updates controller state
     */
    function resetFormState() {
        $ctrl.selectedRoles = {};
        $ctrl.model = {
            isBusiness: false
        };
        $ctrl.state = _.assign($ctrl.state, {
            saveInProgress: false,
            rolesSelected: false
        });
        $ctrl[formName].$setUntouched();
        $ctrl[formName].$setPristine();
    }

    /**
     * Extends controller's model with the selected account id
     * @param {Object} account
     */
    function onAccountSelected({id}) {
        $ctrl.model.accountId = id;
    }

    function getData(query) {
        return accountService.getChildAccounts({query})
                             .then(data => data.content.map(({id, name}) => ({
                                 id,
                                 primary: name
                             })));
    }

    /**
     * Updates state and makes data available to the template
     * @param {Array} data
     */
    function onDataLoaded({roles, billingOverview, countryCurrencies}) {
        $ctrl.state.canInviteSubAccountUser = Boolean(_.intersection(roles, subAccountRoles).length);
        $ctrl.roles = userService.sortUserRoles(getAvailableRoles(roles));

        if ($ctrl.state.canInviteSubAccountUser && $ctrl.isInvitingEndUser) {
            $ctrl.selectedRole = $ctrl.roles[0];

            // TODO: find a better way to resolve currency for reimbursement
            $ctrl.currency = _.isEmpty(billingOverview) ? {code: 'EUR', symbol: '€'} : _.find(countryCurrencies, {country: billingOverview.billingAddress.country}).currency;
            $ctrl.searchOptions = {
                id: 'accounts',
                asyncSearch: true,
                getDataAsync: getData,
                onSelect: onAccountSelected
            };
        }

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['parent', 'current']);
    }
}
