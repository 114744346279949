angular.module('everon.accounts')
       .controller('AccountsController', AccountsController);

AccountsController.$inject = ['accountService', 'mediatorService', 'CONSTANTS', '$state', 'stateHelper'];

function AccountsController(accountService, mediatorService, CONSTANTS, $state, stateHelper) {
    const $ctrl = this;
    let stateChangeListener;

    $ctrl.$onInit = () => {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        stateChangeListener = stateHelper.subscribeToParamsChange({
            type: setType,
            country: setCountry,
            sort: setSortingParams
        }, getData);

        $ctrl.pagination = null;
        $ctrl.types = ['allTypes', 'private', 'business'];
        $ctrl.selectedType = $state.params.type || $ctrl.types[0];

        $ctrl.state = {
            dataLoaded: false,
            pageLoadInProgress: false,
            filterActive: false
        };

        $ctrl.columns = [
            {
                property: 'number',
                translationKey: 'generic.label.number',
                sortable: true,
                cssClass: 'xs-hide sm-col-2'
            },
            {
                property: 'name',
                translationKey: 'generic.label.name',
                sortable: true,
                cssClass: 'col-6 sm-col-3'
            },
            {
                property: 'email',
                translationKey: 'generic.label.email',
                sortable: true,
                cssClass: 'col-6 sm-col-3'
            },
            {
                property: 'country',
                translationKey: 'generic.entity.country',
                sortable: true,
                cssClass: 'xs-hide sm-col-2'
            },
            {
                property: 'type',
                translationKey: 'generic.label.type',
                sortable: true,
                cssClass: 'xs-hide sm-col-2'
            }
        ];

        $ctrl.sortingParams = stateHelper.getSortingParams();

        $ctrl.noResultsConfig = {
            heading: 'accounts.list.noResults.heading',
            paragraph: 'accounts.list.noResults.paragraph'
        };

        $ctrl.noFilterResultsConfig = {
            heading: 'generic.noResults.heading',
            paragraph: 'generic.noFilterResults.paragraph'
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    /**
     * Unsubscribe from state changes on destroying
     */
    $ctrl.$onDestroy = () => {
        stateChangeListener.resolve();
    };

    /**
     * Updates state with pagination params
     * @param {number} page
     */
    $ctrl.goToPage = (page = 0) => {
        const {size} = $ctrl.pagination;

        stateHelper.changeParams({size, page});
    };

    /**
     * Updates state with sorting params
     * @param {Object} sortingParams
     */
    $ctrl.onSortingChange = ({property, order}) => {
        stateHelper.changeParams({sort: `${property},${order}`});
    };

    /**
     * Updates state with selected filters and zero-pagination
     */
    $ctrl.onFilterChange = () => {
        const type = $ctrl.selectedType === 'allTypes' ? null : $ctrl.selectedType;
        const country = _.get($ctrl.selectedCountry, 'code', null);

        $ctrl.state.filterActive = isFilterActive();
        stateHelper.changeParams({page: 0, country, type});
    };

    /**
     * Checks is any filter selected
     * @returns {boolean}
     */
    function isFilterActive() {
        return $ctrl.selectedType !== 'allTypes' || Boolean($ctrl.selectedCountry);
    }

    function setType(type) {
        $ctrl.selectedType = type || 'allTypes';
    }

    function setCountry(country) {
        $ctrl.selectedCountry = getCountryByCode(country);
    }

    function setSortingParams() {
        $ctrl.sortingParams = stateHelper.getSortingParams();
    }

    /**
     * Requests accounts
     */
    function getData() {
        const params = _.pick($state.params, ['type', 'country', 'sort', 'page', 'size']);

        $ctrl.state.pageLoadInProgress = true;

        accountService.getAccounts(params)
                      .then(updateData)
                      .catch(onRejected)
                      .finally(() => {
                          $ctrl.state.pageLoadInProgress = false;
                      });
    }

    function getCountryByCode(code) {
        return _.find($ctrl.countries, {code});
    }

    /**
     * Manage rejected requests
     * @param {Object} response
     */
    function onRejected(response) {
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
            type: 'error',
            messageKey: CONSTANTS.ERROR_CODES[response.status]
        });
    }

    /**
     * Updates data model on every change
     * @param {Object} data
     */
    function updateData(data) {
        $ctrl.accounts = data.content;
        $ctrl.pagination = accountService.getPagination(data);
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data composed by 'accounts' and 'countries'
     */
    function onDataLoaded({accounts, countries}) {
        updateData(accounts);

        $ctrl.countries = countries;
        $ctrl.selectedCountry = getCountryByCode($state.params.country);

        $ctrl.state.filterActive = isFilterActive();
        $ctrl.state.dataLoaded = true;
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['current']);
    }
}
