angular.module('everon')
       .factory('modalService', modalService);

modalService.$inject = ['$rootScope', '$compile', '$q', '$document', '$controller', '$templateRequest'];

function modalService($rootScope, $compile, $q, $document, $controller, $templateRequest) {
    const modals = [];
    const body = $document.find('body');

    return {
        register(options) {
            const modalScope = $rootScope.$new(true);
            let closeDeferred;
            let modalElement;

            ['templateUrl', 'controller'].forEach(option => {
                if (!options[option]) {
                    throw new Error(`Expected '${option}' in options`);
                }
            });

            /**
             * Adds necessary properties on the scope, compiles the template and appends to the DOM
             * @param {Object} templates
             * @returns {Promise.<Object>}
             */
            function compile({containerTemplate, modalTemplate}) {
                return $q(resolve => {
                    const containerElement = angular.element(containerTemplate);
                    const controllerAs = options.controllerAs || '$ctrl';
                    const locals = {
                        $scope: modalScope,
                        data: options.data,
                        close
                    };
                    const modal = {
                        id: _.uniqueId('modal-'),
                        open,
                        destroy
                    };

                    angular.element(containerElement[0].querySelector('.modal-dialog-body')).append(modalTemplate);
                    modalElement = $compile(containerElement)(modalScope);
                    locals.$element = modalElement;

                    const childController = modalScope[controllerAs] || {};

                    childController.data = options.data || null;
                    _.merge($controller(options.controller, locals, false, controllerAs), childController);
                    body.append(modalElement);

                    modals.push(modal);
                    resolve(modal);
                });
            }

            /**
             * Returns a promise which is resolved when modal gets closed. Optional data can be provided
             * @param {Object} [data]
             * @returns {Promise}
             */
            function open(data) {
                if (data) {
                    modalScope.$ctrl.data = _.merge(options.data, data);
                }

                closeDeferred = $q.defer();
                modalElement.attr('aria-hidden', false);
                $document.off('keyup', onKeyUp)
                         .on('keyup', onKeyUp);

                return closeDeferred.promise;
            }

            /**
             * Resolves the promise with data (if any)
             * @param {*} data
             */
            function close(data) {
                modalElement.attr('aria-hidden', true);
                closeDeferred.resolve(data);
                closeDeferred = null;
            }

            /**
             * Destroys modal scope and removes keyup event handler from the document
             */
            function destroy() {
                closeDeferred = null;
                modalScope.$destroy();
                modalElement.remove();
                $document.off('keyup', onKeyUp);
            }

            /**
             * Closes a modal on escape key press
             * @param {Event} event
             */
            function onKeyUp(event) {
                if (event.keyCode === 27) {
                    $document.off('keyup', onKeyUp);
                    close();
                }
            }

            return $q.all({
                         containerTemplate: $templateRequest('components/modal-dialog/modal-dialog.html'),
                         modalTemplate: $templateRequest(options.templateUrl)
                     })
                     .then(compile);
        },

        /**
         * Returns modal instance by given id
         * @param {string} id
         * @returns {Object|undefined}
         */
        get(id) {
            return _.find(modals, {id});
        },

        /**
         * Destroys modal instance, unbinds event handlers and removes modal element
         * @param {Object} modal
         */
        destroy({id}) {
            const modal = _.find(modals, {id});

            if (modal) {
                modal.destroy();
                _.remove(modals, modal);
            }
        }
    };
}
