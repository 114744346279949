angular.module('everon.component.password-strength-indicator')
       .controller('PasswordStrengthIndicatorController', PasswordStrengthIndicatorController);

function PasswordStrengthIndicatorController() {
    const $ctrl = this;
    const strengthLevelNames = ['weak', 'medium', 'strong'];
    const strengthLevelsMapping = [0, 0, 1, 2, 2];

    $ctrl.$onInit = () => {
        $ctrl.strengthLevels = ['', '', ''];
        $ctrl.strengthLevelName = 'unset';
        $ctrl.levelStyleMap = {weak: '-error', medium: '-alert', strong: '-success'};
    };

    $ctrl.$onChanges = ({strength}) => {
        if (strength && !strength.isFirstChange()) {
            const strengthLevel = strength.currentValue === -1 ? -1 : strengthLevelsMapping[Math.min(Math.max(strength.currentValue, 0), 4)];

            $ctrl.strengthLevelName = strengthLevel < 0 ? 'unset' : strengthLevelNames[strengthLevel];
            $ctrl.strengthLevels = $ctrl.strengthLevels.map((_, index) => index > strengthLevel ? '' : strengthLevelNames[index]);
        }
    };
}
