angular.module('everon.stations.station.charging-profile')
       .controller('StationChargingProfileController', StationChargingProfileController);

StationChargingProfileController.$inject = ['$q', 'mediatorService', 'CONSTANTS', 'permissionService', 'sessionService', 'stationService', 'chargingProfilesService', 'utils'];

function StationChargingProfileController($q, mediatorService, CONSTANTS, permissionService, sessionService, stationService, chargingProfilesService, utils) {
    const $ctrl = this;
    const intervalsInADay = 24;

    $ctrl.$onInit = () => {
        const profile = sessionService.getUserProfile();
        const checkPermissions = _.partial(permissionService.resolve.bind(permissionService), profile.permissions);

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

        $ctrl.state = {
            dataLoaded: false,
            stationHasProfile: false,
            assignInProgress: false,
            chargingProfileSelected: false,
            availableChargingProfiles: false,
            canManageStation: checkPermissions(['STATION:UPDATE']),
            isTenantAdmin: profile.roles.includes('tenantAdmin')

        };

        $ctrl.searchOptions = {
            id: 'charging-profiles',
            placeholder: 'stations.chargingProfiles.form.label.chargingProfileName',
            initialValue: '',
            onSelect: onProfileSelected,
            tabIndex: 1
        };

        $ctrl.weekDays = chargingProfilesService.buildDefaultWeekDaysObject();

        $ctrl.noResultsConfig = {
            noAccountProfilesCreated: {
                icon: 'empty-state-generic',
                heading: 'stations.chargingProfiles.noResults.noAccountProfilesCreated.heading',
                paragraph: 'stations.chargingProfiles.noResults.noAccountProfilesCreated.paragraph',
                links: [
                    {
                        state: 'auth.stations.charging-profiles.charging-profile-add',
                        text: 'stations.chargingProfiles.title.create'
                    }
                ]
            },
            accountProfilesNotEnabled: {
                icon: 'empty-state-generic',
                heading: 'stations.chargingProfiles.noResults.accountProfilesNotEnabled.heading',
                paragraph: 'stations.chargingProfiles.noResults.accountProfilesNotEnabled.paragraph'
            }
        };

        $ctrl.dataPromise.then(onDataLoaded);
    };

    $ctrl.cancel = () => {
        $ctrl.model = JSON.parse($ctrl.modelCopy);
        $ctrl.chargingProfileData = undefined;
        $ctrl.state.stationHasProfile = false;
        $ctrl.state.chargingProfileSelected = false;
        $ctrl.weekDays.forEach(weekDay => weekDay.isExpanded = false);
        $ctrl.stationChargingProfileForm.$setUntouched();
        $ctrl.stationChargingProfileForm.$setPristine();
    };

    $ctrl.toggleChargingProfileAssignment = () => {
        $ctrl.state.assignInProgress = true;

        const action = $ctrl.state.stationHasProfile ? 'unassign' : 'assign';
        const stationId = $ctrl.model.id;
        const profileId = $ctrl.state.stationHasProfile ? null : $ctrl.model.chargingProfileId;

        stationService.updateChargingProfile(stationId, profileId)
                      .then(station => {
                          $ctrl.model = _.assign({}, station, {formattedUtcOffset: utils.generateHumanReadableUtcOffset(station.utcOffset)});
                          $ctrl.modelCopy = JSON.stringify($ctrl.model);
                          $ctrl.state.stationHasProfile = Boolean(station.chargingProfile);
                          $ctrl.state.chargingProfileSelected = Boolean(station.chargingProfile);
                          $ctrl.model.chargingProfileId = $ctrl.state.stationHasProfile ? station.chargingProfile.id : null;
                          $ctrl.searchOptions.initialValue = $ctrl.state.stationHasProfile ? station.chargingProfile.name : '';

                          if (!$ctrl.state.stationHasProfile) {
                              $ctrl.weekDays.forEach(weekDay => weekDay.isExpanded = false);
                              $ctrl.chargingProfileData = undefined;
                          }

                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'success',
                              messageKey: `stations.chargingProfiles.form.notification.${action}.success`
                          });
                      })
                      .catch(() => {
                          mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.NOTIFICATION, {
                              type: 'error',
                              messageKey: `stations.chargingProfiles.form.notification.${action}.failure`
                          });
                      })
                      .finally(() => {
                          $ctrl.state.assignInProgress = false;
                      });
    };

    /**
     * Collapses or expands a single weekDay view
     * Changes are handled by evcloud-accordion
     * @param {Object} weekDay
     */
    $ctrl.toggleWeekDayView = weekDay => {
        weekDay.isExpanded = !weekDay.isExpanded;
    };

    /**
     * Builds the items list object for the search with the right format
     * @param {Array} chargingProfiles
     * @returns {Array}
     */
    function buildItemsListForSearchOptions({tenantProfiles, accountProfiles}, {tenantGroupName, accountGroupName}) {
        const tenantProfilesList = _.map(tenantProfiles, ({id, name}) => {
            return {
                id,
                primary: name,
                secondary: tenantGroupName
            };
        });

        const accountProfilesList = _.map(accountProfiles, ({id, name}) => {
            return {
                id,
                primary: name,
                secondary: accountGroupName
            };
        });

        return [...tenantProfilesList, ...accountProfilesList];
    }

    /**
     * Extends controller's model with the selected site
     * @param {Object} chargingProfile
     */
    function onProfileSelected({id}) {
        const profile = _.find($ctrl.availableChargingProfiles, {id});

        if (profile) {
            $ctrl.model.chargingProfileId = id;
            $ctrl.chargingProfileData = generateChargingProfileData(profile);
            $ctrl.state.chargingProfileSelected = true;
        } else {
            $ctrl.cancel();
        }
    }

    /**
     * Generates all the needed charging profile data for the specified profile
     * @param {Object} profile
     * @returns {Object}
     */
    function generateChargingProfileData(profile) {
        const daySchedules = chargingProfilesService.generateDaySchedules(profile);
        const powerBars = chargingProfilesService.createPowerBarsFromDaySchedules(daySchedules, profile.maxCurrent);
        const powerValues = _.map(daySchedules, daySchedule => chargingProfilesService.calculatePowerValuesForDay(daySchedule));
        const offsetOptions = chargingProfilesService.createOffsetOptions(daySchedules, intervalsInADay);

        return {daySchedules, powerBars, powerValues, offsetOptions};
    }

    /**
     * Transforms the charging profiles object to make sure it has two properties, tenantProfiles and accountProfiles
     * @param {Object} chargingProfiles
     * @returns {Object}
     */
    function transformChargingProfiles(chargingProfiles) {
        return {
            tenantProfiles: _.get(chargingProfiles, 'tenantProfiles', []),
            accountProfiles: _.get(chargingProfiles, 'accountProfiles', [])
        };
    }

    /**
     * Updates state and makes data available to the template
     * @param {Object} data
     */
    function onDataLoaded({station, chargingProfiles, translatedGroupNames, accountFeatures}) {
        $ctrl.state.hasChargingProfilesEnabled = accountFeatures.chargingProfile;

        chargingProfiles = transformChargingProfiles(chargingProfiles);
        const anyAvailableChargingProfiles = chargingProfiles.accountProfiles.length || chargingProfiles.tenantProfiles.length;
        const stationHasAssignedChargingProfile = station.chargingProfile;

        $ctrl.model = _.assign({}, station, {formattedUtcOffset: utils.generateHumanReadableUtcOffset(station.utcOffset)});
        $ctrl.modelCopy = JSON.stringify($ctrl.model);

        if (anyAvailableChargingProfiles) {
            $ctrl.availableChargingProfiles = [...chargingProfiles.tenantProfiles, ...chargingProfiles.accountProfiles];
            $ctrl.searchOptions.data = buildItemsListForSearchOptions(chargingProfiles, translatedGroupNames);
            $ctrl.state.availableChargingProfiles = true;
        }

        if (stationHasAssignedChargingProfile) {
            $ctrl.chargingProfileData = generateChargingProfileData(station.chargingProfile);
            $ctrl.state.stationHasProfile = true;
            $ctrl.model.chargingProfileId = station.chargingProfile.id;
            $ctrl.searchOptions.initialValue = station.chargingProfile.name;
            $ctrl.state.chargingProfileSelected = true;
        }

        $ctrl.state.dataLoaded = true;

        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
        mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, [station.reference || station.identityCode, 'current']);
    }
}
