angular.module('everon.cards.card.billing-plan')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.cards.card.billing-plan', {
        url: '/billing-plan',
        data: {
            id: 'card-billing-plan-navigation-link',
            title: 'generic.title.billingPlan',
            navigationType: ['context'],
            menuPriority: 3,
            permissions: ['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']
        },
        resolve: {
            data: ['$q', 'data', 'subscriptionService', 'permissionService', 'sessionService', 'cardService', ($q, data, subscriptionService, permissionService, sessionService, cardService) => {
                return $q.all({
                    subscription: subscriptionService.getCardSubscription(data.id),
                    card: cardService.getCard(data.id)
                }).then(({subscription, card}) => {
                    return $q.all({
                        card,
                        subscription,
                        canUpdatePlan: cardService.isUpdatable(data.id),
                        products: cardService.getAvailableProductCatalog(subscription, card)
                    });
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.cards.card': {
                component: 'evcloudCardBillingPlan',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
