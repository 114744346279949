angular.module('everon.tenant-settings.companies.company-add')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.tenant-settings.companies.company-add', {
        url: '/new/',
        data: {
            title: 'tenantSettings.legalEntities.action.add'
        },
        resolve: {
            data: ['$q', '$stateParams', 'countryService', 'financialService', ($q, $stateParams, countryService, financialService) => {
                return $q.all({
                    currencies: financialService.getCurrencies(),
                    countries: countryService.getCountries()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.tenant-settings': {
                component: 'evcloudCompanyAdd',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
