import createVueApp from './index-vue';

angular.module('everon.support')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.support', {
        url: '/support',
        data: {
            id: 'support-navigation-link',
            title: 'support.title',
            navigationType: ['user'],
            menuPriority: 8
        },
        resolve: {
            data: ['$transition$', 'tenantSettingsService', 'mediatorService', 'CONSTANTS', ($transition$, tenantSettingsService, mediatorService, CONSTANTS) => {
                const dataPromise = tenantSettingsService.getSupportLinks();

                // By registering an onSuccess hook with a slightly lower priority, our hook will be invoked after the view synchronizing hook completes
                $transition$.onSuccess({}, () => {
                    // Dispatch an event to show the spinner while data is loading
                    mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, true);

                    // Instantiate Vue app and pass the data promise to it
                    createVueApp(dataPromise).then(() => {
                        // Once the promise is resolved, we can dispatch an event to hide the spinner
                        dataPromise.then(() => {
                            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.DATA_LOADING, false);
                            mediatorService.dispatch(CONSTANTS.EVENTS.GENERIC.CONTEXT_TITLE, ['current']);
                        });
                    });
                }, {
                    priority: -1 // Angular UI-Router executes internal view synchronizing onSuccess hook with priority 0
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: `<div id="support"
                                class="col-12 flex flex-column items-center"
                                role="main">
                                    <evo-support :data-promise="dataPromise"></evo-support>
                            </div>`
            }
        }
    });
}
