angular.module('everon.users.customers')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.users.customers', {
        url: '/customers?query&sort&page&size',
        params: {
            sort: {
                value: 'name,asc',
                squash: true
            },
            page: {
                value: 0,
                type: 'page',
                squash: true
            },
            size: {
                value: 30,
                type: 'int',
                squash: true
            },
            highlightItem: null
        },
        dynamic: true,
        data: {
            id: 'customers-navigation-link',
            title: 'generic.entity.customers',
            navigationType: ['secondary'],
            menuPriority: 1,
            permissions: ['CUSTOMER:READ_ALL'],
            userType: 'customers'
        },
        resolve: {
            data: ['$transition$', 'userService', ($transition$, userService) => {
                const params = _.pick($transition$.params('to'), ['query', 'sort', 'page', 'size']);

                return userService.getUsers(_.assign({type: 'customers'}, params));
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'main@auth': {
                template: '<evcloud-users data-data-promise="::$resolve.data" class="flex-auto flex flex-column col-12" role="main" />'
            }
        }
    });
}
