angular.module('everon')
       .factory('utils', utils);

utils.$inject = ['$window', '$q', 'moment', 'CONSTANTS'];

function utils($window, $q, moment, CONSTANTS) {
    /**
     * Creates new selection range
     * @param {Element} element
     * @returns {Object}
     */
    function getSelection(element) {
        const range = document.createRange(); // Create new range object

        range.selectNodeContents(element); // Set range to encompass desired element text

        const selection = document.getSelection(); // Get Selection object from currently user selected text

        selection.removeAllRanges(); // Unselect any user selected text (if any)
        selection.addRange(range); // Add range to Selection object to select it

        return selection;
    }

    return {
        /**
         * Returns moment instance
         * @param {Date} [date]
         * @returns {Object.<Moment>}
         */
        getMoment(date) {
            return date ? moment(date) : moment();
        },

        /**
         * Converts UTC date string to local Date object
         * @param {string} utcDate
         * @returns {Object.<Moment>}
         */
        toLocalMoment(utcDate) {
            return moment.utc(utcDate).local();
        },

        toUtcMoment(localDate) {
            return moment(localDate).utc();
        },

        /**
         * Converts local date to UTC date format
         * @param {Date} localDate JS date instance
         * @returns {string}
         */
        toUtcDateFormat(localDate) {
            return this.toUtcMoment(localDate).format();
        },

        /**
         * Converts local JS Dates to UTC date format for the server
         * @param {Object} dates
         * @returns {Object}
         */
        localDatesToUTC(dates) {
            return _.mapValues(dates, date => this.toUtcDateFormat(date));
        },

        /**
         * Returns humanised datetime, e.g. `2 days`, `a minute`, `4 months`, etc.
         * @param {number} duration Duration in milliseconds
         * @returns {string}
         */
        humaniseDuration(duration) {
            return moment.duration(duration).humanize();
        },

        /**
         * Displays datetime in relative format if number of days from now is less than 8, else returns local Date instance
         * @param {string} utcDate
         * @param {number} [days=7] Number of days to show relative time for
         * @returns {string|Date}
         */
        toRelativeTime(utcDate, days) {
            const localMoment = this.toLocalMoment(utcDate);
            const diff = moment().diff(localMoment);
            const daysFromNow = moment.duration(diff).get('days');

            return daysFromNow > (days || 7) ? localMoment.toDate() : localMoment.fromNow();
        },

        /**
         * Adds UTC offset to local date and converts to UTC date string
         * @param {Date} localDate
         * @returns {string}
         */
        toUtcDateWithOffset(localDate) {
            const clonedMoment = this.toUtcMoment(localDate).clone();

            return clonedMoment.add(moment(localDate).utcOffset(), 'minute').format();
        },

        /**
         * Removes UTC offset and converts UTC to local date
         * @param {string} utcDate
         * @returns {Date}
         */
        toLocalDateWithOffset(utcDate) {
            const clonedMoment = this.toUtcMoment(utcDate).clone();

            return clonedMoment.subtract(moment(utcDate).utcOffset(), 'minute').local();
        },

        /**
         * Formats duration. If it's more than 1 day (86400000), humanised duration is shown
         * @param {number} duration
         * @param {string} format
         * @returns {string}
         */
        formatDuration(duration, format) {
            const oneDay = 24 * 60 * 60 * 1000;

            return duration < oneDay ? moment(moment.duration(duration)._data).format(format) : this.humaniseDuration(duration);
        },

        /**
         * Generates a human readable UTC offset from offset in milliseconds
         * @param {number} offset
         * @returns {string}
         */
        generateHumanReadableUtcOffset(offset) {
            const offsetInMinutes = Math.abs(offset) / CONSTANTS.TIMES.MILLISECONDS_PER_MINUTE;
            const hours = Math.floor(offsetInMinutes / CONSTANTS.TIMES.MINUTES_PER_HOUR);
            const minutes = this.padStart(offsetInMinutes % CONSTANTS.TIMES.MINUTES_PER_HOUR);
            const symbol = offset < 0 ? '-' : '+';

            return `UTC${symbol}${hours}:${minutes}`;
        },

        /**
         * Converts a number to a string and pads it with leading zero if necessary
         * @param {number} number
         * @returns {string}
         */
        padStart(number) {
            return (`0${number}`).slice(-2);
        },

        /**
         * Encodes a string in base64. First we use encodeURIComponent to get percent-encoded UTF-8, then we convert the percent encodings into raw bytes which can be fed into btoa
         * @param {string} string
         * @returns {string}
         */
        base64EncodeUnicode(string) {
            return btoa(encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, (match, param) => String.fromCharCode(`0x${param}`)));
        },

        /**
         * Rounds numbers to the specified decimal point using exponential notation
         * @param {number} value
         * @param {number} decimals
         * @returns {number}
         */
        roundToDecimalPrecision(value, decimals) {
            return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
        },

        /**
         * Opens provided URL in a new tab/window
         * @param {string} url
         */
        openExternalLink(url) {
            const link = $window.document.createElement('a');

            link.href = url;
            link.rel = 'noopener';
            link.target = '_blank';

            const isSuccessFull = link.dispatchEvent(new MouseEvent('click'));

            if (!isSuccessFull) {
                throw new Error(`Failed to open external link ${url}`);
            }
        },

        /**
         * Copies selection to clipboard
         * @param {string} elementId
         */
        copyToClipboard(elementId) {
            const containerElement = document.getElementById(elementId);
            const selection = getSelection(containerElement);

            document.execCommand('copy');
            selection.removeAllRanges(); // Remove selection
        },

        /**
         * Generates password placeholder as given length
         * @param {number} length
         * @returns {string}
         */
        generatePasswordPlaceholder(length) {
            return '•'.repeat(length);
        },

        /**
         * Returns visually formatted json for valid json-string input or input itself otherwise
         * @param {string} jsonStr
         * @returns {string}
         */
        formatJSON(jsonStr) {
            try {
                const json = JSON.parse(jsonStr);

                return JSON.stringify(json, null, 2);
            } catch (e) {
                return jsonStr;
            }
        },

        /**
         * Returns new date which set to the last minute of the given date
         * @param {Date} date
         * @returns {Date}
         */
        endOfTheDay(date = new Date()) {
            return moment(date).endOf('day').toDate();
        },

        /**
         * Returns new date which set to the first minute of the given date
         * @param {Date} date
         * @returns {Date}
         */
        startOfTheDay(date = new Date()) {
            return moment(date).startOf('day').toDate();
        },

        /**
         * Returns new date which is one month after given date but isn't later than today
         * @param {Date} startDate
         * @returns {Date}
         */
        monthAfterDateOrToday(startDate) {
            const today = moment();
            const monthAfterDate = moment(startDate).add(1, 'month');

            return this.endOfTheDay(monthAfterDate > today ? today : monthAfterDate);
        },

        /**
         * Returns new date which is one month before given date
         * @param {Date} date
         * @returns {Date}
         */
        monthBeforeDate(date) {
            return moment(date).subtract(1, 'month').startOf('day').toDate();
        },

        /**
         * Returns range difference by given two dates in date type (day, month, year)
         * @param {Date} firstDate
         * @param {Date} secondDate
         * @param {string} diffType
         * @returns {number}
         */
        getRangeDifference(firstDate, secondDate, diffType) {
            return this.getMoment(secondDate)
                       .subtract(1, 'day')
                       .diff(firstDate, diffType);
        },

        /**
         * Returns a date range - from beginning of last given days to midnight of today (tomorrow at 0:00 hours)
         * @param {number} rangeInDays
         * @returns {Object}
         */
        getDateRangeFromLastGivenDays(rangeInDays) {
            const endDate = this.getMoment().endOf('day').toDate();
            const startDate = this.getMoment(endDate).subtract(rangeInDays, 'day').startOf('day').toDate();

            return {startDate, endDate};
        }
    };
}
