angular.module('everon.accounts.account.reimbursement')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.accounts.account.reimbursement', {
        url: '/reimbursement',
        data: {
            id: 'account-reimbursement-navigation-link',
            title: 'account.reimbursement.title',
            navigationType: ['context'],
            menuPriority: 3,
            permissions: ['ACCOUNT:UPDATE_REIMBURSEMENT_RATE']
        },
        resolve: {
            data: ['$q', '$transition$', 'accountOwner', 'accountFormModel', 'accountService', 'countryService',
             ($q, $transition$, accountOwner, accountFormModel, accountService, countryService) => {
                const {id} = $transition$.params('to');

                return $q.all({
                    accountOwner,
                    reimbursementFormModel: accountFormModel.reimbursement,
                    reimbursement: accountService.getReimbursementSettings(id),
                    billingOverview: accountService.getBillingOverview(id),
                    countryCurrencies: countryService.getTenantCountryCurrencies(),
                    accountId: id
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.accounts.account': {
                component: 'evcloudAccountReimbursement',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
