angular.module('everon.account.advenir')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.account.advenir', {
        url: '/advenir',
        data: {
            id: 'account-advenir-navigation-link',
            title: 'account.advenir.title',
            navigationType: ['context'],
            features: ['advenir'],
            menuPriority: 4
        },
        resolve: {
            data: ['$q', 'accountFormModel', 'accountService', ($q, accountFormModel, accountService) => {
                return $q.all({
                    advenirFormModel: accountFormModel.advenir,
                    advenir: accountService.getAdvenirSettings()
                });
            }]
        },
        resolvePolicy: {
            data: {
                when: 'LAZY',
                async: 'NOWAIT'
            }
        },
        views: {
            'content@auth.account': {
                component: 'evcloudAccountAdvenir',
                bindings: {
                    dataPromise: 'data'
                }
            }
        }
    });
}
