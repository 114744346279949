angular.module('everon.account')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.account', {
        url: '/account',
        redirectTo: 'auth.account.billing-information',
        data: {
            id: 'account-navigation-link',
            title: 'generic.entity.account',
            navigationType: ['user'],
            menuPriority: 7,
            permissions: ['ACCOUNT:UPDATE', 'ACCOUNT:READ_PERSONAL_INFO']
        },
        views: {
            'main@auth': {
                templateUrl: 'modules/account/account.html'
            },
            'contextNav@auth.account': {
                template: '<evcloud-nav-list filter="{navigationType: \'context\', parent: \'auth.account\'}"  />'
            }
        }
    });
}

