angular.module('everon.stations.sites.site')
       .config(stateConfig);

stateConfig.$inject = ['$stateProvider'];

function stateConfig($stateProvider) {
    $stateProvider.state('auth.stations.sites.site', {
        url: '/:id',
        redirectTo: 'auth.stations.sites.site.details',
        data: {
            title: 'generic.entity.site'
        },
        views: {
            'main@auth': {
                template: '<evcloud-site class="col-12 flex flex-column items-center"/>'
            },
            'contextNav@auth.stations.sites.site': {
                template: '<evcloud-nav-list filter="{navigationType: \'context\', parent: \'auth.stations.sites.site\'}"  />'
            }
        }
    });
}
